/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import React, { useMemo } from 'react'
import {
  PicOSComplianceTableReport,
  DIVISION_REPORT_TABLE_COLUMNS,
  DAITableRow,
  DivisionReportRow,
  RankingChangeOverTime,
} from '@dai/web-components'
import { useParams } from 'react-router-dom'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import PicOSComplianceLinearProgress from 'picos/components/PicOSComplianceLinearProgress'
import PicOSCellActionButton from 'picos/components/PicOSCellActionButton'
import useDivisionComplianceReport from 'picos/hooks/use-division-compliance-report'

const PicOSDivisionReport: React.FC = () => {
  const routeParams = useParams<{
    customerId: string
    divisionId: string
  }>()
  const {
    data: { title, complianceResults, statItems },
    state: { loadingCompliance, loadingStats },
    handlers: { handleSearch, handleSelect, goToPage },
  } = useDivisionComplianceReport(routeParams)

  const rows: DAITableRow<DivisionReportRow>[] = useMemo(
    () =>
      complianceResults?.map(row => ({
        id: row.id,
        name: row.name,
        compliance: (
          <PicOSComplianceLinearProgress
            numCompliant={row.numCompliant}
            totalPriorities={row.totalPriorities}
          />
        ),
        storesSurveyed: row.numStoresSurveyed,
        totalPriorities: row.totalPriorities,
        rank: (
          <RankingChangeOverTime
            rank={row.rank}
            previousRank={row.previousRank}
          />
        ),
        action: (
          <PicOSCellActionButton
            label="View Sales Center"
            onClick={() => handleSelect(row)}
          />
        ),
      })),
    [complianceResults],
  )

  return (
    <PicOSComplianceTableReport<DivisionReportRow>
      title={title}
      columns={DIVISION_REPORT_TABLE_COLUMNS}
      rows={rows}
      onSearch={handleSearch}
      onClickExportCsv={() => null}
      loadingComplianceItems={loadingCompliance}
      onPageChange={(page, _) => goToPage(page)}
      loadingStats={loadingStats}
      statItems={statItems}
    />
  )
}

export default PicOSDivisionReport
