"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WHOLE_DISPLAY_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var CubeDimensions_1 = require("./CubeDimensions");
exports.WHOLE_DISPLAY_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment WholeDisplay on Display {\n    id\n    qrId\n    name\n    shortDescription\n    displayType\n    extraInfo\n    saved\n    isCompetitor\n    salesVolume\n    images {\n      id\n      url\n    }\n    area\n    numCoolerDoors\n    doorHandlePlacement\n    idealInventory {\n      upcs\n    }\n    dateRemoved\n    point {\n      latitude\n      longitude\n    }\n    boundingCubes {\n      ...CubeDimensions\n    }\n  }\n\n  ", "\n"], ["\n  fragment WholeDisplay on Display {\n    id\n    qrId\n    name\n    shortDescription\n    displayType\n    extraInfo\n    saved\n    isCompetitor\n    salesVolume\n    images {\n      id\n      url\n    }\n    area\n    numCoolerDoors\n    doorHandlePlacement\n    idealInventory {\n      upcs\n    }\n    dateRemoved\n    point {\n      latitude\n      longitude\n    }\n    boundingCubes {\n      ...CubeDimensions\n    }\n  }\n\n  ", "\n"])), CubeDimensions_1.CUBE_DIMENSIONS);
var templateObject_1;
