/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportDataType,
  GenericReportHook,
  GenericReportProps,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { FILL_GRID_ALL_SCREENS } from '@dai/web-components'
import { useQuery } from '@apollo/client'
import {
  AggregateBy,
  AggregationMethod,
  GET_DISPLAY_SCANS,
  GET_PRODUCT_COUNTED,
  GetDisplayScansQuery,
  GetDisplayScansQueryVariables,
  GetProductCountedQuery,
  GetProductCountedQueryVariables,
} from '@dai/graphql'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'hooks/use-local-storage'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'
import { useReportDisplay } from 'users/hooks/reporting/use-report-display'

export const useActivityOverviewReports: GenericReportHook<UserProductivityReportName> = () => {
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')

  const useGetUserActivityTable = (
    reportParams: UserProductivityReportInput,
  ) => {
    const numUsers = Object.keys(companyUsers || {}).length
    const isSingleUser =
      !!reportParams.userUuids.length && !reportParams.managerUuid
    const ReportDisplay = useReportDisplay<
      UserProductivityReportName,
      GenericReportProps<UserProductivityReportName>
    >()
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const {
      getProductProductivityQueryInput,
      getUserProductivityQueryInput,
      formatCompositeIdRow,
    } = useReportQuery()
    const [data, setData] = useState<GenericReportDataType[]>([])
    const { data: allCounts, loading: countedLoading } = useQuery<
      GetProductCountedQuery,
      GetProductCountedQueryVariables
    >(GET_PRODUCT_COUNTED, {
      variables: {
        input: getProductProductivityQueryInput(
          AggregationMethod.SUM,
          AggregateBy.ALL_TIME,
        ),
      },
    })
    const { data: userRestocks, loading: restocksLoading } = useQuery<
      GetDisplayScansQuery,
      GetDisplayScansQueryVariables
    >(GET_DISPLAY_SCANS, {
      variables: {
        input: getUserProductivityQueryInput(
          AggregationMethod.COUNT,
          AggregateBy.ALL_TIME,
        ),
      },
    })
    useEffect(() => {
      if (
        allCounts &&
        userRestocks &&
        reportParams.selectedReportTab === 'overview'
      ) {
        let data: GenericReportDataType[]
        if (isSingleUser) {
          data = userRestocks.displayScans.results
            .map(res =>
              formatCompositeIdRow(res, res => ({
                group: res.compositeId!,
                value: res.count.toLocaleString('en-us'),
                category: 'restocks',
              })),
            )
            .flat()
        } else {
          const counts = allCounts.productCounted.results.map(res => ({
            group: res.refId,
            value: res.count.toLocaleString('en-us'),
            category: 'product-counted',
          }))
          const restocks = userRestocks.displayScans.results.map(res => ({
            group: res.refId,
            value: res.count.toLocaleString('en-us'),
            category: 'restocks',
          }))
          const desiredData = ReportDisplay.filterData(reportParams.userType, [
            ...counts,
            ...restocks,
          ])
          const users = Object.entries(companyUsers || {})
            .filter(([userId, _]) =>
              desiredData.find(datum => datum.group === userId),
            )
            .map(([userId, user]) => ({
              group: userId,
              value: user.name,
              category: reportParams.allTeams ? 'Manager' : 'User Name',
            }))
          data = [...users, ...desiredData]
        }
        setData(data)
      }
    }, [
      userRestocks?.displayScans.results.length,
      allCounts?.productCounted.results.length,
      reportParams.selectedReportTab,
      reportParams.reportType,
      reportParams.userType,
      numUsers,
    ])

    return {
      data,
      isEmpty: !data.length,
      isLoading: restocksLoading || countedLoading,
    }
  }

  return [
    {
      reportName: 'overview',
      useGetData: useGetUserActivityTable,
      gridProps: FILL_GRID_ALL_SCREENS,
      type: 'table',
      title: '',
      chartId: 'user-activity-table',
    },
  ]
}
