/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useEffect, useState } from 'react'
import {
  FILL_GRID_ALL_SCREENS,
  DAIFormState,
  MuiFormTypes,
} from '@dai/web-components'
import { ForStoreSize, AuditQuestionFragment } from '@dai/graphql'
import StoreInspectionHelpers from 'store-inspection/helpers/store-inspection.helpers'

interface AuditQuestionForm extends DAIFormState<'text' | 'select' | 'radio'> {
  text: {
    QUESTION: MuiFormTypes['text']
    POINTS: MuiFormTypes['text']
  }
  select: {
    CATEGORY: MuiFormTypes['select']
    GROUP: MuiFormTypes['select']
  }
  radio: {
    ACTIVE: MuiFormTypes['radio']
  }
}

export const useCreateOrEditAuditQuestionForm = ({
  groups,
  question,
}: {
  groups: string[]
  question?: AuditQuestionFragment | null
}) => {
  const CREATE_AUDIT_QUESTION_FORM: AuditQuestionForm = {
    text: {
      QUESTION: {
        validationFn: value => ({
          error: !value || !value.length || value.includes('?'),
          help:
            !value || !value.length
              ? 'Please include a question'
              : value.includes('?')
              ? 'Please remove the "?" from the question'
              : undefined,
        }),
        value: question ? question.question : '',
        input: {
          placeholder: 'The next big important question',
          required: true,
          type: 'text',
          multiline: true,
          variant: 'filled',
          label: 'Question',
        },
        gridProps: FILL_GRID_ALL_SCREENS,
      },
      POINTS: {
        validationFn: value => ({
          error: !value || !value.length || isNaN(Number(value)),
          help:
            !value || !value.length || isNaN(Number(value))
              ? 'A valid number is required'
              : undefined,
        }),
        value: question ? question.pointWorth : '',
        input: {
          variant: 'filled',
          label: 'Points',
          placeholder: 'Type the question point worth',
          required: true,
        },
        gridProps: FILL_GRID_ALL_SCREENS,
      },
    },
    select: {
      CATEGORY: {
        value: question
          ? [
              StoreInspectionHelpers.auditQuestionCategoryToForStoreSize(
                question.category,
              ),
            ]
          : ForStoreSize.ALL,
        label: 'Store Type',
        variant: 'filled',
        input: {
          placeholder: '',
          required: true,
        },
        options: [
          { value: ForStoreSize.ALL, label: 'All' },
          { value: ForStoreSize.LARGE, label: 'Large' },
          { value: ForStoreSize.SMALL, label: 'Small' },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
      },
      GROUP: {
        value: question ? question.group : '',
        label: 'Store Type',
        variant: 'filled',
        input: {
          placeholder: 'Select the group the question should be in',
          required: true,
        },
        options: groups.map(g => ({ value: g, label: g })),
        gridProps: FILL_GRID_ALL_SCREENS,
      },
    },
    radio: {
      ACTIVE: {
        value: question && question.active ? 'Yes' : 'No',
        label: 'Show question on survey',
        required: true,
        group: {},
        options: [
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No',
          },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
      },
    },
  }
  const [formState, setFormState] = useState<AuditQuestionForm>(
    CREATE_AUDIT_QUESTION_FORM,
  )

  const resetForm = () => setFormState(CREATE_AUDIT_QUESTION_FORM)

  useEffect(() => {
    setFormState(CREATE_AUDIT_QUESTION_FORM)
  }, [question])

  return {
    formState,
    setFormState,
    resetForm,
  }
}
