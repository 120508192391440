/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  AddressBlock,
  PicOSComplianceTableReport,
  PicOSPriorityDetails,
  PriorityStoreReportRow,
  PRIORITY_STORE_TABLE_COLUMNS,
} from '@dai/web-components'
import usePriorityComplianceReport from 'picos/hooks/use-priority-compliance-report'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const PicOSPriorityComplianceReport: React.FC = () => {
  const routeParams = useParams<{
    priorityId: string
  }>()

  const {
    data: { title, priority, complianceResults, statItems },
    state: { limit, loadingCompliance, loadingStats },
    handlers: { handleSearch, goToPage },
  } = usePriorityComplianceReport(routeParams)

  const rows: PriorityStoreReportRow[] = useMemo(
    () =>
      complianceResults.map(row => ({
        id: row.id,
        name: row.storeName,
        address: <AddressBlock address={row.address} />,
        compliant: row.isCompliant,
        cost: `$${row.cost}`,
      })),
    [complianceResults],
  )

  const titleComponent = useMemo(() => {
    if (priority) {
      return <PicOSPriorityDetails {...priority} compact={false} />
    }
    return null
  }, [priority])

  return (
    <PicOSComplianceTableReport<PriorityStoreReportRow>
      title={title}
      titleComponent={titleComponent}
      columns={PRIORITY_STORE_TABLE_COLUMNS}
      rows={rows}
      onSearch={handleSearch}
      onClickExportCsv={() => null}
      loadingComplianceItems={loadingCompliance}
      pageSize={limit}
      onPageChange={(page, _) => goToPage(page)}
      loadingStats={loadingStats}
      statItems={statItems}
    />
  )
}

export default PicOSPriorityComplianceReport
