/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */

import { DAIGroupEnum } from '@dai/graphql'
import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {
  Person,
  ShoppingCart,
  Storefront,
  Quiz,
  FactCheck,
  Summarize,
  ViewQuilt,
  Insights,
  BubbleChartOutlined,
  Edit,
  HomeRepairService,
  TableChart,
  GroupOutlined,
  BookOutlined,
  Category,
} from '@mui/icons-material'
import { UserProvider } from 'context/UserContext'
import { hasPermission, PICOS_USERS } from '@dai/common'
import { NavigationDrawer } from '@dai/web-components'
import { DAIAdminRoute } from 'routes/routes.types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useLoggedInUser } from 'hooks/auth/use-logged-in-user'
import Routes from './routes/Routes'
import LoginContainer from './containers/LoginContainer'

const App: React.FC = () => {
  const match = useRouteMatch({ path: '/', exact: true, strict: true })
  const onLogin = useRouteMatch({ path: '/login', exact: true, strict: true })
  const {
    state: userInfo,
    handle: { handleLogout },
  } = useLoggedInUser()

  const isSalesNotRed =
    !(
      userInfo.user?.roles.includes(DAIGroupEnum.RED_ADMINS) ||
      userInfo.user?.roles.includes(DAIGroupEnum.RED_MANAGERS)
    ) &&
    (userInfo.user?.roles.includes(DAIGroupEnum.SALES_ADMINS) ||
      userInfo.user?.roles.includes(DAIGroupEnum.SALES_MANAGERS))

  const location = useLocation<{ prev: string }>()

  if (!onLogin && !userInfo.user && !userInfo.loading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { prev: location.pathname },
        }}
      />
    )
  }
  if (match && userInfo.user && !userInfo.loading) {
    if (location.state?.prev) {
      return <Redirect to={location.state?.prev} />
    }
    return <Redirect to="/users" />
  }

  return (
    <UserProvider value={userInfo}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Switch>
          <Route
            path="/login"
            component={LoginContainer}
            state={{ prev: location.pathname }}
          />
          <Route path="/">
            <NavigationDrawer<DAIAdminRoute>
              logout={handleLogout}
              user={userInfo.user}
              navItems={[
                {
                  label: 'Users',
                  route: 'Users',
                  icon: <Person color="secondary" />,
                  hide: !userInfo.user?.isSuperuser && isSalesNotRed,
                },
                {
                  label: 'Reporting',
                  route: 'Reporting/Brands',
                  hide:
                    !userInfo.user?.isSuperuser &&
                    !userInfo.user?.roles.includes(DAIGroupEnum.REPORT_VIEWERS),
                  icon: <Insights color="secondary" />,
                  more: [
                    {
                      label: 'Brands',
                      hide: !userInfo.user?.roles.includes(
                        DAIGroupEnum.REPORT_VIEWERS,
                      ),
                      icon: <BubbleChartOutlined color="secondary" />,
                      route: 'Reporting/Brands',
                    },
                    {
                      label: 'Users',
                      hide: !userInfo.user?.isSuperuser,
                      icon: <GroupOutlined color="secondary" />,
                      route: 'Reporting/Users',
                    },
                  ],
                },
                {
                  label: 'Products',
                  route: 'Products',
                  icon: <ShoppingCart color="secondary" />,
                  hide: !userInfo.user?.isSuperuser && isSalesNotRed,
                },
                {
                  label: 'Categories',
                  route: 'Categories',
                  icon: <Category color="secondary" />,
                  hide: !userInfo.user?.isSuperuser,
                },
                {
                  label: 'Stores',
                  route: 'Stores/Management',
                  more: [
                    {
                      label: 'Hierarchy',
                      icon: <Storefront color="secondary" />,
                      route: 'Stores/Hierarchy',
                    },
                  ],
                  icon: <Storefront color="secondary" />,
                },
                {
                  label: 'Trainings',
                  route: 'Trainings',
                  hide: !userInfo.user?.isSuperuser,
                  icon: <BookOutlined color="secondary" />,
                },
                {
                  label: 'Planograms',
                  route: 'Planograms',
                  icon: <ViewQuilt color="secondary" />,
                  hide: !userInfo.user?.isSuperuser && isSalesNotRed,
                },
                {
                  label: 'Questions',
                  route: 'Questions',
                  icon: <Quiz color="secondary" />,
                  hide: !userInfo.user?.isSuperuser && isSalesNotRed,
                },
                {
                  label: 'Audits',
                  route: 'Audits',
                  icon: <FactCheck color="secondary" />,
                  hide: !userInfo.user?.isSuperuser && isSalesNotRed,
                },
                {
                  label: 'PicOS',
                  route: 'PicOS/Priorities',
                  icon: <Summarize color="secondary" />,
                  hide: !(
                    userInfo.user?.isSuperuser ||
                    hasPermission(userInfo.user, PICOS_USERS)
                  ),
                  more: [
                    {
                      label: 'PicOS Priorities',
                      icon: <Edit color="secondary" />,
                      route: 'PicOS/Priorities',
                    },
                    {
                      label: 'Reports',
                      icon: <TableChart color="secondary" />,
                      route: 'PicOS/Reports',
                    },
                  ],
                },
                {
                  label: 'Restock Trouble',
                  route: 'display-restock-troubleshooting',
                  icon: <HomeRepairService color="secondary" />,
                  hide: !userInfo.user?.isSuperuser,
                },
              ]}
            >
              <Routes />
            </NavigationDrawer>
          </Route>
        </Switch>
      </LocalizationProvider>
    </UserProvider>
  )
}

export default App
