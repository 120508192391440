/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DAIGroupEnum } from '@dai/graphql'

export type SimpleUserRow = {
  id: string
  name: string
  email: string
  role: string
}

export type UserRow = {
  numStores: number
  active: boolean
  manager: string
} & SimpleUserRow

export type UserProductivityReportInput = {
  reportLevel: string
  reportType: string
  userType: DAIGroupEnum
  startDate: string
  endDate: string
  searchTerm: string
  summaryTimeFrame: 'All-Time' | '30 Day Summary'
  userUuids: string[]
  managerUuid?: string
  allUsers?: boolean
  allTeams?: boolean
  selectedReportTab: UserProductivityTabType
}

export enum UserProductivityReportType {
  ACTIVITY = 'activity',
  QUALITY = 'quality',
}

export type UserProductivityTabType = 'overview' | 'leaderboard' | 'history'
