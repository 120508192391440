"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEGMENT_COMPLIANCE_GROUP_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ShelfComplianceGroupFragment_1 = require("./ShelfComplianceGroupFragment");
exports.SEGMENT_COMPLIANCE_GROUP_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment SegmentComplianceGroup on SegmentComplianceGroup {\n    id\n    segment {\n      segmentNumber\n    }\n    scanComplete\n    strictScore\n    sequenceScore\n    shelfCompliance {\n      ...ShelfComplianceGroup\n    }\n  }\n  ", "\n"], ["\n  fragment SegmentComplianceGroup on SegmentComplianceGroup {\n    id\n    segment {\n      segmentNumber\n    }\n    scanComplete\n    strictScore\n    sequenceScore\n    shelfCompliance {\n      ...ShelfComplianceGroup\n    }\n  }\n  ", "\n"])), ShelfComplianceGroupFragment_1.SHELF_COMPLIANCE_GROUP_FRAGMENT);
var templateObject_1;
