/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { LinearProgressWithThresholds } from '@dai/web-components'
import React from 'react'

interface PicOSComplianceLinearProgressProps {
  numCompliant: number
  totalPriorities: number
  width?: string | number
}

const PicOSComplianceLinearProgress: React.FC<PicOSComplianceLinearProgressProps> = props => {
  const { numCompliant, totalPriorities, width = '80%' } = props
  const compliancePercentage =
    (numCompliant / Math.max(totalPriorities, 1)) * 100
  return (
    <LinearProgressWithThresholds
      value={compliancePercentage}
      label="Compliant"
      sx={{ width }}
    />
  )
}

export default PicOSComplianceLinearProgress
