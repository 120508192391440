/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  PageTitle,
  SearchBar,
  DAITable,
  FullScreenLayout,
  Filter,
} from '@dai/web-components'
import { Build, Feedback, Flag } from '@mui/icons-material'
import { usePlanogramFeedbackLogic } from 'planograms/containers/PlanogramFeedback.logic'
import { ViewPlanogramFeedbackModal } from 'planograms/components/modal/ViewPlanogramFeedback.modal'

// <PlanogramFilter filter={filter} setFilter={setFilter} />

const PlanogramFeedbackContainer: React.FC = () => {
  const {
    state: {
      showViewPlanogramFeedbackImage,
      setShowViewPlanogramFeedbackImage,
      setPlanogramFeedbackSearch,
      selectedIds,
      setSelectedIds,
      FilterProps,
    },
    ref: { tableRef },
    data: {
      planogramRows,
      allSelectedProductsResolved,
      columns,
      PAGE_SIZE,
      count,
      loading,
    },
    handle: { handleMarkResolved, handleMarkUnresolved },
  } = usePlanogramFeedbackLogic()
  return (
    <>
      <FullScreenLayout
        bodyStyle={{ p: 0 }}
        header={<PageTitle title="Planogram Feedback" />}
        body={
          <DAITable
            ref={tableRef}
            rows={planogramRows}
            tableToolBarProps={{
              tableTitle: 'Planogram Segments',
              right: {
                others: [
                  <SearchBar
                    useResponsive
                    onChange={value => setPlanogramFeedbackSearch(value)}
                  />,
                  <Filter
                    filterTitle="Filter Planogram Issues"
                    {...FilterProps}
                  />,
                ],
              },
            }}
            columns={columns}
            pageSize={PAGE_SIZE}
            rowCount={count}
            defaultOrderBy="store"
            loading={loading}
            onSelectionChange={setSelectedIds}
            bulkActions={[
              {
                condition: allSelectedProductsResolved,
                onClick: handleMarkUnresolved,
                icon: <Flag color="secondary" />,
                label: 'Flag',
              },
              {
                condition: !allSelectedProductsResolved,
                onClick: handleMarkResolved,
                icon: <Build color="secondary" />,
                label: 'Resolve',
              },
              {
                condition: selectedIds.length === 1,
                onClick: () => setShowViewPlanogramFeedbackImage(true),
                icon: <Feedback color="secondary" />,
                label: 'Feedback',
              },
            ]}
          />
        }
      />
      <ViewPlanogramFeedbackModal
        isOpen={showViewPlanogramFeedbackImage}
        handleClose={() => setShowViewPlanogramFeedbackImage(false)}
        planogramSectionId={selectedIds[0] || ''}
      />
    </>
  )
}

export default PlanogramFeedbackContainer
