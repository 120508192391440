"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_OR_EDIT_STORE_TASK = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.CREATE_OR_EDIT_STORE_TASK = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CreateOrEditStoreTask($input: CreateOrEditTaskInput!) {\n    createOrEditTask(input: $input) {\n      task {\n        __typename\n        description\n        store {\n          name\n        }\n        createdBy {\n          email\n        }\n        progress {\n          status\n          user {\n            email\n          }\n        }\n        ... on DisplayTask {\n          ...Task\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation CreateOrEditStoreTask($input: CreateOrEditTaskInput!) {\n    createOrEditTask(input: $input) {\n      task {\n        __typename\n        description\n        store {\n          name\n        }\n        createdBy {\n          email\n        }\n        progress {\n          status\n          user {\n            email\n          }\n        }\n        ... on DisplayTask {\n          ...Task\n        }\n      }\n    }\n  }\n  ", "\n"])), fragments_1.TASK_FRAGMENT);
var templateObject_1;
