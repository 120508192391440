/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  BaseFilterAttributes,
  MuiFormHelpers,
  StringHelpers,
  useCommonFilter,
} from '@dai/web-components'
import { useEffect, useState } from 'react'
import {
  UserProductivityReportInput,
  UserProductivityReportType,
  UserProductivityTabType,
} from 'users/users.types'
import { useReportBuilder } from 'reporting/hooks/use-report-builder'
import { useReportSelectForm } from 'users/hooks/reporting/use-report-select-form'
import { useActivityLeaderboardReports } from 'users/hooks/reporting/activity/use-activity-leaderboard-reports'
import { useActivityReportSummaries } from 'users/hooks/reporting/activity/use-activity-report-summaries'
import { useActivityOverviewReports } from 'users/hooks/reporting/activity/use-activity-overview-reports'
import { useActivityHistoryReports } from 'users/hooks/reporting/activity/use-activity-history-reports'
import { useQualityReportSummaries } from 'users/hooks/reporting/quality/use-quality-report-summaries'
import { useQualityOverviewReports } from 'users/hooks/reporting/quality/use-quality-overview-reports'
import { useQualityLeaderboardReports } from 'users/hooks/reporting/quality/use-quality-leaderboard-reports'
import { useQualityHistoryReports } from 'users/hooks/reporting/quality/use-quality-history-reports'
import { useLocalStorage } from 'hooks/use-local-storage'
import { UserProductivityReportingConstants } from 'users/constants/reporting/user-productivity-reporting.constants'
import { useReportDisplay } from 'users/hooks/reporting/use-report-display'
import {
  GenericReportProps,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'

const formatSelectedReport = (
  reportType: string,
  tabType: UserProductivityTabType,
) => {
  const rt = StringHelpers.stringValue(reportType).toLowerCase()
  return `${rt}-${tabType}`
}

export const useUserProductivityLogic = () => {
  const { Storage } = useLocalStorage()
  const ReportDisplay = useReportDisplay<
    UserProductivityReportName,
    GenericReportProps<UserProductivityReportName>
  >()
  ReportDisplay.writeRules({
    AUDITORS: [
      'overview',
      'product-counted',
      'scan-facing-score',
      'identification-accuracy',
      'point-cloud-score',
    ],
    MERCHANDISERS: [
      'overview',
      'product-counted',
      'scan-facing-score',
      'identification-accuracy',
      'point-cloud-score',
      'recommendation-compliance-score',
      'restocks',
    ],
    SALES: [
      'overview',
      'product-counted',
      'recommendation-compliance-score',
      'scan-facing-score',
      'identification-accuracy',
      'point-cloud-score',
      'restocks',
    ],
  })
  const {
    reports,
    summaries,
    selectedReportGroup,
    setSelectedReportGroup,
  } = useReportBuilder({
    reportHooks: {
      [formatSelectedReport(
        UserProductivityReportType.ACTIVITY,
        'overview',
      )]: useActivityOverviewReports,
      [formatSelectedReport(
        UserProductivityReportType.ACTIVITY,
        'leaderboard',
      )]: useActivityLeaderboardReports,
      [formatSelectedReport(
        UserProductivityReportType.ACTIVITY,
        'history',
      )]: useActivityHistoryReports,
      [formatSelectedReport(
        UserProductivityReportType.QUALITY,
        'overview',
      )]: useQualityOverviewReports,
      [formatSelectedReport(
        UserProductivityReportType.QUALITY,
        'leaderboard',
      )]: useQualityLeaderboardReports,
      [formatSelectedReport(
        UserProductivityReportType.QUALITY,
        'history',
      )]: useQualityHistoryReports,
    },
    summaryHooks: {
      [UserProductivityReportType.ACTIVITY]: useActivityReportSummaries,
      [UserProductivityReportType.QUALITY]: useQualityReportSummaries,
    },
  })
  const { formState, setFormState, stateKey } = useReportSelectForm()
  const [reportSearchTerm, setReportSearchTerm] = useState<string>('')
  const [selectedSummaryTimeFrame, setSelectedSummaryTimeFrame] = useState<
    UserProductivityReportInput['summaryTimeFrame']
  >('All-Time')
  const [tableToDownload, setTableToDownload] = useState<{
    columns: { headerName: string }[]
    rows: Record<string, string | boolean>[]
  }>({ columns: [], rows: [] })
  const [
    selectedReportTab,
    setSelectedReportTab,
  ] = useState<UserProductivityTabType>('overview')
  const defaultFilter: BaseFilterAttributes = {
    filterBy: {
      selected: [],
      options: [],
      dateOptions: {
        startDate: '',
        endDate: '',
      },
    },
  }
  const FilterProps = useCommonFilter(defaultFilter)

  useEffect(() => {
    if (formState[stateKey].REPORT_TYPE) {
      setSelectedReportGroup(
        formatSelectedReport(
          formState[stateKey].REPORT_TYPE.value as string,
          selectedReportTab,
        ),
      )
    }
  }, [
    selectedReportTab,
    formState.select.REPORT_TYPE?.value,
    formState.radio.REPORT_TYPE?.value,
    stateKey,
  ])

  const reportTitle = formState[stateKey].REPORT_TYPE
    ? `${StringHelpers.stringValue(
        formState[stateKey].REPORT_TYPE.value as string,
      )} Report`
    : 'Loading...'

  const moreInfo = MuiFormHelpers.getOptionFromValue(
    formState.autocomplete.REPORT_LEVEL?.options || [],
    'formState.autocomplete.REPORT_LEVEL?.value as string',
  )
  const selectedUserOrTeam = (moreInfo && moreInfo.value) || ''
  const userInLocalStorage = Storage.getItem('companyUsers')?.[
    selectedUserOrTeam
  ]
  const managerUuid =
    userInLocalStorage && userInLocalStorage.subUsers.length
      ? selectedUserOrTeam
      : undefined
  const userUuids = userInLocalStorage
    ? !userInLocalStorage.subUsers.length
      ? [selectedUserOrTeam]
      : userInLocalStorage.subUsers
    : []
  const allUsers =
    moreInfo?.value === UserProductivityReportingConstants.ALL_USERS
  const allTeams =
    moreInfo?.value === UserProductivityReportingConstants.ALL_TEAMS
  const isLeaderboardNonManager =
    selectedReportTab === 'leaderboard' &&
    !(allTeams || allUsers || managerUuid)

  useEffect(() => {
    if (isLeaderboardNonManager) {
      setSelectedReportTab('overview')
    }
  }, [allUsers, allTeams, managerUuid, selectedReportTab])

  return {
    state: {
      formState,
      setFormState,
      stateKey,
      selectedReportTab: isLeaderboardNonManager
        ? 'overview'
        : selectedReportTab,
      setSelectedReportTab,
      selectedReportGroup,
      setSelectedReportGroup,
      FilterProps,
      selectedSummaryTimeFrame,
      setSelectedSummaryTimeFrame,
      reportSearchTerm,
      setReportSearchTerm,
      tableToDownload,
      setTableToDownload,
    },
    data: {
      reports,
      summaries,
      reportTitle,
      moreInfo,
      managerUuid,
      userUuids,
      allUsers,
      allTeams,
    },
  }
}
