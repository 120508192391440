/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import {
  MuiForm,
  MuiFormModal,
  MuiFormModalProps,
  useMuiForm,
  TableContainerClass,
  DAITable,
  DAITableColumns,
  MuiTheme,
} from '@dai/web-components'
import React, { useState } from 'react'
import {
  ArrowBack,
  Check,
  Close,
  ManageHistory,
  Save,
} from '@mui/icons-material'
import { useEditOptimizationsForm } from 'stores/hooks/edit-optimizations.form'
import {
  useGetStoreHistory,
  useUpdateOptimizationSchedule,
  DateTimeConstants,
} from '@dai/common'
import dayjs from 'dayjs'
import { AdminCompanyStoresInput } from '@dai/graphql'
import { StoreRow } from 'stores/stores.types'

interface EditOptimizationModalProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  stores: StoreRow[]
  companyStoresInput: AdminCompanyStoresInput
}

type StoreActionRow = {
  action: string
  actionCompletedOn: string
  email: string
  id: string
}

const columns: DAITableColumns<StoreActionRow>[] = [
  {
    field: 'action',
    headerName: 'Action',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  {
    field: 'email',
    headerName: 'User',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'actionCompletedOn',
    headerName: 'Date',
    width: MuiTheme.sizes.table.column.width.lg,
  },
]

const PAGE_SIZE = 20

export const EditOptimizationsModal: React.FC<EditOptimizationModalProps> = props => {
  const { isOpen, handleClose: close, stores, companyStoresInput } = props

  const storeIds = stores.map(store => store.id)
  const { formState, setFormState } = useEditOptimizationsForm({ stores })
  const {
    handle: { handleOnChange },
    data: { canSubmit },
  } = useMuiForm({ formState, setFormState })

  const [showHistory, setShowHistory] = useState(false)
  const [allStores, setAllStores] = useState(false)

  const handleClose = () => {
    close()
    setShowHistory(false)
    setAllStores(false)
  }

  const storeId = stores.length === 1 ? stores[0].id : ''

  const { actions, count, loading, fetchNextPage } = useGetStoreHistory({
    storeId,
  })

  const {
    UpdateOptimizationScheduleMutation,
    UpdateGlobalOptimizationScheduleMutation,
  } = useUpdateOptimizationSchedule(companyStoresInput)

  const valueToInput = (value?: string | string[]): number | undefined => {
    if (Array.isArray(value)) return undefined
    return !value || value.trim() === ''
      ? undefined
      : Number.parseInt(value, 10)
  }

  const handleSave = () => {
    const numTimesOptimizeHighPerYear = valueToInput(
      formState.select.highNumberOfOptimizations.value,
    )
    const numTimesOptimizeMediumPerYear = valueToInput(
      formState.select.mediumNumberOfOptimizations.value,
    )
    const numTimesOptimizeLowPerYear = valueToInput(
      formState.select.lowNumberOfOptimizations.value,
    )
    const timesPerWeekTwoDScan = valueToInput(
      formState.select.complianceScanFrequency.value,
    )

    if (allStores) {
      UpdateGlobalOptimizationScheduleMutation({
        input: {
          numTimesOptimizeHighPerYear,
          numTimesOptimizeMediumPerYear,
          numTimesOptimizeLowPerYear,
          timesPerWeekTwoDScan,
        },
      })
    } else {
      UpdateOptimizationScheduleMutation({
        input: {
          storeIds,
          numTimesOptimizeHighPerYear,
          numTimesOptimizeMediumPerYear,
          numTimesOptimizeLowPerYear,
          timesPerWeekTwoDScan,
        },
      })
    }

    handleClose()
  }

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= actions.length) {
      await fetchNextPage()
    }
  }

  return (
    <MuiFormModal
      title={
        showHistory
          ? 'History'
          : `Edit Optimization Schedule${
              storeIds.length > 1 ? ` (${storeIds.length} Stores)` : ``
            }`
      }
      subtitle={
        !showHistory
          ? undefined
          : '(Leaving a field blank will keep it the same for all selected stores)'
      }
      isOpen={isOpen}
      handleClose={handleClose}
      otherButtonsProps={[
        {
          hide: showHistory || storeIds.length > 1,
          onClick: () => setShowHistory(true),
          children: 'History',
          color: 'secondary',
          startIcon: <ManageHistory color="secondary" />,
        },
        {
          hide: showHistory,
          onClick: () => {
            setAllStores(!allStores)
          },
          children: allStores ? 'Only Selected Stores' : 'All Stores',
          color: 'primary',
          startIcon: allStores ? (
            <Close color="secondary" />
          ) : (
            <Check color="secondary" />
          ),
        },
      ]}
      actionButtonsProps={[
        {
          hide: showHistory,
          onClick: handleClose,
          children: 'Cancel',
          color: 'secondary',
          startIcon: <Close color="secondary" />,
        },
        {
          hide: showHistory,
          onClick: handleSave,
          children: 'Save',
          startIcon: <Save color="secondary" />,
          disabled: !canSubmit,
        },
        {
          hide: !showHistory,
          onClick: () => setShowHistory(false),
          children: 'Back',
          color: 'secondary',
          startIcon: <ArrowBack color="secondary" />,
        },
      ]}
    >
      {showHistory ? (
        <DAITable
          rows={actions.map(({ employee, actionCompletedOn, action, id }) => ({
            action,
            actionCompletedOn: dayjs(actionCompletedOn).format(
              DateTimeConstants.MM_DD_YYYY_H_MM_A,
            ),
            email: employee?.email || 'Automated',
            id,
          }))}
          columns={columns}
          pageSize={PAGE_SIZE}
          rowCount={count}
          onPageChange={handlePageChange}
          defaultOrderBy="actionCompletedOn"
          loading={loading}
          className={TableContainerClass.MODAL}
        />
      ) : (
        <MuiForm
          formState={formState}
          handleOnChange={handleOnChange}
          loading={false}
        />
      )}
    </MuiFormModal>
  )
}
