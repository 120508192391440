"use strict";
/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePauseOptimizations = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var usePauseOptimizations = function (input) {
    var _a = (0, client_1.useMutation)(graphql_1.PAUSE_OPTIMIZATIONS, {
        update: function (cache, _a) {
            var _b;
            var data = _a.data;
            if ((_b = data === null || data === void 0 ? void 0 : data.pauseOptimizationsInCompanyStores) === null || _b === void 0 ? void 0 : _b.success) {
                var _c = data.pauseOptimizationsInCompanyStores, storeIds_1 = _c.storeIds, resumeOptimizingAt_1 = _c.resumeOptimizingAt, optimizingPausedAt_1 = _c.optimizingPausedAt;
                var existing = cache.readQuery({ query: graphql_1.GET_ADMIN_COMPANY_STORES, variables: { input: input } });
                if (existing) {
                    var newResults = existing.adminCompanyStores.results.map(function (store) {
                        return storeIds_1.includes(store.id)
                            ? __assign(__assign({}, store), { allOptimizingPaused: true, resumeOptimizingAt: resumeOptimizingAt_1, optimizingPausedAt: optimizingPausedAt_1 }) : store;
                    });
                    cache.writeQuery({
                        query: graphql_1.GET_ADMIN_COMPANY_STORES,
                        variables: { input: input },
                        data: __assign(__assign({}, existing), { adminCompanyStores: __assign(__assign({}, existing.adminCompanyStores), { results: newResults }) }),
                    });
                }
            }
        },
    }), _pauseOptimizations = _a[0], _b = _a[1], rest = __rest(_b, []);
    var pauseOptimizations = function (variables) {
        return _pauseOptimizations({ variables: variables });
    };
    return __assign({ pauseOptimizations: pauseOptimizations }, rest);
};
exports.usePauseOptimizations = usePauseOptimizations;
