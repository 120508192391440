"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ADMIN_PORTAL_AUDITS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_ADMIN_PORTAL_AUDITS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetAdminPortalAudits($input: AdminPortalAuditsInput) {\n    adminPortalAudits(input: $input) {\n      results {\n        ...AdminAudit\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"], ["\n  query GetAdminPortalAudits($input: AdminPortalAuditsInput) {\n    adminPortalAudits(input: $input) {\n      results {\n        ...AdminAudit\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"])), fragments_1.ADMIN_AUDIT_FRAGMENT);
var templateObject_1;
