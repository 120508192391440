/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DAIFormState, FILL_GRID_ALL_SCREENS } from '@dai/web-components'
import { useState } from 'react'
import {
  DateTimeConstants,
  DateTimeHelpers,
  THIRTY_DAYS,
  NINETY_DAYS,
  SIX_MONTHS,
  TWELVE_MONTHS,
  THIRTY_DAYS_YOY,
  THIRTY_DAYS_VALUE_UNIQUE,
} from '@dai/common'
import { DateRangeType } from '@dai/graphql'

export const useDisruptiveBrandPeriod = () => {
  const BRAND_PERIOD: DAIFormState<'select'> = {
    select: {
      PERIOD: {
        variant: 'filled',
        label: 'Time Frame',
        input: {},
        options: [
          {
            label: THIRTY_DAYS,
            value: String(DateTimeConstants.THIRTY_DAYS),
          },
          {
            label: NINETY_DAYS,
            value: String(DateTimeConstants.THIRTY_DAYS * 3),
          },
          {
            label: SIX_MONTHS,
            value: String(DateTimeConstants.THIRTY_DAYS * 6),
          },
          {
            label: TWELVE_MONTHS,
            value: String(DateTimeConstants.DAYS_IN_YEAR),
          },
          {
            label: THIRTY_DAYS_YOY,
            value: THIRTY_DAYS_VALUE_UNIQUE,
          },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
        value: String(DateTimeConstants.THIRTY_DAYS * 3),
        minWidth: 250,
      },
    },
  }
  const [formState, setFormState] = useState<DAIFormState<'select'>>(
    BRAND_PERIOD,
  )

  const endDate = DateTimeHelpers.getDateDaysDelta(0).format(
    DateTimeConstants.YYYY_MM_DD,
  )
  const startDate = DateTimeHelpers.getDateDaysDelta(
    -Number(formState.select.PERIOD.value),
  ).format(DateTimeConstants.YYYY_MM_DD)

  return {
    data: {
      startDate,
      endDate,
      dateRangeType:
        formState.select.PERIOD.value == THIRTY_DAYS_VALUE_UNIQUE
          ? DateRangeType.YoY
          : DateRangeType.NOW_VS_PAST,
    },
    state: {
      formState,
      setFormState,
    },
  }
}
