/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { GetDisplayRestockHistory_displayRestockHistory_results } from '@dai/graphql'
import { FlexBox } from '@dai/web-components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ImageList,
  ImageListItem,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import {
  ExpandMore,
  ExpandMoreRounded,
  ImageOutlined,
  LocalDrinkOutlined,
  Verified,
} from '@mui/icons-material'
import React from 'react'
import { orderBy } from 'lodash'
import InventoryList from './InventoryList'
import TransactionsTable from './TransactionsTable'
import CentroidsTable from './CentroidsTable'
import CombinedInventoryTransaction from './CombinedInventoryTransactionTable'

type TrackInventoryEventProps = {
  restockData: GetDisplayRestockHistory_displayRestockHistory_results
  maxSlots: number
  customerDistributedUpcs: string[]
}

const TrackInventoryEvent: React.FC<TrackInventoryEventProps> = (
  props: TrackInventoryEventProps,
) => {
  const { restockData, maxSlots, customerDistributedUpcs } = props
  const keyFramesTaken = restockData.scans.pointClouds.flatMap(p =>
    p.keyframes.map(i => i.url),
  )
  const keyFramesUploaded = keyFramesTaken.filter(i => !!i)
  const numUploaded = keyFramesUploaded.length
  const date = new Date(restockData.transactions[0].dateCompleted)
  const dateString = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  const dateStringLogFile = date.toISOString().slice(0, 10)
  const allCentroids = restockData.scans.pointClouds.flatMap(p => {
    const centroids = JSON.parse(p.centroids) as {
      upc: string
      quantity: number
      centroid: { x: number; y: number; z: number }
    }[]
    return centroids
  })

  const numberCustomerFacings = restockData.transactions.reduce((count, t) => {
    if (customerDistributedUpcs.includes(t.upc)) {
      return count + (t.numFacings || 0)
    }
    return count
  }, 0)

  const wasHumanVerified = restockData.transactions.some(t => t.fieldVerified)

  return (
    <FlexBox.Col>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <FlexBox.Col sx={{ width: '100%', flex: 1 }}>
            <FlexBox.Row
              sx={{
                justifyContent: 'space-between',
                flex: 1,
                width: '100%',
              }}
            >
              <Typography variant="h5">
                {restockData.scans?.pointClouds[0]?.merchandiserStep}
              </Typography>
              <Typography sx={{ ml: 1 }} variant="subtitle2">
                {dateString}
              </Typography>
            </FlexBox.Row>
            <Typography variant="subtitle2">
              {restockData.scans.employee.email}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <ImageOutlined fontSize="small" />:{'   '}
                {numUploaded}/{keyFramesTaken.length}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <LocalDrinkOutlined fontSize="small" />: {numberCustomerFacings}
                /{maxSlots}
              </Typography>
              {wasHumanVerified && <Verified />}
            </Stack>
          </FlexBox.Col>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Keyframes Taken: {keyFramesTaken.length} Keyframes Uploaded:{' '}
            {numUploaded}
          </Typography>
          {!!keyFramesUploaded.length && (
            <ImageList sx={{ width: '100%' }} cols={3} rowHeight={300}>
              {keyFramesUploaded.map(url => (
                <ImageListItem key={url}>
                  <img src={url} alt="keyframe" loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography>Best Table Ever</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CombinedInventoryTransaction
                customerDistributedUpcs={customerDistributedUpcs}
                inventoryBefore={restockData.inventoryBefore}
                inventoryAfter={restockData.inventoryAfter}
                centroids={allCentroids}
                transactions={restockData.transactions}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography>Inventory Before</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InventoryList inventory={restockData.inventoryBefore} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography>Transactions Created</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TransactionsTable transactions={restockData.transactions} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreRounded />}>
              <Typography>Inventory After</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InventoryList inventory={restockData.inventoryAfter} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Centroids From Scan</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FlexBox.Row sx={{ flexWrap: 'wrap' }}>
                {restockData.scans.pointClouds.map(p => {
                  const centroids = JSON.parse(p.centroids) as {
                    upc: string
                    quantity: number
                    centroid: { x: number; y: number; z: number }
                  }[]
                  const sorted = orderBy(
                    centroids,
                    ['centroid.y', 'centroid.x'],
                    'desc',
                  )
                  return <CentroidsTable centroids={sorted} />
                })}
              </FlexBox.Row>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Logs and Other Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link
                target="_blank"
                href={`https://console.cloud.google.com/storage/browser/_details/dai-production-app-logs/${restockData.scans.employee.email}/logs-${dateStringLogFile}.log;tab=live_object?project=dai-production`}
              >
                App Log File (logs-{dateStringLogFile}.log)
              </Link>
              <Typography>
                Number Point Clouds: {restockData.scans.pointClouds.length}
              </Typography>
              <Typography>
                Point Cloud Files:{' '}
                {restockData.scans.pointClouds.map(c => c.bucketFileName)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </FlexBox.Col>
  )
}

export default TrackInventoryEvent
