/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { useGetCompanyUsers } from '@dai/common'
import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'
import {
  UpdateUserMutation,
  UpdateUserInput,
  UpdateUserMutationVariables,
  UPDATE_USER,
  UserFragment,
} from '@dai/graphql'
import { useMutation } from '@apollo/client'
import {
  SearchBar,
  DAITable,
  DAITableColumns,
  TableContainerClass,
  MuiTheme,
  MuiFormModal,
  MuiFormModalProps,
} from '@dai/web-components'
import { Close, Save } from '@mui/icons-material'
import { SimpleUserRow } from 'users/users.types'

interface ReassignUsersFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  loading?: boolean
  originUser: UserFragment | null | undefined
  onSubmit: () => void
}

const columns: DAITableColumns<SimpleUserRow & { numStores: number }>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  { field: 'numStores', headerName: '# Stores' },
]

const PAGE_SIZE = MuiTheme.sizes.table.page.sm

export const ReassignUsersToStoresModal: React.FunctionComponent<ReassignUsersFormProps> = props => {
  const { originUser, onSubmit, handleClose, isOpen } = props
  const [userSearch, setUserSearch] = useState('')
  const [debouncedUserSearchQuery] = useDebounce(userSearch, 500)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const { users, count, loading, fetchNextPage } = useGetCompanyUsers({
    limit: PAGE_SIZE,
    searchTerm: debouncedUserSearchQuery,
  })

  const selectedUser =
    selectedIds.length === 1 ? users?.find(q => q.id === selectedIds[0]) : null

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= users.length) {
      await fetchNextPage()
    }
  }

  const [updateUser] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER)

  const getRole = (user: UserFragment): string => {
    return user.roles.join('/')
  }

  const onAssignClick = async () => {
    const newStoreIds = selectedUser!.stores.map(store => store.id)

    originUser!.stores
      .map(store => store.id)
      .forEach(storeId => {
        if (!newStoreIds.includes(storeId)) {
          newStoreIds.push(storeId)
        }
      })

    const selectedInput: UpdateUserInput = {
      userId: selectedUser!.id,
      stores: newStoreIds,
    }
    const originInput: UpdateUserInput = {
      userId: originUser!.id,
      stores: [],
    }
    await updateUser({ variables: { input: selectedInput } })
    await updateUser({ variables: { input: originInput } })
    props.onSubmit()
  }

  const userRows = users.map(user => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: getRole(user),
    numStores: user.stores.length,
  }))

  return (
    <MuiFormModal
      sx={{ padding: 0 }}
      isOpen={isOpen}
      title={`Reassign ${originUser?.firstName} ${originUser?.lastName}'s stores`}
      subtitle={`${originUser?.stores.length} stores total`}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          startIcon: <Close color="secondary" />,
          disabled: false,
          color: 'secondary',
        },
        {
          onClick: onAssignClick,
          children: 'Save',
          startIcon: <Save color="secondary" />,
          disabled: !selectedUser,
        },
      ]}
    >
      <DAITable
        rows={userRows}
        columns={columns}
        className={TableContainerClass.MODAL}
        tableToolBarProps={{
          tableTitle: 'Users',
          right: {
            others: [
              <SearchBar
                useResponsive
                onChange={value => setUserSearch(value)}
              />,
            ],
          },
        }}
        loading={loading}
        pageSize={PAGE_SIZE}
        rowCount={count}
        onPageChange={handlePageChange}
        disableSelectAll
        singleSelect
        showCheckbox
        onSelectionChange={setSelectedIds}
      />
    </MuiFormModal>
  )
}
