/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import { LineChartData, ReportLayout } from '@dai/web-components'
import { LineChart } from '@dai/web-components'
import {
  GenericReportDataType,
  GenericReportProps,
} from 'reporting/admin-reporting.types'
import ReportContext from 'context/ReportContext'

export const LineChartReport: React.FC<GenericReportProps<string>> = props => {
  const { useGetData, ...rest } = props
  const { reportParams } = useContext(ReportContext)
  const { data, isEmpty, isLoading } = useGetData(reportParams)
  const formatData = (data: GenericReportDataType[]) => {
    return data.reduce<LineChartData>((all, curr) => {
      const instance = {
        date: curr.category as number,
        value: curr.value as number,
      }
      return {
        ...all,
        [curr.group!]: all[curr.group!]
          ? [...all[curr.group!], instance]
          : [instance],
      }
    }, {})
  }

  const formattedData: LineChartData = formatData(data)
  return (
    <ReportLayout
      {...props}
      Body={
        <LineChart
          {...rest}
          isEmpty={isEmpty}
          isLoading={isLoading}
          xAxisLabel={''}
          yAxisLabel={''}
          data={formattedData}
        />
      }
    />
  )
}
