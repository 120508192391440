/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import PicOSLayout from 'picos/components/PicOSLayout'
import { FlexBox, SearchBar, MUIDatePicker } from '@dai/web-components'
import { Button, Stack, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useEditPicOSPrioritiesLogic } from 'picos/container/edit/EditPicOSPriorities.logic'
import PicOSPriorities from 'picos/components/PicOSPriorities'
import dayjs from 'dayjs'
import { DateTimeHelpers } from '@dai/common'

const EditPicOSPrioritiesContainer: React.FC = () => {
  const {
    state: { FilterProps, bannerSelected },
    handle: { handleSelectCreatePriority, handleUpdateSearchParams },
    data: { priorities, prioritiesLoading },
    PriorityLazyQuery,
  } = useEditPicOSPrioritiesLogic()
  const startDate = FilterProps.filter.filterBy?.dateOptions?.startDate
  const endDate = FilterProps.filter.filterBy?.dateOptions?.endDate

  const theme = useTheme()

  const dateError =
    !!startDate &&
    !!endDate &&
    !DateTimeHelpers.isValidDateRange(startDate, endDate)

  return (
    <PicOSLayout>
      <FlexBox.Col>
        <FlexBox.Row
          mb={bannerSelected ? 2 : 7}
          sx={
            bannerSelected
              ? {
                  backgroundColor: theme.palette.common.white,
                  p: 2,
                  m: -2,
                  mb: 2,
                  borderBottom: 1,
                  borderColor: 'divider',
                }
              : {}
          }
        >
          {bannerSelected && (
            <FlexBox.RowBetween flex={1}>
              <Stack direction={'row'} spacing={2}>
                <SearchBar onChange={PriorityLazyQuery.setItemQuery} />
                <MUIDatePicker
                  variant="outlined"
                  label="Start Date"
                  date={startDate ? dayjs(startDate) : null}
                  onDateChange={date => {
                    handleUpdateSearchParams({ startDate: date?.toISOString() })
                    FilterProps.updateDateOptions(
                      'startDate',
                      date?.toISOString() || null,
                    )
                  }}
                  textProps={{
                    size: 'small',
                  }}
                  error={dateError}
                />
                <MUIDatePicker
                  variant="outlined"
                  label="End Date"
                  date={endDate ? dayjs(endDate) : null}
                  onDateChange={date => {
                    handleUpdateSearchParams({ endDate: date?.toISOString() })
                    FilterProps.updateDateOptions(
                      'endDate',
                      date?.toISOString() || null,
                    )
                  }}
                  textProps={{
                    size: 'small',
                  }}
                  error={dateError}
                />
              </Stack>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSelectCreatePriority}
                startIcon={<Add color="secondary" />}
              >
                Create Priority
              </Button>
            </FlexBox.RowBetween>
          )}
        </FlexBox.Row>
        <PicOSPriorities priorities={priorities} loading={prioritiesLoading} />
      </FlexBox.Col>
    </PicOSLayout>
  )
}

export default EditPicOSPrioritiesContainer
