/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext, useEffect, useMemo } from 'react'
import {
  DAITable,
  ReportLayout,
  RowData,
  TableContainerClass,
  EmptyReportBody,
  StringHelpers,
  ReportingHelpers,
} from '@dai/web-components'
import {
  GenericReportDataType,
  GenericReportProps,
} from 'reporting/admin-reporting.types'
import { groupBy } from 'lodash'
import ReportContext from 'context/ReportContext'
import { Box, Paper } from '@mui/material'

export const TableReport: React.FC<GenericReportProps<string>> = props => {
  const { useGetData } = props
  const { setDownloadableTable, reportParams } = useContext(ReportContext)
  const { data, isLoading, isEmpty } = useGetData(reportParams)

  const formatData = (data: GenericReportDataType[]) => {
    const groupedRows = groupBy(data, 'group')
    const rows = Object.entries(groupedRows).reduce<RowData[]>(
      (agg, [id, columns]) => {
        const row: RowData & Record<string, any> = { id }
        columns.forEach(col => {
          row[col.category as string] = col.value
        })
        return [...agg, row]
      },
      [],
    )
    const columns = Object.keys(groupBy(data, 'category')).map(colName => ({
      field: colName,
      headerName: StringHelpers.stringValue(colName, '-'),
    }))
    return { rows, columns }
  }

  const { rows, columns } = formatData(data)
  const columnHeadersId = columns.map(col => col.headerName).join(',')

  const filteredRows = useMemo(
    () =>
      !('searchTerm' in reportParams) ||
      StringHelpers.isNullOrEmpty(reportParams.searchTerm)
        ? rows
        : rows.filter(row =>
            Object.keys(row)
              .filter(
                key =>
                  key !== 'id' && typeof row[key as keyof RowData] === 'string',
              )
              .some(key =>
                ReportingHelpers.TABLE.filterBySearchTerm(
                  row,
                  reportParams.searchTerm,
                  key as keyof RowData,
                ),
              ),
          ),
    [
      columnHeadersId,
      props.chartId,
      columns.length,
      rows.length,
      reportParams?.searchTerm,
    ],
  )

  useEffect(() => {
    setDownloadableTable?.({
      columns,
      rows: filteredRows,
    })
  }, [columnHeadersId, props.chartId, columns.length, filteredRows.length])

  return (
    <Box m={-2}>
      <ReportLayout
        {...props}
        Body={
          <>
            {isEmpty && !isLoading ? (
              <Paper
                variant={'outlined'}
                sx={{ height: 300, m: { xs: 2, sm: 0 } }}
              >
                <EmptyReportBody />
              </Paper>
            ) : (
              <DAITable
                rows={rows}
                className={TableContainerClass.NONE}
                // @ts-ignore
                columns={columns}
                loading={isLoading}
              />
            )}
          </>
        }
      />
    </Box>
  )
}
