/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import {
  PageTitle,
  NavigationTabs,
  FullScreenLayout,
} from '@dai/web-components'
import UserProductivityContainer from 'users/containers/UserProductivity.container'
import DisruptiveBrandsContainer from 'brands/brand-disruptor/containers/DisruptiveBrands.container'
import ProtectedRoute from 'routes/ProtectedRoute'
import { DAIGroupEnum } from '@dai/graphql'
import UserContext from 'context/UserContext'

const ReportingContainer: React.FC = () => {
  const { user } = useContext(UserContext)

  const tabs = user?.isSuperuser
    ? [
        {
          label: 'Brands',
          ext: 'brands' as 'brands' | 'users',
        },
        {
          label: 'Users',
          ext: 'users' as 'brands' | 'users',
        },
      ]
    : [
        {
          label: 'Brands',
          ext: 'brands' as 'brands' | 'users',
        },
      ]

  return (
    <FullScreenLayout
      header={
        <PageTitle
          title="Reporting"
          RightComponent={
            <NavigationTabs<'brands' | 'users'>
              routeBase="reporting"
              tabs={tabs}
            />
          }
        />
      }
      body={
        <Switch>
          <ProtectedRoute
            path="/reporting/brands"
            authorized={[DAIGroupEnum.REPORT_VIEWERS]}
          >
            <DisruptiveBrandsContainer />
          </ProtectedRoute>
          <ProtectedRoute path="/reporting/users">
            <UserProductivityContainer />
          </ProtectedRoute>
        </Switch>
      }
    />
  )
}

export default ReportingContainer
