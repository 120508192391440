/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Braxton Geary <braxton.geary@deliciousai.com>
 */

import React, { useState } from 'react'
import {
  AdminCompanyStoresForDownloadQuery,
  AdminCompanyStoresForDownloadQueryVariables,
  GET_ADMIN_COMPANY_STORES_FOR_DOWNLOAD,
} from '@dai/graphql'
import { MuiFormModal } from '@dai/web-components'
import { useLazyQuery } from '@apollo/client'
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'

type StoresInfoFormProps = {
  onCancel: () => void
  isOpen: boolean
}

export const StoresInfoModal: React.FunctionComponent<StoresInfoFormProps> = props => {
  const { onCancel, isOpen } = props
  const [groupsToInclude, setGroupsToInclude] = useState<string[]>([])
  const possibleGroups = ['All Users', 'Sales', 'Auditors', 'Merchandisers']

  const handleCheckboxClick = (groupName: string) => {
    if (groupsToInclude.indexOf(groupName) === -1) {
      if (groupName === 'All Users') {
        setGroupsToInclude([groupName])
      } else {
        setGroupsToInclude([...groupsToInclude, groupName])
      }
    } else if (groupName === 'All Users') {
      setGroupsToInclude([])
    } else {
      const tempArray = [...groupsToInclude]
      tempArray.splice(tempArray.indexOf(groupName), 1)
      setGroupsToInclude(tempArray)
    }
  }

  const [queryThenDownload] = useLazyQuery<
    AdminCompanyStoresForDownloadQuery,
    AdminCompanyStoresForDownloadQueryVariables
  >(GET_ADMIN_COMPANY_STORES_FOR_DOWNLOAD, {
    onCompleted: data => {
      const storeInfo = data.adminCompanyStores.results?.map(store =>
        store.users
          .filter(user => user.isActive)
          .map(
            user =>
              `${user.email},${store.internalStoreId},${store.name},${
                store.address
                  ? `${
                      store.address.address1 +
                      (store.address.address2
                        ? ` ${store.address.address2}`
                        : '')
                    },${store.address.city},${store.address.state},${
                      store.address.postalCode
                    }`
                  : ',,,,'
              }, ${store.category}`,
          )
          .join('\n'),
      )
      if (storeInfo) {
        const todayDate = new Date()
        const day = todayDate.getDate()
        const month = todayDate.toLocaleString('default', { month: 'short' })
        const year = todayDate.getFullYear()

        const file = new File(
          [
            `Email,Customer Number,Name,Address,City,State,Postal Code,Channel\n${storeInfo?.join(
              '\n',
            )}`,
          ],
          `store_info_for${groupsToInclude
            .map(group => `_${group.toLowerCase()}`)
            .join('')}_${month}_${day}_${year}.csv`,
          {
            type: 'text/csv',
          },
        )
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)

        link.href = url
        link.download = file.name
        link.click()
        window.URL.revokeObjectURL(url)

        onCancel()
      }
    },
    nextFetchPolicy: 'cache-and-network',
  })

  return (
    <MuiFormModal
      title="Download Store Info"
      subtitle="Select which group(s) to include in the report"
      isOpen={isOpen}
      handleClose={onCancel}
      actionButtonsProps={[
        {
          onClick: () => {
            queryThenDownload({
              variables: {
                input: {
                  onlyActive: true,
                  returnAll: true,
                  userTypesFilter: groupsToInclude,
                },
              },
            })
            onCancel()
          },
          children: 'Download Info',
          color: 'secondary',
        },
      ]}
    >
      <Typography variant="subtitle2">
        * Note: It may take a few moments for the download to complete
      </Typography>
      <Typography variant="h5">Groups</Typography>
      <Stack>
        {possibleGroups.map(groupName => (
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  groupsToInclude.indexOf('All Users') !== -1 ||
                  groupsToInclude.indexOf(groupName) !== -1
                }
                onChange={() => handleCheckboxClick(groupName)}
              />
            }
            label={groupName}
          />
        ))}
      </Stack>
    </MuiFormModal>
  )
}
