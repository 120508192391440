/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import UserContext from 'context/UserContext'
import { useDebounce } from 'use-debounce'
import {
  DAITable,
  DAITableColumns,
  MuiTheme,
  StringHelpers,
  useCommonFilter,
  BaseFilterAttributes,
  FilterHelpers,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { hasPermission, useGetAdminAudits } from '@dai/common'
import {
  AdminDeleteAuditsMutation,
  AdminDeleteAuditsMutationVariables,
  AdminPortalAuditsOrderBy,
  DAIGroupEnum,
  DELETE_AUDITS,
  GET_ALL_COMPANY_USER_OPTIONS,
  GET_COMPANY_STORE_OPTIONS,
  GetAllCompanyUserOptionsQuery,
  GetCompanyStoreOptionsQuery,
  GetCompanyStoreOptionsQueryVariables,
} from '@dai/graphql'
import { useMutation } from '@apollo/client'
import { AuditRow } from 'store-inspection/store-inspection.types'
import StoreInspectionHelpers from 'store-inspection/helpers/store-inspection.helpers'
import { round } from 'lodash'

const PAGE_SIZE = MuiTheme.sizes.table.page.md

export const useAuditsLogic = () => {
  const { user } = useContext(UserContext)

  const [auditSearch, setAuditSearch] = useState('')
  const [debouncedAuditSearchQuery] = useDebounce(auditSearch, 500)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const tableRef = useRef<DAITable<AuditRow>>(null)
  const isRedAdmin = hasPermission(user, [DAIGroupEnum.RED_ADMINS])

  const CompUserLazyQuery = useDebouncedItemQuery<
    GetAllCompanyUserOptionsQuery,
    {}
  >({
    queryStr: GET_ALL_COMPANY_USER_OPTIONS,
    options: { fetchPolicy: 'cache-first' },
  })

  const StoresLazyQuery = useDebouncedItemQuery<
    GetCompanyStoreOptionsQuery,
    GetCompanyStoreOptionsQueryVariables
  >({
    queryStr: GET_COMPANY_STORE_OPTIONS,
    options: { fetchPolicy: 'cache-first' },
  })

  const defaultFilter: BaseFilterAttributes = {
    filterBy: {
      selected: [],
      options: [
        {
          label: 'Store Category',
          selected: [],
          subOptions: [
            {
              value: 'LG',
              label: 'Large Stores',
            },
            {
              value: 'CR',
              label: 'Convenience Retail',
            },
          ],
        },
        {
          label: 'Stores',
          subOptions: [],
          selected: [],
          showSearch: true,
        },
        {
          label: 'Employees',
          subOptions: [],
          selected: [],
          showSearch: true,
        },
      ],
    },
  }

  const FilterProps = useCommonFilter(defaultFilter)
  const employeeSearchTerm = FilterHelpers.getSubOptions(
    FilterProps.filter.filterBy!,
    'Employees',
  )?.searchTerm
  const storeSearchTerm = FilterHelpers.getSubOptions(
    FilterProps.filter.filterBy!,
    'Stores',
  )?.searchTerm

  const { audits, count, loading, fetchNextPage, refetch } = useGetAdminAudits({
    userId: user?.uuid,
    searchTerm: debouncedAuditSearchQuery,
    limit: PAGE_SIZE,
    storeTypes: FilterHelpers.getSubOptions(
      FilterProps.filter.filterBy!,
      'Store Category',
    )?.selected,
    storeIds: FilterHelpers.getSubOptions(
      FilterProps.filter.filterBy!,
      'Stores',
    )?.selected,
    employeeIds: FilterHelpers.getSubOptions(
      FilterProps.filter.filterBy!,
      'Employees',
    )?.selected,
    orderBy: AdminPortalAuditsOrderBy.DATE,
    ascending: false,
  })

  useEffect(() => {
    CompUserLazyQuery.lazyQuery.query()
  }, [])

  useEffect(() => {
    const employees =
      CompUserLazyQuery.lazyQuery.meta.data?.adminCompanyAllUsers || []
    if (employees.length) {
      const employeeOptions = employees.map(em => ({
        label: `${em.firstName} ${em.lastName}`,
        value: em.id,
      }))
      FilterProps.handleSetSubOptions(
        employeeOptions.filter(emp =>
          StringHelpers.isNullOrEmpty(employeeSearchTerm)
            ? true
            : emp.label.toLowerCase().includes(employeeSearchTerm || ''),
        ),
        'Employees',
      )
    }
  }, [CompUserLazyQuery.lazyQuery.meta.data, employeeSearchTerm])

  useEffect(() => {
    if (storeSearchTerm) {
      StoresLazyQuery.lazyQuery.query({
        variables: { searchTerm: storeSearchTerm },
      })
    }
  }, [storeSearchTerm])

  useEffect(() => {
    const stores =
      StoresLazyQuery.lazyQuery.meta.data?.companyStoreOptions || []
    if (stores) {
      FilterProps.handleSetSubOptions(
        stores.map(st => ({
          label: st.store.name,
          value: st.id,
        })),
        'Stores',
      )
    }
  }, [StoresLazyQuery.lazyQuery.meta.data])

  useEffect(() => {
    tableRef.current?.returnToFirstPage()
  }, [debouncedAuditSearchQuery])

  const [
    showConfirmDeleteAuditsModal,
    setShowConfirmDeleteAuditsModal,
  ] = useState(false)

  const [showAuditInfo, setShowAuditInfo] = useState(false)
  const [deleteAudits] = useMutation<
    AdminDeleteAuditsMutation,
    AdminDeleteAuditsMutationVariables
  >(DELETE_AUDITS)

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= page) {
      await fetchNextPage()
    }
  }

  const handleDeletePressConfirm = async () => {
    const variables = { input: { ids: selectedIds } }
    await deleteAudits({ variables })
    setSelectedIds([])
    tableRef.current?.setSelectedIds([])
    refetch()
    setShowConfirmDeleteAuditsModal(false)
  }

  const auditRows = useMemo(
    () =>
      audits.map(audit => ({
        id: audit.id,
        store: `${audit.store.name}`,
        employee: `${audit.employee?.firstName} ${audit.employee?.lastName}`,
        date_completed: audit.dateCompleted,
        sovi: isNaN(StoreInspectionHelpers.soviPercent(audit))
          ? '-'
          : `${round(StoreInspectionHelpers.soviPercent(audit) * 100, 0)}%`,
      })),
    [audits],
  )

  const columns: DAITableColumns<AuditRow>[] = useMemo(
    () => [
      {
        field: 'date_completed',
        headerName: 'Date',
        width: MuiTheme.sizes.table.column.width.xl,
        disableSort: true,
      },
      {
        field: 'store',
        headerName: 'Store',
        width: MuiTheme.sizes.table.column.width.xl,
        disableSort: true,
      },
      {
        field: 'employee',
        headerName: 'Employee',
        width: MuiTheme.sizes.table.column.width.lg,
        disableSort: true,
      },
      {
        field: 'sovi',
        headerName: 'Total Sovi',
        width: MuiTheme.sizes.table.column.width.md,
        disableSort: true,
      },
    ],
    [MuiTheme],
  )

  return {
    state: {
      auditSearch,
      setAuditSearch,
      debouncedAuditSearchQuery,
      selectedIds,
      setSelectedIds,
      showConfirmDeleteAuditsModal,
      setShowConfirmDeleteAuditsModal,
      showAuditInfo,
      setShowAuditInfo,
      FilterProps,
    },
    handle: {
      handleDeletePressConfirm,
      handlePageChange,
    },
    ref: {
      tableRef,
    },
    data: {
      isRedAdmin,
      auditRows,
      columns,
      count,
      loading,
      PAGE_SIZE,
    },
  }
}
