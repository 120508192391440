/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clark.sandholtz@deliciousai.com>
 */

import React from 'react'
import { UserFragment } from '@dai/graphql'

export interface UserState {
  user: UserFragment | null
  loading: boolean
  error: string | null
}

const UserContext = React.createContext<UserState>({
  loading: true,
  user: null,
  error: null,
})

export const UserProvider = UserContext.Provider

export default UserContext
