"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteRegion = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var updateHandler = function (args) {
    var companyId = args.companyId, regionId = args.regionId, deleteDescendants = args.deleteDescendants, regions = args.regions;
    return function (cache, _a) {
        var _b;
        var data = _a.data;
        if (!((_b = data === null || data === void 0 ? void 0 : data.deleteRegion) === null || _b === void 0 ? void 0 : _b.deleted)) {
            return;
        }
        try {
            var cachedData = cache.readQuery({
                query: graphql_1.GET_COMPANY_REGIONS,
                variables: { companyId: companyId },
            });
            var cachedRegions = (cachedData === null || cachedData === void 0 ? void 0 : cachedData.companyRegions) || [];
            var regionIds_1 = regions.map(function (region) { return region.id; });
            var updatedRegions = deleteDescendants
                ? cachedRegions.filter(function (region) { return !regionIds_1.includes(region.id); })
                : cachedRegions
                    .filter(function (region) { return region.id !== regionId; })
                    .map(function (region) {
                    return region.parentId === regionId
                        ? __assign(__assign({}, region), { parentId: null }) : region;
                });
            cache.writeQuery({
                query: graphql_1.GET_COMPANY_REGIONS,
                variables: { companyId: companyId },
                data: {
                    companyRegions: updatedRegions,
                },
            });
        }
        catch (error) {
            console.error(error);
        }
    };
};
var useDeleteRegion = function () {
    var _a = (0, client_1.useMutation)(graphql_1.DELETE_REGION), _deleteRegion = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var deleteRegion = function (args) {
        var companyId = args.companyId, regions = args.regions, input = __rest(args, ["companyId", "regions"]);
        _deleteRegion({
            variables: {
                input: input,
            },
            optimisticResponse: {
                deleteRegion: {
                    __typename: 'DeleteRegion',
                    deleted: true,
                },
            },
            update: updateHandler(args),
        });
    };
    return {
        deleteRegion: deleteRegion,
        loading: loading,
        error: error,
    };
};
exports.useDeleteRegion = useDeleteRegion;
