"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMAGE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.IMAGE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Image on Image {\n    id\n    url\n    created\n    modified\n    type\n    width\n    height\n    orientation\n    isThumbnail\n    isPrimary\n    uploadedToGcsOn\n    bucketFileName\n    saved\n  }\n"], ["\n  fragment Image on Image {\n    id\n    url\n    created\n    modified\n    type\n    width\n    height\n    orientation\n    isThumbnail\n    isPrimary\n    uploadedToGcsOn\n    bucketFileName\n    saved\n  }\n"])));
var templateObject_1;
