"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_COMPLIANCE_AUDIT = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.CREATE_COMPLIANCE_AUDIT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CreateComplianceAudit($input: CreateComplianceAuditInput!) {\n    createComplianceAudit(input: $input) {\n      complianceAudit {\n        id\n        dateCompleted\n        strictScore\n        sequenceScore\n        employee {\n          ...User\n        }\n        saved\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation CreateComplianceAudit($input: CreateComplianceAuditInput!) {\n    createComplianceAudit(input: $input) {\n      complianceAudit {\n        id\n        dateCompleted\n        strictScore\n        sequenceScore\n        employee {\n          ...User\n        }\n        saved\n      }\n    }\n  }\n  ", "\n"])), fragments_1.USER_FRAGMENT);
var templateObject_1;
