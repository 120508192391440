/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  CreateAuditQuestionMutationVariables,
  ForStoreSize,
  GetAuditQuestions_auditQuestions,
  UpdateAuditQuestionsInput,
} from '@dai/graphql'
import { Close, Save } from '@mui/icons-material'
import {
  FillContainerLoading,
  MuiFormModalProps,
  MuiFormModal,
  MuiForm,
  useMuiForm,
} from '@dai/web-components'
import { useCreateOrEditAuditQuestionForm } from 'store-inspection/hooks/create-or-edit-audit-question.form'

interface AuditQuestionFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  question?: GetAuditQuestions_auditQuestions | null
  groups: string[]
  loading?: boolean
  onSubmit: (
    question:
      | CreateAuditQuestionMutationVariables
      | Omit<UpdateAuditQuestionsInput, 'ids'>,
    update: boolean,
  ) => void
}

export const CreateOrEditAuditQuestionModal: React.FunctionComponent<AuditQuestionFormProps> = props => {
  const { question, isOpen, onSubmit, handleClose, groups, loading } = props
  const {
    formState,
    setFormState,
    resetForm,
  } = useCreateOrEditAuditQuestionForm({ groups, question })
  const {
    handle: { handleOnChange },
    data: { canSubmit },
  } = useMuiForm({
    formState,
    setFormState,
  })

  const handleCancel = () => {
    resetForm()
    handleClose()
  }

  const handleSubmitQuestion = () => {
    const questionInput = {
      question: formState.text.QUESTION.value as string,
      active: formState.radio.ACTIVE.value === 'Yes',
      category: (formState.select.CATEGORY.value as string) as ForStoreSize,
      group: formState.select.GROUP.value as string,
      pointWorth: formState.text.POINTS.value as number,
    }
    if (question) {
      onSubmit(questionInput, true)
    } else {
      onSubmit(
        {
          input: questionInput,
        },
        false,
      )
    }
    resetForm()
  }

  return (
    <MuiFormModal
      title={`${question ? 'Edit' : 'Create'} Audit Question`}
      isOpen={isOpen}
      handleClose={handleCancel}
      actionButtonsProps={[
        {
          children: 'Cancel',
          startIcon: <Close color="secondary" />,
          onClick: handleCancel,
          color: 'secondary',
        },
        {
          children: 'Save',
          startIcon: <Save color="secondary" />,
          onClick: handleSubmitQuestion,
          disabled: !canSubmit,
        },
      ]}
    >
      {loading ? (
        <FillContainerLoading />
      ) : (
        <MuiForm
          formState={formState}
          handleOnChange={handleOnChange}
          loading={false}
        />
      )}
    </MuiFormModal>
  )
}
