"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BRAND_LANDSCAPE = void 0;
var client_1 = require("@apollo/client");
exports.BRAND_LANDSCAPE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery BrandLandscapeQuery($input: DateRangeInput!) {\n    brandLandscape(input:$input) {\n    \tresults {\n        brand {\n          name\n          id\n        }\n        sovi\n        changeInSovi\n      }\n  \t}\n}\n"], ["\nquery BrandLandscapeQuery($input: DateRangeInput!) {\n    brandLandscape(input:$input) {\n    \tresults {\n        brand {\n          name\n          id\n        }\n        sovi\n        changeInSovi\n      }\n  \t}\n}\n"])));
var templateObject_1;
