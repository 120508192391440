/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportSummaryHook,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { useQuery } from '@apollo/client'
import {
  AggregationMethod,
  GET_POINT_CLOUD_SCAN_SCORES,
  GET_RECOMMENDATION_COMPLIANCE_SCORES,
  GetPointCloudScoresQuery,
  GetPointCloudScores_pointCloudScanScores_results,
  GetPointCloudScoresQueryVariables,
  GetRecommendationComplianceScoresQuery,
  GetRecommendationComplianceScoresQueryVariables,
} from '@dai/graphql'
import { StringHelpers } from '@dai/web-components'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'

export const useQualityReportSummaries: GenericReportSummaryHook<UserProductivityReportName> = () => {
  const useGetAverageRecommendationCompliance = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useSummaryQuery } = useUserProductivityQuery(reportParams)
    const {
      getUserProductivityQueryInput,
      formatOverTimeChange,
      formatStatCardStatistic,
    } = useSummaryQuery()
    const { data, loading } = useQuery<
      GetRecommendationComplianceScoresQuery,
      GetRecommendationComplianceScoresQueryVariables
    >(GET_RECOMMENDATION_COMPLIANCE_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.AVG, false),
      },
    })
    const { data: scoresByMonth, loading: byMonthLoading } = useQuery<
      GetRecommendationComplianceScoresQuery,
      GetRecommendationComplianceScoresQueryVariables
    >(GET_RECOMMENDATION_COMPLIANCE_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.AVG, true),
      },
    })

    return {
      loading: loading || byMonthLoading,
      title: 'Avg Recommendation Compliance',
      stat: formatStatCardStatistic(
        data?.recommendationScores.results[0]?.score,
        true,
      ),
      change: formatOverTimeChange(
        scoresByMonth?.recommendationScores.results,
        'score',
      ),
    }
  }
  const useGetAverageScanFacingsScore = (
    reportParams: UserProductivityReportInput,
    dataKey: keyof GetPointCloudScores_pointCloudScanScores_results,
  ) => {
    const { useSummaryQuery } = useUserProductivityQuery(reportParams)
    const {
      getUserProductivityQueryInput,
      formatStatCardStatistic,
      formatOverTimeChange,
    } = useSummaryQuery()
    const { data, loading } = useQuery<
      GetPointCloudScoresQuery,
      GetPointCloudScoresQueryVariables
    >(GET_POINT_CLOUD_SCAN_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.AVG, false),
      },
    })
    const { data: scoresByMonth, loading: byMonthLoading } = useQuery<
      GetPointCloudScoresQuery,
      GetPointCloudScoresQueryVariables
    >(GET_POINT_CLOUD_SCAN_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.AVG, true),
      },
    })

    return {
      loading: loading || byMonthLoading,
      title: `Avg ${StringHelpers.camelCaseToTitleCase(dataKey)}`,
      stat: formatStatCardStatistic(
        data?.pointCloudScanScores.results[0]?.[dataKey],
        true,
      ),
      change: formatOverTimeChange(
        scoresByMonth?.pointCloudScanScores.results,
        dataKey,
      ),
    }
  }

  return [
    {
      reportName: 'recommendation-compliance-score',
      useGetData: useGetAverageRecommendationCompliance,
    },
    {
      reportName: 'point-cloud-score',
      useGetData: reportParams =>
        useGetAverageScanFacingsScore(reportParams, 'pointCloudScore'),
    },
    {
      reportName: 'scan-facing-score',
      useGetData: reportParams =>
        useGetAverageScanFacingsScore(reportParams, 'facingScore'),
    },
    {
      reportName: 'identification-accuracy',
      useGetData: reportParams =>
        useGetAverageScanFacingsScore(reportParams, 'identificationAccuracy'),
    },
  ]
}
