/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { notNullOrUndefined, toTitleCase } from '@dai/common'
import { DisplayElements, MandateTypes } from '@dai/graphql'
import {
  complianceColumn,
  LinearProgressWithThresholds,
  PicOSComplianceDataGridReport,
  PicOSPriorityDetails,
  priorityColumn,
} from '@dai/web-components'
import { Box, Typography } from '@mui/material'
import { startCase } from 'lodash'
import useAllPrioritiesComplianceReport from 'picos/hooks/use-all-priorities-compliance-report'
import React, { useMemo } from 'react'

const PicOSAllPrioritiesReport: React.FC = () => {
  const {
    data: { title, complianceResults, statItems },
    state: { limit, loadingCompliance, loadingStats },
    handlers: { handleSearch, handleSelectRow, goToPage, getPriorityDetails },
  } = useAllPrioritiesComplianceReport()

  const columns = useMemo(() => {
    const known = [
      priorityColumn(),
      {
        field: 'description',
        headerName: 'Description',
        width: 200,
        sortable: false,
        disableColumnMenu: true,
      },
      complianceColumn({ field: 'overall', headerName: 'Overall' }),
    ]
    if (complianceResults.length) {
      const first = complianceResults[0]
      const regions = first.regionCompliance
        .filter(r => r.name !== 'overall')
        .map(region =>
          complianceColumn({
            field: region.name,
            headerName: startCase(region.name),
          }),
        )
      return [...known, ...regions]
    }
    return known
  }, [complianceResults])

  const rows = useMemo(
    () =>
      complianceResults.map(row => ({
        id: row.id,
        priority: getPriorityDetails(row),
        description: row.priority.description,
        ...row.regionCompliance.reduce((acc, region) => ({
          ...acc,
          [region.name]: region.compliancePercentage
            ? region.compliancePercentage * 100
            : undefined,
        })),
      })),
    [complianceResults],
  )

  return (
    <PicOSComplianceDataGridReport
      title={title}
      columns={columns}
      rows={rows}
      onSearch={handleSearch}
      loadingComplianceItems={loadingCompliance}
      pageSize={limit}
      onSelect={data => handleSelectRow(data.row)}
      onPageChange={goToPage}
      loadingStats={loadingStats}
      statItems={statItems}
    />
  )
}

export default PicOSAllPrioritiesReport
