"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAY_AUDIT_IMAGE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var BoundingBoxesFragment_1 = require("./BoundingBoxesFragment");
var WhiteRabbitProductFragment_1 = require("./WhiteRabbitProductFragment");
exports.DISPLAY_AUDIT_IMAGE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DisplayAuditImage on DisplayAuditImage {\n    id\n    url\n    boundingBoxSet {\n      ...BoundingBoxes\n    }\n    products {\n      ...WhiteRabbitSimpleProduct\n    }\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        email\n      }\n      levelRaisedBy {\n        email\n        firstName\n        lastName\n      }\n      date\n    }\n    protectedBoxes {\n      id\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment DisplayAuditImage on DisplayAuditImage {\n    id\n    url\n    boundingBoxSet {\n      ...BoundingBoxes\n    }\n    products {\n      ...WhiteRabbitSimpleProduct\n    }\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        email\n      }\n      levelRaisedBy {\n        email\n        firstName\n        lastName\n      }\n      date\n    }\n    protectedBoxes {\n      id\n    }\n  }\n  ", "\n  ", "\n"])), BoundingBoxesFragment_1.BOUNDING_BOXES_FRAGMENT, WhiteRabbitProductFragment_1.WHITE_RABBIT_SIMPLE_PRODUCT_FRAGMENT);
var templateObject_1;
