"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DISPLAY_AUDIT_IMAGE = void 0;
var client_1 = require("@apollo/client");
var BasicDisplayAuditImageFragment_1 = require("../fragments/BasicDisplayAuditImageFragment");
exports.GET_DISPLAY_AUDIT_IMAGE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetDisplayAuditImage($imageId: ID!) {\n    displayAuditImage(imageId: $imageId) {\n      ...BasicDisplayAuditImage\n    }\n  }\n  ", "\n"], ["\n  query GetDisplayAuditImage($imageId: ID!) {\n    displayAuditImage(imageId: $imageId) {\n      ...BasicDisplayAuditImage\n    }\n  }\n  ", "\n"])), BasicDisplayAuditImageFragment_1.BASIC_DISPLAY_AUDIT_IMAGE_FRAGMENT);
var templateObject_1;
