"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_POS_SCANS = void 0;
var client_1 = require("@apollo/client");
exports.ADD_POS_SCANS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation AddPOSScans($input: [AddPOSScanInput!]!) {\n    addPosScans(input: $input) {\n      posScans {\n        created\n        id\n        posId\n        display {\n          id\n        }\n        store {\n          id\n        }\n        area {\n          name\n        }\n        employee {\n          id\n        }\n        retiredDate\n      }\n    }\n  }\n"], ["\n  mutation AddPOSScans($input: [AddPOSScanInput!]!) {\n    addPosScans(input: $input) {\n      posScans {\n        created\n        id\n        posId\n        display {\n          id\n        }\n        store {\n          id\n        }\n        area {\n          name\n        }\n        employee {\n          id\n        }\n        retiredDate\n      }\n    }\n  }\n"])));
var templateObject_1;
