"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DRAFT_PRODUCT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var BrandFragment_1 = require("./BrandFragment");
var CategoryFragment_1 = require("./CategoryFragment");
var FlavorFragment_1 = require("./FlavorFragment");
var ImageFragment_1 = require("./ImageFragment");
var ManufacturerFragment_1 = require("./ManufacturerFragment");
var PackagingFragment_1 = require("./PackagingFragment");
var ProductFragment_1 = require("./ProductFragment");
var ShippingFragment_1 = require("./ShippingFragment");
exports.DRAFT_PRODUCT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DraftProduct on DraftProduct {\n    id\n    name\n    createdBy\n    dateResolved\n    fieldsAccepted\n    notes\n    shipping {\n      ...Shipping\n    }\n    category {\n      ...Category\n    }\n    brand {\n      ...Brand\n    }\n    packaging {\n      ...Packaging\n    }\n    manufacturer {\n      ...Manufacturer\n    }\n    flavors {\n      ...Flavor\n    }\n    product {\n      ...Product\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment DraftProduct on DraftProduct {\n    id\n    name\n    createdBy\n    dateResolved\n    fieldsAccepted\n    notes\n    shipping {\n      ...Shipping\n    }\n    category {\n      ...Category\n    }\n    brand {\n      ...Brand\n    }\n    packaging {\n      ...Packaging\n    }\n    manufacturer {\n      ...Manufacturer\n    }\n    flavors {\n      ...Flavor\n    }\n    product {\n      ...Product\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), ImageFragment_1.IMAGE_FRAGMENT, BrandFragment_1.SABRE_BRAND_FRAGMENT, FlavorFragment_1.SABRE_FLAVOR_FRAGMENT, CategoryFragment_1.CATEGORY_FRAGMENT, PackagingFragment_1.SABRE_PACKAGING_FRAGMENT, ShippingFragment_1.SABRE_SHIPPING_FRAGMENT, ProductFragment_1.SABRE_PRODUCT_FRAGMENT, ManufacturerFragment_1.SABRE_MANUFACTURER_FRAGMENT);
var templateObject_1;
