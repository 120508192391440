/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  SearchBar,
  PageTitle,
  FullScreenLayout,
  DAITable,
} from '@dai/web-components'
import { Add, Group, Person, PersonOff, Storefront } from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useUserManagementLogic } from './UserManagement.logic'
import { UserStoresModal } from '../components/modal/UserStores.modal'
import { UserSupervisorModal } from '../components/modal/UserSupervisor.modal'
import { ReassignUsersToStoresModal } from '../components/modal/ReassignUsersToStores.modal'
import { CreateUserModal } from '../components/modal/CreateUser.modal'

const UserManagementContainer: React.FC = () => {
  const {
    state: {
      setUserSearch,
      setSelectedIds,
      showEditStoresModal,
      showCreateUser,
      hideInactive,
      setHideInactive,
      showEditSupervisorModal,
      showReassignStoresModal,
      setShowEditSupervisorModal,
      setShowReassignStoresModal,
      setShowCreateUser,
      setShowEditStoresModal,
    },
    ref: { tableRef },
    data: {
      activeSelectedUsers,
      inactiveSelectedUsers,
      isRedAdmin,
      userRows,
      columns,
      PAGE_SIZE,
      count,
      loading,
      selectedUsers,
      selectedUser,
      userStores,
    },
    handle: {
      handleSupervisorPressed,
      handleEditStoresPressed,
      handleMarkInactive,
      handleMarkActive,
      handlePageChange,
      handleRefetchUsers,
      handleAddUser,
      handleRefetchUserStores,
    },
  } = useUserManagementLogic()

  return (
    <>
      <FullScreenLayout
        header={<PageTitle title="User Management" />}
        bodyStyle={{ p: 0 }}
        body={
          <DAITable
            ref={tableRef}
            tableToolBarProps={{
              tableTitle: 'Users',
              right: {
                iconButton: {
                  buttonProps: {
                    onClick: () => setShowCreateUser(true),
                  },
                  icon: <Add />,
                  toolTipLabel: 'Add User',
                },
                others: [
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setHideInactive(!hideInactive)}
                        checked={hideInactive}
                      />
                    }
                    label="Hide Inactive"
                  />,
                  <SearchBar
                    useResponsive
                    placeholder="Search Users"
                    onChange={setUserSearch}
                  />,
                ],
              },
            }}
            rows={userRows}
            columns={columns}
            pageSize={PAGE_SIZE}
            rowCount={count}
            onPageChange={handlePageChange}
            loading={loading}
            onSelectionChange={setSelectedIds}
            bulkActions={[
              {
                condition: true,
                icon: <Person color="secondary" />,
                label: 'Manager',
                onClick: handleSupervisorPressed,
              },
              {
                condition: isRedAdmin,
                icon: <Storefront color="secondary" />,
                label: 'Stores',
                onClick: handleEditStoresPressed,
              },
              {
                condition: activeSelectedUsers,
                icon: <PersonOff color="secondary" />,
                label: 'Inactive',
                onClick: handleMarkInactive,
              },
              {
                condition: inactiveSelectedUsers,
                icon: <Group color="secondary" />,
                label: 'Active',
                onClick: handleMarkActive,
              },
            ]}
          />
        }
      />
      {selectedUser && (
        <UserStoresModal
          isOpen={showEditStoresModal}
          user={selectedUser}
          userStores={userStores.map(userStore => userStore.id)}
          handleClose={() => setShowEditStoresModal(false)}
          onSubmit={() => {
            handleRefetchUsers()
            handleRefetchUserStores()
            setShowEditStoresModal(false)
          }}
          onReassignStores={() => {
            handleRefetchUserStores()
            setShowEditStoresModal(false)
            setShowReassignStoresModal(true)
          }}
        />
      )}
      <UserSupervisorModal
        loading={false}
        isOpen={showEditSupervisorModal}
        selectedUsers={selectedUsers}
        handleClose={() => setShowEditSupervisorModal(false)}
        onSubmit={() => {
          handleRefetchUsers()
          setShowEditSupervisorModal(false)
        }}
      />
      {/* { TODO: this currently doesn't render in production... not sure what this is supposed to do? } */}
      <ReassignUsersToStoresModal
        handleClose={() => setShowReassignStoresModal(false)}
        loading={false}
        isOpen={showReassignStoresModal}
        originUser={selectedUser}
        onSubmit={() => {
          handleRefetchUsers()
          setShowReassignStoresModal(false)
        }}
      />
      <CreateUserModal
        handleClose={() => setShowCreateUser(false)}
        isOpen={showCreateUser}
        onSubmit={handleAddUser}
      />
    </>
  )
}

export default UserManagementContainer
