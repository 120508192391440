/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Mitch Wilson <mitchell.wilson@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import {
  gridAllScreens,
  SelectableOption,
  NotificationContext,
  DAIFormState,
  MuiFormTypes,
} from '@dai/web-components'
import { Training_training } from '@dai/graphql'
import { Edit, EditOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { useGetGroups } from '@dai/common'
import { FILL_GRID_ALL_SCREENS } from '@dai/web-components'
import { Training } from '../Trainings.logic'

interface TrainingForm extends DAIFormState<'text' | 'select'> {
  text: {
    TITLE: MuiFormTypes['text']
    DESCRIPTION: MuiFormTypes['text']
    TIME_REQUIRED: MuiFormTypes['text']
    KEY_TAKEAWAY_1?: MuiFormTypes['text']
    KEY_TAKEAWAY_2?: MuiFormTypes['text']
    KEY_TAKEAWAY_3?: MuiFormTypes['text']
    KEY_TAKEAWAY_4?: MuiFormTypes['text']
  }
  select: {
    DEVICE_TYPE: MuiFormTypes['select']
    GROUPS: MuiFormTypes['select']
  }
}

type EditTrainingFormProps = {
  trainingEditing: Training | undefined
  originalTraining: Training_training | undefined
}

export const useEditTrainingForm = ({
  trainingEditing,
  originalTraining,
}: EditTrainingFormProps) => {
  const { groups } = useGetGroups()
  const [editNameReadOnly, setEditNameReadOnly] = useState<boolean>(true)

  const deviceTypeOptions: SelectableOption[] = [
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Tablet', label: 'Tablet' },
    { value: 'Both', label: 'Both' },
  ]

  const [training, setTraining] = useState<Training>()

  useEffect(() => {
    setTraining(trainingEditing)
  }, [trainingEditing])

  const getGroupValues = () => {
    return originalTraining?.groups?.map(g => g.id) || []
  }

  const EDIT_TRAINING_FORM: TrainingForm = {
    text: {
      TITLE: {
        input: {
          required: true,
          type: 'text',
          variant: 'standard',
          label: 'Title',
          InputProps: {
            readOnly: false,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setEditNameReadOnly(!editNameReadOnly)}
                >
                  {editNameReadOnly ? <Edit /> : <EditOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: training?.title || '',
      },
      TIME_REQUIRED: {
        input: {
          required: true,
          type: 'number',
          variant: 'standard',
          label: 'Time Required for Training (in Minutes)',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: training?.timeRequired,
      },
      DESCRIPTION: {
        input: {
          required: true,
          type: 'text',
          variant: 'standard',
          label: 'Description',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: gridAllScreens(12),
        value: training?.description || '',
      },
      KEY_TAKEAWAY_1: {
        input: {
          required: false,
          type: 'text',
          variant: 'standard',
          label: 'Key Takeaway 1',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value:
          training && training.keyTakeaways?.length
            ? training?.keyTakeaways[0]
            : '',
      },
      KEY_TAKEAWAY_2: {
        input: {
          required: false,
          type: 'text',
          variant: 'standard',
          label: 'Key Takeaway 2',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value:
          training &&
          training.keyTakeaways?.length &&
          training.keyTakeaways?.length > 1
            ? training?.keyTakeaways[1]
            : '',
      },
      KEY_TAKEAWAY_3: {
        input: {
          required: false,
          type: 'text',
          variant: 'standard',
          label: 'Key Takeaway 3',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value:
          training &&
          training.keyTakeaways?.length &&
          training.keyTakeaways?.length > 2
            ? training?.keyTakeaways[2]
            : '',
      },
      KEY_TAKEAWAY_4: {
        input: {
          required: false,
          type: 'text',
          variant: 'standard',
          label: 'Key Takeaway 4',
          InputProps: {
            readOnly: false,
          },
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value:
          training &&
          training.keyTakeaways?.length &&
          training.keyTakeaways?.length > 3
            ? training?.keyTakeaways[3]
            : '',
      },
    },
    select: {
      DEVICE_TYPE: {
        help: 'Device type of training (i.e. Mobile, Training, or Both)',
        variant: 'filled',
        label: 'Device Type',
        options: deviceTypeOptions,
        input: {
          required: true,
        },
        gridProps: { ...gridAllScreens(6), xs: 12 },
        value: training?.deviceType || 'Both',
      },
      GROUPS: {
        help: groups
          ? 'Assign roles that will see this training'
          : 'An error occurred loading the groups...',
        variant: 'filled',
        label: 'Groups',
        error: groups === undefined,
        input: {
          multiple: true,
        },
        options:
          groups?.map(group => ({ label: group.name, value: group.id })) || [],
        gridProps: FILL_GRID_ALL_SCREENS,
        value: getGroupValues(),
      },
    },
  }

  const [formState, setFormState] = useState<DAIFormState<'text' | 'select'>>(
    EDIT_TRAINING_FORM,
  )

  useEffect(() => {
    setFormState(EDIT_TRAINING_FORM)
  }, [training])

  return {
    formState,
    setFormState,
  }
}
