/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import {
  Box,
  CardMedia,
  Skeleton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { FlexBox, Product } from '@dai/web-components'
import {
  GetProductByUpcQuery,
  GetProductByUpcQueryVariables,
  GET_PRODUCT_BY_UPC,
} from '@dai/graphql'
import { useQuery } from '@apollo/client'

type ProductRowsProps = {
  columnsFromProduct: string[]
  additionalColumnValues: (string | number)[]
  upc: string
  isCompetitor?: boolean
}

const ProductColumns: React.FC<ProductRowsProps> = (
  props: ProductRowsProps,
) => {
  const {
    columnsFromProduct,
    additionalColumnValues,
    upc,
    isCompetitor,
  } = props
  const { loading, data } = useQuery<
    GetProductByUpcQuery,
    GetProductByUpcQueryVariables
  >(GET_PRODUCT_BY_UPC, { variables: { upc }, fetchPolicy: 'cache-first' })

  const product = data?.productByUpc

  return loading ? (
    <Skeleton />
  ) : product ? (
    <TableRow
      key={product.upcA}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      {columnsFromProduct.map(key => (
        <TableCell component="th" scope="row">
          {key === 'marketingImageUrl' ? (
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                sx={theme => ({
                  padding: 1,
                  width: theme.sizes.img.sm - 8,
                  height: theme.sizes.img.sm - 8,
                })}
                image={
                  product.marketingImageUrl
                    ? product.marketingImageUrl
                    : '/delish-bottle-square.png'
                }
              />
              {isCompetitor && (
                <Tooltip
                  sx={{ position: 'absolute', top: 0, left: 0 }}
                  title="Competitive Product"
                >
                  <VisibilityOffIcon />
                </Tooltip>
              )}
            </Box>
          ) : key === 'name' ? (
            <FlexBox.Col>
              <Typography>{product.name}</Typography>
              <Typography>{product.upcA}</Typography>
            </FlexBox.Col>
          ) : (
            // @ts-ignore
            product[key]
          )}
        </TableCell>
      ))}
      {additionalColumnValues.map(value => (
        <TableCell component="th" scope="row">
          <Typography>{value}</Typography>
        </TableCell>
      ))}
    </TableRow>
  ) : null
}

export default ProductColumns
