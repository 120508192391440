/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clarksandholtz@gmail.com>
 */

import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
// import logger from 'apollo-link-logger'
import { getAccessToken } from './auth'
import cache from './cache'

const {
  REACT_APP__GNARNIA_API_URL,
  REACT_APP__SABRE_API_URL,
  REACT_APP__ML_ULTRA_API_URL,
  NODE_ENV,
} = process.env
const DEBUG = NODE_ENV === 'development'

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken()
  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
      TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      )
    })
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const sabre = new HttpLink({
  uri: `${REACT_APP__SABRE_API_URL}/graphql/`,
  credentials: 'same-origin',
})

const gnarnia = new HttpLink({
  uri: `${REACT_APP__GNARNIA_API_URL}/graphql/`,
  credentials: 'same-origin',
})

const mlUltra = new HttpLink({
  uri: `${REACT_APP__ML_ULTRA_API_URL}/graphql/`,
  credentials: 'same-origin',
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    authLink.split(
      operation => operation.getContext().endPoint === 'sabre',
      sabre,
      ApolloLink.split(
        operation => operation.getContext().endPoint === 'mlUltra',
        mlUltra,
        gnarnia,
      ),
    ),
  ]),
  cache,
})
