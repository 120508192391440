"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_IMAGE_ULTRA = void 0;
var client_1 = require("@apollo/client");
exports.GET_IMAGE_ULTRA = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetImage($imageId: ID!) {\n    imageData(imageId: $imageId) {\n      imageId\n      imageType\n      url\n      products\n      boundingBoxSet {\n        id\n        verified\n        verifiedById\n        verifiedDate\n        verifiedLocation\n        updateCount\n        boundingBoxes {\n          id\n          upcA\n          productName\n          brandName\n          packagingName\n          marketingImageUrl\n          coordinates {\n            minX\n            minY\n            maxX\n            maxY\n          }\n          verified\n          verifiedById\n          verifiedLocation\n        }\n      }\n      verified\n      verifiedById\n      verifiedByFirstName\n      verifiedByLastName\n      verifiedByEmail\n      verifiedDate\n      verifiedDate\n      eulerAngles {\n        x\n        y\n        z\n      }\n    }\n  }\n"], ["\n  query GetImage($imageId: ID!) {\n    imageData(imageId: $imageId) {\n      imageId\n      imageType\n      url\n      products\n      boundingBoxSet {\n        id\n        verified\n        verifiedById\n        verifiedDate\n        verifiedLocation\n        updateCount\n        boundingBoxes {\n          id\n          upcA\n          productName\n          brandName\n          packagingName\n          marketingImageUrl\n          coordinates {\n            minX\n            minY\n            maxX\n            maxY\n          }\n          verified\n          verifiedById\n          verifiedLocation\n        }\n      }\n      verified\n      verifiedById\n      verifiedByFirstName\n      verifiedByLastName\n      verifiedByEmail\n      verifiedDate\n      verifiedDate\n      eulerAngles {\n        x\n        y\n        z\n      }\n    }\n  }\n"])));
var templateObject_1;
