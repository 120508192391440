"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuditQuestionAnswers = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useAuditQuestionAnswers = function (props) {
    var auditId = props.auditId;
    var _a = (0, client_1.useQuery)(graphql_1.GET_STORE_AUDIT, { fetchPolicy: 'cache-first', variables: { auditId: auditId } }), data = _a.data, loading = _a.loading;
    var answers = data === null || data === void 0 ? void 0 : data.audit.auditAnswers;
    return { answers: answers, loading: loading };
};
exports.useAuditQuestionAnswers = useAuditQuestionAnswers;
