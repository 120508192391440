/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext, useEffect } from 'react'
import PicOSContext from 'context/PicOSContext'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'
import {
  GET_STORE_CHAIN,
  GetStoreChainQuery,
  GetStoreChainQueryVariables,
} from '@dai/graphql'
import {
  FillContainerLoading,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { useLocalStorage } from 'hooks/use-local-storage'
import UserContext from 'context/UserContext'

const PicOSLayout: React.FC = props => {
  const { Storage } = useLocalStorage()
  const { user } = useContext(UserContext)
  const { selectedBanner, setSelectedBanner, urlSearchParams } = useContext(
    PicOSContext,
  )

  const StoreChainLazyQuery = useDebouncedItemQuery<
    GetStoreChainQuery,
    GetStoreChainQueryVariables
  >({
    queryStr: GET_STORE_CHAIN,
    options: { fetchPolicy: 'cache-first' },
  })

  const updateWithCached = () => {
    const cached = Storage.getItem('selectedBanner')
    if (cached && cached?.id === urlSearchParams.bannerId) {
      setSelectedBanner(cached)
    }
  }

  useEffect(() => {
    if (urlSearchParams.bannerId && !selectedBanner && user) {
      StoreChainLazyQuery.lazyQuery
        .query({
          variables: {
            storeChainId: urlSearchParams.bannerId,
          },
        })
        .then(res => {
          const chain = res.data?.storeChain
          if (chain) {
            setSelectedBanner({
              label: chain.name,
              id: chain.id,
            })
          } else {
            updateWithCached()
          }
        })
        .catch(updateWithCached)
    }
  }, [urlSearchParams.bannerId, user])

  useEffect(() => {
    if (!urlSearchParams.bannerId) {
      setSelectedBanner(Storage.getItem('selectedBanner') || null)
    }
  }, [urlSearchParams.bannerId])

  const loading = StoreChainLazyQuery.lazyQuery.meta.loading

  if (!selectedBanner) {
    return loading ? (
      <FillContainerLoading />
    ) : (
      <ErrorIconAndDescription subtitle="Select a customer banner in the top left corner to show PicOS Priorities." />
    )
  }

  return <>{props.children}</>
}

export default PicOSLayout
