"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICOS_STORE_COMPLIANCE = void 0;
var client_1 = require("@apollo/client");
exports.GET_PICOS_STORE_COMPLIANCE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetPicOSStoreCompliance($input: PicOSStoreComplianceInput!) {\n    picosStoreCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        id\n        priority\n        name\n        description\n        mandateType\n        displayElements\n        imageUrl\n        cost\n        isCompliant\n      }\n    }\n  }\n"], ["\n  query GetPicOSStoreCompliance($input: PicOSStoreComplianceInput!) {\n    picosStoreCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        id\n        priority\n        name\n        description\n        mandateType\n        displayElements\n        imageUrl\n        cost\n        isCompliant\n      }\n    }\n  }\n"])));
var templateObject_1;
