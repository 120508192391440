/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { MuiTheme } from '@dai/web-components'
import { DAITableColumns } from '@dai/web-components'
import { DAITable } from '@dai/web-components'
import { TableContainerClass } from '@dai/web-components'
import { Delete, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { set } from 'lodash'
import { FormPicOSPriority } from 'picos/picos.types'
import { useEffect, useRef, useState } from 'react'

type PicOSAddedProductsAccordionProps = {
  items: FormPicOSPriority['addedProducts']
  handleRemove: (ids: string[]) => void
}

type AddPicOSProductRow = {
  id: string
  brand: string
  packaging: string
  product: string
}

const columns: DAITableColumns<AddPicOSProductRow>[] = [
  {
    field: 'brand',
    headerName: 'Brand',
    width: MuiTheme.sizes.table.column.width.md,
  },
  {
    field: 'packaging',
    headerName: 'Packaging',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'product',
    headerName: 'Product',
    width: MuiTheme.sizes.table.column.width.xl,
  },
]

const PicOSAddedProductsAccordion: React.FC<PicOSAddedProductsAccordionProps> = props => {
  const { items = [], handleRemove } = props
  const [open, setOpen] = useState<boolean>(false)
  const tableRef = useRef<DAITable<AddPicOSProductRow>>(null)

  useEffect(() => {
    if (items.length) {
      setOpen(true)
    }
  }, [items.length])

  return (
    <Accordion
      sx={{ backgroundColor: 'white' }}
      expanded={open}
      onChange={(_, exp) => setOpen(exp)}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ py: 0 }}>
        <Typography variant="h4">Added ({items.length})</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <DAITable
          ref={tableRef}
          className={TableContainerClass.MODAL}
          rows={items.map(row => ({
            id: row.id,
            brand: row.brand?.label || '—',
            packaging: row.packaging?.label || '—',
            product: row.product?.label || '—',
          }))}
          columns={columns}
          rowCount={items.length}
          showCheckbox
          tableToolBarProps={{
            tableTitle: '',
            right: {
              iconButton: {
                buttonProps: {
                  onClick: () => {
                    const selected = tableRef.current?.state.selected
                    if (selected) handleRemove(selected)
                  },
                },
                icon: <Delete color="error" />,
                toolTipLabel: 'Delete Rows',
              },
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default PicOSAddedProductsAccordion
