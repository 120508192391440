"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_IMAGE = void 0;
var client_1 = require("@apollo/client");
var ProductImageFragment_1 = require("../fragments/ProductImageFragment");
var MultiProductImageFragment_1 = require("../fragments/MultiProductImageFragment");
var DisplayAuditImageFragment_1 = require("../fragments/DisplayAuditImageFragment");
var MerchandiserDisplayScanImageFragment_1 = require("../fragments/MerchandiserDisplayScanImageFragment");
var TrainingImageFragment_1 = require("../fragments/TrainingImageFragment");
var ScanImageFragment_1 = require("../fragments/ScanImageFragment");
exports.GET_IMAGE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetImage($imageId: ID!, $imageType: ImageType!) {\n    image(imageId: $imageId, imageType: $imageType) {\n      ... on ProductImage {\n        ...ProductImage\n      }\n      ... on MultiProductImage {\n        ...MultiProductImage\n      }\n      ... on DisplayAuditImage {\n        ...DisplayAuditImage\n      }\n      ... on MerchandiserDisplayScanImage {\n        ...MerchandiserDisplayScanImage\n      }\n      ... on ScanImage {\n        ...ScanImage\n      }\n      ... on TrainingImage {\n        ...TrainingImage\n      }\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  query GetImage($imageId: ID!, $imageType: ImageType!) {\n    image(imageId: $imageId, imageType: $imageType) {\n      ... on ProductImage {\n        ...ProductImage\n      }\n      ... on MultiProductImage {\n        ...MultiProductImage\n      }\n      ... on DisplayAuditImage {\n        ...DisplayAuditImage\n      }\n      ... on MerchandiserDisplayScanImage {\n        ...MerchandiserDisplayScanImage\n      }\n      ... on ScanImage {\n        ...ScanImage\n      }\n      ... on TrainingImage {\n        ...TrainingImage\n      }\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), MultiProductImageFragment_1.MULTI_PRODUCT_IMAGE_FRAGMENT, ProductImageFragment_1.PRODUCT_IMAGE_FRAGMENT, DisplayAuditImageFragment_1.DISPLAY_AUDIT_IMAGE_FRAGMENT, MerchandiserDisplayScanImageFragment_1.MERCHANDISER_DISPLAY_SCAN_IMAGE_FRAGMENT, ScanImageFragment_1.SCAN_IMAGE_FRAGMENT, TrainingImageFragment_1.TRAINING_IMAGE_FRAGMENT);
var templateObject_1;
