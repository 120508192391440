/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Michael Selander <michael.selander@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import {
  BrandLandscapeQueryQuery,
  BrandLandscapeQueryQueryVariables,
  BRAND_LANDSCAPE,
} from '@dai/graphql'
import React, { useEffect, useState } from 'react'
import {
  Brand,
  MuiTheme,
  ReportLayout,
  ScatterChart,
  ScatterDatum,
} from '@dai/web-components'
import { BrandDisruptorQueryProps } from 'brands/brand-disruptor/brand-disruptor.types'
import { AxisRenderer, LineSeries, ValueAxis } from '@amcharts/amcharts5/xy'
import { Color, color, Tooltip } from '@amcharts/amcharts5'
import { BrandSelector } from './BrandSelector'

const roundBps = (num: number) => {
  return Math.round(num * 1000) / 1000
}

const SELECTED_DOT_SIZE = 6
const STANDARD_DOT_SIZE = 1

export const BrandLandscape: React.FC<BrandDisruptorQueryProps> = props => {
  const {
    startDate,
    endDate,
    dateRangeType,
    selectedBrands,
    setSelectedBrands,
    moreInfo,
  } = props

  const [scatterData, setScatterData] = useState<ScatterDatum[]>([])

  const { data: brandLandscape, loading } = useQuery<
    BrandLandscapeQueryQuery,
    BrandLandscapeQueryQueryVariables
  >(BRAND_LANDSCAPE, {
    variables: {
      input: {
        startDate,
        endDate,
        type: dateRangeType,
      },
    },
  })

  useEffect(() => {
    if (brandLandscape) {
      const scatterData = brandLandscape.brandLandscape.results.map(item => {
        const selected = selectedBrands
          .map(brand => brand.name)
          .includes(item.brand.name)
        return {
          title: item.brand.name,
          id: item.brand.id,
          x: item.changeInSovi,
          y: item.sovi,
          value: selected ? SELECTED_DOT_SIZE : STANDARD_DOT_SIZE,
          color: selected ? MuiTheme.palette.success.main : undefined,
        }
      })
      setScatterData(scatterData)
    }
  }, [brandLandscape, selectedBrands])

  const handleBubbleClicked = (data: ScatterDatum) => {
    const selectedIds = selectedBrands.map(b => b.id)
    if (selectedIds.includes(data.id)) {
      setSelectedBrands(selectedBrands.filter(b => b.id != data.id))
    } else {
      setSelectedBrands([...selectedBrands, { id: data.id, name: data.title }])
    }
  }

  return (
    <ReportLayout
      title={'Brand Landscape'}
      subtitle={'Non-Core Brands'}
      moreInfo={moreInfo}
      Body={
        <ScatterChart
          isLoading={loading}
          chartId="scatter"
          xAxisLabel="Change in SOVI"
          yAxisLabel="Current SOVI (Past 14 Days)"
          smallNumberPrefixes={[
            { number: 1.0 / 100, suffix: 'bps' },
            { number: 1.0 / 10000, suffix: 'cbps' },
            { number: 1.0 / 100000, suffix: 'mbps' },
            { number: 1.0 / 1000000, suffix: 'nbps' },
          ]}
          renderExtraSeries={({ root, chart, xAxis, yAxis }) => {
            const dividerLineData = [
              {
                x: Math.min(...scatterData.map(d => d.x)) - 1,
                y: 0.0001,
              },
              {
                x: Math.max(...scatterData.map(d => d.x)) + 1,
                y: 0.0001,
              },
            ]
            ;(xAxis as ValueAxis<AxisRenderer>).set(
              'max',
              Math.max(...scatterData.map(d => d.x)),
            )
            ;(xAxis as ValueAxis<AxisRenderer>).set(
              'min',
              Math.min(...scatterData.map(d => d.x)),
            )
            // @ts-ignore
            const dividerLine = chart.series.push(
              LineSeries.new(root, {
                calculateAggregates: true,
                xAxis,
                yAxis,
                valueYField: 'y',
                valueXField: 'x',
                fill: color(MuiTheme.palette.secondary.main),
                stroke: color(MuiTheme.palette.secondary.main),
                tooltip: Tooltip.new(root, {
                  labelText: 'Core Brand Threshold',
                  showTooltipOn: 'always',
                }),
              }),
            )
            dividerLine.data.setAll(dividerLineData)
            dividerLine.strokes.template.setAll({
              fill: Color.fromString(MuiTheme.palette.secondary.main),
              strokeWidth: 2,
              strokeDasharray: [10, 5],
            })
          }}
          data={scatterData}
          tooltip={[
            {
              text: '{title}',
              bold: true,
              newline: true,
            },
            {
              text: 'Change in SOVI: {valueX.formatNumber()}',
              newline: true,
            },
            {
              text: 'Current SOVI (14d): {valueY.formatNumber()}',
            },
          ]}
          isEmpty={!brandLandscape?.brandLandscape.results.length}
          onClick={handleBubbleClicked}
        />
      }
    />
  )
}
