"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GLOBAL_OPTIMIZATION_CADENCE = void 0;
var client_1 = require("@apollo/client");
exports.UPDATE_GLOBAL_OPTIMIZATION_CADENCE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdateGlobalOptimizationSchedule(\n    $input: GlobalUpdateOpitimizationCadenceInput!\n  ) {\n    globalUpdateOptimizingCadence(input: $input) {\n      success\n      numTimesOptimizeHighPerYear\n      numTimesOptimizeMediumPerYear\n      numTimesOptimizeLowPerYear\n      timesPerWeekTwoDScan\n    }\n  }\n"], ["\n  mutation UpdateGlobalOptimizationSchedule(\n    $input: GlobalUpdateOpitimizationCadenceInput!\n  ) {\n    globalUpdateOptimizingCadence(input: $input) {\n      success\n      numTimesOptimizeHighPerYear\n      numTimesOptimizeMediumPerYear\n      numTimesOptimizeLowPerYear\n      timesPerWeekTwoDScan\n    }\n  }\n"])));
var templateObject_1;
