/**
 * Copyright © 2020-2021 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clarksandholtz@gmail.com>
 */

import { auth } from './Firebase'

export const logout = async () => {
  try {
    await auth.signOut()
    window.location.replace('/login')
  } catch (error: any) {
    console.error('Error logging out', error)
  }
}

export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    const token = await auth.currentUser?.getIdToken()
    return token
  } catch (error: any) {
    console.error('Error getting firebase auth token: ', error)
    return error
  }
}
