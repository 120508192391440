"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MINIMAL_STORE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.MINIMAL_STORE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment MinimalStore on CompanyStore {\n    id\n    name\n    category\n    physicalStoreId\n    image {\n      url\n      id\n    }\n    address {\n      address1\n      address2\n      city\n      state\n      postalCode\n    }\n    point {\n      latitude\n      longitude\n    }\n  }\n"], ["\n  fragment MinimalStore on CompanyStore {\n    id\n    name\n    category\n    physicalStoreId\n    image {\n      url\n      id\n    }\n    address {\n      address1\n      address2\n      city\n      state\n      postalCode\n    }\n    point {\n      latitude\n      longitude\n    }\n  }\n"])));
var templateObject_1;
