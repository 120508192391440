"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_OR_UPDATE_ZONE_TERRITORY = void 0;
var client_1 = require("@apollo/client");
exports.CREATE_OR_UPDATE_ZONE_TERRITORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nmutation CreateOrUpdateZoneTerritory($input: CreateOrUpdateZoneTerritoryInput!) {\n  createOrUpdateZoneTerritory(input: $input) {\n    territory {\n      manager {\n        email\n      }\n      routes {\n        id\n        manager {\n          email\n        }\n        stores {\n          id\n        }\n      }\n    }\n  }\n}\n"], ["\nmutation CreateOrUpdateZoneTerritory($input: CreateOrUpdateZoneTerritoryInput!) {\n  createOrUpdateZoneTerritory(input: $input) {\n    territory {\n      manager {\n        email\n      }\n      routes {\n        id\n        manager {\n          email\n        }\n        stores {\n          id\n        }\n      }\n    }\n  }\n}\n"])));
var templateObject_1;
