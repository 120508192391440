/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import {
  GetDisplayRestockHistory_displayRestockHistory_results_inventoryBefore,
  GetDisplayRestockHistory_displayRestockHistory_results_transactions,
} from '@dai/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { uniq } from 'lodash'
import ProductColumns from './ProductRows'

const getChangeString = (value: number) => {
  if (value > 0) {
    return `+${value}`
  }
  return value
}

const getError = (
  predicted: { quantity: number } | undefined,
  actual: { quantity: number } | undefined,
) => {
  if (predicted && actual) {
    return `${Math.floor(
      Math.sqrt(Math.pow(1 - predicted.quantity / actual.quantity, 2)) * 100,
    )}%`
  }
  return '?'
}

type CombinedInventoryTransactionTableProps = {
  transactions: GetDisplayRestockHistory_displayRestockHistory_results_transactions[]
  inventoryBefore: GetDisplayRestockHistory_displayRestockHistory_results_inventoryBefore[]
  inventoryAfter: GetDisplayRestockHistory_displayRestockHistory_results_inventoryBefore[]
  centroids: { upc: string; quantity: number }[]
  customerDistributedUpcs: string[]
}

const CombinedInventoryTransaction: React.FC<CombinedInventoryTransactionTableProps> = (
  props: CombinedInventoryTransactionTableProps,
) => {
  const {
    transactions,
    inventoryAfter,
    inventoryBefore,
    centroids,
    customerDistributedUpcs,
  } = props
  const allUpcs = [
    ...transactions.map(p => p.upc),
    ...centroids.map(c => c.upc),
    ...inventoryBefore.map(i => i.upc),
    ...inventoryAfter.map(i => i.upc),
  ]
  const uniqueUpcs = uniq(allUpcs)
  const transactionsObj: {
    [key in string]: GetDisplayRestockHistory_displayRestockHistory_results_transactions
  } = transactions.reduce((obj, t) => ({ ...obj, [t.upc]: { ...t } }), {})
  const inventoryAfterUpcObj: {
    [key in string]: GetDisplayRestockHistory_displayRestockHistory_results_inventoryBefore
  } = inventoryAfter.reduce((obj, i) => ({ ...obj, [i.upc]: { ...i } }), {})
  const inventoryBeforeUpcObj: {
    [key in string]: GetDisplayRestockHistory_displayRestockHistory_results_inventoryBefore
  } = inventoryBefore.reduce((obj, i) => ({ ...obj, [i.upc]: { ...i } }), {})
  const centroidsAggregateObj: {
    [key in string]: { quantity: number }
  } = centroids.reduce((obj, c) => {
    if (obj[c.upc]) {
      return { ...obj, [c.upc]: { quantity: obj[c.upc].quantity + c.quantity } }
    }
    return { ...obj, [c.upc]: { quantity: c.quantity } }
  }, {} as { [key in string]: { quantity: number } })
  return (
    // <TableContainer sx={{ maxWidth: { md: 752 } }}>
    // TODO: look here to see if we should set a max width on the table for tablet...
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Before</TableCell>
            <TableCell>Change</TableCell>
            <TableCell>After</TableCell>
            <TableCell>Facings</TableCell>
            <TableCell>Predicted</TableCell>
            <TableCell>Human Verified</TableCell>
            <TableCell>Count Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uniqueUpcs.map(upc => (
            <ProductColumns
              isCompetitor={!customerDistributedUpcs.includes(upc)}
              upc={upc}
              columnsFromProduct={['marketingImageUrl', 'name']}
              additionalColumnValues={[
                inventoryBeforeUpcObj[upc]?.quantity || 0,
                transactionsObj[upc]
                  ? getChangeString(
                      transactionsObj[upc].credit - transactionsObj[upc].debit,
                    )
                  : 0,
                inventoryAfterUpcObj[upc]?.quantity || 0,
                transactionsObj[upc]
                  ? transactionsObj[upc].numFacings || '?'
                  : '?',
                centroidsAggregateObj[upc]?.quantity || 0,
                transactionsObj[upc]
                  ? String(transactionsObj[upc].fieldVerified)
                  : '?',
                getError(centroidsAggregateObj[upc], inventoryAfterUpcObj[upc]),
              ]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CombinedInventoryTransaction
