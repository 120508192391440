/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportSummaryHook,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { useQuery } from '@apollo/client'
import {
  AggregationMethod,
  GET_DISPLAY_SCANS,
  GET_PRODUCT_COUNTED,
  GetDisplayScansQuery,
  GetDisplayScansQueryVariables,
  GetProductCountedQuery,
  GetProductCountedQueryVariables,
} from '@dai/graphql'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'

export const useActivityReportSummaries: GenericReportSummaryHook<UserProductivityReportName> = () => {
  const useGetProductCountedAggregate = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useSummaryQuery } = useUserProductivityQuery(reportParams)
    const {
      getProductProductivityQueryInput,
      formatStatCardStatistic,
      formatOverTimeChange,
    } = useSummaryQuery()
    const { data: allCounts, loading } = useQuery<
      GetProductCountedQuery,
      GetProductCountedQueryVariables
    >(GET_PRODUCT_COUNTED, {
      variables: {
        input: getProductProductivityQueryInput(AggregationMethod.SUM, false),
      },
    })
    const { data: allCountsByMonth, loading: loadingByMonth } = useQuery<
      GetProductCountedQuery,
      GetProductCountedQueryVariables
    >(GET_PRODUCT_COUNTED, {
      variables: {
        input: getProductProductivityQueryInput(AggregationMethod.SUM, true),
      },
    })
    return {
      loading: loading || loadingByMonth,
      title: `Product Counted`,
      stat: formatStatCardStatistic(
        allCounts?.productCounted.results[0]?.count,
      ),
      change: formatOverTimeChange(
        allCountsByMonth?.productCounted.results,
        'count',
      ),
    }
  }
  const useGetTotalRestocksAggregate = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useSummaryQuery } = useUserProductivityQuery(reportParams)
    const {
      getUserProductivityQueryInput,
      formatStatCardStatistic,
      formatOverTimeChange,
    } = useSummaryQuery()
    const { data: userRestocks, loading } = useQuery<
      GetDisplayScansQuery,
      GetDisplayScansQueryVariables
    >(GET_DISPLAY_SCANS, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.COUNT, false),
      },
    })
    const { data: userRestocksByMonth, loading: byMonthLoading } = useQuery<
      GetDisplayScansQuery,
      GetDisplayScansQueryVariables
    >(GET_DISPLAY_SCANS, {
      variables: {
        input: getUserProductivityQueryInput(AggregationMethod.COUNT, true),
      },
    })
    return {
      loading: loading || byMonthLoading,
      title: `Total Restocks`,
      stat: formatStatCardStatistic(
        userRestocks?.displayScans.results[0]?.count,
      ),
      change: formatOverTimeChange(
        userRestocksByMonth?.displayScans.results,
        'count',
      ),
    }
  }

  return [
    {
      reportName: 'product-counted',
      useGetData: useGetProductCountedAggregate,
    },
    {
      reportName: 'restocks',
      useGetData: useGetTotalRestocksAggregate,
    },
  ]
}
