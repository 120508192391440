/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  useDebouncedItemQuery,
  PicOSComplianceCardReport,
  usePagination,
  BasicPicOSCustomerInfo,
  PicOSReportContext,
} from '@dai/web-components'
import React, { FC, useContext, useEffect } from 'react'
import {
  GetPicOSAllCustomersComplianceQuery,
  GetPicOSAllCustomersComplianceStatsQuery,
  GetPicOSAllCustomersComplianceStatsQueryVariables,
  GetPicOSAllCustomersComplianceQueryVariables,
  GET_PICOS_ALL_CUSTOMERS_COMPLIANCE,
  GET_PICOS_ALL_CUSTOMERS_COMPLIANCE_STATS,
} from '@dai/graphql'
import { useHistory } from 'react-router-dom'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import { plurality } from '@dai/common'

const pageSize = 20

const PicOSAllCustomers: FC = () => {
  const history = useHistory()
  const { selectedDateRange, setSelectedCustomer } = useContext(
    PicOSReportContext,
  )
  const {
    offset,
    limit,
    currentPage,
    handleOffset: { goToPage },
  } = usePagination({ pageSize })

  const OverviewQuery = useDebouncedItemQuery<
    GetPicOSAllCustomersComplianceQuery,
    GetPicOSAllCustomersComplianceQueryVariables
  >({
    queryStr: GET_PICOS_ALL_CUSTOMERS_COMPLIANCE,
    options: {
      variables: {
        input: {
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    GetPicOSAllCustomersComplianceStatsQuery,
    GetPicOSAllCustomersComplianceStatsQueryVariables
  >({
    queryStr: GET_PICOS_ALL_CUSTOMERS_COMPLIANCE_STATS,
    options: {
      variables: {
        input: {
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const overviewQueryMeta = OverviewQuery.lazyQuery.meta
  const statsQueryMeta = StatsQuery.lazyQuery.meta

  const handleSelectCustomer = (customer: BasicPicOSCustomerInfo) => {
    setSelectedCustomer(customer)
    history.push(`customers/${customer.id}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  const handleExportCsv = () => {
    // TODO: Implement on backend
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          customerName: OverviewQuery.debouncedQuery || undefined,
          offset,
          limit,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const customersData =
    overviewQueryMeta.data?.picosAllCustomersCompliance.results || []
  const stats = statsQueryMeta.data?.picosAllCustomersStats

  const totalItems =
    overviewQueryMeta.data?.picosAllCustomersCompliance.count || 0
  const totalPages = Math.ceil(totalItems / pageSize)

  return (
    <PicOSComplianceCardReport
      title="Customers"
      loadingStats={statsQueryMeta.loading}
      loadingComplianceItems={overviewQueryMeta.loading}
      onSearch={handleSearch}
      onClickExportCsv={handleExportCsv}
      pageSize={pageSize}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(_, page) => goToPage(page)}
      onSelect={handleSelectCustomer}
      statItems={PicOSReportHelpers.getStatItems(
        ['compliance', 'customers', 'stores', 'priorities', 'cost'],
        stats,
      )}
      complianceItems={customersData.map(customer => ({
        id: customer.id,
        title: customer.name,
        compliance:
          customer.totalPriorities !== 0
            ? customer.numCompliant / customer.totalPriorities
            : 0,
        details: [
          {
            count: customer.numChildren,
            name: plurality('Division', customer.numChildren),
          },
          {
            count: customer.numGrandchildren,
            name: plurality('Store', customer.numGrandchildren),
          },
        ],
      }))}
    />
  )
}

export default PicOSAllCustomers
