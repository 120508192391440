/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useMutation, useQuery } from '@apollo/client'
import { useUrlSearchParams } from '@dai/common'
import {
  GET_ZONE,
  GetRetiredUsersForZoneQuery,
  GetRetiredUsersForZoneQueryVariables,
  GET_RETIRED_USERS_FOR_ZONE,
  GetZoneHierarchyQuery,
  GetZoneQuery,
  GetZoneQueryVariables,
  ZONE_HIERARCHY_GLOBAL_SEARCH,
  ZoneHierarchyGlobalSearchQuery,
  ZoneHierarchyGlobalSearchQueryVariables,
  RemoveStoresFromZoneMutation,
  RemoveStoresFromZoneMutationVariables,
  REMOVE_STORES_FROM_ZONE,
  ZoneTier,
} from '@dai/graphql'
import { GET_ZONE_HIERARCHY } from '@dai/graphql'
import {
  NotificationContext,
  StringHelpers,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { useContext, useEffect, useState } from 'react'
import { groupBy } from 'lodash'
import { Zone, ZoneHierarchUrlSearchParams } from './types'
import {
  useZoneMutateAreasModalLogic,
  useZoneMutateStoresModalLogic,
  useZoneMutateUsersModalLogic,
} from './use-zone-mutate-modal.logic'

export const useZoneHierarchyLogic = () => {
  const {
    state: { zoneId, query, zoneTab },
    handle: { handleUpdateSearchParams },
  } = useUrlSearchParams<ZoneHierarchUrlSearchParams>()
  const { setError, setSuccess } = useContext(NotificationContext)

  const [searchString, setSearchString] = useState('')

  const [removeStoresFromZone] = useMutation<
    RemoveStoresFromZoneMutation,
    RemoveStoresFromZoneMutationVariables
  >(REMOVE_STORES_FROM_ZONE)

  const handleRemoveStoreFromZone = async (zoneId: string, storeId: string) =>
    removeStoresFromZone({
      variables: {
        input: {
          zoneId,
          ids: [storeId],
        },
      },
    })
      .then(() => {
        setSuccess(`Your changes were saved successfully.`)
      })
      .catch(error => setError(`An error occurred: ${error}`))

  const {
    data,
    loading: zoneHierarchyLoading,
  } = useQuery<GetZoneHierarchyQuery>(GET_ZONE_HIERARCHY, {
    fetchPolicy: 'cache-first',
  })
  const { data: retiredUsersResponse } = useQuery<
    GetRetiredUsersForZoneQuery,
    GetRetiredUsersForZoneQueryVariables
  >(GET_RETIRED_USERS_FOR_ZONE, {
    fetchPolicy: 'network-only',
    variables: { input: { limit: 100 } },
  })

  const GetZone = useDebouncedItemQuery<GetZoneQuery, GetZoneQueryVariables>({
    queryStr: GET_ZONE,
    options: { fetchPolicy: 'network-only' },
  })

  const ZoneSearch = useDebouncedItemQuery<
    ZoneHierarchyGlobalSearchQuery,
    ZoneHierarchyGlobalSearchQueryVariables
  >({
    queryStr: ZONE_HIERARCHY_GLOBAL_SEARCH,
    options: { fetchPolicy: 'cache-first' },
  })

  const zoneHierarchy = data?.zoneHierarchy.zones || []

  const zone: Zone | null = GetZone.lazyQuery.meta.data?.zone || null
  const zoneError = GetZone.lazyQuery.meta.error
  const zoneLoading = GetZone.lazyQuery.meta.loading

  const retiredUsers =
    retiredUsersResponse?.retiredUsersForZone.results
      .filter(u => !!u.zone?.zoneId)
      .map<{ zoneId: string }>(u => ({
        ...u,
        zoneId: u.zone!.zoneId!,
      })) || []

  const zonesWithRetiredUsers = Object.values(
    groupBy(retiredUsers, 'zoneId'),
  ).flat()

  const searchResults = ZoneSearch.lazyQuery.meta.data
    ?.zoneHierarchyGlobalSearch || {
    stores: [],
    areas: [],
    people: [],
  }

  const zoneUsersLogic = useZoneMutateUsersModalLogic({ zone })
  const zoneStoresLogic = useZoneMutateStoresModalLogic({ zone })
  const zoneAreasLogic = useZoneMutateAreasModalLogic({ zone })

  useEffect(() => {
    GetZone.lazyQuery.query({
      variables: { zoneId: zoneId || null },
    })
  }, [zoneId])

  useEffect(() => {
    if (zone) {
      const newTab =
        zoneTab === undefined
          ? zone.tier === ZoneTier.ROUTE
            ? 'stores'
            : 'areas'
          : zoneTab
      handleUpdateSearchParams({ zoneTab: newTab })
    }
  }, [zone])

  useEffect(() => {
    if (ZoneSearch.debouncedQuery) {
      ZoneSearch.lazyQuery
        .query({
          variables: {
            searchTerm: ZoneSearch.debouncedQuery,
          },
        })
        .then(() => {
          handleUpdateSearchParams({ query: ZoneSearch.itemQuery })
        })
    }
  }, [ZoneSearch.debouncedQuery])

  useEffect(() => {
    if (
      StringHelpers.isNullOrEmpty(ZoneSearch.itemQuery) &&
      !StringHelpers.isNullOrEmpty(query)
    ) {
      ZoneSearch.setItemQuery(query!)
    }
  }, [])

  const filterFn = (o: { name: string }) =>
    o.name.toLowerCase().includes(searchString.toLowerCase())

  const filteredUsers = zone?.assignedUsers.filter(filterFn) || []

  const filteredStores = zone?.stores.filter(filterFn) || []

  const filteredSubzones = zone?.subZones.filter(filterFn) || []

  return {
    state: {
      zoneId,
      query,
      zoneTab,
      searchString,
      setSearchString,
    },
    data: {
      zonesWithRetiredUsers,
      searchResults,
      zone,
      zoneError,
      zoneLoading,
      zoneHierarchy,
      zoneHierarchyLoading,
      filteredUsers,
      filteredStores,
      filteredSubzones,
    },
    handle: {
      handleUpdateSearchParams,
      handleRemoveStoreFromZone,
    },
    ZoneSearch,
    zoneUsersLogic,
    zoneStoresLogic,
    zoneAreasLogic,
  }
}
