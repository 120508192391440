"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MULTI_PRODUCT_IMAGE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var BoundingBoxesFragment_1 = require("./BoundingBoxesFragment");
var MultiProductImageProductFragment_1 = require("./MultiProductImageProductFragment");
exports.MULTI_PRODUCT_IMAGE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment MultiProductImage on MultiProductImage {\n    id\n    url\n    upcSet\n    boundingBoxSet {\n      ...BoundingBoxes\n    }\n    products {\n      ...MultiProductImageProduct\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment MultiProductImage on MultiProductImage {\n    id\n    url\n    upcSet\n    boundingBoxSet {\n      ...BoundingBoxes\n    }\n    products {\n      ...MultiProductImageProduct\n    }\n  }\n  ", "\n  ", "\n"])), BoundingBoxesFragment_1.BOUNDING_BOXES_FRAGMENT, MultiProductImageProductFragment_1.MULTI_PRODUCT_IMAGE_PRODUCT_FRAGMENT);
var templateObject_1;
