/**
 * Copyright © 2023 Delicious AI, LLC
 */

import { useMutation, useQuery } from '@apollo/client'
import {
  SabreUpdateCategoryMutation,
  SabreUpdateCategoryMutationVariables,
  SabreCategoryFragment,
  SabreCategoriesQuery,
  SabreCategoriesQueryVariables,
  SabreCreateCategoryInput,
  SabreCreateCategoryMutation,
  SabreCreateCategoryMutationVariables,
  SabreDeleteCategoryMutation,
  SabreDeleteCategoryMutationVariables,
  SabreUpdateCategoryInput,
  SabreDeleteCategoryInput,
  SABRE_GET_CATEGORIES,
  SABRE_UPDATE_CATEGORY,
  SABRE_CREATE_CATEGORY,
  SABRE_DELETE_CATEGORY,
} from '@dai/graphql'
import { NotificationContext, SelectableOption } from '@dai/web-components'
import { CategoryRow } from 'categories/categories.types'
import { useContext, useEffect, useMemo, useState } from 'react'

export type ModalState =
  | 'edit-category'
  | 'create-category'
  | 'delete-category'
  | null

export const useCategoriesLogic = () => {
  const [modalState, setModalState] = useState<ModalState>(null)

  const [searchResults, setSearchResults] = useState<SelectableOption[]>([])

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  const notificationState = useContext(NotificationContext)

  const [categoryEditing, setCategoryEditing] = useState<
    SabreCategoryFragment | undefined
  >()

  const { data: categoriesData } = useQuery<
    SabreCategoriesQuery,
    SabreCategoriesQueryVariables
  >(SABRE_GET_CATEGORIES, {
    context: { endPoint: 'sabre' },
  })

  const refresh = () => {
    window.location.reload()
  }

  const categories = categoriesData?.categories.results || []
  const rowCount = categoriesData?.categories.count || 0

  const [updateCategory] = useMutation<
    SabreUpdateCategoryMutation,
    SabreUpdateCategoryMutationVariables
  >(SABRE_UPDATE_CATEGORY, { context: { endPoint: 'sabre' } })

  const [createCategory] = useMutation<
    SabreCreateCategoryMutation,
    SabreCreateCategoryMutationVariables
  >(SABRE_CREATE_CATEGORY, { context: { endPoint: 'sabre' } })

  const [deleteCategory] = useMutation<
    SabreDeleteCategoryMutation,
    SabreDeleteCategoryMutationVariables
  >(SABRE_DELETE_CATEGORY, { context: { endPoint: 'sabre' } })

  useEffect(() => {
    if (selectedId) {
      const cat = categories.find(c => c.id === selectedId)
      if (cat) {
        setCategoryEditing(cat)
      }
    }
  }, [selectedId])

  const handleParentChange = (parentId: string) => {
    const parentCat = categories.find(cat => cat.id === parentId) || undefined
    const newParent = parentCat
      ? { id: parentCat?.id, name: parentCat?.name }
      : undefined
    if (categoryEditing && newParent) {
      setCategoryEditing(
        c =>
          c && {
            ...c,
            parent: newParent as SabreCategoryFragment,
          },
      )
    }
  }

  const handleNameChange = (name: string) => {
    setCategoryEditing(c =>
      c
        ? {
            ...c,
            name,
          }
        : ({ name } as SabreCategoryFragment),
    )
  }

  const handleSubmit = () => {
    const input = {
      id: categoryEditing?.id,
      name: categoryEditing?.name,
      parentId: categoryEditing?.parent?.id,
    }
    if (modalState === 'edit-category') {
      updateCategory({
        variables: { input: input as SabreUpdateCategoryInput },
      })
        .then(res => {
          refresh()
          notificationState.setSuccess(
            `Successfully updated new category: "${res?.data?.updateCategory?.category.name}"`,
          )
        })
        .catch(err =>
          notificationState.setError(`Failed to update new category: ${err}`),
        )
    }
    if (modalState === 'create-category') {
      createCategory({
        variables: { input: input as SabreCreateCategoryInput },
      })
        .then(res => {
          refresh()
          notificationState.setSuccess(
            `Successfully created new category: "${res?.data?.createCategory?.category.name}"`,
          )
        })
        .catch(err =>
          notificationState.setError(`Failed to create new category: ${err}`),
        )
    }
  }

  const handleDelete = (categoryId: string) => {
    deleteCategory({
      variables: { input: { id: categoryId } as SabreDeleteCategoryInput },
    })
      .then(() => {
        refresh()
        notificationState.setSuccess(`Successfully deleted category`)
      })
      .catch(err =>
        notificationState.setError(`Failed to create new category: ${err}`),
      )
  }

  const categoryRows: CategoryRow[] = useMemo(
    () =>
      categories.map(c => ({
        name: c.name,
        id: c.id,
        parent: c.parent?.name || '-',
      })),
    [categories],
  )

  return {
    state: {
      setModalState,
      modalState,
      categoryEditing,
      setCategoryEditing,
      selectedId,
      setSelectedId,
      searchResults,
      setSearchResults,
    },
    data: {
      categoryRows,
      rowCount,
    },
    handle: {
      handleNameChange,
      handleParentChange,
      handleSubmit,
      handleDelete,
    },
  }
}
