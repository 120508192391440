"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ML_MODEL_FILE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.ML_MODEL_FILE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment MLModelFile on MLModel {\n    id\n    version\n    bucketFileName\n    namesBucketFileName\n    modelType\n    configBucketFileName\n  }\n"], ["\n  fragment MLModelFile on MLModel {\n    id\n    version\n    bucketFileName\n    namesBucketFileName\n    modelType\n    configBucketFileName\n  }\n"])));
var templateObject_1;
