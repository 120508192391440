"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PRODUCT_BY_UPC = void 0;
var client_1 = require("@apollo/client");
var SimpleProductFragment_1 = require("../fragments/SimpleProductFragment");
exports.GET_PRODUCT_BY_UPC = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetProductByUpc($upc: ID!) {\n    productByUpc(upc: $upc) {\n      ...SimpleProduct\n    }\n  }\n  ", "\n"], ["\n  query GetProductByUpc($upc: ID!) {\n    productByUpc(upc: $upc) {\n      ...SimpleProduct\n    }\n  }\n  ", "\n"])), SimpleProductFragment_1.SIMPLE_PRODUCT_FRAGMENT);
var templateObject_1;
