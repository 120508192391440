/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Braxton Geary <braxton.geary@deliciousai.com>
 */

import React, { useEffect, useState } from 'react'
import {
  FileUploader,
  HandleFormChangeFn,
  MuiFormModal,
  MuiFormModalProps,
} from '@dai/web-components'
import { Close, Save } from '@mui/icons-material'
import { DZFile } from '@dai/common'

interface StoreCSVUploadProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  userStores?: string[] | null | undefined
  handleFormChange: (newStoreGroupName: string) => void
  setInternalIdList: (newList: string[]) => void
  onSubmit: () => void
}

export const StoreCSVUploadModal: React.FunctionComponent<StoreCSVUploadProps> = props => {
  const {
    isOpen,
    onSubmit,
    handleClose,
    handleFormChange,
    setInternalIdList,
  } = props

  const [uploadFile, setUploadFile] = useState<DZFile>()

  useEffect(() => {
    if (uploadFile !== undefined) {
      const reader = new FileReader()
      reader.readAsText(uploadFile)
      reader.onload = () => {
        if (reader.result) {
          const text = reader.result.toString()
          const lines = text.split('\n')
          const ids = lines
            .map(line => line.slice(0, line.search(/,/gi)))
            .filter(id => id.search(/internal/gi) === -1 && id !== '')
          const fileName = uploadFile.name
            .slice(0, uploadFile.name.search(/.csv/gi))
            .replace(/_/gi, ' ')
          handleFormChange(fileName)
          setInternalIdList(ids)
        }
      }
    }
  }, [uploadFile])

  return (
    <MuiFormModal
      sx={{ padding: 0 }}
      title="CSV Upload"
      subtitle={'Drag or Drop CSV file to create store group'}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          startIcon: <Close color="secondary" />,
          disabled: false,
          color: 'secondary',
        },
        {
          onClick: () => {
            onSubmit()
          },
          children: 'Save',
          startIcon: <Save color="secondary" />,
          disabled: false,
        },
      ]}
    >
      <FileUploader
        title={`Upload CSV`}
        onFilesSelected={files => setUploadFile(files[0])}
        files={uploadFile ? [uploadFile] : undefined}
        onClearFiles={() => setUploadFile(undefined)}
        allowedFileTypes={['.csv']}
      />
    </MuiFormModal>
  )
}
