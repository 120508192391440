/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useGetGroups } from '@dai/common'
import { useEffect, useState } from 'react'
import { FILL_GRID_ALL_SCREENS, DAIFormState } from '@dai/web-components'

export const useCreateUserForm = () => {
  const { groups } = useGetGroups()
  const CREATE_USER_FORM: DAIFormState<'text' | 'select'> = {
    text: {
      EMAIL: {
        validationFn: value => ({
          error: !!value.length && !isValidEmail(value),
          help:
            value.length && !isValidEmail(value)
              ? 'Valid email required'
              : undefined,
        }),
        input: {
          placeholder: 'user@example.com',
          required: true,
          type: 'email',
          variant: 'filled',
          label: 'Email',
        },
        gridProps: FILL_GRID_ALL_SCREENS,
        value: '',
      },
      'FIRST NAME': {
        validationFn: value => ({
          error: !value.length,
          help: !value.length ? 'First name is required' : undefined,
        }),
        input: {
          required: true,
          type: 'text',
          variant: 'filled',
          label: 'First Name',
        },
        gridProps: {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        },
        value: '',
      },
      'LAST NAME': {
        validationFn: value => ({
          error: !value.length,
          help: !value.length || !value ? 'Last name is required' : undefined,
        }),
        input: {
          required: true,
          type: 'text',
          variant: 'filled',
          label: 'Last Name',
        },
        gridProps: {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        },
        value: '',
      },
    },
    select: {
      GROUPS: {
        help: groups
          ? 'Assign roles for this user'
          : 'An error occurred loading the groups...',
        variant: 'filled',
        label: 'Groups',
        error: groups === undefined,
        input: {
          multiple: true,
        },
        options:
          groups?.map(group => ({ label: group.name, value: group.id })) || [],
        gridProps: FILL_GRID_ALL_SCREENS,
        value: [],
      },
    },
  }
  const [formState, setFormState] = useState<DAIFormState<'text' | 'select'>>(
    CREATE_USER_FORM,
  )

  const isValidEmail = (email: string): boolean =>
    /^.*?@.*?\.[a-zA-Z]+\s*?$/.test(email)

  useEffect(() => {
    setFormState(CREATE_USER_FORM)
  }, [groups?.length])

  return {
    formState,
    setFormState,
  }
}
