"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PRODUCT_PRICE_OBJECTS = void 0;
var client_1 = require("@apollo/client");
var ProductPriceObjectFragment_1 = require("../fragments/ProductPriceObjectFragment");
exports.GET_PRODUCT_PRICE_OBJECTS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetProductPriceObjects($storeId: ID, $storeSize: ForStoreSize) {\n    productPriceObjects(storeId: $storeId, storeSize: $storeSize) {\n      ...ProductPriceObject\n    }\n  }\n  ", "\n"], ["\n  query GetProductPriceObjects($storeId: ID, $storeSize: ForStoreSize) {\n    productPriceObjects(storeId: $storeId, storeSize: $storeSize) {\n      ...ProductPriceObject\n    }\n  }\n  ", "\n"])), ProductPriceObjectFragment_1.PRODUCT_PRICE_OBJECT_FRAGMENT);
var templateObject_1;
