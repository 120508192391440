"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHELF_COMPLIANCE_GROUP_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ModComActualFacing_1 = require("./ModComActualFacing");
exports.SHELF_COMPLIANCE_GROUP_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment ShelfComplianceGroup on ShelfComplianceGroup {\n    id\n    strictScore\n    sequenceScore\n    shelf {\n      isUnderShelf\n      yOffset\n      depth\n      displayShelfNumber\n      segmentShelfNumber\n    }\n    productScores {\n      upc\n      totalProductFullness\n      requiredVerticalFacings\n      requiredFacingsCount\n      compliantFacingsCount\n      totalAvailableProducts\n    }\n    facingScores {\n      upc\n      score\n      issue\n      resolved\n      actuals {\n        ...ModComActualFacing\n      }\n    }\n  }\n  ", "\n"], ["\n  fragment ShelfComplianceGroup on ShelfComplianceGroup {\n    id\n    strictScore\n    sequenceScore\n    shelf {\n      isUnderShelf\n      yOffset\n      depth\n      displayShelfNumber\n      segmentShelfNumber\n    }\n    productScores {\n      upc\n      totalProductFullness\n      requiredVerticalFacings\n      requiredFacingsCount\n      compliantFacingsCount\n      totalAvailableProducts\n    }\n    facingScores {\n      upc\n      score\n      issue\n      resolved\n      actuals {\n        ...ModComActualFacing\n      }\n    }\n  }\n  ", "\n"])), ModComActualFacing_1.MOD_COM_ACTUAL_FACING);
var templateObject_1;
