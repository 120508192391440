/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext, useEffect } from 'react'
import {
  DAIFormState,
  FILL_GRID_ALL_SCREENS,
  gridAllScreens,
  MuiFormTypes,
  useDebouncedItemQuery,
  useMuiForm,
} from '@dai/web-components'
import {
  DisplayElements,
  DisplayLocationsQuery,
  DisplayLocationsQueryVariables,
  DisplayTypesQuery,
  DisplayTypesQueryVariables,
  GET_DISPLAY_LOCATIONS,
  GET_DISPLAY_TYPES,
} from '@dai/graphql'
import PicOSContext from 'context/PicOSContext'
import { helpMsg, OptionsCheckBox } from 'picos/components/OptionsCheckBox'
import { merge, uniqBy } from 'lodash'

export interface PicosDisplayForm
  extends DAIFormState<'searchSelect' | 'radio' | 'checkbox'> {
  searchSelect: {
    TYPES: MuiFormTypes['searchSelect']
    LOCATIONS: MuiFormTypes['searchSelect']
  }
  radio: {}
  checkbox: {
    SUSTAIN: MuiFormTypes['checkbox']
    POS: MuiFormTypes['checkbox']
  }
}

export const usePicosDisplayInfoForm = () => {
  const {
    priorityToEdit,
    storeTypesInCustomer,
    setPriorityToEdit,
  } = useContext(PicOSContext)

  const DisplayTypesLazyQuery = useDebouncedItemQuery<
    DisplayTypesQuery,
    DisplayTypesQueryVariables
  >({
    queryStr: GET_DISPLAY_TYPES,
    options: { fetchPolicy: 'cache-first' },
  })

  const DisplayLocationsLazyQuery = useDebouncedItemQuery<
    DisplayLocationsQuery,
    DisplayLocationsQueryVariables
  >({
    queryStr: GET_DISPLAY_LOCATIONS,
    options: { fetchPolicy: 'cache-first' },
  })

  const handleToggleAllowed = (
    variant: 'otherDisplayLocationsAllowed' | 'otherDisplayTypesAllowed',
  ) => {
    if (priorityToEdit) {
      setPriorityToEdit({
        ...priorityToEdit,
        [variant]: !priorityToEdit[variant],
      })
    }
  }

  const othersAllowed = {
    types: priorityToEdit.otherDisplayTypesAllowed || false,
    location: priorityToEdit.otherDisplayLocationsAllowed || false,
  }
  const displayTypesFromQuery =
    DisplayTypesLazyQuery.lazyQuery.meta.data?.displayTypes?.results.map(
      dt => ({
        label: dt.name,
        value: dt.id,
      }),
    ) || []
  const displayLocationsFromQuery =
    DisplayLocationsLazyQuery.lazyQuery.meta.data?.displayLocations?.results.map(
      dl => ({
        label: dl.name,
        value: dl.id,
      }),
    ) || []
  const displayForm: PicosDisplayForm = {
    searchSelect: {
      TYPES: {
        selectAllEnabled: true,
        label: 'Display Type',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: priorityToEdit.displayTypes.map(pr => pr.value),
        options: uniqBy(
          [...displayTypesFromQuery, ...priorityToEdit.displayTypes],
          'value',
        ),
        multiple: true,
        setSearchText: DisplayTypesLazyQuery.setItemQuery,
        loading: DisplayTypesLazyQuery.lazyQuery.meta.loading,
        required: true,
        onChange: displayTypes => {
          setPriorityToEdit({ ...priorityToEdit, displayTypes })
        },
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.types}
            onChange={() => {
              handleToggleAllowed('otherDisplayTypesAllowed')
            }}
            label="Allow other display types"
            tooltip={helpMsg('display types')}
          />
        ),
      },
      LOCATIONS: {
        selectAllEnabled: true,
        label: 'Display Location',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: priorityToEdit.displayLocations.map(pr => pr.value),
        options: uniqBy(
          [...displayLocationsFromQuery, ...priorityToEdit.displayLocations],
          'value',
        ),
        multiple: true,
        loading: DisplayLocationsLazyQuery.lazyQuery.meta.loading,
        setSearchText: DisplayLocationsLazyQuery.setItemQuery,
        required: true,
        onChange: displayLocations => {
          setPriorityToEdit({ ...priorityToEdit, displayLocations })
        },
        Footer: (
          <OptionsCheckBox
            checked={othersAllowed.location}
            onChange={() => {
              handleToggleAllowed('otherDisplayLocationsAllowed')
            }}
            label="Allow other display locations"
            tooltip={helpMsg('display locations')}
          />
        ),
      },
    },
    radio: {},
    checkbox: {
      SUSTAIN: {
        value: priorityToEdit.displayElements && [
          priorityToEdit.displayElements,
        ],
        label: 'Display Elements',
        required: false,
        group: {},
        options: [
          {
            label: 'Sustaining Elements',
            value: DisplayElements.SUSTAIN,
          },
          {
            label: 'On Ad',
            value: DisplayElements.AD,
          },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
        onChange: values => {
          setPriorityToEdit({
            ...priorityToEdit,
            displayElements: values.length
              ? (values[values.length - 1].value as DisplayElements)
              : undefined,
          })
        },
      },
      POS: {
        value:
          priorityToEdit && priorityToEdit.posElementRequired
            ? ['required']
            : undefined,
        label: 'Point of Sale',
        required: false,
        group: {},
        options: [
          {
            label: 'Swire POS Element Required',
            value: 'required',
          },
        ],
        gridProps: FILL_GRID_ALL_SCREENS,
        onChange: values => {
          setPriorityToEdit({
            ...priorityToEdit,
            posElementRequired: values.some(o => o.value === 'required'),
          })
        },
      },
    },
  }

  const { data } = useMuiForm<PicosDisplayForm>({ formState: displayForm })

  useEffect(() => {
    DisplayTypesLazyQuery.lazyQuery.query({
      variables: {
        input: {
          limit: DisplayTypesLazyQuery.Pagination.limit,
          searchTerm: DisplayTypesLazyQuery.debouncedQuery,
          storeTypes: storeTypesInCustomer,
        },
      },
    })
  }, [
    DisplayTypesLazyQuery.lazyQuery.query,
    DisplayTypesLazyQuery.debouncedQuery,
    storeTypesInCustomer,
  ])

  useEffect(() => {
    DisplayLocationsLazyQuery.lazyQuery.query({
      variables: {
        input: {
          limit: DisplayLocationsLazyQuery.Pagination.limit,
          searchTerm: DisplayLocationsLazyQuery.debouncedQuery,
          storeTypes: storeTypesInCustomer,
        },
      },
    })
  }, [
    DisplayLocationsLazyQuery.lazyQuery.query,
    DisplayLocationsLazyQuery.debouncedQuery,
    storeTypesInCustomer,
  ])

  return {
    state: {
      formState: displayForm,
    },
    data,
  }
}
