/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Braxton Geary <braxton.geary@deliciousai.com>
 */

import React from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Tooltip,
} from '@mui/material'
import { HelpCenter } from '@mui/icons-material'

type OptionsCheckBoxProps = {
  checked: boolean
  onChange: () => void
  label: string
  tooltip: string
}

type OthersAllowedType =
  | 'categories'
  | 'brands'
  | 'packaging'
  | 'products'
  | 'display types'
  | 'display locations'

export const helpMsg = (variant: OthersAllowedType): string =>
  `To be compliant, non-selected ${variant} MUST NOT be on the display. However, if this box is selected, other ${variant} MAY be present and the display can still be compliant.`

export const OptionsCheckBox: React.FC<OptionsCheckBoxProps> = props => {
  return (
    <FormControl>
      <FormLabel>Options</FormLabel>
      <FormGroup row sx={{ alignItems: 'center' }}>
        <FormControlLabel
          label={props.label}
          value={props.checked}
          control={
            <Checkbox checked={props.checked} onChange={props.onChange} />
          }
        />
        <Tooltip title={props.tooltip} placement="right">
          <HelpCenter />
        </Tooltip>
      </FormGroup>
    </FormControl>
  )
}
