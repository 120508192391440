/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import ProductSearch from 'products/components/ProductSearch'
import {
  FullScreenLayout,
  PageTitle,
  ActionBar,
  MuiTheme,
  gridAllScreens,
  NavigationDrawerContext,
  FlexBox,
  MuiForm,
  ImageCarousel,
  ProductFormattingHelpers,
  NotificationAlert,
  GridSections,
  AvatarWithText,
  FILL_GRID_ALL_SCREENS,
} from '@dai/web-components'
import { useWindowDimensions, DateTimeHelpers } from '@dai/common'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  SpeedDialIcon,
} from '@mui/material'
import { useProductsLogic } from 'products/containers/Products.logic'
import Box from '@mui/material/Box'
import {
  Close,
  Edit,
  FiberNew,
  PushPin,
  RestartAlt,
  Save,
  Search,
  ShoppingCart,
} from '@mui/icons-material'
import ConfirmSaveProductModal from 'products/components/modal/ConfirmSaveProduct.modal'
import FlaggedMoreInfo, { DraftProduct } from 'components/DraftInfo'
import { ImageType, SabreProductImageType } from '@dai/graphql'

const ProductsContainer: React.FC = () => {
  const {
    state: {
      productEditing,
      selectedProductUpc,
      setSelectedProductUpc,
      ProductLazyQuery,
      modalOpen,
      setModalOpen,
      FilterProps,
      formState,
      showConfirmSaveProduct,
      setShowConfirmSetProduct,
    },
    data: {
      loading,
      hasMore,
      products,
      marketingImages,
      marketingImagesLoading,
      productImages,
      productImagesLoading,
      planogramImages,
      planogramImagesLoading,
    },
    handle: {
      handleResolveProduct,
      handleSubmit,
      clearForm,
      handleDeleteImage,
      handleSetDefaultImage,
      handleUploadImage,
    },
  } = useProductsLogic()

  const { width } = useWindowDimensions()
  const { isOpen: drawerIsOpen } = useContext(NavigationDrawerContext)

  return (
    <>
      <NotificationAlert />
      <FullScreenLayout
        header={<PageTitle title="Product Management" />}
        body={
          <FlexBox.Row>
            <ProductSearch
              {...FilterProps}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              products={products}
              onProductSelected={setSelectedProductUpc}
              selectedProductUPC={selectedProductUpc}
              loading={loading}
              setSearchValue={ProductLazyQuery.setItemQuery}
              hasMore={hasMore || false}
              onLoadMore={() =>
                setTimeout(
                  ProductLazyQuery.Pagination.handleOffset.nextPage,
                  1000,
                )
              }
            />
            <FlexBox.Col flexGrow={1} mb={14}>
              <Grid container spacing={2} marginBottom={2}>
                {!!productEditing?.drafts && !!productEditing.drafts[0] && (
                  <Grid item {...gridAllScreens(12)}>
                    {productEditing.productFlagResolved ? (
                      <Alert severity="warning">
                        Press "Save" to submit the product error resolution.
                      </Alert>
                    ) : (
                      <Card>
                        <FlaggedMoreInfo
                          errorMessage="A user in the field has reported an error with this product"
                          draft={productEditing.drafts[0] as DraftProduct}
                        />
                        <CardActions>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleResolveProduct}
                          >
                            Resolve
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                  </Grid>
                )}
                {productEditing && (
                  <Grid item {...gridAllScreens(12)}>
                    <Card>
                      <CardContent>
                        <GridSections
                          items={{
                            'Product Timestamps': {
                              gridProps: FILL_GRID_ALL_SCREENS,
                              body: (
                                <>
                                  <Grid
                                    item
                                    {...gridAllScreens(3)}
                                    md={6}
                                    xs={12}
                                  >
                                    <AvatarWithText
                                      primaryText="Created Date"
                                      secondaryText={DateTimeHelpers.getDateFromStringYearMonthDay(
                                        productEditing?.created,
                                      )}
                                      boldSecondary
                                    >
                                      <FiberNew color="secondary" />
                                    </AvatarWithText>
                                  </Grid>
                                  <Grid
                                    item
                                    {...gridAllScreens(3)}
                                    md={6}
                                    xs={12}
                                  >
                                    <AvatarWithText
                                      primaryText="Last Modified"
                                      secondaryText={DateTimeHelpers.getDateFromStringYearMonthDay(
                                        productEditing?.modified,
                                      )}
                                      boldSecondary
                                    >
                                      <Edit color="secondary" />
                                    </AvatarWithText>
                                  </Grid>
                                  {/* <Grid
                                    item
                                    {...gridAllScreens(3)}
                                    md={6}
                                    xs={12}
                                  >
                                    <AvatarWithText
                                      primaryText="Last Seen In Field"
                                      secondaryText={
                                        productEditing.lastInAudit
                                          ? DateTimeHelpers.getDateFromStringYearMonthDay(
                                              productEditing.lastInAudit,
                                            )
                                          : 'Never Seen in Audit'
                                      }
                                      boldSecondary
                                      avatarColor={
                                        !productEditing.lastInAudit
                                          ? 'primary'
                                          : 'secondary'
                                      }
                                    >
                                      <TravelExplore color="secondary" />
                                    </AvatarWithText>
                                  </Grid> */}
                                  {/* <Grid
                                    item
                                    {...gridAllScreens(3)}
                                    md={6}
                                    xs={12}
                                  >
                                    <AvatarWithText
                                      primaryText="Last Barcode Scan"
                                      secondaryText={
                                        productEditing?.lastBarcodeScan
                                          ? DateTimeHelpers.getDateFromStringYearMonthDay(
                                              productEditing?.lastBarcodeScan,
                                            )
                                          : 'Never Scanned'
                                      }
                                      boldSecondary
                                      avatarColor={
                                        !productEditing?.lastBarcodeScan
                                          ? 'primary'
                                          : 'secondary'
                                      }
                                    >
                                      <QrCodeScanner color="secondary" />
                                    </AvatarWithText>
                                  </Grid> */}
                                </>
                              ),
                            },
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                <Grid
                  item
                  {...gridAllScreens(6)}
                  md={drawerIsOpen ? 12 : 6}
                  xs={12}
                >
                  <ImageCarousel
                    title="Marketing Images"
                    buttons={[]}
                    loading={marketingImagesLoading}
                    enableDelete
                    enableUpload
                    onPinPress={handleSetDefaultImage}
                    onDeleteImage={(id: string) => handleDeleteImage(id)}
                    onConfirmUploadImage={handleUploadImage}
                    images={marketingImages}
                    primaryImageUri={
                      productEditing?.defaultImage?.uri ||
                      productEditing?.marketingImageUrl ||
                      undefined
                    }
                  />
                </Grid>
                <Grid
                  item
                  {...gridAllScreens(6)}
                  md={drawerIsOpen ? 12 : 6}
                  xs={12}
                >
                  <ImageCarousel
                    transparentImageBackground
                    title="Planogram Images"
                    buttons={[]}
                    enableDelete
                    onDeleteImage={(id: string) => handleDeleteImage(id)}
                    loading={planogramImagesLoading}
                    images={planogramImages}
                  />
                </Grid>
                <Grid
                  item
                  {...gridAllScreens(6)}
                  md={drawerIsOpen ? 12 : 6}
                  xs={12}
                >
                  <ImageCarousel
                    title="From The Field"
                    buttons={[
                      {
                        tooltip: '',
                        icon: <PushPin color="secondary" />,
                        onClick: () => null,
                        gridProps: {
                          ...gridAllScreens(1),
                          xs: 1.5,
                        },
                      },
                    ]}
                    images={productImages}
                    loading={productImagesLoading}
                  />
                </Grid>
              </Grid>
              {loading || productEditing ? (
                <MuiForm formState={formState} loading={loading} />
              ) : undefined}
            </FlexBox.Col>
            <Box
              sx={{
                position: 'absolute',
                bottom: -32,
                right: 8,
              }}
            >
              <ActionBar
                speedDialIcon={
                  <SpeedDialIcon
                    icon={<ShoppingCart color="secondary" />}
                    openIcon={<Close color="secondary" />}
                  />
                }
                bulkActions={[
                  {
                    condition: width <= MuiTheme.breakpoints.values.sm,
                    onClick: () => setModalOpen(true),
                    icon: <Search color="secondary" />,
                    label: 'Search',
                  },
                  {
                    condition: true,
                    onClick: () => setShowConfirmSetProduct(true),
                    icon: <Save color="secondary" />,
                    label: 'Save',
                  },
                  {
                    condition: true,
                    onClick: clearForm,
                    icon: <RestartAlt color="secondary" />,
                    label: 'Reset',
                  },
                ]}
              />
            </Box>
          </FlexBox.Row>
        }
      />
      <ConfirmSaveProductModal
        isOpen={showConfirmSaveProduct}
        setIsOpen={setShowConfirmSetProduct}
        handleSave={handleSubmit}
        product={{
          draft: productEditing?.productFlagResolved
            ? undefined
            : productEditing?.drafts[0],
          brand: productEditing?.brand?.name,
          packaging: ProductFormattingHelpers.getPackagingName({
            size: productEditing?.packaging?.size,
            quantity: productEditing?.packaging?.quantity,
            container: productEditing?.packaging?.container,
            unit: ProductFormattingHelpers.includesReplaceEnumUnits(
              productEditing?.packaging?.unit || '',
            ),
          }),
          category: productEditing?.category?.name,
          imageUrl: productEditing?.defaultImage?.uri,
        }}
      />
    </>
  )
}

export default ProductsContainer
