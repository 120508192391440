"use strict";
/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateOptimizationSchedule = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useUpdateOptimizationSchedule = function (input) {
    var _UpdateOptimizationScheduleMutation = (0, client_1.useMutation)(graphql_1.UPDATE_OPTIMIZATION_SCHEDULE, {
        update: function (cache, _a) {
            var _b;
            var data = _a.data;
            if ((_b = data === null || data === void 0 ? void 0 : data.updateOptimizingCadenceInCompanyStores) === null || _b === void 0 ? void 0 : _b.success) {
                var _c = data.updateOptimizingCadenceInCompanyStores, storeIds_1 = _c.storeIds, numTimesOptimizeHighPerYear_1 = _c.numTimesOptimizeHighPerYear, numTimesOptimizeMediumPerYear_1 = _c.numTimesOptimizeMediumPerYear, numTimesOptimizeLowPerYear_1 = _c.numTimesOptimizeLowPerYear, timesPerWeekTwoDScan_1 = _c.timesPerWeekTwoDScan;
                var existing = cache.readQuery({ query: graphql_1.GET_ADMIN_COMPANY_STORES, variables: { input: input } });
                if (existing) {
                    var newResults = existing.adminCompanyStores.results.map(function (store) {
                        return storeIds_1.includes(store.id)
                            ? __assign(__assign({}, store), { numTimesOptimizeHighPerYear: numTimesOptimizeHighPerYear_1, numTimesOptimizeMediumPerYear: numTimesOptimizeMediumPerYear_1, numTimesOptimizeLowPerYear: numTimesOptimizeLowPerYear_1, timesPerWeekTwoDScan: timesPerWeekTwoDScan_1 }) : store;
                    });
                    cache.writeQuery({
                        query: graphql_1.GET_ADMIN_COMPANY_STORES,
                        variables: { input: input },
                        data: __assign(__assign({}, existing), { AdminCompanyStoresQuery: __assign(__assign({}, existing.adminCompanyStores), { results: newResults }) }),
                    });
                }
            }
        },
    })[0];
    var UpdateOptimizationScheduleMutation = function (variables) {
        return _UpdateOptimizationScheduleMutation({ variables: variables });
    };
    var _UpdateGlobalOptimizationScheduleMutation = (0, client_1.useMutation)(graphql_1.UPDATE_GLOBAL_OPTIMIZATION_CADENCE, {
        update: function (cache, _a) {
            var _b;
            var data = _a.data;
            if ((_b = data === null || data === void 0 ? void 0 : data.globalUpdateOptimizingCadence) === null || _b === void 0 ? void 0 : _b.success) {
                var _c = data.globalUpdateOptimizingCadence, numTimesOptimizeHighPerYear_2 = _c.numTimesOptimizeHighPerYear, numTimesOptimizeMediumPerYear_2 = _c.numTimesOptimizeMediumPerYear, numTimesOptimizeLowPerYear_2 = _c.numTimesOptimizeLowPerYear, timesPerWeekTwoDScan_2 = _c.timesPerWeekTwoDScan;
                var existing = cache.readQuery({ query: graphql_1.GET_ADMIN_COMPANY_STORES, variables: { input: input } });
                if (existing) {
                    var newResults = existing.adminCompanyStores.results.map(function (store) { return (__assign(__assign({}, store), { numTimesOptimizeHighPerYear: numTimesOptimizeHighPerYear_2, numTimesOptimizeMediumPerYear: numTimesOptimizeMediumPerYear_2, numTimesOptimizeLowPerYear: numTimesOptimizeLowPerYear_2, timesPerWeekTwoDScan: timesPerWeekTwoDScan_2 })); });
                    cache.writeQuery({
                        query: graphql_1.GET_ADMIN_COMPANY_STORES,
                        variables: { input: input },
                        data: __assign(__assign({}, existing), { AdminCompanyStoresQuery: __assign(__assign({}, existing.adminCompanyStores), { results: newResults }) }),
                    });
                }
            }
        },
    })[0];
    var UpdateGlobalOptimizationScheduleMutation = function (variables) {
        return _UpdateGlobalOptimizationScheduleMutation({ variables: variables });
    };
    return {
        UpdateOptimizationScheduleMutation: UpdateOptimizationScheduleMutation,
        UpdateGlobalOptimizationScheduleMutation: UpdateGlobalOptimizationScheduleMutation,
    };
};
exports.useUpdateOptimizationSchedule = useUpdateOptimizationSchedule;
