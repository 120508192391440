/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect } from 'react'
import PicOSContext from 'context/PicOSContext'
import {
  StringHelpers,
  useCommonFilter,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { DateTimeHelpers } from '@dai/common'
import {
  GET_PICOS_PRIORITIES,
  PicOSPrioritiesQuery,
  PicOSPrioritiesQueryVariables,
} from '@dai/graphql'
import { BaseFilterAttributes } from '@dai/web-components'
import { useLocation } from 'react-router-dom'

export const useSearchPriorities = () => {
  const location = useLocation()
  const {
    handleUpdateSearchParams,
    handleNavTo,
    urlSearchParams,
    selectedBanner,
  } = useContext(PicOSContext)
  const PriorityLazyQuery = useDebouncedItemQuery<
    PicOSPrioritiesQuery,
    PicOSPrioritiesQueryVariables
  >({
    queryStr: GET_PICOS_PRIORITIES,
    options: { fetchPolicy: 'network-only' },
  })

  const {
    startDate: urlStartDate,
    endDate: urlEndDate,
    bannerId,
  } = urlSearchParams

  const priorities =
    PriorityLazyQuery.lazyQuery.meta.data?.picosPriorities.results || []
  const currentWeek = DateTimeHelpers.getDateWeekDelta(0).toISOString()

  const defaultFilter: BaseFilterAttributes = {
    filterBy: {
      selected: [],
      options: [],
      dateOptions: {
        startDate: urlStartDate ?? currentWeek,
        endDate:
          urlEndDate ?? DateTimeHelpers.getDateWeekDelta(1).toISOString(),
      },
    },
  }
  const FilterProps = useCommonFilter(defaultFilter)

  const startDate = FilterProps.filter.filterBy?.dateOptions?.startDate
  const endDate = FilterProps.filter.filterBy?.dateOptions?.endDate

  useEffect(() => {
    if (!urlStartDate && !urlEndDate) {
      handleUpdateSearchParams({
        startDate,
        endDate,
      })
    }
  }, [location.search])

  useEffect(() => {
    const _bannerId = bannerId || selectedBanner?.id
    if (!StringHelpers.isNullOrEmpty(startDate) && _bannerId) {
      PriorityLazyQuery.lazyQuery.query({
        variables: {
          input: {
            startDate,
            endDate,
            storeChainId: _bannerId,
            name: PriorityLazyQuery.debouncedQuery,
          },
        },
      })
    }
  }, [bannerId, PriorityLazyQuery.debouncedQuery, startDate, endDate])

  return {
    state: {
      urlSearchParams,
      FilterProps,
    },
    data: {
      priorities,
      prioritiesLoading: PriorityLazyQuery.lazyQuery.meta.loading,
      currentWeek,
    },
    handle: {
      handleUpdateSearchParams,
      handleNavTo,
    },
    PriorityLazyQuery,
  }
}
