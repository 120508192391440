/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  PicOSComplianceTableReport,
  StoreReportRow,
  STORE_REPORT_TABLE_COLUMNS,
  PicOSPriorityDetails,
} from '@dai/web-components'
import useStoreComplianceReport from 'picos/hooks/use-store-compliance-report'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const PicOSStoreReport: React.FC = () => {
  const routeParams = useParams<{
    customerId: string
    divisionId: string
    salesCenterId: string
    storeId: string
  }>()

  const {
    data: { title, complianceResults, statItems },
    state: { loadingCompliance, loadingStats, limit },
    handlers: { handleSearch, goToPage },
  } = useStoreComplianceReport(routeParams)

  const rows = useMemo(
    () =>
      complianceResults.map(row => ({
        ...row,
        priority: <PicOSPriorityDetails {...row} />,
        cost: `$${row.cost}`,
        compliant: row.isCompliant,
      })),
    [complianceResults],
  )

  return (
    <PicOSComplianceTableReport<StoreReportRow>
      title={title}
      columns={STORE_REPORT_TABLE_COLUMNS}
      rows={rows}
      onSearch={handleSearch}
      onClickExportCsv={() => null}
      loadingComplianceItems={loadingCompliance}
      pageSize={limit}
      onPageChange={(page, _) => goToPage(page)}
      loadingStats={loadingStats}
      statItems={statItems}
    />
  )
}

export default PicOSStoreReport
