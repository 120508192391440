"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_PRODUCT_MARKETING_IMAGE = void 0;
var client_1 = require("@apollo/client");
exports.REPORT_PRODUCT_MARKETING_IMAGE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation ReportProductMarketingImage($imageId: ID!) {\n    reportProductMarketingImage(imageId: $imageId) {\n      image {\n        id\n        url\n        isBadImage\n     }\n    }\n  }\n"], ["\n  mutation ReportProductMarketingImage($imageId: ID!) {\n    reportProductMarketingImage(imageId: $imageId) {\n      image {\n        id\n        url\n        isBadImage\n     }\n    }\n  }\n"])));
var templateObject_1;
