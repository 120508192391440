"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetCompanyRegions = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var performant_array_to_tree_1 = require("performant-array-to-tree");
var useGetCompanyRegions = function (companyId) {
    var _a = (0, client_1.useQuery)(graphql_1.GET_COMPANY_REGIONS, { variables: { companyId: companyId } }), data = _a.data, loading = _a.loading, error = _a.error;
    var flatRegions = (data === null || data === void 0 ? void 0 : data.companyRegions) || [];
    var allRegionIds = flatRegions.map(function (region) { return region.id; });
    var regionTree = (0, performant_array_to_tree_1.arrayToTree)(flatRegions, {
        dataField: null,
        childrenField: 'subregions',
    });
    return {
        flatRegions: flatRegions,
        allRegionIds: allRegionIds,
        regions: regionTree,
        loading: loading,
        error: error,
    };
};
exports.useGetCompanyRegions = useGetCompanyRegions;
