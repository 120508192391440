/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { UserProductivityTabType } from 'users/users.types'

const REPORT_TABS: UserProductivityTabType[] = [
  'overview',
  'leaderboard',
  'history',
]

export const UserProductivityConstants = {
  REPORT_TABS,
}
