"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLANOGRAM_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var DIsplaySegmentFragment_1 = require("./DIsplaySegmentFragment");
exports.PLANOGRAM_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Planogram on Planogram {\n    numSegments\n    segments {\n      ...DisplaySegment\n    }\n  }\n  ", "\n"], ["\n  fragment Planogram on Planogram {\n    numSegments\n    segments {\n      ...DisplaySegment\n    }\n  }\n  ", "\n"])), DIsplaySegmentFragment_1.DISPLAY_SEGMENT_FRAGMENT);
var templateObject_1;
