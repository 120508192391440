"use strict";
// import { gql } from '@apollo/client'
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_DISPLAY_SCENE_TEST = void 0;
exports.ADD_DISPLAY_SCENE_TEST = '';
// export const ADD_DISPLAY_SCENE_TEST = gql`
//   #  mutation AddDisplaySceneTest($input: AddPointCloudSceneInput!) {
//   #    addDisplaySceneTest(input: $input) {
//   #      pointCloudScene {
//   #        id
//   #        bucketFileName
//   #        labels
//   #        predicted
//   #        centroids
//   #      }
//   #    }
//   #  }
// `
