"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_DISPLAYS = void 0;
var client_1 = require("@apollo/client");
exports.ALL_DISPLAYS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query AllDisplays($input: AllDisplaysInput!) {\n    displays(input: $input) {\n      count\n      hasMore\n      results {\n        id\n        shortDescription\n        displayType\n        store {\n          id\n          name\n        }\n        images {\n          url\n        }\n        numCoolerDoors\n        idealInventory {\n          upcs\n        }\n      }\n    }\n  }\n"], ["\n  query AllDisplays($input: AllDisplaysInput!) {\n    displays(input: $input) {\n      count\n      hasMore\n      results {\n        id\n        shortDescription\n        displayType\n        store {\n          id\n          name\n        }\n        images {\n          url\n        }\n        numCoolerDoors\n        idealInventory {\n          upcs\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
