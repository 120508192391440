/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Mitchell Wilson <mitchell.wilson@deliciousai.com>
 */

import React, { useContext, useState } from 'react'
import { Paper, Stack, Grid, Box, TextField, Button } from '@mui/material'
import { useWindowDimensions } from '@dai/common'
import {
  MuiTheme,
  gridAllScreens,
  NotificationContext,
} from '@dai/web-components'
import { CheckCircle, Edit } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import {
  CREATE_OR_UPDATE_ZONE,
  CreateOrUpdateZoneMutation,
  CreateOrUpdateZoneMutationVariables,
} from '@dai/graphql'
import SearchableSelectManager from './SearchableSelectManager'
import { Zone } from './types'

type ZoneFormHeaderProps = {
  zone: Zone
  container?: boolean
  Tabs?: React.ReactNode
  statistic?: string
  moreInfo?: string
  FilterAndSearch?: React.ReactNode
  onPressEdit: () => void
}

export const ZoneFormHeader: React.FC<ZoneFormHeaderProps> = props => {
  const { container, Tabs, zone, onPressEdit } = props
  const { width } = useWindowDimensions()
  const isMobile = width < MuiTheme.breakpoints.values.sm

  const [selectedManagerId, setSelectedManagerId] = useState<string | null>(
    zone.manager?.id || null,
  )
  const [zoneName, setZoneName] = useState<string>(zone.name)
  const { setError, setSuccess } = useContext(NotificationContext)

  const canSave =
    zoneName !== zone.name || selectedManagerId !== zone.manager?.id

  const [createOrUpdateZone] = useMutation<
    CreateOrUpdateZoneMutation,
    CreateOrUpdateZoneMutationVariables
  >(CREATE_OR_UPDATE_ZONE)

  const handleSave = () => {
    createOrUpdateZone({
      variables: {
        input: {
          zoneId: zone.id,
          name: zoneName,
          managerId: selectedManagerId,
        },
      },
    })
      .then(() => {
        window.location.reload()
        setSuccess(`Your changes were saved successfully.`)
      })
      .catch(error => setError(`An error occurred: ${error}`))
  }

  const Content = (
    <Stack direction={'column'} spacing={2}>
      <Grid container spacing={4} width={'100%'}>
        <Grid item {...gridAllScreens(4)}>
          <TextField
            label={'Area Name'}
            fullWidth
            defaultValue={zone.name}
            placeholder="e.g. Utah, Utah County, Provo"
            onChange={e => setZoneName(e.target.value)}
            size="small"
          />
        </Grid>
        <Grid item {...gridAllScreens(4)}>
          <SearchableSelectManager
            size="small"
            handleManagerSelected={setSelectedManagerId}
            defaultValue={
              zone.manager
                ? {
                    label: zone.manager.fullName,
                    id: zone.manager.id,
                    zoneName: zone.name,
                    zonePath: zone.zonePath,
                    manager: true,
                  }
                : undefined
            }
          />
        </Grid>
        <Grid item {...gridAllScreens(4)}>
          <Button
            onClick={handleSave}
            color="secondary"
            startIcon={
              <CheckCircle color={!canSave ? 'disabled' : undefined} />
            }
            sx={{ height: '100%' }}
            variant="outlined"
            disabled={!canSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={1}
        sx={{ borderWidth: 2 }}
      >
        {Tabs}
        <Button
          color="secondary"
          startIcon={<Edit />}
          onClick={onPressEdit}
          variant={'outlined'}
        >
          Edit
        </Button>
      </Stack>
    </Stack>
  )
  if (container) {
    return <Paper sx={{ p: 2 }}>{Content}</Paper>
  }
  return <Box p={!isMobile ? 2 : 0}>{Content}</Box>
}
