"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASSIGN_USER_SUPERVISOR = void 0;
var client_1 = require("@apollo/client");
exports.ASSIGN_USER_SUPERVISOR = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation AssignUserSupervisor($input: AssignUserSupervisorInput!) {\n    assignSupervisor(input: $input) {\n      users {\n        uuid\n        supervisor {\n          uuid\n        }\n      }\n    }\n  }\n"], ["\n  mutation AssignUserSupervisor($input: AssignUserSupervisorInput!) {\n    assignSupervisor(input: $input) {\n      users {\n        uuid\n        supervisor {\n          uuid\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
