"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TRAINING_POINT_CLOUD = void 0;
var client_1 = require("@apollo/client");
var TrainingPointCloud_1 = require("../fragments/TrainingPointCloud");
exports.UPDATE_TRAINING_POINT_CLOUD = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdateTrainingPointCloud($input: UpdateTrainingPointCloudInput!) {\n    updateTrainingPointCloud(input: $input) {\n      pointCloud {\n        ...TrainingPointCloud\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation UpdateTrainingPointCloud($input: UpdateTrainingPointCloudInput!) {\n    updateTrainingPointCloud(input: $input) {\n      pointCloud {\n        ...TrainingPointCloud\n      }\n    }\n  }\n  ", "\n"])), TrainingPointCloud_1.TRAINING_POINT_CLOUD_FRAGMENT);
var templateObject_1;
