"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_COMPANY_AUDIT_QUESTIONS = void 0;
var client_1 = require("@apollo/client");
exports.GET_COMPANY_AUDIT_QUESTIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetCompanyAuditQuestions($input: AuditQuestionsInput) {\n    auditQuestions(input: $input) {\n      id\n      question\n      category\n      pointWorth\n      group\n    }\n  }\n"], ["\n  query GetCompanyAuditQuestions($input: AuditQuestionsInput) {\n    auditQuestions(input: $input) {\n      id\n      question\n      category\n      pointWorth\n      group\n    }\n  }\n"])));
var templateObject_1;
