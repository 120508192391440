/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  AdminPortalAuditsOrderBy,
  AuditsAuditQuestionCategoryChoices,
  ForStoreSize,
} from '@dai/graphql'
import { Theme } from '@mui/material/styles'
import { sumBy } from 'lodash'

const getOrderByFromString = (order?: string) => {
  switch (order) {
    case 'date':
      return AdminPortalAuditsOrderBy.DATE
    case 'store':
      return AdminPortalAuditsOrderBy.STORE
    case 'employee':
      return AdminPortalAuditsOrderBy.EMPLOYEE
    default:
      return AdminPortalAuditsOrderBy.DATE
  }
}

const formatDate = (date: Date) =>
  `${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${
    date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  }/${date.getFullYear()}`

const swireOrder = {
  sparkling: 1,
  water: 2,
  isotonic: 3,
  rejuvenate: 4,
  energy: 5,
}

const auditScoringColorFn = ({
  theme,
  numerator,
  denominator,
}: {
  theme: Theme
  numerator: number
  denominator: number
}) => {
  const pct = numerator / denominator
  if (pct < 0.5) {
    return theme.palette.error.main
  }
  if (pct < 0.65) {
    return theme.palette.warning.main
  }
  if (pct < 0.75) {
    return theme.palette.warning.light
  }
  if (pct < 0.9) {
    return theme.palette.success.light
  }
  return theme.palette.success.main
}

const auditQuestionCategoryToForStoreSize = (
  category: AuditsAuditQuestionCategoryChoices,
) => {
  switch (category) {
    case AuditsAuditQuestionCategoryChoices.AL:
      return ForStoreSize.ALL
    case AuditsAuditQuestionCategoryChoices.LG:
      return ForStoreSize.LARGE
    case AuditsAuditQuestionCategoryChoices.CR:
      return ForStoreSize.SMALL
    case AuditsAuditQuestionCategoryChoices.DR:
      return ForStoreSize.DRUG
    case AuditsAuditQuestionCategoryChoices.HM:
      return ForStoreSize.HOME_IMPROVEMENT
    case AuditsAuditQuestionCategoryChoices.VL:
      return ForStoreSize.VALUE
  }
}

const soviPercent = (audit: {
  score?: { soviScores?: {}[] | null } | null
}) => {
  const numerator = sumBy(audit.score?.soviScores, 'customerCasesCount')
  const denominator =
    sumBy(audit.score?.soviScores, 'customerCasesCount') +
    sumBy(audit.score?.soviScores, 'competitorCasesCount')
  return numerator / denominator
}

const StoreInspectionHelpers = {
  getOrderByFromString,
  formatDate,
  soviPercent,
  auditScoringColorFn,
  auditQuestionCategoryToForStoreSize,
}

export default StoreInspectionHelpers
