/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import { GetDisplayRestockHistory_displayRestockHistory_results_transactions } from '@dai/graphql'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ProductColumns from './ProductRows'

type TransactionsListProps = {
  transactions: GetDisplayRestockHistory_displayRestockHistory_results_transactions[]
}

const TransactionsTable: React.FC<TransactionsListProps> = (
  props: TransactionsListProps,
) => {
  const { transactions } = props
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Facings</TableCell>
            <TableCell>Human Verified</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map(t => (
            <ProductColumns
              upc={t.upc}
              columnsFromProduct={['marketingImageUrl', 'name']}
              additionalColumnValues={[
                t.credit - t.debit,
                t.numFacings || 0,
                String(t.fieldVerified),
              ]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TransactionsTable
