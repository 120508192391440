"use strict";
/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Michael Selander <michael.selander@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAdminCompanyRegions = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useGetAdminCompanyRegions = function () {
    var _a = (0, client_1.useQuery)(graphql_1.GET_ADMIN_COMPANY_REGIONS), data = _a.data, loading = _a.loading, error = _a.error;
    // @ts-ignore
    var regions = (data === null || data === void 0 ? void 0 : data.regions) || [];
    return {
        regions: regions,
        loading: loading,
        error: error,
    };
};
exports.useGetAdminCompanyRegions = useGetAdminCompanyRegions;
