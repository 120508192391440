/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useState } from 'react'
import {
  DAIFormState,
  gridAllScreens,
  SelectableOption,
  useDebouncedItemQuery,
  useMuiForm,
  useInfiniteScrollAndSearch,
  MuiFormTypes,
  MultipleState,
} from '@dai/web-components'
import {
  SABRE_GET_BRANDS,
  SabreGetBrandsQuery,
  SabreGetBrandsQueryVariables,
  SabreGetPackagingQuery,
  SabreGetProductsQuery,
  SabreGetProductsQueryVariables,
  SabreGetPackagingQueryVariables,
  SABRE_GET_PACKAGING,
  SABRE_GET_PRODUCTS,
} from '@dai/graphql'
import PicOSContext from 'context/PicOSContext'
import { uniqBy } from 'lodash'
import { useGetCompanyByName } from 'hooks/useGetCompanyByName'
import { AddCircle, HelpCenter } from '@mui/icons-material'
import { PicosConstants } from 'picos/picos.constants'
import { PicOSHelpers } from 'picos/helpers/picos.helpers'
import { FormPicOSPriority, PicOSProductFormState } from 'picos/picos.types'
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material'

export interface PicosProductsForm<
  Multiple extends MultipleState = MultipleState,
> extends DAIFormState<'text' | 'autocomplete' | 'button', Multiple> {
  text: {
    PRICE: MuiFormTypes<Multiple>['text']
  }
  autocomplete: {
    BRAND: MuiFormTypes<Multiple>['autocomplete']
    PRODUCT: MuiFormTypes<Multiple>['autocomplete']
    PACKAGING: MuiFormTypes<Multiple>['autocomplete']
  }
}
const sharedSearchSelectProps = {
  gridProps: { ...gridAllScreens(10.5 / 3), xs: 12 },
  variant: 'standard' as 'standard',
  multiple: true,
  required: true,
  disableCloseOnSelect: true,
  limitTags: 2,
  hideSearchIcon: true,
  showCheckbox: true,
}

export const usePicosProductsForm = () => {
  const { priorityToEdit, setPriorityToEdit } = useContext(PicOSContext)
  const { company } = useGetCompanyByName()
  const companyUpcs = company?.companyByName.companyUpcs || []
  const [selected, setSelected] = useState<PicOSProductFormState>({
    brands: [],
    packaging: [],
    productOptions: [],
    sabreProducts: [],
  })

  const BrandLazyQuery = useDebouncedItemQuery<
    SabreGetBrandsQuery,
    SabreGetBrandsQueryVariables
  >({
    queryStr: SABRE_GET_BRANDS,
    options: { fetchPolicy: 'cache-first' },
    context: { endPoint: 'sabre' },
  })
  const PackagingLazyQuery = useDebouncedItemQuery<
    SabreGetPackagingQuery,
    SabreGetPackagingQueryVariables
  >({
    queryStr: SABRE_GET_PACKAGING,
    options: { fetchPolicy: 'network-only' },
    context: { endPoint: 'sabre' },
  })
  const ProductLazyQuery = useDebouncedItemQuery<
    SabreGetProductsQuery,
    SabreGetProductsQueryVariables
  >({
    queryStr: SABRE_GET_PRODUCTS,
    options: { fetchPolicy: 'cache-first' },
    context: { endPoint: 'sabre' },
  })

  const brandIdsInput = selected.brands.map(b => b.value)
  const packagingIdsInput = selected.packaging.map(p => p.value)

  const { itemsCache: brandsCache } = useInfiniteScrollAndSearch<
    SabreGetBrandsQuery,
    SabreGetBrandsQueryVariables,
    SelectableOption
  >({
    LazyQuery: BrandLazyQuery,
    QueryVariables: {
      input: {
        brandName: BrandLazyQuery.debouncedQuery,
        offset: BrandLazyQuery.Pagination.offset,
        productUpcs: companyUpcs,
      },
    },
    formatResponse: response =>
      response.data?.brands?.results?.map(b => ({
        label: b.name,
        value: b.id,
      })) || [],
    context: { endPoint: 'sabre' },
  })
  const { itemsCache: packagingCache } = useInfiniteScrollAndSearch<
    SabreGetPackagingQuery,
    SabreGetPackagingQueryVariables,
    SelectableOption
  >({
    LazyQuery: PackagingLazyQuery,
    QueryVariables: {
      input: {
        brandIds: brandIdsInput,
        searchTerm: PackagingLazyQuery.debouncedQuery,
        offset: PackagingLazyQuery.Pagination.offset,
        filterIncomplete: true,
      },
    },
    context: { endPoint: 'sabre' },
    formatResponse: response =>
      response.data?.packaging.results.map(pack => ({
        label: pack.packagingString,
        value: pack.id,
      })) || [],
    resetQueryDependencies: [brandIdsInput.length],
  })
  const { itemsCache: productCache } = useInfiniteScrollAndSearch<
    SabreGetProductsQuery,
    SabreGetProductsQueryVariables,
    SelectableOption
  >({
    LazyQuery: ProductLazyQuery,
    QueryVariables: {
      input: {
        brandIds: brandIdsInput,
        packagingIds: packagingIdsInput,
        searchTerm: ProductLazyQuery.debouncedQuery,
        offset: ProductLazyQuery.Pagination.offset,
        onlyActive: true,
      },
    },
    context: { endPoint: 'sabre' },
    formatResponse: response =>
      response.data?.products.results.map(prod => ({
        label: prod.fullName || prod.upcA,
        value: prod.upcA,
      })) || [],
    resetQueryDependencies: [brandIdsInput.length, packagingIdsInput.length],
  })

  const handleFetchCommonBrands = (brandIds: string[]) =>
    BrandLazyQuery.lazyQuery
      .query({
        variables: {
          input: {
            brandIds,
          },
        },
      })
      .then(res => {
        const brands = res.data?.brands.results
        if (brands) {
          setSelected({
            ...selected,
            brands: uniqBy(
              [
                ...selected.brands,
                ...brands.map(b => ({
                  label: b.name,
                  value: b.id,
                })),
              ],
              'value',
            ),
          })
        }
      })

  const InputFieldFooter = (type: 'Brands' | 'Packaging' | 'Products') => {
    const key: keyof FormPicOSPriority = `other${type}Allowed`
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox
          checked={priorityToEdit[key] || false}
          onChange={(_, checked) =>
            setPriorityToEdit({
              ...priorityToEdit,
              [key]: checked,
            })
          }
        />
        <Typography>Other {type} Allowed</Typography>
        <Tooltip
          title={`When disabled, a display CANNOT have ${type.toLowerCase()} other the ones enumerated in the priority and be compliant.`}
        >
          <HelpCenter />
        </Tooltip>
      </Stack>
    )
  }

  const picosProductForm: PicosProductsForm<{ autocomplete: true }> = {
    autocomplete: {
      BRAND: {
        ...sharedSearchSelectProps,
        options: uniqBy([...brandsCache, ...selected.brands], 'value'),
        onClose: () => BrandLazyQuery.setItemQuery(''),
        value: selected.brands,
        label: 'Brand',
        loading: BrandLazyQuery.lazyQuery.meta.loading,
        handleOnChange: brands => setSelected({ ...selected, brands }),
        multiple: true,
        TextFieldProps: {
          onChange: e => BrandLazyQuery.setItemQuery(e.target.value),
        },
        Footer: InputFieldFooter('Brands'),
      },
      PACKAGING: {
        ...sharedSearchSelectProps,
        value: selected.packaging,
        options: uniqBy([...packagingCache, ...selected.packaging], 'value'),
        handleOnChange: packaging => setSelected({ ...selected, packaging }),
        disableCloseOnSelect: true,
        onClose: () => PackagingLazyQuery.setItemQuery(''),
        multiple: true,
        label: 'Packaging',
        loading: PackagingLazyQuery.lazyQuery.meta.loading,
        help: 'Select one or more brands to load packaging types.',
        TextFieldProps: {
          onChange: e => PackagingLazyQuery.setItemQuery(e.target.value),
        },
        Footer: InputFieldFooter('Packaging'),
      },
      PRODUCT: {
        ...sharedSearchSelectProps,
        value: selected.productOptions,
        options: uniqBy([...productCache, ...selected.productOptions], 'value'),
        label: 'Product',
        required: false,
        loading: ProductLazyQuery.lazyQuery.meta.loading,
        onClose: () => ProductLazyQuery.setItemQuery(''),
        Footer: InputFieldFooter('Products'),
        handleOnChange: products => {
          if (products.length) {
            ProductLazyQuery.lazyQuery
              .query({
                variables: { input: { upcs: products.map(p => p.value) } },
              })
              .then(res => {
                const data = res.data?.products.results
                let newState = {
                  ...selected,
                  productOptions: products,
                }
                if (data) {
                  newState = {
                    ...newState,
                    sabreProducts: data.map(d => ({
                      product: { upc: d.upcA, name: d.name || d.upcA },
                      brand: d.brand
                        ? { name: d.brand.name, id: d.brand.id }
                        : null,
                      packaging: d.packaging
                        ? {
                            name: d.packaging.packagingString,
                            id: d.packaging.id,
                          }
                        : null,
                    })),
                  }
                }
                setSelected(newState)
              })
          } else {
            setSelected({
              ...selected,
              productOptions: [],
              sabreProducts: [],
            })
          }
        },
        TextFieldProps: {
          onChange: e => ProductLazyQuery.setItemQuery(e.target.value),
        },
        multiple: true,
      },
    },
    button: {
      SAVE: {
        gridProps: gridAllScreens(1.5),
        buttons: [
          {
            label: 'Save',
            variant: 'contained',
            color: 'secondary',
            sx: { p: 1.5 },
            disabled: !selected.brands.length || !selected.packaging.length,
            onClick: () => {
              setPriorityToEdit({
                ...priorityToEdit,
                addedProducts:
                  PicOSHelpers.getBrandsPackagingProductFromPriority(
                    selected,
                    priorityToEdit.addedProducts || [],
                  ),
              })
              setSelected({
                brands: [],
                packaging: [],
                productOptions: [],
                sabreProducts: [],
              })
            },
          },
        ],
      },
      ACTIONS: {
        label: 'Quick Add',
        gridProps: gridAllScreens(12),
        maxWidthPerButton: 150,
        buttons: [
          {
            size: 'small',
            label: 'Core 4',
            startIcon: <AddCircle />,
            variant: 'contained',
            color: 'info',
            onClick: () =>
              handleFetchCommonBrands(
                PicosConstants.QUICK_ADD_BRAND_IDS?.core || [],
              ),
          },
          {
            size: 'small',
            label: 'Dr. Pepper',
            startIcon: <AddCircle />,
            variant: 'contained',
            color: 'info',
            onClick: () =>
              handleFetchCommonBrands(
                PicosConstants.QUICK_ADD_BRAND_IDS?.dp || [],
              ),
          },
        ],
      },
    },
    text: {
      PRICE: {
        input: {
          type: 'text',
          variant: 'standard',
          label: 'Price',
          placeholder:
            'ex. BOGO Free, Buy Two Get the Third 50% off, Promo Price: $2.89',
        },
        gridProps: gridAllScreens(12),
        value: priorityToEdit.pricing || '',
        onChange: pricing => setPriorityToEdit({ ...priorityToEdit, pricing }),
      },
    },
  }

  const { data } = useMuiForm<PicosProductsForm>({
    // @ts-ignore
    formState: picosProductForm,
  })

  return {
    state: {
      formState: picosProductForm,
    },
    data,
  }
}
