"use strict";
/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.THIRTY_DAYS_VALUE_UNIQUE = exports.THIRTY_DAYS_YOY = exports.TWELVE_MONTHS = exports.SIX_MONTHS = exports.NINETY_DAYS = exports.THIRTY_DAYS = exports.DateTimeConstants = void 0;
var YYYY_MM_DD = 'YYYY-MM-DD';
var YYYY_MM = 'YYYY-MM';
var MM_DD = 'MM-DD';
var DD_MM_YY = 'DD/MM/YY';
var MM_DD_YY = 'MM/DD/YY';
var MM_DD_YYYY = 'MM/DD/YYYY';
var MM_DD_YYYY_H_MM_A = 'MM/DD/YYYY h:mm A';
var MMM_YYYY_TEXT = 'MMM YYYY';
var YYYY_TEXT = 'YYYY';
var MMMM_D_YYYY = 'MMMM D, YYYY';
exports.DateTimeConstants = {
    THIRTY_DAYS: 30,
    TWO_WEEKS: 14,
    WEEK_DAYS: 7,
    MONTHS_IN_YEAR: 12,
    DAYS_IN_YEAR: 365,
    SIXTY_DAYS: 60,
    YYYY_MM_DD: YYYY_MM_DD,
    YYYY_MM: YYYY_MM,
    MM_DD: MM_DD,
    DD_MM_YY: DD_MM_YY,
    MM_DD_YY: MM_DD_YY,
    MMM_YYYY_TEXT: MMM_YYYY_TEXT,
    YYYY_TEXT: YYYY_TEXT,
    MM_DD_YYYY: MM_DD_YYYY,
    MMMM_D_YYYY: MMMM_D_YYYY,
    MM_DD_YYYY_H_MM_A: MM_DD_YYYY_H_MM_A,
};
exports.THIRTY_DAYS = 'Past 30 Days vs Prev. 30 Days';
exports.NINETY_DAYS = 'Past 90 Days vs Prev. 90 Days';
exports.SIX_MONTHS = 'Past 6 Months vs Prev. 6 Months';
exports.TWELVE_MONTHS = 'Past 12 Months vs Prev. 12 Months';
exports.THIRTY_DAYS_YOY = 'Past 30 Days Year-over-Year';
exports.THIRTY_DAYS_VALUE_UNIQUE = String(exports.DateTimeConstants.THIRTY_DAYS) + '.0';
