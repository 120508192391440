"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DISPLAY_TRANSACTIONS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_DISPLAY_TRANSACTIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetDisplayTransactions($input: DisplayTransactionInput!) {\n    displayTransactions(input: $input) {\n      ...DisplayTransaction\n    }\n  }\n  ", "\n"], ["\n  query GetDisplayTransactions($input: DisplayTransactionInput!) {\n    displayTransactions(input: $input) {\n      ...DisplayTransaction\n    }\n  }\n  ", "\n"])), fragments_1.DISPLAY_TRANSACTION_FRAGMENT);
var templateObject_1;
