/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DZFile } from '@dai/common'
import UserContext from 'context/UserContext'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { useContext } from 'react'
import { imageStorage, videoStorage } from './../api/Firebase'
import { FirebaseHelpers } from '../helpers/firebase.helpers'
import 'firebase/firestore'

const { REACT_APP__FB_PROJECT_ID } = process.env

export const useUploadToFirestorage = () => {
  const { user } = useContext(UserContext)

  const handleUploadFile = async ({
    file,
    id,
    cloudDir,
    fileType,
  }: {
    file: DZFile
    id: string
    fileType: 'images' | 'video'
    cloudDir: 'picos' | 'productMarketing' | 'training' | 'training-videos'
  }) => {
    const tenant = await FirebaseHelpers.getUserTenant(user!.email)
    const extension = file.path.slice(file.path.lastIndexOf('.') + 1)
    const uploadFilePath = `${tenant}/${cloudDir}/${id}.${extension}`

    const storageRef = ref(
      fileType === 'images' ? imageStorage : videoStorage,
      uploadFilePath,
    )

    const uploadTask = uploadBytesResumable(storageRef, file)
    return {
      bucketPath: `${REACT_APP__FB_PROJECT_ID}-${fileType}/${uploadFilePath}`,
      fileUuid: id,
      uploadTask,
    }
  }

  return { handleUploadFile }
}
