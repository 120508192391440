"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PRODUCT_LABELED_IMAGE_COUNTS = void 0;
var client_1 = require("@apollo/client");
exports.GET_PRODUCT_LABELED_IMAGE_COUNTS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetProductLabeledImageCounts($productInput: ProductsInput!) {\n    products(input: $productInput) {\n      count\n      results {\n        upcA\n        marketingImageUrl\n        labeledImageCounts {\n          singleVerified\n          singleUnverified\n          multiVerified\n          multiUnverified\n          multiTraining\n          multiValidation\n        }\n      }\n    }\n  }\n"], ["\n  query GetProductLabeledImageCounts($productInput: ProductsInput!) {\n    products(input: $productInput) {\n      count\n      results {\n        upcA\n        marketingImageUrl\n        labeledImageCounts {\n          singleVerified\n          singleUnverified\n          multiVerified\n          multiUnverified\n          multiTraining\n          multiValidation\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
