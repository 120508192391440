"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAY_AUDIT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var AuditInventoryItemFragment_1 = require("./AuditInventoryItemFragment");
exports.DISPLAY_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DisplayAudit on DisplayAudit {\n    __typename\n    id\n    predictions\n    products {\n      ...AuditInventoryItem\n    }\n    dateCompleted\n    display {\n      id\n      isCompetitor\n      area\n    }\n  }\n  ", "\n"], ["\n  fragment DisplayAudit on DisplayAudit {\n    __typename\n    id\n    predictions\n    products {\n      ...AuditInventoryItem\n    }\n    dateCompleted\n    display {\n      id\n      isCompetitor\n      area\n    }\n  }\n  ", "\n"])), AuditInventoryItemFragment_1.AUDIT_INVENTORY_ITEM_FRAGMENT);
var templateObject_1;
