/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  Check,
  Download,
  Edit,
  Pause,
  PlayArrow,
  Stop,
  Storefront,
  TrendingUp,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { DAITable, Filter, SearchBar } from '@dai/web-components'
import { EditStoreFormulasModal } from 'stores/components/modal/EditStoreFormulas.modal'
import { StoresInfoModal } from 'stores/components/modal/StoresInfo.modal'
import { EditOptimizationsModal } from 'stores/components/modal/EditOptimizations.modal'
import { PauseScansModal } from 'stores/components/modal/PauseScans.modal'
import { ResumeScansModal } from 'stores/components/modal/ResumeScans.modal'
import { StopScansModal } from 'stores/components/modal/StopScans.modal'
import { useStoresLogic } from 'stores/containers/Stores.logic'
import { StoreRow } from 'stores/stores.types'

const StoreManagementContainer: React.FC = () => {
  const {
    state: {
      setStoreSearch,
      selectedIds,
      setSelectedIds,
      modalState,
      setModalState,
      hideInactive,
      setHideInactive,
      fetching,
      FilterProps,
    },
    handle: {
      handleMarkActive,
      handleMarkInactive,
      handleSaveCompanyStoresUpdate,
      handlePageChange,
      handleFetchAll,
      handleCancelFetchAll,
    },
    ref: { tableRef },
    data: {
      fetchProgress,
      isRedAdmin,
      isSalesAdmin,
      isSalesManager,
      columns,
      storeRows,
      selectedStores,
      loading,
      count,
      PAGE_SIZE,
      companyStoresInput,
    },
  } = useStoresLogic<StoreRow>({})
  const theme = useTheme()

  const noStoppedStoresSelected = selectedStores.every(
    store => !store.allOptimizingPaused || !!store.resumeOptimizingAt,
  )
  return (
    <>
      <DAITable
        ref={tableRef}
        tableToolBarProps={{
          tableTitle: 'Stores ',
          right: {
            iconButton: {
              icon: <Download />,
              buttonProps: {
                onClick: () => setModalState('show-stores-info'),
              },
              toolTipLabel: 'Download Store Info',
            },
            others: [
              <>
                {fetching ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                    gap={theme.spacing()}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={fetchProgress}
                      sx={{ width: 300 }}
                    />
                    <Typography>{Math.round(fetchProgress)}%</Typography>
                    <Button
                      variant={'contained'}
                      onClick={handleCancelFetchAll}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : loading || count === 0 ? null : count ===
                  storeRows.length ? (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Typography>
                      All {count.toLocaleString('en-US')} Fetched
                    </Typography>
                    <Check />
                  </Box>
                ) : (
                  <Button variant={'contained'} onClick={handleFetchAll}>
                    Fetch All {count.toLocaleString('en-US')}
                  </Button>
                )}
              </>,
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setHideInactive(!hideInactive)}
                    checked={hideInactive}
                  />
                }
                label="Hide Inactive"
              />,
              <SearchBar
                useResponsive
                placeholder="Search Stores"
                onChange={value => setStoreSearch(value)}
              />,
              <Filter filterTitle="Filter Stores" {...FilterProps} />,
            ],
          },
        }}
        rows={storeRows}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowCount={count}
        onPageChange={handlePageChange}
        defaultOrderBy="store"
        loading={loading}
        onSelectionChange={setSelectedIds}
        bulkActions={[
          {
            condition: isRedAdmin,
            icon: <Edit color="secondary" />,
            label: 'Edit',
            onClick: () => setModalState('edit-store'),
          },
          {
            condition:
              isRedAdmin && selectedStores.some(store => !store.isActive),
            icon: <Storefront color="secondary" />,
            label: 'Active',
            onClick: handleMarkActive,
          },
          {
            condition:
              isRedAdmin && selectedStores.some(store => store.isActive),
            icon: <Storefront color="secondary" />,
            label: 'Inactive',
            onClick: handleMarkInactive,
          },
          {
            condition: isSalesAdmin,
            icon: <TrendingUp color="secondary" />,
            label: 'Schedule',
            onClick: () => setModalState('edit-optimizations'),
          },
          {
            condition:
              (isSalesAdmin || (isSalesManager && noStoppedStoresSelected)) &&
              selectedStores.some(store => store.allOptimizingPaused),
            icon: <PlayArrow color="secondary" />,
            label: 'Scans',
            onClick: () => setModalState('resume-scans'),
          },
          {
            condition:
              isSalesAdmin &&
              selectedStores.some(
                store =>
                  !store.allOptimizingPaused || !!store.resumeOptimizingAt,
              ),
            icon: <Stop color="secondary" />,
            label: 'Scans',
            onClick: () => setModalState('stop-scans'),
          },
          {
            condition:
              (isSalesAdmin || (isSalesManager && noStoppedStoresSelected)) &&
              selectedStores.some(
                store =>
                  !store.allOptimizingPaused || !store.resumeOptimizingAt,
              ),
            icon: <Pause color="secondary" />,
            label: 'Scans',
            onClick: () => setModalState('pause-scans'),
          },
        ]}
      />
      <EditStoreFormulasModal
        handleClose={() => setModalState(null)}
        isOpen={!!selectedIds.length && modalState === 'edit-store'}
        onSubmit={handleSaveCompanyStoresUpdate}
        storeIds={selectedIds}
      />
      <StoresInfoModal
        onCancel={() => setModalState(null)}
        isOpen={modalState === 'show-stores-info'}
      />
      <EditOptimizationsModal
        isOpen={modalState === 'edit-optimizations'}
        handleClose={() => setModalState(null)}
        stores={storeRows.filter(store => selectedIds.includes(store.id))}
        companyStoresInput={companyStoresInput}
      />
      <PauseScansModal
        isOpen={modalState === 'pause-scans'}
        handleClose={() => setModalState(null)}
        stores={storeRows.filter(store => selectedIds.includes(store.id))}
        companyStoresInput={companyStoresInput}
      />
      <ResumeScansModal
        isOpen={modalState === 'resume-scans'}
        handleClose={() => setModalState(null)}
        stores={storeRows.filter(store => selectedIds.includes(store.id))}
        companyStoresInput={companyStoresInput}
      />
      <StopScansModal
        isOpen={modalState === 'stop-scans'}
        handleClose={() => setModalState(null)}
        stores={storeRows.filter(store => selectedIds.includes(store.id))}
        companyStoresInput={companyStoresInput}
      />
    </>
  )
}

export default StoreManagementContainer
