"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORE_HIERARCHY = void 0;
var client_1 = require("@apollo/client");
exports.GET_STORE_HIERARCHY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetStoreHierarchy {\n    zoneHierarchy {\n      zones {\n        id\n        parentId\n        name\n        assignedUsers {\n          fullName\n          mainRole\n        }\n        manager {\n          fullName\n        }\n      }\n    }\n  }\n"], ["\n  query GetStoreHierarchy {\n    zoneHierarchy {\n      zones {\n        id\n        parentId\n        name\n        assignedUsers {\n          fullName\n          mainRole\n        }\n        manager {\n          fullName\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
