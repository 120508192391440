"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getName = exports.hasGroups = exports.hasGroup = exports.hasPermission = void 0;
var hasPermission = function (user, allowedRoles, options) {
    if (user) {
        if (allowedRoles === undefined ||
            (user.isSuperuser && !(options === null || options === void 0 ? void 0 : options.ignoreSuperuser))) {
            return true;
        }
        return (!!allowedRoles && allowedRoles.some(function (role) { return user.roles.includes(role); }));
    }
    return false;
};
exports.hasPermission = hasPermission;
var hasGroup = function (user, role) {
    return !!user && !!(user === null || user === void 0 ? void 0 : user.roles) && (user === null || user === void 0 ? void 0 : user.roles.includes(role));
};
exports.hasGroup = hasGroup;
var hasGroups = function (user, allowedRoles) {
    return (!!(user === null || user === void 0 ? void 0 : user.roles) &&
        !!allowedRoles &&
        allowedRoles.every(function (role) { return user === null || user === void 0 ? void 0 : user.roles.includes(role); }));
};
exports.hasGroups = hasGroups;
var getName = function (user) {
    if (user) {
        var firstName = user.firstName, lastName = user.lastName, email = user.email;
        if (firstName || lastName) {
            return "".concat(firstName, " ").concat(lastName);
        }
        return email;
    }
    return 'User';
};
exports.getName = getName;
