"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_TRAINING = void 0;
var client_1 = require("@apollo/client");
var TrainingFragment_1 = require("../fragments/TrainingFragment");
exports.CREATE_TRAINING = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CreateTraining($input: TrainingInput!) {\n    createTraining(input: $input) {\n      training {\n        ...Training\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation CreateTraining($input: TrainingInput!) {\n    createTraining(input: $input) {\n      training {\n        ...Training\n      }\n    }\n  }\n  ", "\n"])), TrainingFragment_1.TRAINING_FRAGMENT);
var templateObject_1;
