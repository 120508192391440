/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  GetPicOSSalesCenterComplianceQuery,
  GetPicOSZoneComplianceStatsQuery,
  GetPicOSZoneComplianceStatsQueryVariables,
  GetPicOSSalesCenterComplianceQueryVariables,
  GET_PICOS_SALES_CENTER_COMPLIANCE,
  GET_PICOS_ZONE_COMPLIANCE_STATS,
} from '@dai/graphql'
import {
  useDebouncedItemQuery,
  usePagination,
  BasicPicOSCustomerInfo,
  PicOSReportContext,
} from '@dai/web-components'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface SalesCenterReportProps {
  customerId: string
  divisionId: string
  salesCenterId: string
}

const useSalesCenterComplianceReport = (props: SalesCenterReportProps) => {
  const { customerId, divisionId, salesCenterId } = props
  const history = useHistory()
  const {
    selectedDateRange,
    selectedSalesCenter,
    setSelectedStore,
  } = useContext(PicOSReportContext)
  const {
    offset,
    limit,
    handleOffset: { goToPage },
  } = usePagination({ pageSize: 20 })

  const OverviewQuery = useDebouncedItemQuery<
    GetPicOSSalesCenterComplianceQuery,
    GetPicOSSalesCenterComplianceQueryVariables
  >({
    queryStr: GET_PICOS_SALES_CENTER_COMPLIANCE,
    options: {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          zoneId: salesCenterId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    GetPicOSZoneComplianceStatsQuery,
    GetPicOSZoneComplianceStatsQueryVariables
  >({
    queryStr: GET_PICOS_ZONE_COMPLIANCE_STATS,
    options: {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          zoneId: salesCenterId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const handleSelect = (info: BasicPicOSCustomerInfo) => {
    setSelectedStore(info)
    history.push(`${salesCenterId}/${info.id}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          zoneId: salesCenterId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          storeName: OverviewQuery.debouncedQuery || undefined,
          offset,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const overviewQuery = OverviewQuery.lazyQuery.meta
  const statsQuery = StatsQuery.lazyQuery.meta

  const statItems = PicOSReportHelpers.getStatItems(
    ['compliance', 'customers', 'stores', 'priorities', 'cost'],
    statsQuery.data?.picosZoneStats,
  )

  return {
    data: {
      title: selectedSalesCenter?.name || 'Sales Center',
      complianceResults:
        overviewQuery.data?.picosSalesCenterCompliance.results || [],
      statItems,
    },
    state: {
      limit,
      loadingStats: !!statsQuery.loading,
      loadingCompliance: !!overviewQuery.loading,
    },
    handlers: {
      handleSelect,
      handleSearch,
      goToPage,
    },
  }
}

export default useSalesCenterComplianceReport
