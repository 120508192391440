/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import PicOSContext from 'context/PicOSContext'
import { usePicosPriorityForm } from 'picos/hooks/form/use-picos-priority.form'
import { usePicosStoreGroupForm } from 'picos/hooks/form/use-picos-store-group.form'
import { usePicosDisplayInfoForm } from 'picos/hooks/form/use-picos-display-info.form'
import { usePicosProductsForm } from 'picos/hooks/form/use-picos-products.form'
import { useLazyQuery, useApolloClient, useQuery } from '@apollo/client'
import {
  StoreTypesQueryVariables,
  GET_STORE_TYPES,
  GET_VALID_IMAGE_FORMATS,
  StoreCategory,
  GET_PICOS_PRIORITY,
  StoreTypesQuery,
  GetPicOSPriorityQuery,
  GetPicOSPriorityQueryVariables,
} from '@dai/graphql'
import { useStepper, StringHelpers } from '@dai/web-components'
import { Banner, FormPicOSPriority } from 'picos/picos.types'
import { debounce, isEqual } from 'lodash'
import { PicosConstants } from 'picos/picos.constants'
import { uniqueId } from '@dai/common'
import { CachedPicosPriority, PicOSHelpers } from 'picos/helpers/picos.helpers'
import { useDebouncedItemQuery } from '@dai/web-components'
import { usePicosPriorityMutations } from 'picos/hooks/use-picos-priority-mutations'

const debouncedWriteToLocalStorage = debounce(
  (
    priorityToEdit: FormPicOSPriority,
    selectedBanner: Banner,
    picosId: string,
  ) =>
    PicOSHelpers.savePicosLocalStorage(
      priorityToEdit,
      selectedBanner.label,
      picosId,
    ),
  1000,
)

const steps = {
  picosPriority: { name: 'PicOS Priority', indexNum: 0 },
  displayInfo: { name: 'Display Info', indexNum: 1 },
  products: { name: 'Products', indexNum: 2 },
  picosImage: { name: 'PicOS Image', indexNum: 3 },
}

export const useCreatePicOSPriorityLogic = () => {
  const {
    state: { activeStep },
    handle: { handleStep },
  } = useStepper(Object.values(steps).map(step => step.name))

  const {
    urlSearchParams,
    handleUpdateSearchParams,
    handleNavTo,
    selectedBanner,
    setPriorityToEdit,
    priorityToEdit,
    isNewPriority,
    setIsNewPriority,
    setStoreTypesInCustomer,
  } = useContext(PicOSContext)
  const {
    state: {
      imageFile,
      setImageFile,
      createPicOSLoading,
      updatePicOSLoading,
      imageUploadLoading,
    },
    handle: { handleSubmitCreatePicOS, handleSubmitUpdatePicOS },
  } = usePicosPriorityMutations()

  const PicOSPriorityForm = usePicosPriorityForm()
  const SGF = usePicosStoreGroupForm()
  const DisplayInfoForm = usePicosDisplayInfoForm()
  const PicOSProductForm = usePicosProductsForm()

  const [draftPicos, setDraftPicos] = useState<CachedPicosPriority[]>([])
  const [incompleteOrLoading, setIncompleteOrLoading] = useState<boolean>(true)
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [allowedFileFormats, setAllowedFileFormats] = useState<string[]>([])

  const client = useApolloClient()

  const picosId = urlSearchParams.priorityId || ''

  useQuery(GET_VALID_IMAGE_FORMATS, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      setAllowedFileFormats([...data.validImageFormat])
    },
  })
  const PicOSPriorityLQ = useDebouncedItemQuery<
    GetPicOSPriorityQuery,
    GetPicOSPriorityQueryVariables
  >({
    queryStr: GET_PICOS_PRIORITY,
    options: { fetchPolicy: 'cache-and-network' },
  })

  const [getStoreTypes] = useLazyQuery<
    StoreTypesQuery,
    StoreTypesQueryVariables
  >(GET_STORE_TYPES, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      setStoreTypesInCustomer(data.storeTypes as StoreCategory[])
    },
  })

  const getDraftsForBanner = () => {
    if (selectedBanner?.label) {
      const drafts = PicOSHelpers.getAllPicosPrioritiesFromLocalStorage(
        selectedBanner.label,
      )
      if (drafts) {
        setDraftPicos(drafts)
      }
    }
  }

  const forms = {
    0: {
      completed:
        PicOSPriorityForm.data.canSubmit &&
        (PicOSPriorityForm.state.formState?.radio.STORES.value === 'true' ||
          SGF.data.canSubmit),
      formState: PicOSPriorityForm.state.formState,
    },
    1: {
      completed: DisplayInfoForm.data.canSubmit,
      formState: DisplayInfoForm.state.formState,
    },
    2: {
      completed: !!priorityToEdit.addedProducts?.length,
      formState: PicOSProductForm.state.formState,
    },
    3: {
      completed: !!imageFile || !!(priorityToEdit && priorityToEdit.imageUrl),
    },
  }

  const isImageStep = activeStep === 3

  const totalSteps = Object.values(forms).length
  const completedSteps = Object.values(forms).filter(form => form.completed)
    .length
  const activeStepCompleted = Object.values(forms).some(
    (key, idx) => idx === activeStep && key.completed,
  )
  // @ts-ignore
  const orderOfForms = Object.keys(forms[activeStep].formState || {})

  const handleClearForms = () => {
    setPriorityToEdit(PicosConstants.EMPTY_PICOS)
  }

  const handleSubmit = () => {
    if (selectedBanner) {
      if (isNewPriority) {
        handleSubmitCreatePicOS()
      } else {
        handleSubmitUpdatePicOS()
        client.clearStore()
      }
      PicOSHelpers.removePicosPriorityFromLocalStorage(
        selectedBanner.label,
        picosId,
      )
    }
  }

  const handleDeletePicosDraft = (id: string, banner: string) => {
    PicOSHelpers.removePicosPriorityFromLocalStorage(banner, id)
    getDraftsForBanner()
  }

  const handleEditPicosDraft = (priorityId: string) => {
    setIsNewPriority(true)
    handleNavTo('/picos/create', { replaceParams: { priorityId } })
  }

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(picosId) && !priorityToEdit) {
      PicOSPriorityLQ.lazyQuery
        .query({
          variables: {
            priorityId: picosId,
          },
        })
        .then(res => {
          const pr = res.data?.picosPriority
          if (pr) {
            setPriorityToEdit(PicOSHelpers.transformPriority(pr))
          }
        })
    }
  }, [picosId, priorityToEdit])

  useEffect(() => {
    setIncompleteOrLoading(
      completedSteps !== totalSteps ||
        createPicOSLoading ||
        updatePicOSLoading ||
        imageUploadLoading,
    )
  }, [
    completedSteps,
    totalSteps,
    createPicOSLoading,
    updatePicOSLoading,
    imageUploadLoading,
  ])

  useEffect(() => {
    if (StringHelpers.isNullOrEmpty(picosId)) {
      handleUpdateSearchParams({ priorityId: uniqueId() })
    }
  }, [picosId])

  useEffect(() => {
    if (isNewPriority) {
      if (selectedBanner?.label) {
        if (!isEqual(priorityToEdit, PicosConstants.EMPTY_PICOS)) {
          debouncedWriteToLocalStorage(priorityToEdit, selectedBanner, picosId)
        }
      }
    }
  }, [priorityToEdit])

  useEffect(() => {
    if (isNewPriority) {
      const picos = PicOSHelpers.getPicosLocalStorage(picosId)
      if (picos) {
        setPriorityToEdit(picos)
      }
    }
  }, [picosId])

  useEffect(() => {
    getDraftsForBanner()
  }, [selectedBanner?.label])

  useEffect(() => {
    if (PicOSPriorityForm.state.formState?.radio.STORES.value === 'true') {
      getStoreTypes({
        variables: {
          input: {
            bannerId: selectedBanner?.id,
          },
        },
      })
    } else if (
      PicOSPriorityForm.state.formState?.radio.STORES.value === 'false'
    ) {
      if (Array.isArray(SGF.state.formState?.searchSelect.GROUPS.value)) {
        getStoreTypes({
          variables: {
            input: {
              groupIds: SGF.state.formState?.searchSelect.GROUPS.value,
            },
          },
        })
      }
    }
  }, [priorityToEdit])

  return {
    state: {
      activeStep,
      imageFile,
      draftPicos,
      incompleteOrLoading,
      setImageFile,
      showConfirmCancel,
      setShowConfirmCancel,
      showConfirmDelete,
      setShowConfirmDelete,
    },
    data: {
      picosId,
      forms,
      isImageStep,
      totalSteps,
      completedSteps,
      activeStepCompleted,
      orderOfForms,
      steps,
      createPicOSLoading,
      updatePicOSLoading,
      imageUploadLoading,
      allowedFileFormats,
    },
    forms: {
      PicOSPriorityForm,
      SGF,
      DisplayInfoForm,
      PicOSProductForm,
    },
    handle: {
      handleStep,
      handleSubmit,
      handleClearForms,
      handleDeletePicosDraft,
      handleEditPicosDraft,
    },
  }
}
