"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUDIT_QUESTION_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.AUDIT_QUESTION_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment AuditQuestion on AuditQuestion {\n    id\n    question\n    category\n    pointWorth\n    group\n    active\n    hideInApp\n  }\n"], ["\n  fragment AuditQuestion on AuditQuestion {\n    id\n    question\n    category\n    pointWorth\n    group\n    active\n    hideInApp\n  }\n"])));
var templateObject_1;
