"use strict";
/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Michael Selander <michael.selander@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUserStores = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useGetUserStores = function (input) {
    var _a = (0, client_1.useLazyQuery)(graphql_1.GET_USER_STORES, { variables: { input: input }, fetchPolicy: 'no-cache' }), getUserStores = _a[0], _b = _a[1], data = _b.data, refetch = _b.refetch;
    var userStores = (data === null || data === void 0 ? void 0 : data.userStores) || [];
    var refetchUserStores = refetch;
    return {
        getUserStores: getUserStores,
        refetchUserStores: refetchUserStores,
        userStores: userStores,
    };
};
exports.useGetUserStores = useGetUserStores;
