/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { useGetCompanyUsers } from '@dai/common'
import {
  UserFragment,
  ASSIGN_USER_SUPERVISOR,
  AssignUserSupervisorMutation,
  AssignUserSupervisorMutationVariables,
  AssignUserSupervisorInput,
} from '@dai/graphql'
import UserContext from 'context/UserContext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useMutation } from '@apollo/client'
import {
  DAITable,
  DAITableColumns,
  MuiTheme,
  SearchBar,
  TableContainerClass,
  MuiFormModal,
  MuiFormModalProps,
} from '@dai/web-components'
import { Close, Save } from '@mui/icons-material'
import { SimpleUserRow } from 'users/users.types'

interface UserSupervisorFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  loading?: boolean
  selectedUsers: UserFragment[] | null | undefined
  onSubmit: () => void
}

const columns: DAITableColumns<SimpleUserRow>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: MuiTheme.sizes.table.column.width.xl,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: MuiTheme.sizes.table.column.width.lg,
  },
]

const PAGE_SIZE = MuiTheme.sizes.table.page.sm

export const UserSupervisorModal: React.FunctionComponent<UserSupervisorFormProps> = props => {
  const { selectedUsers, onSubmit, handleClose, isOpen } = props
  const { user } = useContext(UserContext)
  const [userSearch, setUserSearch] = useState('')
  const [debouncedUserSearchQuery] = useDebounce(userSearch, 500)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const tableRef = useRef<DAITable<SimpleUserRow>>(null)

  const [assignSupervisor] = useMutation<
    AssignUserSupervisorMutation,
    AssignUserSupervisorMutationVariables
  >(ASSIGN_USER_SUPERVISOR)

  const { users, count, loading, fetchNextPage } = useGetCompanyUsers({
    limit: PAGE_SIZE,
    searchTerm: debouncedUserSearchQuery,
  })

  useEffect(() => {
    tableRef.current?.returnToFirstPage()
  }, [debouncedUserSearchQuery])

  const selectedUser =
    selectedIds.length === 1 ? users?.find(q => q.id === selectedIds[0]) : null

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= users.length) {
      await fetchNextPage()
    }
  }

  const onAssignClick = async () => {
    if (selectedUser) {
      const input: AssignUserSupervisorInput = {
        supervisorId: selectedUser.uuid,
        users: selectedUsers?.map(user => user.uuid),
      }
      await assignSupervisor({ variables: { input } })
      onSubmit()
    }
  }

  const userRows = users.map(user => ({
    id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: user.mainRole || '',
  }))

  return (
    <MuiFormModal
      sx={{ padding: 0 }}
      isOpen={isOpen}
      title="Assign to Manager"
      subtitle={`${selectedUsers?.length || 0} user${
        selectedUsers?.length || 0 > 1 ? 's' : ''
      } selected`}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          startIcon: <Close color="secondary" />,
          disabled: false,
          color: 'secondary',
        },
        {
          onClick: onAssignClick,
          children: 'Save',
          startIcon: <Save color="secondary" />,
          disabled: !selectedUser,
        },
      ]}
    >
      <DAITable
        rows={userRows}
        columns={columns}
        className={TableContainerClass.MODAL}
        tableToolBarProps={{
          tableTitle: 'Managers',
          right: {
            others: [
              <SearchBar
                useResponsive
                onChange={value => setUserSearch(value)}
              />,
            ],
          },
        }}
        pageSize={PAGE_SIZE}
        rowCount={count}
        onPageChange={handlePageChange}
        loading={loading}
        disableSelectAll
        singleSelect
        showCheckbox
        onSelectionChange={setSelectedIds}
      />
    </MuiFormModal>
  )
}
