/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { Paper, Stack, Typography, useTheme } from '@mui/material'
import { Lock } from '@mui/icons-material'
import { ApolloError } from '@apollo/client'

const ZoneErrorState: React.FC<{ error: ApolloError }> = ({ error }) => {
  const theme = useTheme()
  const permissionError = error.message.includes(
    "You don't have access to view this zone",
  )

  const props = permissionError
    ? {
        Icon: Lock,
        message:
          "You don't have access to this page. Please contact your supervisor to gain access.",
      }
    : {
        Icon: Lock,
        message:
          "You don't have access to this page. Please contact your supervisor to gain access.",
      }

  return (
    <Paper sx={{ m: 2, p: 2, flex: 1, ...theme.view.col.center }} elevation={0}>
      <Stack alignItems="center" spacing={2}>
        <props.Icon
          sx={theme => ({
            color: theme.palette.grey['400'],
            width: 50,
            height: 50,
          })}
        />
        <Typography
          textAlign={'center'}
          variant="h6"
          sx={theme => ({
            color: theme.palette.grey['400'],
          })}
        >
          {props.message}
        </Typography>
      </Stack>
    </Paper>
  )
}

export default ZoneErrorState
