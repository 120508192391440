/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import {
  BrandHistoryQueryQuery,
  BrandHistoryQueryQueryVariables,
  BRAND_HISTORY,
} from '@dai/graphql'
import React, { useEffect, useState } from 'react'
import {
  Brand,
  ReportLayout,
  LineChartData,
  LineChart,
  MuiTheme,
} from '@dai/web-components'
import { BrandDisruptorQueryProps } from 'brands/brand-disruptor/brand-disruptor.types'
import { AxisRenderer, DateAxis, XYChart } from '@amcharts/amcharts5/xy'
import { BrandSelector } from './BrandSelector'

export const BrandHistory: React.FC<BrandDisruptorQueryProps> = props => {
  const { startDate, endDate, dateRangeType, selectedBrands, moreInfo } = props

  const { data: brandHistory, loading: brandHistoryLoading } = useQuery<
    BrandHistoryQueryQuery,
    BrandHistoryQueryQueryVariables
  >(BRAND_HISTORY, {
    variables: {
      input: {
        startDate,
        endDate,
        type: dateRangeType,
        brandIds: selectedBrands.map(b => b.id),
      },
    },
  })

  const [lineData, setLineData] = useState<LineChartData>({})

  useEffect(() => {
    if (brandHistory) {
      const lineData: LineChartData = brandHistory.brandHistory.brandHistories.reduce<LineChartData>(
        (acc, brand_item) => ({
          ...acc,
          [brand_item.brand.name]: brand_item.historicalRelevancyList
            .map(item => ({
              date: new Date(item.date).getTime(),
              value: item.relevancyScore,
            }))
            .sort((a: any, b: any) =>
              a.date > b.date ? 1 : a.date < b.date ? -1 : 0,
            ),
        }),
        {},
      )
      setLineData(lineData)
    }
  }, [brandHistory, selectedBrands])

  return (
    <ReportLayout
      title={'Brand History'}
      moreInfo={moreInfo}
      Body={
        <LineChart
          xAxisLabel="Date"
          yAxisLabel="Current SOVI"
          chartId="line_chart"
          data={lineData}
          smallNumberPrefixes={[
            { number: 1.0 / 100, suffix: 'bps' },
            { number: 1.0 / 10000, suffix: 'cbps' },
            { number: 1.0 / 100000, suffix: 'mbps' },
            { number: 1.0 / 1000000, suffix: 'nbps' },
          ]}
          renderExtraSeries={({ root, chart, xAxis, yAxis }) => {
            const xyChart = chart as XYChart
            if (xyChart) {
              xyChart.series
                .getIndex(0)
                ?.events.once('datavalidated', event => {
                  const dateAxis = xyChart.xAxes.getIndex(
                    0,
                  ) as DateAxis<AxisRenderer>
                  if (dateAxis && dateAxis.zoomToDates) {
                    dateAxis.zoomToDates(new Date(startDate), new Date(endDate))
                  }
                })
            }
          }}
          isEmpty={
            !selectedBrands.length ||
            !brandHistory?.brandHistory.brandHistories.length
          }
          tooltip={[
            {
              text: '{valueY.formatValue()}',
              fontSize: 28,
              color: MuiTheme.palette.secondary.main,
              bold: true,
            },
            {
              text: 'SOVI',
              color: MuiTheme.palette.secondary.light,
              newline: true,
            },
            {
              text: '{valueX.formatDate()}',
              color: MuiTheme.palette.secondary.light,
            },
          ]}
          isLoading={brandHistoryLoading}
        />
      }
    />
  )
}
