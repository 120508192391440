"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DISPLAY_BY_QR_ID = void 0;
var client_1 = require("@apollo/client");
exports.GET_DISPLAY_BY_QR_ID = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery GetDisplayByQrId($qrId: ID!) {\n    displayByQrId(qrId: $qrId) {\n        id\n        name\n        qrId\n        images {\n            url\n        }\n        store {\n            name\n        }\n    }\n}\n"], ["\nquery GetDisplayByQrId($qrId: ID!) {\n    displayByQrId(qrId: $qrId) {\n        id\n        name\n        qrId\n        images {\n            url\n        }\n        store {\n            name\n        }\n    }\n}\n"])));
var templateObject_1;
