"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PICOS_ALL_PRIORITIES_COMPLIANCE_STATS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.PICOS_ALL_PRIORITIES_COMPLIANCE_STATS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PicOSAllPrioritiesComplianceStats(\n    $input: PicOSAllPrioritiesComplianceInput!\n  ) {\n    picosAllPrioritiesStats(input: $input) {\n      current {\n        ...PicOSGroupComplianceStats\n      }\n      previous {\n        ...PicOSGroupComplianceStats\n      }\n    }\n  }\n  ", "\n"], ["\n  query PicOSAllPrioritiesComplianceStats(\n    $input: PicOSAllPrioritiesComplianceInput!\n  ) {\n    picosAllPrioritiesStats(input: $input) {\n      current {\n        ...PicOSGroupComplianceStats\n      }\n      previous {\n        ...PicOSGroupComplianceStats\n      }\n    }\n  }\n  ", "\n"])), fragments_1.PICOS_GROUP_COMPLIANCE_STATS_FRAGMENT);
var templateObject_1;
