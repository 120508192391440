"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLAGGABLE_OBJECT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var SimpleProductFragment_1 = require("./SimpleProductFragment");
var DisplayAuditImageFragment_1 = require("./DisplayAuditImageFragment");
var UserFragment_1 = require("./UserFragment");
exports.FLAGGABLE_OBJECT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment FlaggableObject on FlaggableObject {\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        ...User\n      }\n      levelRaisedBy {\n        ...User\n      }\n      date\n    }\n    ... on DisplayAuditImage {\n      ...DisplayAuditImage\n    }\n    ... on Product {\n      ...SimpleProduct\n    }\n  }\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment FlaggableObject on FlaggableObject {\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        ...User\n      }\n      levelRaisedBy {\n        ...User\n      }\n      date\n    }\n    ... on DisplayAuditImage {\n      ...DisplayAuditImage\n    }\n    ... on Product {\n      ...SimpleProduct\n    }\n  }\n  ", "\n  ", "\n  ", "\n"])), UserFragment_1.USER_FRAGMENT, DisplayAuditImageFragment_1.DISPLAY_AUDIT_IMAGE_FRAGMENT, SimpleProductFragment_1.SIMPLE_PRODUCT_FRAGMENT);
var templateObject_1;
