/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { Category, Close, LocalDrink, Save } from '@mui/icons-material'
import {
  AvatarWithText,
  FILL_GRID_ALL_SCREENS,
  Flagged,
  GridSections,
  MuiFormModal,
  MuiTheme,
  StringHelpers,
} from '@dai/web-components'
import { Card, CardMedia } from '@mui/material'
import DraftInfo, { DraftProduct } from 'components/DraftInfo'

type ConfirmSaveProductModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  handleSave: () => void
  product: {
    draft?: DraftProduct | null
    brand?: string | null
    packaging?: string | null
    category?: string | null
    imageUrl?: string | null
  }
}

const ConfirmSaveProductModal: React.FC<ConfirmSaveProductModalProps> = props => {
  const { isOpen, setIsOpen, handleSave, product } = props

  const submitState = {
    packaging: !StringHelpers.isNullOrEmpty(product.packaging),
    brand: !StringHelpers.isNullOrEmpty(product.brand),
    // category: !StringHelpers.isNullOrEmpty(product.category),
  }

  const canSubmit = Object.values(submitState).every(st => st) && !product.draft

  return (
    <MuiFormModal
      title="Are you sure?"
      subtitle="Verify the information below before saving."
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      actionButtonsProps={[
        {
          children: 'Cancel',
          onClick: () => setIsOpen(false),
          startIcon: <Close color="secondary" />,
          color: 'secondary',
        },
        {
          children: 'Save & Upload',
          startIcon: <Save color="secondary" />,
          onClick: handleSave,
          disabled: !canSubmit,
        },
      ]}
    >
      {product.draft && (
        <Card variant="outlined" sx={{ mb: 2 }}>
          <DraftInfo
            errorMessage="A user in the field has reported an error with this product"
            draft={product.draft}
          />
        </Card>
      )}
      <GridSections
        items={{
          'Packaging & Brand': {
            gridProps: FILL_GRID_ALL_SCREENS,
            body: (
              <AvatarWithText
                primaryText={
                  submitState.brand && product.brand
                    ? product.brand
                    : 'Missing Brand'
                }
                secondaryText={
                  submitState.packaging && product.packaging
                    ? product.packaging
                    : 'Missing Packaging'
                }
                avatarColor={
                  submitState.brand && submitState.packaging
                    ? MuiTheme.palette.success.main
                    : MuiTheme.palette.error.main
                }
              >
                <LocalDrink color="secondary" />
              </AvatarWithText>
            ),
          },
          // Category: {
          //   gridProps: FILL_GRID_ALL_SCREENS,
          //   body: (
          //     <AvatarWithText
          //       primaryText={
          //         submitState.category && product.category
          //           ? product.category
          //           : 'Missing Category'
          //       }
          //       avatarColor={
          //         submitState.category
          //           ? MuiTheme.palette.success.main
          //           : MuiTheme.palette.error.main
          //       }
          //     >
          //       <Category color="secondary" />
          //     </AvatarWithText>
          //   ),
          // },
          'Marketing Image': {
            gridProps: FILL_GRID_ALL_SCREENS,
            body: (
              <CardMedia
                component="img"
                sx={theme => ({
                  objectFit: 'contain',
                  height: theme.sizes.img.md,
                })}
                image={product.imageUrl || '/delish-bottle-square.png'}
              />
            ),
          },
        }}
      />
    </MuiFormModal>
  )
}

export default ConfirmSaveProductModal
