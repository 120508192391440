/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Mitch Wilson <mitchell.wilson@deliciousai.com>
 */

import React, { useContext } from 'react'
import {
  FullScreenLayout,
  PageTitle,
  ActionBar,
  gridAllScreens,
  NavigationDrawerContext,
  FlexBox,
  MuiForm,
  ImageCarousel,
} from '@dai/web-components'
import { Grid, SpeedDialIcon } from '@mui/material'
import Box from '@mui/material/Box'
import { Close, RestartAlt, Save, ShoppingCart } from '@mui/icons-material'
import { ImageType } from '@dai/graphql'
import VideoUploadCard from 'trainings/components/VideoUploadCard'
import TrainingSearch from './TrainingSearch'
import { useTrainingsLogic } from './Trainings.logic'

const TrainingsContainer: React.FC = () => {
  const {
    state: {
      trainingEditing,
      selectedTrainingId,
      setSelectedTrainingId,
      modalOpen,
      setModalOpen,
      formState,
      imageUrlToDisplay,
      FilterProps,
      filteredTrainings,
      videoFile,
      setVideoFile,
    },
    data: { canSubmit, canUpdateTraining, loading, addingImage },
    handle: {
      handleUpdateTraining,
      handleOnChange,
      clearForm,
      handleUploadImage,
      handleSubmitNewTraining,
      handleDeleteTraining,
      handleUpdateSearchValue,
    },
  } = useTrainingsLogic()

  const { isOpen: drawerIsOpen } = useContext(NavigationDrawerContext)

  return (
    <FullScreenLayout
      header={<PageTitle title="Training Management" />}
      body={
        <>
          <FlexBox.Row>
            <TrainingSearch
              trainings={filteredTrainings}
              onTrainingSelected={(id: string) => {
                setSelectedTrainingId(id)
              }}
              selectedTrainingId={selectedTrainingId || null}
              setSearchValue={searchValue =>
                handleUpdateSearchValue(searchValue)
              }
              hasMore={false}
              onLoadMore={() => {}}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              {...FilterProps}
            />
            <FlexBox.Col flexGrow={1} mb={14}>
              <Grid container spacing={2}>
                <Grid
                  item
                  {...gridAllScreens(6)}
                  md={drawerIsOpen ? 12 : 6}
                  xs={12}
                >
                  <ImageCarousel
                    title="Training Image"
                    formTitle="Upload new training image"
                    buttons={[]}
                    loading={addingImage}
                    enableUpload
                    onConfirmUploadImage={handleUploadImage}
                    images={[
                      {
                        uri: imageUrlToDisplay || '',
                        id: trainingEditing?.trainingImageId || '',
                        imageType: ImageType.TRAINING,
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  {...gridAllScreens(6)}
                  md={drawerIsOpen ? 12 : 6}
                  xs={12}
                  sx={{ md: { height: '100%' } }}
                >
                  <VideoUploadCard
                    title={'Training Video'}
                    subheader={'Assign a video to this training'}
                    objectType={'training'}
                    videoUrl={trainingEditing?.videoUrl || videoFile?.path}
                    onVideoFileChange={file => setVideoFile(file)}
                    videoFile={videoFile}
                  />
                </Grid>
              </Grid>
              <FlexBox.Row style={{ paddingTop: 15 }}>
                <MuiForm
                  formState={formState}
                  loading={loading}
                  handleOnChange={handleOnChange}
                />
              </FlexBox.Row>
            </FlexBox.Col>
            <Box
              sx={{
                position: 'absolute',
                bottom: -32,
                right: 8,
              }}
            >
              <ActionBar
                speedDialIcon={
                  <SpeedDialIcon
                    icon={<ShoppingCart color="secondary" />}
                    openIcon={<Close color="secondary" />}
                  />
                }
                bulkActions={[
                  {
                    condition: !!selectedTrainingId && canUpdateTraining,
                    onClick: handleUpdateTraining,
                    icon: <Save color="secondary" />,
                    label: 'Save Edits',
                  },
                  {
                    condition: !!imageUrlToDisplay && canSubmit,
                    onClick: handleSubmitNewTraining,
                    icon: <Save color="secondary" />,
                    label: 'Save New Training',
                  },
                  {
                    condition: !!selectedTrainingId,
                    onClick: clearForm,
                    icon: <RestartAlt color="secondary" />,
                    label: 'Reset',
                  },
                  {
                    condition: !!selectedTrainingId,
                    onClick: handleDeleteTraining,
                    icon: <RestartAlt color="secondary" />,
                    label: 'Delete',
                  },
                ]}
              />
            </Box>
          </FlexBox.Row>
        </>
      }
    />
  )
}

export default TrainingsContainer
