/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GET_STORE_CHAINS,
  GetStoreChainsQuery,
  GetStoreChainsQueryVariables,
  StoreCategory,
} from '@dai/graphql'
import { useEffect, useState } from 'react'
import { useDebouncedItemQuery } from '@dai/web-components'
import {
  Banner,
  FormPicOSPriority,
  PicOSURLSearchParams,
} from 'picos/picos.types'
import { PicosConstants } from 'picos/picos.constants'
import { useUrlSearchParams } from '@dai/common'
import { useLocalStorage } from 'hooks/use-local-storage'
import { useLocation } from 'react-router-dom'

export const usePicOSLogic = () => {
  const {
    state: urlSearchParams,
    handle,
  } = useUrlSearchParams<PicOSURLSearchParams>()
  const { Storage } = useLocalStorage()
  const location = useLocation()
  const [selectedBanner, setSelectedBanner] = useState<Banner | null>(null)
  const [priorityToEdit, setPriorityToEdit] = useState<FormPicOSPriority>(
    PicosConstants.EMPTY_PICOS,
  )
  const [isNewPriority, setIsNewPriority] = useState(true)
  const [storeTypesInCustomer, setStoreTypesInCustomer] = useState<
    StoreCategory[]
  >()
  const CustomerBannersLazyQuery = useDebouncedItemQuery<
    GetStoreChainsQuery,
    GetStoreChainsQueryVariables
  >({
    queryStr: GET_STORE_CHAINS,
    options: { fetchPolicy: 'cache-first' },
  })

  const handleSelectBanner = (banner: Banner | null) => {
    setSelectedBanner(banner)
    handle.handleUpdateSearchParams({ bannerId: banner?.id })
    if (banner) {
      Storage.setItem('selectedBanner', banner)
    } else {
      Storage.removeItem('selectedBanner')
    }
  }

  useEffect(() => {
    CustomerBannersLazyQuery.lazyQuery.query({
      variables: {
        input: {
          searchTerm: CustomerBannersLazyQuery.debouncedQuery,
        },
      },
    })
  }, [CustomerBannersLazyQuery.debouncedQuery])

  useEffect(() => {
    if (
      !location.pathname.includes('picos/create') &&
      !!urlSearchParams?.priorityId
    ) {
      handle.handleUpdateSearchParams({ priorityId: undefined })
    }
  }, [location.pathname])

  const banners =
    CustomerBannersLazyQuery.lazyQuery.meta.data?.storeChains?.results?.map(
      ban => ({
        label: ban.name,
        id: ban.id,
      }),
    ) || []

  return {
    state: {
      CustomerBannersLazyQuery,
      selectedBanner,
      priorityToEdit,
      setPriorityToEdit,
      storeTypesInCustomer,
      setStoreTypesInCustomer,
      isNewPriority,
      setIsNewPriority,
      urlSearchParams,
    },
    data: {
      banners,
    },
    handle: {
      ...handle,
      handleSelectBanner,
    },
  }
}
