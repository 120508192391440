"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SABRE_GET_SHIPPING = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.SABRE_GET_SHIPPING = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetShipping($input: ShippingQueryInput!) {\n    shipping(input: $input) {\n      count\n      hasMore\n      results {\n        ...Shipping\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetShipping($input: ShippingQueryInput!) {\n    shipping(input: $input) {\n      count\n      hasMore\n      results {\n        ...Shipping\n      }\n    }\n  }\n  ", "\n"])), fragments_1.SABRE_SHIPPING_FRAGMENT);
var templateObject_1;
