/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportHook,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { useLocalStorage } from 'hooks/use-local-storage'
import { FILL_GRID_ALL_SCREENS, MuiTheme } from '@dai/web-components'
import { useQuery } from '@apollo/client'
import {
  AggregateBy,
  AggregationMethod,
  GetRecommendationComplianceScoresQuery,
  GetRecommendationComplianceScoresQueryVariables,
  GET_RECOMMENDATION_COMPLIANCE_SCORES,
  GetPointCloudScores_pointCloudScanScores_results,
  GetPointCloudScoresQuery,
  GetPointCloudScoresQueryVariables,
  GET_POINT_CLOUD_SCAN_SCORES,
} from '@dai/graphql'
import { DateTimeConstants, DateTimeHelpers } from '@dai/common'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'

export const useQualityHistoryReports: GenericReportHook<UserProductivityReportName> = () => {
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')
  const getTooltip = (label: string) => [
    {
      text: '{valueY}',
      fontSize: 28,
      color: MuiTheme.palette.secondary.main,
      bold: true,
    },
    {
      text: label,
      color: MuiTheme.palette.secondary.light,
      newline: true,
    },
    {
      text: '{valueX.formatDate()}',
      color: MuiTheme.palette.secondary.light,
    },
  ]

  const useGetAvgRecommendationComplianceOverTime = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const timeGroupBy = DateTimeHelpers.isDiffDaysLessThanOrEQ(
      reportParams.startDate,
      reportParams.endDate,
      DateTimeConstants.SIXTY_DAYS,
    )
      ? AggregateBy.WEEK
      : AggregateBy.MONTH
    const input = getUserProductivityQueryInput(
      AggregationMethod.AVG,
      timeGroupBy,
    )
    const { data: scores, loading } = useQuery<
      GetRecommendationComplianceScoresQuery,
      GetRecommendationComplianceScoresQueryVariables
    >(GET_RECOMMENDATION_COMPLIANCE_SCORES, {
      variables: {
        input: {
          ...input,
          aggregationInput: {
            ...input.aggregationInput!,
            mergeTeams: false,
          },
        },
      },
    })
    const compliance =
      scores?.recommendationScores.results.map(res => ({
        category: new Date(res.date).getTime(),
        value: res.score,
        group: companyUsers?.[res.refId].name,
      })) || []
    return {
      data: compliance,
      isEmpty: !compliance.length,
      isLoading: loading,
    }
  }
  const useGetAvgPointCloudScoresOverTime = (
    reportParams: UserProductivityReportInput,
    dataKey: keyof GetPointCloudScores_pointCloudScanScores_results,
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const timeGroupBy = DateTimeHelpers.isDiffDaysLessThanOrEQ(
      reportParams.startDate,
      reportParams.endDate,
      DateTimeConstants.SIXTY_DAYS,
    )
      ? AggregateBy.WEEK
      : AggregateBy.MONTH
    const input = getUserProductivityQueryInput(
      AggregationMethod.AVG,
      timeGroupBy,
    )
    const { data: scores, loading } = useQuery<
      GetPointCloudScoresQuery,
      GetPointCloudScoresQueryVariables
    >(GET_POINT_CLOUD_SCAN_SCORES, {
      variables: {
        input: {
          ...input,
          aggregationInput: {
            ...input.aggregationInput!,
            mergeTeams: false,
          },
        },
      },
    })
    const compliance = (
      scores?.pointCloudScanScores.results.map(res => ({
        category: new Date(res.date).getTime(),
        value: res[dataKey],
        group: companyUsers?.[res.refId].name,
      })) || []
    ).filter(score => score.value)
    return {
      data: compliance,
      isEmpty: !compliance.length,
      isLoading: loading,
    }
  }
  return [
    {
      reportName: 'recommendation-compliance-score',
      useGetData: useGetAvgRecommendationComplianceOverTime,
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Compliance Over Time',
      type: 'line-chart',
      chartId: 'compliance-over-time-line-chart',
      tooltip: getTooltip('Compliance Score'),
    },
    {
      reportName: 'point-cloud-score',
      useGetData: reportParams =>
        useGetAvgPointCloudScoresOverTime(reportParams, 'pointCloudScore'),
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Point Cloud Scan Score Over Time',
      type: 'line-chart',
      chartId: 'point-cloud-scan-score-over-time-line-chart',
      tooltip: getTooltip('Point Cloud Scan Score'),
    },
    {
      reportName: 'scan-facing-score',
      useGetData: reportParams =>
        useGetAvgPointCloudScoresOverTime(reportParams, 'facingScore'),
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Facing Scan Score Over Time',
      type: 'line-chart',
      chartId: 'facing-scan-score-over-time-line-chart',
      tooltip: getTooltip('Facing Scan Score'),
    },
    {
      reportName: 'identification-accuracy',
      useGetData: reportParams =>
        useGetAvgPointCloudScoresOverTime(
          reportParams,
          'identificationAccuracy',
        ),
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Identification Accuracy Over Time',
      type: 'line-chart',
      chartId: 'prod-id-acc-score-over-time-line-chart',
      tooltip: getTooltip('Identification Accuracy'),
    },
  ]
}
