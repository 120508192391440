"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICOS_DIVISION_COMPLIANCE = void 0;
var client_1 = require("@apollo/client");
var PicOSSalesCenterComplianceFragment_1 = require("../fragments/PicOSSalesCenterComplianceFragment");
exports.GET_PICOS_DIVISION_COMPLIANCE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetPicOSDivisionCompliance($input: PicOSDivisionComplianceInput!) {\n    picosDivisionCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        ...PicOSSalesCenterCompliance\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetPicOSDivisionCompliance($input: PicOSDivisionComplianceInput!) {\n    picosDivisionCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        ...PicOSSalesCenterCompliance\n      }\n    }\n  }\n  ", "\n"])), PicOSSalesCenterComplianceFragment_1.PICOS_SALES_CENTER_COMPLIANCE_FRAGMENT);
var templateObject_1;
