/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DocumentNode, gql, useMutation } from '@apollo/client'
import {
  ADD_USERS_TO_ZONE,
  AddUsersToZoneMutation,
  AddUsersToZoneMutationVariables,
  GetCompanyUsersQuery,
  GET_COMPANY_USERS,
  RemoveUsersFromZoneMutation,
  RemoveUsersFromZoneMutationVariables,
  REMOVE_USERS_FROM_ZONE,
  AddStoresToZoneMutation,
  AddStoresToZoneMutationVariables,
  ADD_STORES_TO_ZONE,
  RemoveStoresFromZoneMutation,
  RemoveStoresFromZoneMutationVariables,
  REMOVE_STORES_FROM_ZONE,
  GetStoresWithFilterQuery,
  GET_STORES_WITH_FILTER,
  StoreFragment,
  CreateOrUpdateZoneMutation,
  CreateOrUpdateZoneMutationVariables,
  CREATE_OR_UPDATE_ZONE,
  DeleteZonesMutation,
  DeleteZonesMutationVariables,
  DELETE_ZONES,
} from '@dai/graphql'
import {
  FlexBox,
  NotificationContext,
  RowData,
  StoresHelpers,
  StringHelpers,
  gridAllScreens,
  useDebouncedItemQuery,
} from '@dai/web-components'
import { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { Zone, ZoneRow, ZoneStoreRow, ZoneUserRow } from './types'
import SearchableSelectManager from './SearchableSelectManager'

type Tab = 'Current' | 'Add'

export const useZoneMutateModalLogic = <
  Model extends RowData,
  QueryType = {}
>(props: {
  zone: Zone | null
  queryStr?: DocumentNode
  getItemsFromZone: (z: Zone, searchTerm?: string) => Model[]
  getItemsFromResponse?: (response: QueryType | undefined) => Model[]
  handleAddItems: (z: Zone, ids: string[]) => Promise<any>
  handleRemoveItems: (z: Zone, ids: string[]) => Promise<any>
  CurrentTabBody?: ({}: { handleButtonPress: () => void }) => React.ReactNode
  AddTabBody?: ({}: { handleButtonPress: () => void }) => React.ReactNode
}) => {
  const tabs: Tab[] = ['Current', 'Add']
  const {
    zone,
    queryStr,
    getItemsFromResponse,
    getItemsFromZone,
    handleAddItems,
    handleRemoveItems,
    CurrentTabBody,
    AddTabBody,
  } = props
  const { setError, setSuccess } = useContext(NotificationContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<Tab>('Current')
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false)

  const ToAddLazyQuery = useDebouncedItemQuery<
    QueryType,
    { input: { searchTerm?: string } }
  >({
    queryStr:
      queryStr ||
      gql`
        query Query {
          __schema {
            description
          }
        }
      `,
    options: { fetchPolicy: 'cache-first' },
  })

  const searchTerm = ToAddLazyQuery.debouncedQuery

  useEffect(() => {
    if (isOpen && selectedTab === 'Add' && !!queryStr) {
      ToAddLazyQuery.lazyQuery.query({ variables: { input: { searchTerm } } })
    }
  }, [isOpen, searchTerm, selectedTab, queryStr])

  const current = zone ? getItemsFromZone(zone, searchTerm) : []
  const add = getItemsFromResponse?.(ToAddLazyQuery.lazyQuery.meta.data) || []

  const { goToPage } = ToAddLazyQuery.Pagination.handleOffset

  const handleButtonPress = () => {
    if (zone) {
      setLoadingMutation(true)
      const mutate =
        selectedTab === 'Current' ? handleRemoveItems : handleAddItems
      mutate(zone, selectedIds)
        .then(() => {
          window.location.reload()
          setSuccess(`Your changes were saved successfully.`)
        })
        .catch(error => setError(`An error occurred: ${error}`))
        .finally(() => {
          setIsOpen(false)
          setLoadingMutation(false)
        })
    } else {
      setError(`Reload the page and please try again.`)
    }
  }

  return {
    state: {
      current,
      add,
      loading: loadingMutation
        ? true
        : selectedTab === 'Add'
        ? ToAddLazyQuery.lazyQuery.meta.loading
        : selectedTab == 'Current'
        ? !zone
        : true,
      isOpen,
      setIsOpen,
      selectedTabIndex: tabs.indexOf(selectedTab),
      setSelectedTab,
      searchTerm,
      setSearchTerm: ToAddLazyQuery.setItemQuery,
      tabs,
      selectedIds,
      setSelectedIds,
    },
    handle: {
      handlePageChange: (page: number) => {
        if (selectedTab === 'Add') {
          goToPage(page)
        }
      },
      handleButtonPress,
    },
    components: {
      CurrentTabBody: CurrentTabBody?.({ handleButtonPress }),
      AddTabBody: AddTabBody?.({ handleButtonPress }),
    },
  }
}

// Implementations

export const useZoneMutateUsersModalLogic = ({
  zone,
}: {
  zone: Zone | null
}) => {
  const [addUsersToZone] = useMutation<
    AddUsersToZoneMutation,
    AddUsersToZoneMutationVariables
  >(ADD_USERS_TO_ZONE)

  const [removeUsersFromZone] = useMutation<
    RemoveUsersFromZoneMutation,
    RemoveUsersFromZoneMutationVariables
  >(REMOVE_USERS_FROM_ZONE)

  return useZoneMutateModalLogic<ZoneUserRow, GetCompanyUsersQuery>({
    zone,
    queryStr: GET_COMPANY_USERS,
    getItemsFromResponse: response =>
      response?.companyUsers.results.map(u => ({
        id: u.id,
        email: u.email,
        name: u.name,
        role: u.mainRole
          ? StringHelpers.titleCase(StringHelpers.stringValue(u.mainRole))
          : 'No Assigned Role',
        zone: u.zone?.name ?? 'No Area',
      })) || [],
    getItemsFromZone: (_zone, searchTerm) =>
      _zone.assignedUsers
        .filter(u =>
          searchTerm
            ? u.name.toLowerCase().includes(searchTerm.toLowerCase())
            : true,
        )
        .map<ZoneUserRow>(u => ({
          id: u.id,
          email: u.email,
          name: u.name,
          role: u.mainRole
            ? StringHelpers.titleCase(StringHelpers.stringValue(u.mainRole))
            : 'No Assigned Role',
          zone: _zone.name,
        })),
    handleAddItems: async (zone, ids) =>
      addUsersToZone({
        variables: {
          input: {
            zoneId: zone.id,
            ids,
          },
        },
      }),
    handleRemoveItems: async (zone, ids) =>
      removeUsersFromZone({
        variables: {
          input: {
            zoneId: zone.id,
            ids,
          },
        },
      }),
  })
}

export const useZoneMutateStoresModalLogic = ({
  zone,
}: {
  zone: Zone | null
}) => {
  const [addStoresToZone] = useMutation<
    AddStoresToZoneMutation,
    AddStoresToZoneMutationVariables
  >(ADD_STORES_TO_ZONE)

  const [removeStoresFromZone] = useMutation<
    RemoveStoresFromZoneMutation,
    RemoveStoresFromZoneMutationVariables
  >(REMOVE_STORES_FROM_ZONE)

  const format = (
    store: Pick<
      StoreFragment,
      'id' | 'name' | 'address' | 'category' | 'internalStoreId'
    >,
  ) => ({
    id: store.id,
    store: store.name,
    address: store.address
      ? StoresHelpers.formatAddress(store.address)
      : 'Missing Address',
    category: store.category,
    customerId: store.internalStoreId ?? 'N/A',
  })

  return useZoneMutateModalLogic<ZoneStoreRow, GetStoresWithFilterQuery>({
    zone,
    queryStr: GET_STORES_WITH_FILTER,
    getItemsFromResponse: response =>
      response?.companyStoresWithFilter.results.map(store => ({
        ...format(store),
        zone: store.zones?.[0].name ?? 'No Area',
      })) || [],
    getItemsFromZone: (_zone, searchTerm) =>
      _zone.stores
        .filter(s =>
          searchTerm
            ? s.name.toLowerCase().includes(searchTerm.toLowerCase())
            : true,
        )
        .map<ZoneStoreRow>(store => ({
          ...format(store),
          zone: zone?.name ?? 'No Area',
        })),
    handleAddItems: async (zone, ids) =>
      addStoresToZone({
        variables: {
          input: {
            zoneId: zone.id,
            ids,
          },
        },
      }),
    handleRemoveItems: async (zone, ids) =>
      removeStoresFromZone({
        variables: {
          input: {
            zoneId: zone.id,
            ids,
          },
        },
      }),
  })
}

export const useZoneMutateAreasModalLogic = ({
  zone,
}: {
  zone: Zone | null
}) => {
  const [createOrUpdateZone] = useMutation<
    CreateOrUpdateZoneMutation,
    CreateOrUpdateZoneMutationVariables
  >(CREATE_OR_UPDATE_ZONE)

  const [deleteZones] = useMutation<
    DeleteZonesMutation,
    DeleteZonesMutationVariables
  >(DELETE_ZONES)

  const [selectedManagerId, setSelectedManagerId] = useState<string | null>()
  const [zoneName, setZoneName] = useState<string>()

  return useZoneMutateModalLogic<ZoneRow>({
    zone,
    getItemsFromZone: (_zone, searchTerm) =>
      _zone.subZones
        .filter(z =>
          searchTerm
            ? z.name.toLowerCase().includes(searchTerm.toLowerCase())
            : true,
        )
        .map<ZoneRow>(z => ({
          ...z,
          manager: z.manager?.fullName ?? 'No Manager',
        })),
    handleAddItems: async zone =>
      createOrUpdateZone({
        variables: {
          input: {
            name: zoneName,
            managerId: selectedManagerId,
            // The "root" zone has an ID of 0, or "0"
            // This zone doesn't exist in the database, and the
            // user under this context is trying to add a top-level zone.
            parentId: zone.id === '0' ? undefined : zone.id,
          },
        },
      }).finally(() => {
        setSelectedManagerId(undefined)
        setZoneName(undefined)
      }),
    handleRemoveItems: async (_, ids) =>
      deleteZones({
        variables: {
          input: {
            ids,
          },
        },
      }),
    AddTabBody: ({ handleButtonPress }) => (
      <Box
        sx={theme => ({
          height: theme.sizes.table.container.modalHeight.lg,
        })}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Stack spacing={2}>
          <Stack px={4}>
            <Typography variant="h5">
              Create a new area{zone ? ` in ${zone.name}` : ''}
            </Typography>
            <Typography>
              Add the name and manager to create the area.
            </Typography>
          </Stack>
          <Grid container spacing={4} width={'100%'} pr={4}>
            <Grid item {...gridAllScreens(8)}>
              <TextField
                label="Area Name"
                fullWidth
                placeholder="e.g. Utah, Utah County, Provo"
                onChange={e => setZoneName(e.target.value)}
              />
            </Grid>
            <Grid item {...gridAllScreens(4)}>
              <SearchableSelectManager
                handleManagerSelected={setSelectedManagerId}
              />
            </Grid>
          </Grid>
          <FlexBox.RowRight px={4}>
            <Button
              startIcon={<CheckCircle color="secondary" />}
              variant="contained"
              onClick={handleButtonPress}
              disabled={
                !selectedManagerId || StringHelpers.isNullOrEmpty(zoneName)
              }
            >
              Create
            </Button>
          </FlexBox.RowRight>
        </Stack>
      </Box>
    ),
  })
}
