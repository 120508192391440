/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  MuiFormModal,
  DAITable,
  TableContainerClass,
  MuiTheme,
  FlexBox,
  SearchBar,
  MuiFormModalProps,
  DAITableColumns,
  RowData,
  StringHelpers,
} from '@dai/web-components'
import React, { useRef } from 'react'
import { Button, Stack, Tab, Tabs, useTheme } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { ButtonProps } from '@mui/material/Button/Button'
import { useZoneMutateModalLogic } from './use-zone-mutate-modal.logic'

type ZoneMutateModal<Model extends RowData> = {
  columns: DAITableColumns<Model>[]
  logic: ReturnType<typeof useZoneMutateModalLogic<Model, any>>
  label: string
} & Omit<MuiFormModalProps, 'actionButtonsProps' | 'handleClose' | 'isOpen'>

const ZoneMutateModal = <Model extends RowData>(
  props: React.PropsWithChildren<ZoneMutateModal<Model>>,
) => {
  const { columns, logic, label, ...rest } = props
  const {
    state: {
      isOpen,
      setIsOpen,
      current,
      add,
      selectedTabIndex,
      setSelectedTab,
      tabs,
      loading,
      selectedIds,
      setSelectedIds,
      setSearchTerm,
    },
    handle: { handlePageChange, handleButtonPress },
    components: { AddTabBody, CurrentTabBody },
  } = logic
  const theme = useTheme()
  const tableRef = useRef<DAITable<any>>(null)

  const rows: Model[] =
    selectedTabIndex === 0 ? current : selectedTabIndex === 1 ? add : []

  const button: ButtonProps =
    selectedTabIndex === 0
      ? {
          children: `Remove ${selectedIds.length} ${StringHelpers.plurality(
            label,
            selectedIds.length,
          )}`,
          startIcon: <Delete color="error" />,
          variant: 'outlined',
          color: 'error',
        }
      : {
          children: `Add ${selectedIds.length} ${StringHelpers.plurality(
            label,
            selectedIds.length,
          )}`,
          startIcon: <Add color="secondary" />,
          variant: 'contained',
          color: 'secondary',
        }

  const reset = () => {
    handlePageChange(0)
    tableRef.current?.handleChangePage(null, 0)
    tableRef.current?.setSelectedIds([])
    setSelectedIds([])
  }

  const Table = (
    <>
      <FlexBox.RowRight px={2}>
        {!!selectedIds.length && (
          <Button {...button} onClick={handleButtonPress} sx={{ width: 250 }} />
        )}
      </FlexBox.RowRight>
      <DAITable
        ref={tableRef}
        rows={rows}
        columns={columns}
        className={TableContainerClass.MODAL}
        pageSize={MuiTheme.sizes.table.page.sm}
        rowCount={0}
        onPageChange={page => handlePageChange(page)}
        loading={loading}
        onSelectionChange={setSelectedIds}
        showCheckbox
      />
    </>
  )

  const Body =
    selectedTabIndex === 0
      ? CurrentTabBody || Table
      : selectedTabIndex === 1
      ? AddTabBody || Table
      : null

  const Search = (
    <Stack direction="row" spacing={2}>
      <SearchBar useResponsive onChange={setSearchTerm} />
    </Stack>
  )

  const SearchComponent =
    (selectedTabIndex === 0 && CurrentTabBody) ||
    (selectedTabIndex === 1 && AddTabBody)
      ? null
      : Search

  return (
    <MuiFormModal
      sx={{
        padding: 0,
        '& .MuiPaper-root': {
          [theme.breakpoints.only('xs')]: {
            minWidth: `calc(100% - ${theme.spacing(4)})`,
          },
          [theme.breakpoints.up('md')]: {
            minWidth: theme.sizes.modal.xl,
          },
        },
      }}
      {...rest}
      isOpen={isOpen}
      actionButtonsProps={[]}
      handleClose={() => {
        reset()
        setIsOpen(false)
      }}
    >
      <Stack spacing={2} pt={2}>
        <FlexBox.RowBetween px={2}>
          <Tabs
            value={selectedTabIndex}
            onChange={(_, index) => {
              setSelectedTab(tabs[index])
              reset()
            }}
          >
            {tabs.map(t => (
              <Tab disableRipple label={t} />
            ))}
          </Tabs>
          {SearchComponent}
        </FlexBox.RowBetween>
        {Body}
      </Stack>
    </MuiFormModal>
  )
}

export default ZoneMutateModal
