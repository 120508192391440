/**
 * Copyright © 2020-2022 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clarksandholtz@gmail.com>
 */

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './firebaseui-styling.global.css'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { MuiTheme } from '@dai/web-components'
import { client } from './api/client'
import * as serviceWorker from './serviceWorker'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={MuiTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
