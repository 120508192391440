/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { InMemoryCache } from '@apollo/client'
import { keyArgsIgnoreOffsetLimit, mergePaginatedResults } from '@dai/common'
import { generatedIntrospectionSchema } from '@dai/graphql'

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospectionSchema.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        companyUsers: {
          keyArgs: keyArgsIgnoreOffsetLimit,
          merge: mergePaginatedResults,
        },
        adminPortalAudits: {
          keyArgs: keyArgsIgnoreOffsetLimit,
          merge: mergePaginatedResults,
        },
        companyStores: {
          keyArgs: keyArgsIgnoreOffsetLimit,
          merge: mergePaginatedResults,
        },
        adminCompanyStores: {
          keyArgs: keyArgsIgnoreOffsetLimit,
          merge: mergePaginatedResults,
        },
      },
    },
  },
})

export default cache
