/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import React, { useContext } from 'react'
import { PicOSReportContext } from '@dai/web-components'

interface LinkRouterProps extends LinkProps {
  to: string
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink} color="inherit" underline="hover" />
)

const PicOSReportBreadcrumbs: React.FC = () => {
  const reportingRootRoute = '/picos/reports/customers'

  const {
    selectedCustomer,
    selectedDivision,
    selectedSalesCenter,
    selectedStore,
  } = useContext(PicOSReportContext)
  const { pathname } = useLocation()

  const ids = pathname
    .replace(reportingRootRoute, '')
    .split('/')
    .filter(route => route)

  /**
   * Associate entity names
   * with IDs from route params
   */
  const routeNames = (): string[] | null => {
    const names = [
      selectedCustomer?.name || 'Customer',
      selectedDivision?.name || 'Division',
      selectedSalesCenter?.name || 'Sales Center',
      selectedStore?.name || 'Store',
    ]
    if (pathname.includes(reportingRootRoute)) {
      return names.slice(0, ids.length)
    }
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
      <LinkRouter to={reportingRootRoute}>Customers</LinkRouter>
      {routeNames()?.map((name, index) => {
        const isCurrent = index === ids.length - 1

        const toRoute = `${reportingRootRoute}/${ids
          .slice(0, index + 1)
          .join('/')}`

        return isCurrent ? (
          <Typography key={name}>{name}</Typography>
        ) : (
          <LinkRouter key={name} to={toRoute}>
            {name}
          </LinkRouter>
        )
      })}
    </Breadcrumbs>
  )
}

export default PicOSReportBreadcrumbs
