"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATES = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetGeographicHierarchyLeadCandidates(\n    $input: GeographicHierarchyLeadCandidatesInput!\n  ) {\n    geographicHierarchyLeadCandidates(input: $input) {\n      results {\n        ...GeographicHierarchyLead\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"], ["\n  query GetGeographicHierarchyLeadCandidates(\n    $input: GeographicHierarchyLeadCandidatesInput!\n  ) {\n    geographicHierarchyLeadCandidates(input: $input) {\n      results {\n        ...GeographicHierarchyLead\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"])), fragments_1.GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATE_FRAGMENT);
var templateObject_1;
