"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DEPLOYED_ML_MODEL = void 0;
var client_1 = require("@apollo/client");
var MLModelFileFragment_1 = require("../fragments/MLModelFileFragment");
exports.GET_DEPLOYED_ML_MODEL = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetDeployedMLModel($modelType: MLModelType!) {\n    deployedMlModel(modelType: $modelType) {\n      ...MLModelFile\n    }\n  }\n  ", "\n"], ["\n  query GetDeployedMLModel($modelType: MLModelType!) {\n    deployedMlModel(modelType: $modelType) {\n      ...MLModelFile\n    }\n  }\n  ", "\n"])), MLModelFileFragment_1.ML_MODEL_FILE_FRAGMENT);
var templateObject_1;
