/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Divider,
  Snackbar,
  SnackbarProps,
  Stack,
  Typography,
} from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'
import { FlexBox, StringHelpers } from '@dai/web-components'
import { useLocalStorage } from 'hooks/use-local-storage'
import { DateTimeConstants, DateTimeHelpers } from '@dai/common'

type RetiredUserWarningProps = {
  zones: { zoneId: string }[]
  handleViewZone: (zone: { zoneId: string }) => void
} & Partial<SnackbarProps>

const RetiredUserWarning: React.FC<RetiredUserWarningProps> = props => {
  const { zones, handleViewZone, ...rest } = props
  const { Storage } = useLocalStorage()
  const [viewedIndex, setViewedIndex] = useState(0)
  const [hiddenPressed, setHiddenPressed] = useState(false)

  const hideWarningStorage = Storage.getItem('hideRetiredUsersWarningDatetime')
  const hideWarning = (() => {
    if (!hideWarningStorage) return false
    return (
      DateTimeHelpers.getDiffDays(
        new Date().toDateString(),
        hideWarningStorage,
      ) <= 1
    )
  })()

  const handleHideWarning = () => {
    Storage.setItem(
      'hideRetiredUsersWarningDatetime',
      new Date().toDateString(),
    )
    setHiddenPressed(true)
  }

  const handleViewNextUser = () => {
    handleViewZone(zones[viewedIndex])
    const index = viewedIndex + 1
    setViewedIndex(index)
  }

  useEffect(() => {
    if (!!zones.length && viewedIndex === zones.length) {
      handleHideWarning()
    }
  }, [viewedIndex])

  return (
    <Snackbar
      open={!!zones.length && !hideWarning && !hiddenPressed}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...rest}
    >
      <Alert
        severity="warning"
        sx={theme => ({ ...theme.view.row.std })}
        icon={<ErrorOutline />}
      >
        <Stack spacing={0.5}>
          <FlexBox.RowCenter>
            <Typography>
              {zones.length} {StringHelpers.plurality('zone', zones.length)}{' '}
              {zones.length === 1 ? 'has' : 'have'} users that have been
              reported as "retired".
            </Typography>
          </FlexBox.RowCenter>
          <FlexBox.RowRight>
            <Button color="secondary" onClick={handleHideWarning}>
              Dismiss
            </Button>
            <Button color="secondary" onClick={handleViewNextUser}>
              {viewedIndex === 0 ? 'View' : 'Next'}
            </Button>
          </FlexBox.RowRight>
          {zones.length > 1 && viewedIndex > 0 && (
            <>
              <Divider />
              <FlexBox.RowRight>
                <Typography
                  variant="body2"
                  sx={theme => ({ color: theme.palette.grey['600'] })}
                >
                  ({zones.length - viewedIndex} Remaining)
                </Typography>
              </FlexBox.RowRight>
            </>
          )}
        </Stack>
      </Alert>
    </Snackbar>
  )
}

export default RetiredUserWarning
