/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'

export interface ReportState {
  selectedReportGroup: string | undefined
  selectedSummaryGroup: string | undefined
  setSelectedReportGroup: (group: string) => void
  setDownloadableTable?: (data: {
    columns: { headerName: string }[]
    rows: Record<string, string | boolean>[]
  }) => void
  reportParams?: any
}

const ReportContext = React.createContext<ReportState>({
  selectedReportGroup: undefined,
  selectedSummaryGroup: undefined,
  setSelectedReportGroup: () => null,
  setDownloadableTable: () => null,
  reportParams: undefined,
})

export default ReportContext
