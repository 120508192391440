"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAY_WITH_STORE_DATA_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var StoreFragment_1 = require("./StoreFragment");
exports.DISPLAY_WITH_STORE_DATA_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DisplayWithStoreData on Display {\n    id\n    created\n    name\n    shortDescription\n    displayType\n    extraInfo\n    saved\n    isCompetitor\n    salesVolume\n    images {\n      id\n      url\n    }\n    area\n    numCoolerDoors\n    doorHandlePlacement\n    idealInventory {\n      upcs\n    }\n    latestRecommendations {\n      id\n      created\n      score\n      productRecommendations {\n        count\n        upc\n      }\n    }\n    point {\n      latitude\n      longitude\n    }\n    dateRemoved\n    movedToDisplay {\n      id\n    }\n    previousAuditUpcs\n    merchandisingState\n    needsFieldVerifiedCountsPostScan\n    needsFieldVerifiedCountsPreScan\n    store {\n      ...Store\n      company {\n        id\n      }\n    }\n  }\n  ", "\n"], ["\n  fragment DisplayWithStoreData on Display {\n    id\n    created\n    name\n    shortDescription\n    displayType\n    extraInfo\n    saved\n    isCompetitor\n    salesVolume\n    images {\n      id\n      url\n    }\n    area\n    numCoolerDoors\n    doorHandlePlacement\n    idealInventory {\n      upcs\n    }\n    latestRecommendations {\n      id\n      created\n      score\n      productRecommendations {\n        count\n        upc\n      }\n    }\n    point {\n      latitude\n      longitude\n    }\n    dateRemoved\n    movedToDisplay {\n      id\n    }\n    previousAuditUpcs\n    merchandisingState\n    needsFieldVerifiedCountsPostScan\n    needsFieldVerifiedCountsPreScan\n    store {\n      ...Store\n      company {\n        id\n      }\n    }\n  }\n  ", "\n"])), StoreFragment_1.STORE_FRAGMENT);
var templateObject_1;
