"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORE_DISPLAY = void 0;
var client_1 = require("@apollo/client");
var WholeDisplayFragment_1 = require("../fragments/WholeDisplayFragment");
exports.GET_STORE_DISPLAY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetDisplay($displayId: ID!) {\n    display(displayId: $displayId) {\n      ...WholeDisplay\n    }\n  }\n  ", "\n"], ["\n  query GetDisplay($displayId: ID!) {\n    display(displayId: $displayId) {\n      ...WholeDisplay\n    }\n  }\n  ", "\n"])), WholeDisplayFragment_1.WHOLE_DISPLAY_FRAGMENT);
var templateObject_1;
