"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BRAND_DISRUPTOR_SUMMARY_STATS = void 0;
var client_1 = require("@apollo/client");
exports.BRAND_DISRUPTOR_SUMMARY_STATS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery SummaryStatsQuery($input: DateRangeInput!) {\n  summaryStats(input:$input) {\n      trendingUp {\n          brand {\n              id\n              name\n          }\n          delta\n      }\n      trendingDown {\n          brand {\n              id\n              name\n          }\n          delta\n      }\n      newBrands {\n        brand {\n            id\n            name\n        }\n        dateFirstObserved\n        numberOfStores\n        numberOfFacings\n      }\n  }\n}\n"], ["\nquery SummaryStatsQuery($input: DateRangeInput!) {\n  summaryStats(input:$input) {\n      trendingUp {\n          brand {\n              id\n              name\n          }\n          delta\n      }\n      trendingDown {\n          brand {\n              id\n              name\n          }\n          delta\n      }\n      newBrands {\n        brand {\n            id\n            name\n        }\n        dateFirstObserved\n        numberOfStores\n        numberOfFacings\n      }\n  }\n}\n"])));
var templateObject_1;
