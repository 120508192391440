"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DAILY_STATS = void 0;
var client_1 = require("@apollo/client");
exports.GET_DAILY_STATS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query Stats {\n    dailyStats {\n      results {\n        numImages\n        numBoxes\n        dateString\n        email\n      }\n    }\n  }\n"], ["\n  query Stats {\n    dailyStats {\n      results {\n        numImages\n        numBoxes\n        dateString\n        email\n      }\n    }\n  }\n"])));
var templateObject_1;
