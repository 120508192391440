"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_DISPLAY_AUDIT_POINT_CLOUD = void 0;
var client_1 = require("@apollo/client");
var BoundingCubeFragment_1 = require("../fragments/BoundingCubeFragment");
exports.ADD_DISPLAY_AUDIT_POINT_CLOUD = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation AddDisplayAuditPointCloud($input: AddPointCloudSceneInput!) {\n    addDisplayAuditPointCloud(input: $input) {\n      displayAuditPointCloud {\n        id\n        bucketFileName\n        labels\n        predicted\n        centroids\n        metadata\n        boundingCube {\n          ...BoundingCube\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation AddDisplayAuditPointCloud($input: AddPointCloudSceneInput!) {\n    addDisplayAuditPointCloud(input: $input) {\n      displayAuditPointCloud {\n        id\n        bucketFileName\n        labels\n        predicted\n        centroids\n        metadata\n        boundingCube {\n          ...BoundingCube\n        }\n      }\n    }\n  }\n  ", "\n"])), BoundingCubeFragment_1.BOUNDING_CUBE_FRAGMENT);
var templateObject_1;
