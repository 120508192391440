/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Mitch Wilson <mitchell.wilson@deliciousai.com>
 */

import React from 'react'
import {
  FullScreenModal,
  Sidebar,
  FilterComponentProps,
  SearchableListWithSkeleton,
} from '@dai/web-components'
import { Box } from '@mui/material'
import { Training_training } from '@dai/graphql'
import { TrainingTile } from './TrainingTile'

type TrainingSearchProps = {
  trainings: Training_training[] | null
  onTrainingSelected: (upc: string) => void
  selectedTrainingId: string | null
  loading?: boolean
  setSearchValue: (value: string) => void
  onClick?: () => void
  hasMore: boolean
  onLoadMore: () => void
  modalOpen: boolean
  setModalOpen: (isOpen: boolean) => void
} & Omit<FilterComponentProps, 'filterTitle'>

const TrainingSearch: React.FunctionComponent<TrainingSearchProps> = props => {
  const {
    trainings,
    onTrainingSelected,
    selectedTrainingId,
    loading = false,
    setSearchValue,
    onLoadMore,
    modalOpen,
    setModalOpen,
    hasMore,
    ...FilterProps
  } = props

  const TrainingList = (
    <SearchableListWithSkeleton<Training_training>
      items={trainings || []}
      onSearchChange={setSearchValue}
      filterProps={{
        filterTitle: 'Filter Trainings',
        ...FilterProps,
      }}
      loading={loading}
      hasMore={false}
      fetchMore={() => {}}
      renderItem={({ item, loading, index }) => (
        <Box mx={2} mb={1} flex={1} mt={index === 0 ? 1 : 0}>
          <TrainingTile
            enableShowLargeImage
            training={item}
            loading={false}
            selected={selectedTrainingId === item?.id}
            onSelect={(id: string) => {
              onTrainingSelected(id)
              setModalOpen(false)
            }}
            imageUrl={item.trainingImage.url}
          />
        </Box>
      )}
    />
  )

  return (
    <>
      <FullScreenModal
        title="Trainings"
        handleClose={() => setModalOpen(false)}
        mobileOnly
        open={modalOpen}
      >
        {TrainingList}
      </FullScreenModal>
      <Sidebar side="left">{TrainingList}</Sidebar>
    </>
  )
}

export default TrainingSearch
