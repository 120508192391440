"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MULTI_PRODUCT_IMAGE_PRODUCT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var PackagingFragment_1 = require("./PackagingFragment");
exports.MULTI_PRODUCT_IMAGE_PRODUCT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment MultiProductImageProduct on Product {\n    id\n    created\n    modified\n    upcA\n    name\n    brand {\n      id\n      name\n    }\n    flavors {\n      id\n      flavor\n      displayFlavor\n    }\n    packaging {\n      ...Packaging\n    }\n    marketingImageUrl\n    verifiedBy\n  }\n  ", "\n"], ["\n  fragment MultiProductImageProduct on Product {\n    id\n    created\n    modified\n    upcA\n    name\n    brand {\n      id\n      name\n    }\n    flavors {\n      id\n      flavor\n      displayFlavor\n    }\n    packaging {\n      ...Packaging\n    }\n    marketingImageUrl\n    verifiedBy\n  }\n  ", "\n"])), PackagingFragment_1.PACKAGING_FRAGMENT);
var templateObject_1;
