"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAY_FRAGMENT_WITH_PLANOGRAM = void 0;
var client_1 = require("@apollo/client");
var PlanogramFragment_1 = require("./PlanogramFragment");
exports.DISPLAY_FRAGMENT_WITH_PLANOGRAM = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DisplayFragmentWithPlanogram on Display {\n    id\n    created\n    saved\n    planogram {\n      ...Planogram\n    }\n    idealInventory {\n      upcs\n    }\n  }\n  ", "\n"], ["\n  fragment DisplayFragmentWithPlanogram on Display {\n    id\n    created\n    saved\n    planogram {\n      ...Planogram\n    }\n    idealInventory {\n      upcs\n    }\n  }\n  ", "\n"])), PlanogramFragment_1.PLANOGRAM_FRAGMENT);
var templateObject_1;
