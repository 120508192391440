"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetCompanyRegion = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var react_1 = require("react");
var useGetCompanyRegion = function (regionId) {
    var _a = (0, client_1.useLazyQuery)(graphql_1.GET_COMPANY_REGION), getCompanyRegion = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    (0, react_1.useEffect)(function () {
        if (regionId) {
            getCompanyRegion({ variables: { regionId: regionId } });
        }
    }, [regionId]);
    var region = data === null || data === void 0 ? void 0 : data.companyRegion;
    return {
        region: region,
        loading: loading,
        error: error,
    };
};
exports.useGetCompanyRegion = useGetCompanyRegion;
