"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CUBE_DIMENSIONS = void 0;
var client_1 = require("@apollo/client");
exports.CUBE_DIMENSIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment CubeDimensions on CubeDimensions {\n    width\n    height\n    depth\n  }\n"], ["\n  fragment CubeDimensions on CubeDimensions {\n    width\n    height\n    depth\n  }\n"])));
var templateObject_1;
