"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POINT_CLOUD_SCAN_SCORES = void 0;
var client_1 = require("@apollo/client");
exports.GET_POINT_CLOUD_SCAN_SCORES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetPointCloudScores($input: UserProductivityInput!) {\n    pointCloudScanScores(input: $input) {\n      results {\n        refId\n        compositeId\n        compositeName\n        date\n        pointCloudScore\n        facingScore\n        identificationAccuracy\n      }\n      hasMore\n      count\n    }\n  }\n"], ["\n  query GetPointCloudScores($input: UserProductivityInput!) {\n    pointCloudScanScores(input: $input) {\n      results {\n        refId\n        compositeId\n        compositeName\n        date\n        pointCloudScore\n        facingScore\n        identificationAccuracy\n      }\n      hasMore\n      count\n    }\n  }\n"])));
var templateObject_1;
