/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DateTimeHelpers } from '@dai/common'
import { FormPicOSPriority } from './picos.types'

const EMPTY_PICOS: FormPicOSPriority = {
  id: '',
  name: '',
  startDate: DateTimeHelpers.getDateWeekDelta(0).toISOString(),
  endDate: DateTimeHelpers.getDateWeekDelta(1).toISOString(),
  isAllStores: undefined,
  priorityNum: undefined,
  mandateType: undefined,
  imageUrl: undefined,
  description: '',
  displayTypes: [],
  displayLocations: [],
  displayElements: undefined,
  posElementRequired: false,
  otherBrandsAllowed: true,
  otherPackagingAllowed: true,
  otherProductsAllowed: true,
  otherDisplayTypesAllowed: false,
  otherDisplayLocationsAllowed: false,
  addedProducts: [],
  customerBanner: undefined,
  storeGroups: [],
  pricing: undefined,
  cost: undefined,
  isMarketStreetChallenge: false,
}

/**
 * Hacky way to fetch for specific brands
 * It would be better to fetch by brand_name
 * i.e. brandsNames: [...]
 */
const QUICK_ADD_BRAND_IDS = {
  'dai-qa': {
    core: [
      'd0226ae0-9f8b-4514-9257-9d023cf1b155',
      '95b801ea-c873-488e-8995-c7439d4a5b16',
      'e10a5a59-1942-4a28-90b8-9da835974fe0',
      'd8d26fe2-e18e-4851-a3e7-022e4900ddc8',
    ],
    dp: [
      '24c10b7c-a416-4622-a33e-31c1cc500f92',
      'f79c6b9a-147b-4e32-9c3d-e0872ac206f4',
      '203658b1-e413-4c4d-8661-bfb6f9cd18bf',
    ],
  },
  'dai-production': {
    core: [
      'd0226ae0-9f8b-4514-9257-9d023cf1b155',
      'd8d26fe2-e18e-4851-a3e7-022e4900ddc8',
      '95b801ea-c873-488e-8995-c7439d4a5b16',
      'e10a5a59-1942-4a28-90b8-9da835974fe0',
    ],
    dp: [
      '203658b1-e413-4c4d-8661-bfb6f9cd18bf',
      'f79c6b9a-147b-4e32-9c3d-e0872ac206f4',
      '24c10b7c-a416-4622-a33e-31c1cc500f92',
    ],
  },
  'dai-staging': {
    core: [],
    dp: [],
  },
}[process.env.REACT_APP__FB_PROJECT_ID || '']

export const PicosConstants = {
  EMPTY_PICOS,
  QUICK_ADD_BRAND_IDS,
}
