"use strict";
/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorHelpers = void 0;
var pickTextColorBasedOnBgColor = function (bgColor, lightColor, darkColor) {
    var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uiColors = [r / 255, g / 255, b / 255];
    var c = uiColors.map(function (col) {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
};
exports.ColorHelpers = {
    pickTextColorBasedOnBgColor: pickTextColorBasedOnBgColor,
};
