/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GET_ELIGIBLE_MANAGERS,
  GetEligibleManagersQuery,
  GetEligibleManagersQueryVariables,
} from '@dai/graphql'
import {
  SearchableSelectAutocomplete,
  useDebouncedItemQuery,
  StringHelpers,
} from '@dai/web-components'
import { Breadcrumbs, Typography, Box, TextFieldProps } from '@mui/material'
import React, { useEffect } from 'react'
import { ZoneManager } from './types'

type SearchableSelectManagerProps = {
  handleManagerSelected: (id: string | null) => void
  defaultValue?: ZoneManager
  size?: TextFieldProps['size']
}

const SearchableSelectManager: React.FC<SearchableSelectManagerProps> = props => {
  const { handleManagerSelected, defaultValue, size } = props

  const EligibleManagerLQ = useDebouncedItemQuery<
    GetEligibleManagersQuery,
    GetEligibleManagersQueryVariables
  >({
    queryStr: GET_ELIGIBLE_MANAGERS,
    options: { fetchPolicy: 'cache-first' },
  })
  useEffect(() => {
    // TODO: filter by roles
    EligibleManagerLQ.lazyQuery.query({
      variables: {
        input: {
          searchTerm: EligibleManagerLQ.debouncedQuery,
        },
      },
    })
  }, [EligibleManagerLQ.debouncedQuery])

  const users =
    EligibleManagerLQ.lazyQuery.meta.data?.companyUsers.results || []

  return (
    <SearchableSelectAutocomplete<ZoneManager>
      size={size}
      options={users
        .filter(u => !StringHelpers.isNullOrEmpty(u.name))
        .map(u => ({
          label: u.name,
          id: u.id,
          zoneName: (u.zoneAsManager || u.zone)?.name || null,
          zonePath: (u.zoneAsManager || u.zone)?.zonePath || null,
          manager: !!u.zoneAsManager,
        }))}
      loading={EligibleManagerLQ.lazyQuery.meta.loading}
      handleOnChange={opt => handleManagerSelected(opt?.id || null)}
      TextFieldProps={{
        label: 'Manager',
        onChange: e => EligibleManagerLQ.setItemQuery(e.target.value),
      }}
      defaultValue={defaultValue}
      renderOption={(option: ZoneManager) => (
        <Box>
          <Typography>{option.label}</Typography>
          <Typography
            variant="body2"
            sx={theme => ({ color: theme.palette.grey['600'] })}
          >
            {option.zoneName
              ? `${option.manager ? 'Manager in ' : 'Assigned to '}${
                  option.zoneName
                }`
              : 'No Assigned Area'}
          </Typography>
          {!!option.zonePath && (
            <Breadcrumbs separator="›">
              {option.zonePath.map((p: string) => (
                <Typography
                  variant="body2"
                  sx={theme => ({ color: theme.palette.grey[600] })}
                >
                  {p}
                </Typography>
              ))}
            </Breadcrumbs>
          )}
        </Box>
      )}
    />
  )
}

export default SearchableSelectManager
