"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetDrawMapTask = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useGetDrawMapTask = function (variables) {
    var _a = (0, client_1.useQuery)(graphql_1.GET_DRAW_MAP_TASK, { variables: variables }), data = _a.data, loading = _a.loading, error = _a.error;
    var task = data === null || data === void 0 ? void 0 : data.getDrawMapTask;
    return {
        task: task,
        loading: loading,
        error: error,
    };
};
exports.useGetDrawMapTask = useGetDrawMapTask;
