"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIMPLE_FLAGGABLE_OBJECT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.SIMPLE_FLAGGABLE_OBJECT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment SimpleFlaggableObject on FlaggableObject {\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        uuid\n        id\n        email\n        firstName\n        lastName\n      }\n      levelRaisedBy {\n        uuid\n        id\n        email\n        firstName\n        lastName\n        username\n      }\n      date\n    }\n    ... on DisplayAuditImage {\n      id\n      url\n    }\n    ... on Product {\n      upcA\n    }\n  }\n"], ["\n  fragment SimpleFlaggableObject on FlaggableObject {\n    flagged {\n      level\n      reason\n      reasonType\n      moreInfo\n      flaggedBy {\n        uuid\n        id\n        email\n        firstName\n        lastName\n      }\n      levelRaisedBy {\n        uuid\n        id\n        email\n        firstName\n        lastName\n        username\n      }\n      date\n    }\n    ... on DisplayAuditImage {\n      id\n      url\n    }\n    ... on Product {\n      upcA\n    }\n  }\n"])));
var templateObject_1;
