/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  DAIFormState,
  FILL_GRID_ALL_SCREENS,
  FormInputType,
  gridAllScreens,
  MuiTheme,
} from '@dai/web-components'
import { useEffect, useState } from 'react'
import { useWindowDimensions } from '@dai/common'
import { DAIGroupEnum } from '@dai/graphql'
import { UserProductivityReportType } from 'users/users.types'
import { LocalStorage, useLocalStorage } from 'hooks/use-local-storage'
import { UserProductivityReportHelpers } from 'users/helpers/reporting/user-productivity-report.helpers'
import { UserProductivityReportingConstants } from 'users/constants/reporting/user-productivity-reporting.constants'
import { noop } from 'lodash'

export const useReportSelectForm = () => {
  const { width } = useWindowDimensions()
  const isMobile = width <= MuiTheme.breakpoints.values.sm
  const { Storage } = useLocalStorage()
  const companyUsers: LocalStorage['companyUsers'] =
    Storage.getItem('companyUsers') || {}

  const REPORT_SELECT_FORM: DAIFormState<
    FormInputType,
    { autocomplete: true }
  > = {
    autocomplete: {
      REPORT_LEVEL: {
        label: 'Report Level',
        options: UserProductivityReportHelpers.getReportLevelOptions(
          companyUsers,
        ),
        variant: 'filled',
        gridProps: { ...gridAllScreens(3), xs: 12 },
        handleOnChange: noop,
      },
    },
    select: !isMobile
      ? {
          USER_TYPE: {
            value: DAIGroupEnum.MERCHANDISERS,
            variant: 'filled',
            label: 'User Type',
            input: {},
            gridProps: gridAllScreens(2),
            options: [
              {
                label: 'Sales',
                value: DAIGroupEnum.SALES,
              },
              {
                label: 'Merchandiser',
                value: DAIGroupEnum.MERCHANDISERS,
              },
              {
                label: 'RED Auditor',
                value: DAIGroupEnum.AUDITORS,
              },
            ],
          },
          REPORT_TYPE: {
            value: UserProductivityReportType.ACTIVITY,
            variant: 'filled',
            label: 'Report Type',
            input: {},
            gridProps: gridAllScreens(2),
            options: [
              {
                label: 'Activity',
                value: UserProductivityReportType.ACTIVITY,
              },
              {
                label: 'Quality',
                value: UserProductivityReportType.QUALITY,
              },
            ],
          },
        }
      : {},
    radio: isMobile
      ? {
          USER_TYPE: {
            value: DAIGroupEnum.MERCHANDISERS,
            label: 'User Type',
            group: {},
            gridProps: FILL_GRID_ALL_SCREENS,
            required: true,
            row: false,
            options: [
              {
                label: 'Sales',
                value: DAIGroupEnum.SALES,
              },
              {
                label: 'Merchandiser',
                value: DAIGroupEnum.MERCHANDISERS,
              },
              {
                label: 'RED Auditor',
                value: DAIGroupEnum.AUDITORS,
              },
            ],
          },
          REPORT_TYPE: {
            value: UserProductivityReportType.ACTIVITY,
            label: 'Report Type',
            group: {},
            gridProps: FILL_GRID_ALL_SCREENS,
            required: true,
            row: false,
            options: [
              {
                label: 'Activity',
                value: UserProductivityReportType.ACTIVITY,
              },
              {
                label: 'Quality',
                value: UserProductivityReportType.QUALITY,
              },
            ],
          },
        }
      : {},
    text: {},
    searchSelect: {},
    checkbox: {},
    button: {},
  }
  const [formState, setFormState] = useState<
    DAIFormState<FormInputType, { autocomplete: true }>
  >(REPORT_SELECT_FORM)
  const stateKey: keyof DAIFormState = isMobile ? 'radio' : 'select'

  useEffect(() => {
    setFormState(REPORT_SELECT_FORM)
  }, [isMobile])

  useEffect(() => {
    if (formState && formState[stateKey].USER_TYPE?.value) {
      setFormState({
        ...formState,
        autocomplete: {
          ...formState.autocomplete,
          REPORT_LEVEL: {
            ...formState.autocomplete.REPORT_LEVEL,
            options: UserProductivityReportHelpers.getReportLevelOptions(
              companyUsers,
              formState[stateKey].USER_TYPE.value as DAIGroupEnum,
            ),
          },
        },
      })
    }
  }, [formState[stateKey].USER_TYPE?.value, stateKey])

  return {
    formState,
    setFormState,
    stateKey,
  }
}
