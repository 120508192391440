"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_PRODUCTS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.SEARCH_PRODUCTS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query SearchProducts($productsInput: ProductsInput!) {\n    products(input: $productsInput) {\n      count\n      hasMore\n      results {\n        ...MultiProductImageProduct\n      }\n    }\n  }\n  ", "\n"], ["\n  query SearchProducts($productsInput: ProductsInput!) {\n    products(input: $productsInput) {\n      count\n      hasMore\n      results {\n        ...MultiProductImageProduct\n      }\n    }\n  }\n  ", "\n"])), fragments_1.MULTI_PRODUCT_IMAGE_PRODUCT_FRAGMENT);
var templateObject_1;
