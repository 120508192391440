/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

const EMPTY_LEADERBOARD_TITLE = {
  type: '',
  range: '',
}

export const UserProductivityReportingConstants = {
  ALL_USERS: 'All-Users',
  ALL_TEAMS: 'All-Teams',
  LEADERBOARD_DEFAULT_LIMIT: 10,
  EMPTY_LEADERBOARD_TITLE,
}
