"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GEOGRAPHIC_HIERARCHY_DRAFTS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_GEOGRAPHIC_HIERARCHY_DRAFTS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetGeographicHierarchyDrafts($userUuid: ID!) {\n    geographicHierarchyDrafts(userUuid: $userUuid) {\n      territoryName\n      districtManager {\n        uuid\n        firstName\n        lastName\n      }\n      assignedTerritoryUsers {\n        id\n        uuid\n      }\n      drafts {\n        ...GeographicHierarchyDraft\n      }\n    }\n  }\n  ", "\n  ", "\n"], ["\n  query GetGeographicHierarchyDrafts($userUuid: ID!) {\n    geographicHierarchyDrafts(userUuid: $userUuid) {\n      territoryName\n      districtManager {\n        uuid\n        firstName\n        lastName\n      }\n      assignedTerritoryUsers {\n        id\n        uuid\n      }\n      drafts {\n        ...GeographicHierarchyDraft\n      }\n    }\n  }\n  ", "\n  ", "\n"])), fragments_1.GEOOGRAPHIC_HIERARCHY_DRAFT, fragments_1.STORE_ADDRESS_FRAGMENT);
var templateObject_1;
