/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import { MuiFormModal, MuiFormModalProps } from '@dai/web-components'
import React from 'react'
import { Close, PlayArrow } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { StoreRow } from 'stores/stores.types'
import { useResumeOptimizations } from '@dai/common'
import { AdminCompanyStoresInput } from '@dai/graphql'
import { CommaSeparatedStoresText } from '../CommaSeparatedStoresText'

interface ResumeScansModalProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  stores: StoreRow[]
  companyStoresInput: AdminCompanyStoresInput
}

export const ResumeScansModal: React.FC<ResumeScansModalProps> = props => {
  const { isOpen, handleClose, stores, companyStoresInput } = props

  const { resumeOptimizations } = useResumeOptimizations(companyStoresInput)

  return (
    <MuiFormModal
      title={`Resume Scans${
        stores.length > 1 ? ` (${stores.length} Stores)` : ''
      }`}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          color: 'secondary',
          startIcon: <Close color="secondary" />,
        },
        {
          onClick: () => {
            resumeOptimizations({
              input: { storeIds: stores.map(store => store.id) },
            })
            handleClose()
          },
          children: 'Resume Scans',
          startIcon: <PlayArrow color="secondary" />,
        },
      ]}
    >
      <Typography>
        This will resume optimization and compliance scans for all coolers in{' '}
        {<CommaSeparatedStoresText stores={stores} />}.
      </Typography>
    </MuiFormModal>
  )
}
