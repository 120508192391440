/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DAIGroupEnum, UserFragment } from '@dai/graphql'
import { Banner } from 'picos/picos.types'
import { ReportDisplayRules } from 'reporting/admin-reporting.types'

export type LocalStorageCompanyUser = {
  name: string
  email: string
  roles: DAIGroupEnum[]
  subUsers: string[]
}

export type LocalStorage = {
  user?: (UserFragment & { expiresAt: string }) | null
  companyUsers?: Record<string, LocalStorageCompanyUser>
  selectedBanner?: Banner
  reportRules?: ReportDisplayRules<string>
  hideRetiredUsersWarningDatetime?: string
}

export const useLocalStorage = () => {
  const setItem = <T extends keyof LocalStorage>(
    key: T,
    value: LocalStorage[T],
  ) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const removeItem = <T extends keyof LocalStorage>(key: T) => {
    localStorage.removeItem(key)
  }

  const getItem = <T extends keyof LocalStorage>(
    key: T,
  ): LocalStorage[T] | undefined => {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return undefined
  }

  return {
    Storage: {
      getItem,
      setItem,
      removeItem,
      clear: () => localStorage.clear(),
    },
  }
}
