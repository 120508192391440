/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { DAITableColumns, MuiTheme } from '@dai/web-components'
import { ZoneRow, ZoneStoreRow, ZoneUserRow } from './types'

type Columns = {
  user: ZoneUserRow
  store: ZoneStoreRow
  area: ZoneRow
}

const columns: {
  [key in keyof Columns]: DAITableColumns<Columns[key]>[]
} = {
  user: [
    {
      field: 'name',
      headerName: 'Name',
      width: MuiTheme.sizes.table.column.width.xl,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: MuiTheme.sizes.table.column.width.xl,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: MuiTheme.sizes.table.column.width.lg,
    },
    {
      field: 'zone',
      headerName: 'Current Area',
      width: MuiTheme.sizes.table.column.width.lg,
    },
  ],
  store: [
    {
      field: 'store',
      headerName: 'Name',
      width: MuiTheme.sizes.table.column.width.xl,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: MuiTheme.sizes.table.column.width.xl,
    },
    {
      field: 'zone',
      headerName: 'Current Area',
      width: MuiTheme.sizes.table.column.width.md,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: MuiTheme.sizes.table.column.width.xs,
    },
    {
      field: 'customerId',
      headerName: 'Customer Id',
      width: MuiTheme.sizes.table.column.width.sm,
    },
  ],
  area: [
    {
      field: 'name',
      headerName: 'Name',
      width: MuiTheme.sizes.table.column.width.xl,
    },
    {
      field: 'manager',
      headerName: 'Manager',
      width: MuiTheme.sizes.table.column.width.xl,
    },
  ],
}

export const ZoneHierarchyConstants = {
  columns,
}
