"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMIN_WHOLE_DISPLAY_AUDIT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var AdminAuditInventoryItemFragment_1 = require("./AdminAuditInventoryItemFragment");
exports.ADMIN_WHOLE_DISPLAY_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment AdminWholeDisplayAudit on DisplayAudit {\n    __typename\n    id\n    predictions\n    products {\n      ...AdminAuditInventoryItem\n    }\n    dateCompleted\n    display {\n      id\n      area\n      displayType\n      shortDescription\n      isCompetitor\n    }\n    employee {\n      id\n      email\n    }\n  }\n  ", "\n"], ["\n  fragment AdminWholeDisplayAudit on DisplayAudit {\n    __typename\n    id\n    predictions\n    products {\n      ...AdminAuditInventoryItem\n    }\n    dateCompleted\n    display {\n      id\n      area\n      displayType\n      shortDescription\n      isCompetitor\n    }\n    employee {\n      id\n      email\n    }\n  }\n  ", "\n"])), AdminAuditInventoryItemFragment_1.ADMIN_AUDIT_INVENTORY_ITEM_FRAGMENT);
var templateObject_1;
