import { useDebounce } from 'use-debounce'
/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { auth } from 'api/Firebase'
import { ApolloError } from '@apollo/client'
import {
  DateTimeHelpers,
  GROUPS_ALLOWED_TO_LOGIN_ADMIN,
  hasPermission,
} from '@dai/common'
import {
  GET_LOGGED_IN_USER,
  GetLoggedInUserQuery,
  UserFragment,
} from '@dai/graphql'
import { useDebouncedItemQuery } from '@dai/web-components'
import { logout } from 'api/auth'
import { UserState } from 'context/UserContext'
import dayjs from 'dayjs'
import { useLocalStorage } from 'hooks/use-local-storage'
import { useEffect, useState } from 'react'

export const useLoggedInUser = () => {
  const [userInfo, setUserInfo] = useState<UserState>({
    loading: true,
    user: null,
    error: null,
  })
  // We debounce the user info b/c the userInfo is returned too quickly, and many
  // queries in the admin app fail b/c of an Anonymous User Error
  const [userInfoDebounced] = useDebounce(userInfo, 500)
  const { Storage } = useLocalStorage()
  const UserLQ = useDebouncedItemQuery<GetLoggedInUserQuery, {}>({
    queryStr: GET_LOGGED_IN_USER,
    options: {
      context: {
        endPoint: 'gnarnia',
      },
      fetchPolicy: 'network-only',
    },
  })

  const handleValidateUserGroups = (
    user: UserFragment | null,
    error?: ApolloError,
  ): UserState => {
    if (user) {
      if (hasPermission(user, GROUPS_ALLOWED_TO_LOGIN_ADMIN)) {
        return { user, loading: false, error: null }
      } else {
        return {
          user: null,
          loading: false,
          error:
            'You do not have sufficient permission to access the Admin Portal. To resolve this issue, please contact your manager or send an email to support@deliciousai.com',
        }
      }
    }
    return {
      user: null,
      loading: false,
      error: error?.message || null,
    }
  }

  const handleLogout = async () => {
    await logout()
    Storage.removeItem('user')
  }

  const handleFetchUserAndSave = async () => {
    const res = await UserLQ.lazyQuery.query()
    const user = res.data?.loggedInUser || null
    setUserInfo(handleValidateUserGroups(user, res.error))
    if (user) {
      const expiresAt = dayjs().add(1, 'week')
      Storage.setItem('user', {
        ...user,
        expiresAt: expiresAt.toISOString(),
      })
    }
  }

  useEffect(() => {
    const cachedUser = Storage.getItem('user')
    if (
      cachedUser &&
      !DateTimeHelpers.datetimeHasPassed(cachedUser.expiresAt)
    ) {
      setUserInfo(handleValidateUserGroups(cachedUser))
    } else {
      handleFetchUserAndSave()
    }
  }, [])

  useEffect(() => {
    // Listen for changes to firebase auth user
    const unsubscribe = auth.onIdTokenChanged(authUser => {
      if (authUser) {
        const cachedUser = Storage.getItem('user')
        if (
          !cachedUser ||
          DateTimeHelpers.datetimeHasPassed(cachedUser.expiresAt)
        ) {
          handleFetchUserAndSave()
        }
      } else {
        Storage.removeItem('user')
        setUserInfo(handleValidateUserGroups(null))
      }
    })
    // Callback to unsubscribe on unmount
    return unsubscribe
  }, [UserLQ.lazyQuery.query])

  return {
    state: userInfoDebounced,
    handle: { handleLogout },
  }
}
