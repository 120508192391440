/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { UpdateCompanyStoreInput } from '@dai/graphql'
import { Save } from '@mui/icons-material'
import { useCreateEditStoreFormulasForm } from 'stores/hooks/edit-store-formulas.form'
import {
  FillContainerLoading,
  MuiForm,
  MuiFormModal,
  MuiFormModalProps,
  useMuiForm,
} from '@dai/web-components'

interface EditStoreFormulasFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  loading?: boolean
  onSubmit: (storeInput: Omit<UpdateCompanyStoreInput, 'storeId'>) => void
  storeIds: string[]
}
export const EditStoreFormulasModal: React.FC<EditStoreFormulasFormProps> = props => {
  const { loading, isOpen, onSubmit, handleClose, storeIds } = props
  const { formState, setFormState } = useCreateEditStoreFormulasForm({
    storeIds,
  })
  const {
    handle: { handleOnChange },
    data: { canSubmit },
  } = useMuiForm({
    formState,
    setFormState,
  })

  const handleSubmitPress = () => {
    const storeInput: Omit<UpdateCompanyStoreInput, 'storeId'> = {
      categorySoviFormulaGroup: formState.select.LG.value as string,
      coldAvailabilityFormulaGroup: formState.select.CR.value as string,
    }
    onSubmit(storeInput)
  }

  return (
    <MuiFormModal
      title={`Edit ${storeIds.length} Store's Details`}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleSubmitPress,
          children: 'Save & Close',
          startIcon: <Save color="secondary" />,
          disabled: !canSubmit,
        },
      ]}
    >
      {loading ? (
        <FillContainerLoading />
      ) : (
        <MuiForm
          formState={formState}
          handleOnChange={handleOnChange}
          loading={false}
        />
      )}
    </MuiFormModal>
  )
}
