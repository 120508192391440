/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */

import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import UserManagementContainer from 'users/containers/UserManagement.container'
import SurveyQuestionsContainer from 'store-inspection/containers/SurveyQuestions.container'
import StoresContainer from 'stores/containers/Stores.container'
import PlanogramFeedbackContainer from 'planograms/containers/PlanogramFeedback.container'
import AuditsContainer from 'store-inspection/containers/Audits.container'
import ProductsContainer from 'products/containers/Products.container'
import PicOSContainer from 'picos/container/PicOS.container'
import ReportingContainer from 'reporting/containers/ReportingContainer'
import { NotificationContext, useNotificationState } from '@dai/web-components'
import RestockTroubleshootingContainer from 'restock-troubleshooting/containers/RestockTroubleshooting.container'
import { DAIGroupEnum } from '@dai/graphql'
import TrainingsContainer from 'trainings/components/Trainings.container'
import CategoriesContainer from 'categories/containers/Categories.container'
import ProtectedRoute from './ProtectedRoute'
import DisplaySummaryContainer from '../services/display/containers/DisplaySummary.container'

const Routes: FC = () => {
  const notificationState = useNotificationState()

  return (
    <NotificationContext.Provider value={notificationState}>
      <Switch>
        <ProtectedRoute path="/users">
          <UserManagementContainer />
        </ProtectedRoute>
        <ProtectedRoute path={['/reporting/*', '/reporting']}>
          <ReportingContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/questions">
          <SurveyQuestionsContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/products">
          <ProductsContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/categories">
          <CategoriesContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/planograms">
          <PlanogramFeedbackContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/stores">
          <StoresContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/trainings">
          <TrainingsContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/audits">
          <AuditsContainer />
        </ProtectedRoute>
        <ProtectedRoute
          path={['/picos/*', '/picos']}
          authorized={[DAIGroupEnum.PICOS]}
        >
          <PicOSContainer />
        </ProtectedRoute>
        <ProtectedRoute
          path={[
            '/display-restock-troubleshooting/:displayId',
            '/display-restock-troubleshooting',
          ]}
        >
          <RestockTroubleshootingContainer />
        </ProtectedRoute>
        <ProtectedRoute path="/display/qr/:id">
          <DisplaySummaryContainer />
        </ProtectedRoute>
      </Switch>
    </NotificationContext.Provider>
  )
}

export default Routes
