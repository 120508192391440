/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import { FlexBox } from '@dai/web-components'
import { Typography } from '@mui/material'

const QuantityFacings: React.FC<{
  facings?: number
  quantity?: number
  humanVerified?: boolean
  other?: string | number
}> = props => {
  const { facings, quantity, humanVerified, other } = props

  return (
    <FlexBox.Col sx={{ marginRight: 2, minWidth: 80 }}>
      {quantity != undefined && <Typography>Quantity: {quantity}</Typography>}
      {facings != undefined && <Typography>Facings: {facings}</Typography>}
      {humanVerified && <Typography>Human Verified</Typography>}
      {other && <Typography>{other}</Typography>}
    </FlexBox.Col>
  )
}

export default QuantityFacings
