"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORE_MAP = void 0;
var client_1 = require("@apollo/client");
var StoreAddressFragment_1 = require("../fragments/StoreAddressFragment");
exports.GET_STORE_MAP = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetStoreMap($mapId: ID!) {\n    storeMap(mapId: $mapId) {\n      geoJson\n      store {\n        name\n        category\n        image {\n          id\n          url\n        }\n        address {\n          ...StoreAddress\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetStoreMap($mapId: ID!) {\n    storeMap(mapId: $mapId) {\n      geoJson\n      store {\n        name\n        category\n        image {\n          id\n          url\n        }\n        address {\n          ...StoreAddress\n        }\n      }\n    }\n  }\n  ", "\n"])), StoreAddressFragment_1.STORE_ADDRESS_FRAGMENT);
var templateObject_1;
