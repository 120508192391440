/**
 * Copyright © 2023 Delicious AI, LLC
 */

import React, { useEffect, useMemo, useState } from 'react'
import { Category, Close, Edit, Delete } from '@mui/icons-material'
import {
  FullScreenLayout,
  PageTitle,
  MuiTheme,
  DAITableColumns,
  NotificationAlert,
  StringHelpers,
} from '@dai/web-components'
import { DAITable } from '@dai/web-components'
import { CategoryRow } from 'categories/categories.types'
import { CreateEditCategoryModal } from 'categories/components/CreateEditCategoryModal'
import { ActionBar } from '@dai/web-components'
import { Box, SpeedDialIcon, Typography } from '@mui/material'
import { MuiFormModal } from '@dai/web-components'
import { useCategoriesLogic } from './Categories.logic'

const CategoriesContainer: React.FC = () => {
  const {
    state: {
      setModalState,
      modalState,
      categoryEditing,
      setCategoryEditing,
      selectedId,
      setSelectedId,
      searchResults,
      setSearchResults,
    },
    data: { categoryRows, rowCount },
    handle: {
      handleNameChange,
      handleParentChange,
      handleSubmit,
      handleDelete,
    },
  } = useCategoriesLogic()

  const columns: DAITableColumns<CategoryRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'parent',
        headerName: 'Parent',
        width: MuiTheme.sizes.table.column.width.xl,
      },
    ],
    [MuiTheme],
  )

  return (
    <>
      <NotificationAlert />
      <FullScreenLayout
        header={<PageTitle title="Category Management" />}
        body={
          <>
            <DAITable
              rows={categoryRows}
              columns={columns}
              rowCount={rowCount}
              disableSelectAll
              onRowSelect={(val: React.SetStateAction<string | undefined>) => {
                setSearchResults([])
                if (val === selectedId) {
                  setSelectedId(undefined)
                } else {
                  setSelectedId(val)
                }
              }}
              showCheckbox
              singleSelect
            />
          </>
        }
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: -32,
          right: 8,
        }}
      >
        <ActionBar
          speedDialIcon={
            <SpeedDialIcon
              icon={<Category color="secondary" />}
              openIcon={<Close color="secondary" />}
            />
          }
          bulkActions={[
            {
              condition: !StringHelpers.isNullOrEmpty(selectedId),
              icon: <Edit color="secondary" />,
              label: 'Edit',
              onClick: () => {
                setModalState('edit-category')
              },
            },
            {
              condition: !StringHelpers.isNullOrEmpty(selectedId),
              icon: <Delete color="secondary" />,
              label: 'Delete',
              onClick: () => {
                setModalState('delete-category')
              },
            },
            {
              condition: StringHelpers.isNullOrEmpty(selectedId),
              icon: <Edit color="secondary" />,
              label: 'Create',
              onClick: () => {
                setCategoryEditing(undefined)
                setModalState('create-category')
              },
            },
          ]}
        />
      </Box>
      <CreateEditCategoryModal
        handleClose={() => {
          setModalState(null)
        }}
        isOpen={
          (!!selectedId && modalState === 'edit-category') ||
          modalState === 'create-category'
        }
        onSubmit={handleSubmit}
        category={categoryEditing}
        handleParentChange={handleParentChange}
        handleNameChange={handleNameChange}
        modalState={modalState}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
      />
      <MuiFormModal
        title="Delete Category"
        handleClose={() => setModalState(null)}
        actionButtonsProps={[
          {
            children: 'Cancel',
            onClick: () => setModalState(null),
            startIcon: <Close color="secondary" />,
            color: 'secondary',
          },
          {
            children: 'Delete',
            startIcon: <Delete color="secondary" />,
            onClick: () => handleDelete(selectedId || ''),
          },
        ]}
        isOpen={modalState === 'delete-category'}
      >
        <Typography variant="h6">
          Are you sure you want to delete the master product category{' '}
          {categoryEditing?.name}?
        </Typography>
      </MuiFormModal>
    </>
  )
}

export default CategoriesContainer
