/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { CreateOrEditAuditQuestionModal } from 'store-inspection/components/modal/CreateOrEditAuditQuestion.modal'
import {
  PageTitle,
  DAITable,
  FullScreenLayout,
  StringHelpers,
  MuiFormModal,
} from '@dai/web-components'
import { useSurveyQuestionsLogic } from 'store-inspection/containers/SurveyQuestions.logic'
import { Add, Close, Delete, Edit } from '@mui/icons-material'
import { Typography } from '@mui/material'

const SurveyQuestionsContainer: React.FunctionComponent = () => {
  const {
    state: {
      showCreateEditQuestionModal,
      setShowCreateEditQuestionModal,
      showConfirmDeleteQuestionsModal,
      setShowConfirmDeleteModal,
      submittingQuestion,
      selectedIds,
      setSelectedIds,
    },
    ref: { tableRef },
    handle: {
      handleSubmitCreateOrEditQuestion,
      handleCancelDelete,
      handleConfirmDelete,
    },
    data: { questionRows, columns, groups, selectedQuestion, loading },
  } = useSurveyQuestionsLogic()

  return (
    <>
      <FullScreenLayout
        bodyStyle={{ p: 0 }}
        header={<PageTitle title="Survey Questions Management" />}
        body={
          <DAITable
            ref={tableRef}
            columns={columns}
            rows={questionRows}
            tableToolBarProps={{
              tableTitle: 'Questions',
              right: {
                iconButton: {
                  buttonProps: {
                    onClick: () => {
                      setShowCreateEditQuestionModal(true)
                      tableRef.current?.setSelectedIds([])
                      setSelectedIds([])
                    },
                  },
                  icon: <Add />,
                  toolTipLabel: 'Add Question',
                },
              },
            }}
            bulkActions={[
              {
                condition: !!selectedQuestion,
                onClick: () => setShowCreateEditQuestionModal(true),
                icon: <Edit color="secondary" />,
                label: 'Edit',
              },
              {
                condition: true,
                onClick: () => setShowConfirmDeleteModal(true),
                icon: <Delete color="secondary" />,
                label: 'Delete',
              },
            ]}
            loading={loading}
            onSelectionChange={setSelectedIds}
          />
        }
      />
      <CreateOrEditAuditQuestionModal
        handleClose={() => setShowCreateEditQuestionModal(false)}
        loading={submittingQuestion}
        groups={Array.from(groups)}
        question={selectedQuestion}
        isOpen={showCreateEditQuestionModal}
        onSubmit={handleSubmitCreateOrEditQuestion}
      />
      <MuiFormModal
        title="Delete Questions"
        subtitle="Are you sure?"
        handleClose={handleCancelDelete}
        actionButtonsProps={[
          {
            children: 'Cancel',
            onClick: handleCancelDelete,
            startIcon: <Close color="secondary" />,
            color: 'secondary',
          },
          {
            children: 'Delete',
            startIcon: <Delete color="secondary" />,
            onClick: handleConfirmDelete,
          },
        ]}
        isOpen={showConfirmDeleteQuestionsModal}
      >
        <Typography variant="h6">
          Are you sure you want to delete {selectedIds.length}{' '}
          {StringHelpers.plurality('question', selectedIds.length)}?
        </Typography>
      </MuiFormModal>
    </>
  )
}

export default SurveyQuestionsContainer
