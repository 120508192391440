"use strict";
/**
 * Copyright © 2020-2022 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clark.sandholtz@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLabelFeatures = exports.getStoreCenterFromMap = exports.getPolygonMidpoint = exports.mapboxBoundsToPoints = exports.pointToMapboxCoordinates = exports.mapboxCoordinatesToPoint = void 0;
var mapboxCoordinatesToPoint = function (coordinates) {
    var longitude = coordinates[0], latitude = coordinates[1];
    return { longitude: longitude, latitude: latitude };
};
exports.mapboxCoordinatesToPoint = mapboxCoordinatesToPoint;
var pointToMapboxCoordinates = function (point) {
    return [point.longitude, point.latitude];
};
exports.pointToMapboxCoordinates = pointToMapboxCoordinates;
var mapboxBoundsToPoints = function (bounds) {
    return {
        ne: { latitude: bounds[0][1], longitude: bounds[0][0] },
        sw: { latitude: bounds[1][1], longitude: bounds[1][0] },
    };
};
exports.mapboxBoundsToPoints = mapboxBoundsToPoints;
var getPolygonMidpoint = function (coordinates) {
    var minLng = coordinates.reduce(function (min, coordinate) {
        return Math.abs(coordinate[0]) < Math.abs(min) ? coordinate[0] : min;
    }, coordinates[0][0]);
    var maxLng = coordinates.reduce(function (max, coordinate) {
        return Math.abs(coordinate[0]) > Math.abs(max) ? coordinate[0] : max;
    }, coordinates[0][0]);
    var minLat = coordinates.reduce(function (min, coordinate) {
        return Math.abs(coordinate[1]) < Math.abs(min) ? coordinate[1] : min;
    }, coordinates[0][1]);
    var maxLat = coordinates.reduce(function (max, coordinate) {
        return Math.abs(coordinate[1]) > Math.abs(max) ? coordinate[1] : max;
    }, coordinates[0][1]);
    var midpointLng = minLng + (maxLng - minLng) / 2;
    var midpointLat = minLat + (maxLat - minLat) / 2;
    return [midpointLng, midpointLat];
};
exports.getPolygonMidpoint = getPolygonMidpoint;
var getStoreCenterFromMap = function (storeMap) {
    var store = storeMap.features.find(function (f) { return f.properties.type === 'store'; });
    if (store && store.geometry.type === 'Polygon') {
        var coords_1 = store === null || store === void 0 ? void 0 : store.geometry.coordinates[0];
        return coords_1
            ? (0, exports.mapboxCoordinatesToPoint)((0, exports.getPolygonMidpoint)(coords_1))
            : undefined;
    }
    else if (store) {
        var coords_2 = store === null || store === void 0 ? void 0 : store.geometry.coordinates;
        return coords_2
            ? (0, exports.mapboxCoordinatesToPoint)((0, exports.getPolygonMidpoint)(coords_2))
            : undefined;
    }
    var other = storeMap.features.find(function (f) { return f.properties.type === 'area'; });
    var coords = other === null || other === void 0 ? void 0 : other.geometry.coordinates;
    return coords
        ? (0, exports.mapboxCoordinatesToPoint)((0, exports.getPolygonMidpoint)(coords))
        : undefined;
};
exports.getStoreCenterFromMap = getStoreCenterFromMap;
var getLabelFeatures = function (features) {
    var areaLabels = features
        .filter(function (feature) { var _a, _b; return ((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.name) || ((_b = feature.properties) === null || _b === void 0 ? void 0 : _b.area); })
        .map(function (feature) {
        var _a, _b;
        return {
            type: 'Feature',
            properties: {
                description: ((_a = feature.properties) === null || _a === void 0 ? void 0 : _a.name) || ((_b = feature.properties) === null || _b === void 0 ? void 0 : _b.area) || '',
                type: feature.properties.type,
            },
            geometry: {
                type: 'Point',
                coordinates: (0, exports.getPolygonMidpoint)(feature.geometry.type === 'Polygon'
                    ? feature.geometry.coordinates[0]
                    : feature.geometry.coordinates),
            },
        };
    });
    return { type: 'FeatureCollection', features: areaLabels };
};
exports.getLabelFeatures = getLabelFeatures;
