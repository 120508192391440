/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import {
  GET_COMPANY_BY_NAME,
  GetCompanyByNameQuery,
  GetCompanyByNameQueryVariables,
} from '@dai/graphql'
import UserContext from 'context/UserContext'
import { useContext } from 'react'

export const useGetCompanyByName = () => {
  const { user } = useContext(UserContext)
  const name = user?.companyName || ''
  const { data: company } = useQuery<
    GetCompanyByNameQuery,
    GetCompanyByNameQueryVariables
  >(GET_COMPANY_BY_NAME, {
    variables: { name },
    fetchPolicy: 'cache-first',
  })
  return {
    company,
  }
}
