"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DISPLAY_LOCATIONS = void 0;
var client_1 = require("@apollo/client");
exports.GET_DISPLAY_LOCATIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tquery DisplayLocations($input: DisplayLocationsInput!){\n\t\tdisplayLocations(input: $input){\n\t\t\tcount\n\t\t\tresults{\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\ttypes{\n\t\t\t\t\tcategory\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"], ["\n\tquery DisplayLocations($input: DisplayLocationsInput!){\n\t\tdisplayLocations(input: $input){\n\t\t\tcount\n\t\t\tresults{\n\t\t\t\tname\n\t\t\t\tid\n\t\t\t\ttypes{\n\t\t\t\t\tcategory\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"])));
var templateObject_1;
