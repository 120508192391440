"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.STORE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var TaskFragment_1 = require("./TaskFragment");
var StoreAddressFragment_1 = require("./StoreAddressFragment");
var ZoneHierarchyGlobalSearchResultFragment_1 = require("./ZoneHierarchyGlobalSearchResultFragment");
exports.STORE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Store on CompanyStore {\n    id\n    isActive\n    totalAudits\n    distance\n    physicalStoreId\n    storeChain\n    name\n    internalStoreId\n    category\n    hasAllFormulas\n    image {\n      id\n      url\n    }\n    address {\n      ...StoreAddress\n    }\n    point {\n      latitude\n      longitude\n    }\n    lastAudit\n    daysSinceAudit\n    tasks {\n      ...Task\n    }\n    zones {\n      ...ZoneHierarchyGlobalSearchResult\n    }\n  }\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment Store on CompanyStore {\n    id\n    isActive\n    totalAudits\n    distance\n    physicalStoreId\n    storeChain\n    name\n    internalStoreId\n    category\n    hasAllFormulas\n    image {\n      id\n      url\n    }\n    address {\n      ...StoreAddress\n    }\n    point {\n      latitude\n      longitude\n    }\n    lastAudit\n    daysSinceAudit\n    tasks {\n      ...Task\n    }\n    zones {\n      ...ZoneHierarchyGlobalSearchResult\n    }\n  }\n  ", "\n  ", "\n  ", "\n"])), TaskFragment_1.TASK_FRAGMENT, StoreAddressFragment_1.STORE_ADDRESS_FRAGMENT, ZoneHierarchyGlobalSearchResultFragment_1.ZONE_HIERARCHY_GLOBAL_SEARCH_RESULT_FRAGMENT);
var templateObject_1;
