/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { PicOSGroupComplianceStatsFragment } from '@dai/graphql'

declare type PicOSGroupComplianceStats_PicOSAllCustomersStats_Fragment = {
  __typename: 'PicOSAllCustomersStats'
  numCustomers: number
  numStoresSurveyed: number
  numDistinctPriorities: number
  totalPriorities: number
  numCompliantPriorities: number
}
declare type PicOSGroupComplianceStats_PicOSCustomerStats_Fragment = {
  __typename: 'PicOSCustomerStats'
  numDivisions: number
  numStoresSurveyed: number
  numDistinctPriorities: number
  totalPriorities: number
  numCompliantPriorities: number
}

declare type PicOSGroupComplianceStats_PicOSRegionalStats_Fragment = {
  __typename: 'PicOSRegionalStats'
  numStoresSurveyed: number
  numDistinctPriorities: number
  totalPriorities: number
  numCompliantPriorities: number
}

export const isPicOSAllCustomersStats = (
  stat: PicOSGroupComplianceStatsFragment,
): stat is PicOSGroupComplianceStats_PicOSAllCustomersStats_Fragment => {
  return (
    (stat as PicOSGroupComplianceStats_PicOSAllCustomersStats_Fragment)
      .__typename === 'PicOSAllCustomersStats'
  )
}

export const isPicOSCustomerStats = (
  stat: PicOSGroupComplianceStatsFragment,
): stat is PicOSGroupComplianceStats_PicOSCustomerStats_Fragment => {
  return (
    (stat as PicOSGroupComplianceStats_PicOSCustomerStats_Fragment)
      .__typename === 'PicOSCustomerStats'
  )
}

export const isPicOSRegionalStats = (
  stat: PicOSGroupComplianceStatsFragment,
): stat is PicOSGroupComplianceStats_PicOSRegionalStats_Fragment => {
  return (
    (stat as PicOSGroupComplianceStats_PicOSRegionalStats_Fragment)
      .__typename === 'PicOSRegionalStats'
  )
}
