/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  useDebouncedItemQuery,
  usePagination,
  PicOSComplianceCardReport,
  BasicPicOSCustomerInfo,
  PicOSReportContext,
} from '@dai/web-components'
import React, { FC, useContext, useEffect } from 'react'
import {
  GetPicOSCustomerComplianceQuery,
  GetPicOSCustomerComplianceStatsQuery,
  GetPicOSCustomerComplianceStatsQueryVariables,
  GetPicOSCustomerComplianceQueryVariables,
  GET_PICOS_CUSTOMER_COMPLIANCE,
  GET_PICOS_CUSTOMER_COMPLIANCE_STATS,
} from '@dai/graphql'
import { useHistory, useParams } from 'react-router-dom'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import { plurality } from '@dai/common'

const pageSize = 20

const PicOSCustomerReport: FC = () => {
  const history = useHistory()
  const { customerId } = useParams<{ customerId: string }>()
  const {
    selectedDateRange,
    setSelectedDivision,
    selectedCustomer,
  } = useContext(PicOSReportContext)
  const {
    offset,
    limit,
    currentPage,
    handleOffset: { goToPage },
  } = usePagination({ pageSize: 20 })

  const OverviewQuery = useDebouncedItemQuery<
    GetPicOSCustomerComplianceQuery,
    GetPicOSCustomerComplianceQueryVariables
  >({
    queryStr: GET_PICOS_CUSTOMER_COMPLIANCE,
    options: {
      variables: {
        input: {
          customerId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    GetPicOSCustomerComplianceStatsQuery,
    GetPicOSCustomerComplianceStatsQueryVariables
  >({
    queryStr: GET_PICOS_CUSTOMER_COMPLIANCE_STATS,
    options: {
      variables: {
        input: {
          customerId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const overviewQueryMeta = OverviewQuery.lazyQuery.meta
  const statsQueryMeta = StatsQuery.lazyQuery.meta

  const handleSelect = (info: BasicPicOSCustomerInfo) => {
    setSelectedDivision(info)
    history.push(`${customerId}/${info.id}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  const handleExportCsv = () => {
    // TODO: Implement on backend
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          customerId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          divisionName: OverviewQuery.debouncedQuery || undefined,
          offset,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const customersData =
    overviewQueryMeta.data?.picosCustomerCompliance.results || []
  const stats = statsQueryMeta.data?.picosCustomerStats

  const totalItems = overviewQueryMeta.data?.picosCustomerCompliance.count || 0
  const totalPages = Math.ceil(totalItems / pageSize)

  return (
    <PicOSComplianceCardReport
      title={`${selectedCustomer?.name} Divisions`}
      loadingStats={statsQueryMeta.loading}
      loadingComplianceItems={overviewQueryMeta.loading}
      onSearch={handleSearch}
      onClickExportCsv={handleExportCsv}
      pageSize={limit}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(_, page) => goToPage(page)}
      onSelect={handleSelect}
      statItems={PicOSReportHelpers.getStatItems(
        ['compliance', 'customers', 'stores', 'priorities', 'cost'],
        stats,
      )}
      complianceItems={customersData.map(customer => ({
        id: customer.id,
        title: customer.name,
        compliance:
          customer.totalPriorities !== 0
            ? customer.numCompliant / customer.totalPriorities
            : 0,
        details: [
          {
            count: customer.numChildren,
            name: plurality('Store', customer.numChildren),
          },
          {
            count: customer.numGrandchildren,
            name: plurality('Display', customer.numGrandchildren),
          },
        ],
      }))}
    />
  )
}

export default PicOSCustomerReport
