/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  DisplayElements,
  MandateTypes,
  PicOSPriorities_picosPriorities_results,
} from '@dai/graphql'
import {
  FormPicOSPriority,
  PicOSPriority,
  PicOSProductFormState,
} from 'picos/picos.types'
import { uniqueId } from '@dai/common'

const transformPriority = (
  pr: PicOSPriorities_picosPriorities_results,
): PicOSPriority => ({
  id: pr.id,
  name: pr.name,
  startDate: pr.startDate,
  endDate: pr.endDate,
  priorityNum: pr.priority,
  mandateType: (pr.mandateType as unknown) as MandateTypes,
  isAllStores: !pr.storeGroup.length,
  imageUrl: pr.image?.url || '',
  description: pr.description || '',
  displayTypes: pr.displayTypes.map(pri => ({
    value: pri.id,
    label: pri.name,
  })),
  displayLocations: pr.displayLocation.map(pri => ({
    value: pri.id,
    label: pri.name,
  })),
  displayElements: (pr.displayElements as unknown) as DisplayElements,
  posElementRequired: pr.posElementRequired,
  otherDisplayTypesAllowed: pr.otherDisplayTypesAllowed,
  otherDisplayLocationsAllowed: pr.otherDisplayLocationsAllowed,
  otherBrandsAllowed: pr.otherBrandsAllowed,
  otherPackagingAllowed: pr.otherPackagingAllowed,
  otherProductsAllowed: pr.otherProductsAllowed,
  addedProducts: getBrandsPackagingProductFromPriority({
    brands: pr.brands.map(b => ({ value: b.id, label: b.name })),
    packaging: pr.packaging.map(p => ({
      value: p.id,
      label: p.packagingString,
    })),
    sabreProducts: pr.products.map(p => ({
      product: { upc: p.upcA, name: p.name || p.upcA },
      packaging: {
        id: p.packaging?.id || '',
        name: p.packaging?.packagingString || '',
      },
      brand: { id: p.brand?.id || '', name: p.brand?.name || '' },
    })),
  }),
  customerBanner: {
    value: pr.storeChain.id,
    label: pr.storeChain.name,
  },
  storeGroups: pr.storeGroup.map(pri => ({
    value: pri.id,
    label: pri.name,
  })),
  pricing: pr.pricingInfo || '',
  cost: pr.cost,
})

export type CachedPicosPriority = {
  id: string
  picosPriority: FormPicOSPriority
  lastModified: Date
}

// first key is bannerName, second is a unique id
type PicosPriorityDraftCache = {
  [key in string]: {
    [key in string]: CachedPicosPriority
  }
}
const PICOS_PRIORITY_DRAFT_CACHE_KEY = 'PICOS_PRIORITY_DRAFT_CACHE'
const savePicosLocalStorage = async (
  picosPriority: FormPicOSPriority,
  customerBanner: string,
  id?: string,
) => {
  const uuid = id || uniqueId()
  const cachedPicos: CachedPicosPriority = {
    id: uuid,
    picosPriority,
    lastModified: new Date(),
  }
  const currentCache = await localStorage.getItem(
    PICOS_PRIORITY_DRAFT_CACHE_KEY,
  )
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    const draftPicos: PicosPriorityDraftCache = {
      ...cachedDraft,
      [customerBanner]: {
        ...(cachedDraft[customerBanner] ? cachedDraft[customerBanner] : {}),
        [uuid]: cachedPicos,
      },
    }
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(draftPicos),
    )
  } else {
    const draftPicos: PicosPriorityDraftCache = {
      [customerBanner]: { [uuid]: cachedPicos },
    }
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(draftPicos),
    )
  }
}

const getPicosLocalStorage = (id: string): FormPicOSPriority | undefined => {
  const currentCache = localStorage.getItem(PICOS_PRIORITY_DRAFT_CACHE_KEY)
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    const picos = Object.values(cachedDraft).find(customer => {
      if (customer[id]) {
        return true
      }
    })
    return picos?.[id]?.picosPriority
  }
}

const getAllPicosPrioritiesFromLocalStorage = (
  customerBanner: string,
): CachedPicosPriority[] | undefined => {
  const currentCache = localStorage.getItem(PICOS_PRIORITY_DRAFT_CACHE_KEY)
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    if (cachedDraft[customerBanner]) {
      return Object.values(cachedDraft[customerBanner])
    }
  }
}

const removePicosPriorityFromLocalStorage = (
  customerBanner: string,
  id: string,
) => {
  const currentCache = localStorage.getItem(PICOS_PRIORITY_DRAFT_CACHE_KEY)
  if (currentCache) {
    const cachedDraft: PicosPriorityDraftCache = JSON.parse(currentCache)
    delete cachedDraft[customerBanner][id]
    return localStorage.setItem(
      PICOS_PRIORITY_DRAFT_CACHE_KEY,
      JSON.stringify(cachedDraft),
    )
  }
}

const getBrandsPackagingProductFromPriority = (
  priority: Omit<PicOSProductFormState, 'productOptions'>,
  current?: FormPicOSPriority['addedProducts'],
): FormPicOSPriority['addedProducts'] => {
  const brands = priority.brands
  const packaging = priority.packaging
  const products = priority.sabreProducts || []

  const result = [...(current || [])]

  for (const product of products) {
    result.push({
      id: uniqueId(),
      product: { value: product.product.upc, label: product.product.name },
      brand: product.brand
        ? { value: product.brand.id, label: product.brand.name }
        : null,
      packaging: product.packaging
        ? { value: product.packaging.id, label: product.packaging.name }
        : null,
    })
  }
  for (const brand of brands) {
    for (const packageType of packaging) {
      if (
        !result.some(
          r =>
            r.brand?.value === brand.value &&
            r.packaging?.value === packageType.value,
        )
      ) {
        result.push({
          id: uniqueId(),
          brand,
          packaging: packageType,
          product: null,
        })
      }
    }
  }

  return result
}

export const PicOSHelpers = {
  transformPriority,
  savePicosLocalStorage,
  getPicosLocalStorage,
  getAllPicosPrioritiesFromLocalStorage,
  removePicosPriorityFromLocalStorage,
  getBrandsPackagingProductFromPriority,
}
