/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportHook,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { FILL_GRID_ALL_SCREENS, MuiTheme } from '@dai/web-components'
import { useQuery } from '@apollo/client'
import {
  AggregateBy,
  AggregationMethod,
  GET_DISPLAY_SCANS,
  GET_PRODUCT_COUNTED,
  GetDisplayScansQuery,
  GetDisplayScansQueryVariables,
  GetProductCountedQuery,
  GetProductCountedQueryVariables,
} from '@dai/graphql'
import { useLocalStorage } from 'hooks/use-local-storage'
import { DateTimeConstants, DateTimeHelpers } from '@dai/common'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'

export const useActivityHistoryReports: GenericReportHook<UserProductivityReportName> = () => {
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')
  const useGetTotalRestocksOverTime = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const timeGroupBy = DateTimeHelpers.isDiffDaysLessThanOrEQ(
      reportParams.startDate,
      reportParams.endDate,
      DateTimeConstants.SIXTY_DAYS,
    )
      ? AggregateBy.WEEK
      : AggregateBy.MONTH
    const input = getUserProductivityQueryInput(
      AggregationMethod.COUNT,
      timeGroupBy,
    )
    const { data: userRestocks, loading: restocksLoading } = useQuery<
      GetDisplayScansQuery,
      GetDisplayScansQueryVariables
    >(GET_DISPLAY_SCANS, {
      variables: {
        input: {
          ...input,
          aggregationInput: {
            ...input.aggregationInput!,
            mergeTeams: false,
          },
        },
      },
    })
    const restocks =
      userRestocks?.displayScans.results.map(res => ({
        category: new Date(res.date).getTime(),
        value: res.count,
        group: companyUsers?.[res.refId].name,
      })) || []

    return {
      data: restocks,
      isEmpty: !restocks.length,
      isLoading: restocksLoading,
    }
  }

  const useGetTotalProductCountedOverTime = (
    reportParams: UserProductivityReportInput,
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getProductProductivityQueryInput } = useReportQuery()
    const timeGroupBy = DateTimeHelpers.isDiffDaysLessThanOrEQ(
      reportParams.startDate,
      reportParams.endDate,
      DateTimeConstants.SIXTY_DAYS,
    )
      ? AggregateBy.WEEK
      : AggregateBy.MONTH
    const input = getProductProductivityQueryInput(
      AggregationMethod.SUM,
      timeGroupBy,
    )
    const { data: productCounted, loading: restocksLoading } = useQuery<
      GetProductCountedQuery,
      GetProductCountedQueryVariables
    >(GET_PRODUCT_COUNTED, {
      variables: {
        input: {
          ...input,
          aggregationInput: {
            ...input.aggregationInput!,
            mergeTeams: false,
          },
        },
      },
    })
    const counts =
      productCounted?.productCounted.results.map(res => ({
        category: new Date(res.date).getTime(),
        value: res.count,
        group: companyUsers?.[res.refId].name,
      })) || []

    return {
      data: counts,
      isEmpty: !counts.length,
      isLoading: restocksLoading,
    }
  }

  return [
    {
      reportName: 'product-counted',
      useGetData: useGetTotalProductCountedOverTime,
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Total Product Counted',
      type: 'line-chart',
      chartId: 'total-product-counted-over-time',
      tooltip: [
        {
          text: '{valueY}',
          fontSize: 28,
          color: MuiTheme.palette.secondary.main,
          bold: true,
        },
        {
          text: 'Product Counted',
          color: MuiTheme.palette.secondary.light,
          newline: true,
        },
        {
          text: '{valueX.formatDate()}',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'restocks',
      useGetData: useGetTotalRestocksOverTime,
      gridProps: FILL_GRID_ALL_SCREENS,
      title: 'Total Restocks',
      type: 'line-chart',
      chartId: 'total-restocks-over-time',
      tooltip: [
        {
          text: '{valueY}',
          fontSize: 28,
          color: MuiTheme.palette.secondary.main,
          bold: true,
        },
        {
          text: 'Restocks',
          color: MuiTheme.palette.secondary.light,
          newline: true,
        },
        {
          text: '{valueX.formatDate()}',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
  ]
}
