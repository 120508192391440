"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var UserFragment_1 = require("./UserFragment");
var DisplayFragment_1 = require("./DisplayFragment");
exports.TASK_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Task on TaskBase {\n    id\n    description\n    assignedUser {\n      ...User\n    }\n    createdBy {\n      ...User\n    }\n    store {\n      id\n      name\n    }\n    progress {\n      status\n      dateCompleted\n      user {\n        ...User\n      }\n      created\n      modified\n      errorMessage\n    }\n    created\n    modified\n    ... on DisplayTask {\n      __typename\n      description\n      taskType\n      display {\n        ...Display\n      }\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment Task on TaskBase {\n    id\n    description\n    assignedUser {\n      ...User\n    }\n    createdBy {\n      ...User\n    }\n    store {\n      id\n      name\n    }\n    progress {\n      status\n      dateCompleted\n      user {\n        ...User\n      }\n      created\n      modified\n      errorMessage\n    }\n    created\n    modified\n    ... on DisplayTask {\n      __typename\n      description\n      taskType\n      display {\n        ...Display\n      }\n    }\n  }\n  ", "\n  ", "\n"])), UserFragment_1.USER_FRAGMENT, DisplayFragment_1.DISPLAY_FRAGMENT);
var templateObject_1;
