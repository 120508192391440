/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { useLazyQuery } from '@apollo/client'
import {
  GetDisplayWithStoreDataQuery,
  GetDisplayWithStoreDataQueryVariables,
  GetDisplayRestockHistoryQuery,
  GetDisplayRestockHistoryQueryVariables,
  GET_DISPLAY_RESTOCK_HISTORY,
  GET_STORE_DISPLAY_WITH_STORE_DATA,
  GetRandomObservedDisplayQuery,
  GET_RANDOM_OBSERVED_DISPLAY,
  CompanyStoreDistributionsQuery,
  CompanyStoreDistributionsQueryVariables,
  COMPANY_STORE_DISTRIBUTIONS,
} from '@dai/graphql'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

type Props = {
  displayId?: string
  startDate?: Dayjs
  endDate?: Dayjs
}

export const useRestockTroubleshootingLogic = (props: Props) => {
  const { displayId, startDate, endDate } = props
  const NUM_ITEMS_TO_PULL = 10
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(1)
  const [numberPages, setNumberPages] = useState(0)
  const [fetchDisplay, { data, loading: loadingDisplay }] = useLazyQuery<
    GetDisplayWithStoreDataQuery,
    GetDisplayWithStoreDataQueryVariables
  >(GET_STORE_DISPLAY_WITH_STORE_DATA)
  useEffect(() => {
    if (displayId) {
      fetchDisplay({ variables: { displayId } })
    }
  }, [displayId])
  const display = data?.display

  const [
    fetchStoreDistributions,
    { data: storeDistributionData },
  ] = useLazyQuery<
    CompanyStoreDistributionsQuery,
    CompanyStoreDistributionsQueryVariables
  >(COMPANY_STORE_DISTRIBUTIONS, { fetchPolicy: 'cache-first' })
  useEffect(() => {
    if (display?.store) {
      fetchStoreDistributions({
        variables: { companyStoreId: display.store.id },
      })
    }
  }, [display])

  const [
    fetchHistory,
    { data: displayRestockHistoryData, loading: loadingHistory },
  ] = useLazyQuery<
    GetDisplayRestockHistoryQuery,
    GetDisplayRestockHistoryQueryVariables
  >(GET_DISPLAY_RESTOCK_HISTORY, { fetchPolicy: 'no-cache' })
  const [
    _getRandomObservedDisplay,
    { loading: loadingRandomDisplay },
  ] = useLazyQuery<GetRandomObservedDisplayQuery>(GET_RANDOM_OBSERVED_DISPLAY, {
    fetchPolicy: 'no-cache',
  })

  const getRandomObservedDisplay = async () => {
    const data = await _getRandomObservedDisplay()
    if (data?.data?.randomObservedDisplay) {
      return data.data.randomObservedDisplay.id
    }
    return undefined
  }

  useEffect(() => {
    if (displayId) {
      fetchHistory({
        variables: {
          input: {
            displayId,
            offset,
            limit: NUM_ITEMS_TO_PULL,
            startDate,
            endDate,
          },
        },
      })
    }
  }, [displayId, offset, startDate, endDate])

  const history = displayRestockHistoryData?.displayRestockHistory

  useEffect(() => {
    if (history) {
      const numberOfPages =
        history && history.count
          ? Math.ceil(history.count / NUM_ITEMS_TO_PULL)
          : undefined
      setNumberPages(numberOfPages || 0)
    }
  }, [history])

  const goToPage = (pageNumber: number) => {
    setPage(pageNumber)
    const nextOffset = (pageNumber - 1) * NUM_ITEMS_TO_PULL
    setOffset(nextOffset)
  }
  const storeDistributedUpcs =
    storeDistributionData?.distributions.storeDistributedUpcs || []
  const allDistributedProductUpcs = [...storeDistributedUpcs]
  return {
    display,
    history,
    loadingDisplay,
    loadingHistory,
    loadingRandomDisplay,
    numberPages,
    allDistributedProductUpcs,
    getRandomObservedDisplay,
    goToPage,
    page,
  }
}
