"use strict";
/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.levenshteinDistance = void 0;
var levenshteinDistance = function (arr1, arr2) {
    var track = Array(arr2.length + 1)
        .fill(null)
        .map(function () { return Array(arr1.length + 1).fill(null); });
    for (var i = 0; i <= arr1.length; i += 1) {
        track[0][i] = i;
    }
    for (var j = 0; j <= arr2.length; j += 1) {
        track[j][0] = j;
    }
    for (var j = 1; j <= arr2.length; j += 1) {
        for (var i = 1; i <= arr1.length; i += 1) {
            var indicator = arr1[i - 1] === arr2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(track[j][i - 1] + 1, // deletion
            track[j - 1][i] + 1, // insertion
            track[j - 1][i - 1] + indicator);
        }
    }
    return track[arr2.length][arr1.length];
};
exports.levenshteinDistance = levenshteinDistance;
