/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  DAITable,
  DAITableColumns,
  MuiTheme,
  StringHelpers,
  useCommonFilter,
  FilterHelpers,
  SimpleOptionSelect,
} from '@dai/web-components'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SimpleStoreRow, StoreRow } from 'stores/stores.types'
import { useDebounce } from 'use-debounce'
import UserContext from 'context/UserContext'
import {
  hasPermission,
  useGetAdminCompanyRegions,
  useGetAdminCompanyStores,
  useUpdateCompanyStoreMutation,
  DateTimeConstants,
} from '@dai/common'
import {
  DAIGroupEnum,
  GET_COMPANY_CHAINS,
  GetCompanyChainsQuery,
  SortBy,
  StoreCategory,
  UpdateCompanyStoreInput,
  UpdateCompanyStoreMutationVariables,
  UserFragment,
} from '@dai/graphql'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'

const PAGE_SIZE = MuiTheme.sizes.table.page.md

type StoreFilterType = {
  scanState: SimpleOptionSelect<string>
  sortBy: SimpleOptionSelect<string>
  filterBy: SimpleOptionSelect<string[]>
}

type ModalState =
  | 'edit-store'
  | 'edit-optimizations'
  | 'pause-scans'
  | 'resume-scans'
  | 'stop-scans'
  | 'show-stores-info'
  | null

export const useStoresLogic = <Row extends SimpleStoreRow>({
  userStores,
  user,
  defaultToLargeStoresOnly,
}: {
  user?: UserFragment
  userStores?: string[]
  defaultToLargeStoresOnly?: boolean
}) => {
  const [storeSearch, setStoreSearch] = useState('')
  const [hideInactive, setHideInactive] = useState<boolean>(true)
  const [debouncedStoreSearchQuery] = useDebounce(storeSearch, 500)
  const [selectedIds, setSelectedIds] = useState<string[]>(userStores || [])

  const [modalState, setModalState] = useState<ModalState>(null)

  const tableRef = useRef<DAITable<Row>>(null)
  const context = useContext(UserContext)
  const authUser = context.user
  const isRedAdmin = hasPermission(authUser, [DAIGroupEnum.RED_ADMINS])
  const isSalesAdmin = hasPermission(authUser, [DAIGroupEnum.SALES_ADMINS])
  const isSalesManager = hasPermission(authUser, [DAIGroupEnum.SALES_MANAGERS])

  const { regions } = useGetAdminCompanyRegions()
  const { data: chains } = useQuery<GetCompanyChainsQuery>(GET_COMPANY_CHAINS)

  const defaultFilter: StoreFilterType = {
    scanState: {
      selected: 'All',
      options: ['Scanned', 'Paused', 'All'],
    },
    sortBy: {
      selected: StringHelpers.stringValue(SortBy.STORE_NAME),
      options: Object.keys(SortBy).map(sort => StringHelpers.stringValue(sort)),
    },
    filterBy: {
      selected: [],
      options: [
        {
          label: 'Chain',
          selected: [],
          subOptions:
            chains?.companyChains.map(ch => ({
              value: ch.id,
              label: ch.name,
            })) || [],
          showSearch: true,
        },
        {
          label: 'Category',
          selected: defaultToLargeStoresOnly ? ['LG'] : [],
          subOptions: [
            {
              value: 'LG',
              label: 'Large Stores',
            },
            {
              value: 'CR',
              label: 'Convenience Retail',
            },
          ],
        },
        {
          label: 'Regions',
          subOptions: regions.map(region => ({
            value: region.id,
            label: region.name,
          })),
          showSearch: true,
          selected: [],
        },
      ],
    },
  }

  const FilterProps = useCommonFilter(defaultFilter)

  useEffect(() => {
    FilterProps.setFilter(defaultFilter)
  }, [regions.length, chains?.companyChains.length])

  const pausedFilter = FilterProps.filter.scanState.selected

  const companyStoresInput = {
    paused:
      pausedFilter === 'Scanned'
        ? false
        : pausedFilter === 'Paused'
        ? true
        : undefined,
    onlyActive: hideInactive,
    sortBy: StringHelpers.enumValue(
      FilterProps.filter.sortBy.selected,
    ) as SortBy,
    categories: FilterHelpers.getSubOptions(
      FilterProps.filter.filterBy,
      'Category',
    )?.selected,
    regions: FilterHelpers.getSubOptions(
      FilterProps.filter.filterBy!,
      'Regions',
    )?.selected,
    chains: FilterHelpers.getSubOptions(FilterProps.filter.filterBy!, 'Chain')
      ?.selected,
    searchTerm: debouncedStoreSearchQuery,
    limit: PAGE_SIZE,
    prioritizeUserId: user?.uuid,
  }

  const {
    stores,
    count,
    loading,
    refetch,
    fetchNextPage,
    fetchRest,
    setFetchRest,
    fetchProgress,
  } = useGetAdminCompanyStores(companyStoresInput)

  useEffect(() => {
    tableRef.current?.returnToFirstPage()
  }, [
    hideInactive,
    debouncedStoreSearchQuery,
    FilterProps.filter.scanState.selected,
    FilterHelpers.getSubOptions(FilterProps.filter.filterBy, 'Category')
      ?.selected,
    FilterHelpers.getSubOptions(FilterProps.filter.filterBy!, 'Regions')
      ?.selected,
    FilterHelpers.getSubOptions(FilterProps.filter.filterBy!, 'Chain')
      ?.selected,
  ])

  const { updateCompanyStore } = useUpdateCompanyStoreMutation()

  const saveOneStore = (
    storeInput: Omit<UpdateCompanyStoreInput, 'storeId'>,
    storeId: string,
  ) => {
    const variables: UpdateCompanyStoreMutationVariables = {
      input: { ...storeInput, storeId },
    }
    return updateCompanyStore(variables)
  }

  const saveMultipleStores = (
    storeInput: Omit<UpdateCompanyStoreInput, 'storeId'>,
  ) => {
    const promises: Promise<any>[] = []
    selectedIds.forEach(id => {
      promises.push(saveOneStore(storeInput, id))
    })
    return Promise.all(promises)
  }

  const handleSaveCompanyStoresUpdate = async (
    storeInput: Omit<UpdateCompanyStoreInput, 'storeId'>,
  ) => {
    if (selectedIds.length > 1) {
      await saveMultipleStores(storeInput)
    } else if (selectedIds.length === 1) {
      await saveOneStore(storeInput, selectedIds[0])
    }
    setModalState(null)
    tableRef.current?._setSelected([])
    refetch()
  }

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= stores.length) {
      await fetchNextPage()
    }
  }

  const storeRows: StoreRow[] = useMemo(
    () =>
      stores.map(store => ({
        store: `${store.name}`,
        address: `${store.address?.address1} - ${store.address?.city}, ${store.address?.state}`,
        region: store.region?.name || '',
        chain: store.storeChain || '',
        category: store.category || '',
        customerId: store.internalStoreId || '',
        formulaGroup:
          store.category === StoreCategory.CR
            ? store.coldAvailabilityFormulaGroup?.name || ''
            : store.categorySoviFormulaGroup?.name || '',
        id: store.id,
        isActive: store.isActive,
        isScanned: !store.allOptimizingPaused,
        resumeDate: store.resumeOptimizingAt
          ? dayjs(store.resumeOptimizingAt).format(DateTimeConstants.MM_DD_YYYY)
          : 'n/a',
        numTimesOptimizeHighPerYear: store.numTimesOptimizeHighPerYear,
        numTimesOptimizeMediumPerYear: store.numTimesOptimizeMediumPerYear,
        numTimesOptimizeLowPerYear: store.numTimesOptimizeLowPerYear,
        timesPerWeekTwoDScan: store.timesPerWeekTwoDScan,
      })),
    [stores],
  )

  const handleMarkActive = async () => {
    await saveMultipleStores({ isActive: true })
    refetch()
  }

  const handleMarkInactive = async () => {
    await saveMultipleStores({ isActive: false })
    refetch()
  }

  const columns: DAITableColumns<StoreRow>[] = useMemo(
    () => [
      {
        field: 'store',
        headerName: 'Store',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'address',
        headerName: 'Address',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'region',
        headerName: 'Region',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'customerId',
        headerName: 'Customer Id',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'chain',
        headerName: 'Chain',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'category',
        headerName: 'Category',
        width: MuiTheme.sizes.table.column.width.sm,
      },
      {
        field: 'formulaGroup',
        headerName: 'Formula Group',
        width: MuiTheme.sizes.table.column.width.sm,
      },
      {
        field: 'isActive',
        headerName: 'Active',
        width: MuiTheme.sizes.table.column.width.sm,
      },
      {
        field: 'isScanned',
        headerName: 'Scanned',
        width: MuiTheme.sizes.table.column.width.sm,
      },
      {
        field: 'resumeDate',
        headerName: 'Resume Date',
        width: MuiTheme.sizes.table.column.width.sm,
      },
    ],
    [MuiTheme],
  )

  useEffect(() => {
    if (userStores) {
      setSelectedIds(userStores)
    }
  }, [userStores?.length])

  const handleFetchAll = async () => {
    setFetchRest(true)
  }

  const handleCancelFetchAll = () => {
    setFetchRest(false)
  }

  return {
    state: {
      modalState,
      setModalState,
      storeSearch,
      setStoreSearch,
      debouncedStoreSearchQuery,
      selectedIds,
      setSelectedIds,
      hideInactive,
      setHideInactive,
      FilterProps,
      fetching: fetchRest,
    },
    handle: {
      handleMarkActive,
      handleMarkInactive,
      handleSaveCompanyStoresUpdate,
      handlePageChange,
      handleFetchAll,
      handleCancelFetchAll,
    },
    ref: {
      tableRef,
    },
    data: {
      fetchProgress,
      isRedAdmin,
      isSalesAdmin,
      isSalesManager,
      columns,
      stores,
      storeRows,
      selectedStores: stores.filter(s => selectedIds.includes(s.id)),
      loading,
      count,
      PAGE_SIZE,
      companyStoresInput,
    },
  }
}
