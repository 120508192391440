"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDrawMapTasksMutations = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useDrawMapTasksMutations = function () {
    var createIndoorMapDrawTask = (0, client_1.useMutation)(graphql_1.CREATE_INDOOR_MAP_DRAW_TASK)[0];
    var updateIndoorMapDrawTask = (0, client_1.useMutation)(graphql_1.UPDATE_INDOOR_MAP_DRAW_TASK)[0];
    var assignDrawMapTask = (0, client_1.useMutation)(graphql_1.ASSIGN_DRAW_MAP_TASK)[0];
    var createDrawMapTaskComment = (0, client_1.useMutation)(graphql_1.CREATE_DRAW_MAP_TASK_COMMENT)[0];
    var deleteDrawMapTaskComment = (0, client_1.useMutation)(graphql_1.DELETE_DRAW_MAP_TASK_COMMENT)[0];
    var updateDrawMapTaskComment = (0, client_1.useMutation)(graphql_1.UPDATE_DRAW_MAP_TASK_COMMENT)[0];
    var createStoreMap = (0, client_1.useMutation)(graphql_1.CREATE_STORE_MAP)[0];
    return {
        createIndoorMapDrawTask: createIndoorMapDrawTask,
        updateIndoorMapDrawTask: updateIndoorMapDrawTask,
        assignDrawMapTask: assignDrawMapTask,
        deleteDrawMapTaskComment: deleteDrawMapTaskComment,
        updateDrawMapTaskComment: updateDrawMapTaskComment,
        createDrawMapTaskComment: createDrawMapTaskComment,
        createStoreMap: createStoreMap,
    };
};
exports.useDrawMapTasksMutations = useDrawMapTasksMutations;
