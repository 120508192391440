/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { GenericReportProps, ChartType } from 'reporting/admin-reporting.types'
import React from 'react'
import { BarChartReport } from 'reporting/components/BarChartReport'
import { LineChartReport } from 'reporting/components/LineChartReport'
import { TableReport } from 'reporting/components/TableReport'

export const ReportSelector: {
  select: (type: ChartType) => React.FC<GenericReportProps<string>>
} = {
  select: (type: ChartType) => {
    switch (type) {
      case 'bar-chart':
        return BarChartReport
      case 'line-chart':
        return LineChartReport
      case 'table':
        return TableReport
      default:
        throw Error(`Invalid report type given: ${type}`)
    }
  },
}
