/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import React, { useEffect } from 'react'
import {
  PageTitle,
  FullScreenLayout,
  FillContainerLoading,
  FlexBox,
} from '@dai/web-components'

import {
  GetDisplayByQrIdQuery,
  GetDisplayByQrIdQueryVariables,
  GET_DISPLAY_BY_QR_ID,
} from '@dai/graphql'

import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { ErrorIconAndDescription } from 'components/ErrorIconAndDescription'

const DisplaySummaryContainer: React.FC = () => {
  const { id: qrId } = useParams<{ id: string }>()
  const [fetchDisplayByQrId, { data, loading: loadingDisplay }] = useLazyQuery<
    GetDisplayByQrIdQuery,
    GetDisplayByQrIdQueryVariables
  >(GET_DISPLAY_BY_QR_ID, {
    variables: { qrId },
  })

  useEffect(() => {
    fetchDisplayByQrId()
  }, [])

  if (loadingDisplay) {
    return <FillContainerLoading />
  }

  return (
    <FullScreenLayout
      header={<PageTitle title="Display Summary" />}
      body={
        <Stack spacing={2}>
          {data ? (
            <>
              <Typography variant="h5">{data?.displayByQrId.name}</Typography>
              <Typography variant="h6">
                {data?.displayByQrId.store.name}
              </Typography>
              {data?.displayByQrId.images.length && (
                <img src={data?.displayByQrId.images[0].url} />
              )}
            </>
          ) : (
            <ErrorIconAndDescription subtitle="Could not find a display associated with that QR code." />
          )}
        </Stack>
      }
    />
  )
}

export default DisplaySummaryContainer
