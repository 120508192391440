/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { MuiTheme, OverflowedTypography } from '@dai/web-components'
import { DateTimeConstants } from '@dai/common'
import {
  DisplayElements,
  MandateTypes,
  PicOSPriorities_picosPriorities_results,
} from '@dai/graphql'
import dayjs from 'dayjs'
import { Theme } from '@mui/material/styles'
import { ContentCopy, DeleteOutline, Edit, MoreVert } from '@mui/icons-material'

type PicOSCardProps = {
  priority: PicOSPriorities_picosPriorities_results
  handleDelete: () => void
  handleDuplicate?: () => void
  loading?: boolean
  handleEdit?: () => void
}

const PicOSCard: React.FC<PicOSCardProps> = props => {
  const { priority, loading, handleEdit, handleDelete, handleDuplicate } = props
  const [showMoreText, setShowMoreText] = useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const onPressMoreMenu = (event: { currentTarget: any }) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const onCloseMoreMenu = () => {
    setMenuAnchorEl(null)
  }

  const isHQ = priority?.mandateType === MandateTypes.HQ
  const isAD = priority?.displayElements === DisplayElements.AD

  const styles = useMemo(() => createStyles(MuiTheme, isHQ), [isHQ])

  if (loading || !priority) {
    return (
      <Card>
        <CardHeader
          disableTypography
          title={
            <>
              <Skeleton
                variant="rounded"
                sx={theme => ({
                  width: '80%',
                  height: theme.sizes.skeleton.sm,
                  mb: `${theme.sizes.skeleton.xs}px`,
                })}
              />
              <Skeleton
                variant="rounded"
                sx={theme => ({
                  width: '60%',
                  height: theme.sizes.skeleton.sm,
                })}
              />
            </>
          }
          avatar={
            <Skeleton
              sx={theme => ({
                width: theme.sizes.skeleton.xl,
                height: theme.sizes.skeleton.xl,
                mr: 1,
              })}
              variant="rounded"
            />
          }
        />
        <Skeleton
          sx={{
            height: 200,
          }}
          variant="rectangular"
        />
        <CardContent>
          {Array.from({ length: 3 }).map((_, idx) => (
            <Skeleton
              key={`${idx}`}
              sx={theme => ({
                height: theme.sizes.skeleton.md,
                mb: `${theme.sizes.skeleton.xs}px`,
              })}
            />
          ))}
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Card sx={styles.card}>
        {showMoreText && (
          <Paper elevation={10} sx={styles.moreText}>
            <Stack spacing={2}>
              <Box minHeight={250} maxHeight={400} overflow={'scroll'}>
                <Typography variant="h6" fontWeight={'bold'}>
                  {priority.name}
                </Typography>
                <Typography variant="body2">{priority.description}</Typography>
              </Box>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => setShowMoreText(false)}
              >
                Close
              </Button>
            </Stack>
          </Paper>
        )}
        <CardHeader
          title={
            <Stack
              direction="row"
              spacing={1}
              justifyContent={'flex-end'}
              alignItems={'center'}
              pr={1}
            >
              {priority.isMarketStreetChallenge && (
                <Tooltip title="Market Street Challenge">
                  <img style={styles.img} src={'/soda_award.png'} />
                </Tooltip>
              )}
              <Chip
                label={isAD ? 'On Ad' : 'Sustain'}
                variant="outlined"
                color="tertiary"
              />
              <Chip
                label={isHQ ? 'HQM' : 'LSI'}
                color={isHQ ? 'primary' : 'secondary'}
                variant="filled"
              />
            </Stack>
          }
          action={
            <>
              <IconButton sx={{ mt: 0.5 }}>
                <MoreVert onClick={onPressMoreMenu} />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={onCloseMoreMenu}
              >
                <MenuItem onClick={handleDuplicate}>
                  <ListItemIcon>
                    <ContentCopy />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteOutline color="error" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: { color: MuiTheme.palette.error.main },
                    }}
                    primary="Delete"
                  />
                </MenuItem>
              </Menu>
            </>
          }
          avatar={
            <Avatar
              sx={theme => ({
                bgcolor: theme.palette[isHQ ? 'primary' : 'secondary'].main,
              })}
              variant="rounded"
            >
              <Typography variant="h5">{priority.priority}</Typography>
            </Avatar>
          }
        />
        <Box sx={styles.imgContainer}>
          <CardMedia
            component="img"
            image={priority?.image?.url}
            alt="priority image"
            sx={{ height: '100%', objectFit: 'contain' }}
          />
        </Box>
        <CardContent sx={{ flex: 1 }}>
          <Stack spacing={1}>
            <Typography variant="h6" fontWeight={'bold'}>
              {priority.name}
            </Typography>
            <Box>
              <Chip
                variant="outlined"
                color="tertiary"
                label={`${dayjs(priority.startDate).format(
                  DateTimeConstants.MM_DD,
                )} — ${dayjs(priority.endDate).format(
                  DateTimeConstants.MM_DD,
                )}`}
              />
            </Box>
            <OverflowedTypography
              maxLines={3}
              onViewMore={() => setShowMoreText(true)}
            >
              {priority.description}
            </OverflowedTypography>
          </Stack>
        </CardContent>
        {handleEdit && (
          <CardActions sx={{ m: 1 }}>
            <Button
              fullWidth
              variant="outlined"
              color="tertiary"
              startIcon={<Edit />}
              onClick={handleEdit}
            >
              Edit
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  )
}

export default PicOSCard

const createStyles = (theme: Theme, isHQ: boolean) => ({
  imgContainer: {
    height: 200,
    width: '100%',
    bgcolor: theme.palette.grey['50'],
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderTop: `4px solid ${
      theme.palette[isHQ ? 'primary' : 'secondary'].main
    }`,
    position: 'relative',
    justifyContent: 'center',
  },
  moreText: {
    m: 2,
    p: 2,
    zIndex: 1,
    position: 'absolute',
    alignSelf: 'center',
  },
  img: {
    width: 30,
    height: 30,
  },
})
