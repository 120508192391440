"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUrlSearchParams = exports.useUpdateOptimizationSchedule = exports.useGetStoreHistory = exports.useResumeOptimizations = exports.useStopOptimizations = exports.usePauseOptimizations = exports.useGetGroups = exports.useWindowDimensions = exports.useGetAdminAudits = exports.useAuditQuestionsMutations = exports.useGetAdminCompanyStore = exports.useGetAdminCompanyStores = exports.useGetUserStores = exports.useGetStores = exports.useGetCompanyUsers = exports.useAdminAudit = exports.useAudit = exports.useCompanyAuditQuestions = exports.useAuditQuestions = exports.useAuditQuestionAnswers = exports.useGetDrawMapTask = exports.useGetDrawMapTasks = exports.useDrawMapTasksMutations = exports.useStoreAreas = exports.useGetPhysicalStores = void 0;
var useGetPhysicalStores_1 = require("./api/useGetPhysicalStores");
Object.defineProperty(exports, "useGetPhysicalStores", { enumerable: true, get: function () { return useGetPhysicalStores_1.useGetPhysicalStores; } });
var useStoreAreas_1 = require("./api/useStoreAreas");
Object.defineProperty(exports, "useStoreAreas", { enumerable: true, get: function () { return useStoreAreas_1.useStoreAreas; } });
var useDrawMapTasksMutations_1 = require("./api/useDrawMapTasksMutations");
Object.defineProperty(exports, "useDrawMapTasksMutations", { enumerable: true, get: function () { return useDrawMapTasksMutations_1.useDrawMapTasksMutations; } });
var useGetDrawMapTasks_1 = require("./api/useGetDrawMapTasks");
Object.defineProperty(exports, "useGetDrawMapTasks", { enumerable: true, get: function () { return useGetDrawMapTasks_1.useGetDrawMapTasks; } });
var useGetDrawMapTask_1 = require("./api/useGetDrawMapTask");
Object.defineProperty(exports, "useGetDrawMapTask", { enumerable: true, get: function () { return useGetDrawMapTask_1.useGetDrawMapTask; } });
var useAuditQuestionAnswers_1 = require("./api/useAuditQuestionAnswers");
Object.defineProperty(exports, "useAuditQuestionAnswers", { enumerable: true, get: function () { return useAuditQuestionAnswers_1.useAuditQuestionAnswers; } });
var useAuditQuestions_1 = require("./api/useAuditQuestions");
Object.defineProperty(exports, "useAuditQuestions", { enumerable: true, get: function () { return useAuditQuestions_1.useAuditQuestions; } });
var useCompanyAuditQuestions_1 = require("./api/useCompanyAuditQuestions");
Object.defineProperty(exports, "useCompanyAuditQuestions", { enumerable: true, get: function () { return useCompanyAuditQuestions_1.useCompanyAuditQuestions; } });
var useAudit_1 = require("./api/useAudit");
Object.defineProperty(exports, "useAudit", { enumerable: true, get: function () { return useAudit_1.useAudit; } });
var useAdminAudit_1 = require("./api/useAdminAudit");
Object.defineProperty(exports, "useAdminAudit", { enumerable: true, get: function () { return useAdminAudit_1.useAdminAudit; } });
var useGetCompanyUsers_1 = require("./api/useGetCompanyUsers");
Object.defineProperty(exports, "useGetCompanyUsers", { enumerable: true, get: function () { return useGetCompanyUsers_1.useGetCompanyUsers; } });
var useGetStores_1 = require("./api/useGetStores");
Object.defineProperty(exports, "useGetStores", { enumerable: true, get: function () { return useGetStores_1.useGetStores; } });
var useGetUserStores_1 = require("./api/useGetUserStores");
Object.defineProperty(exports, "useGetUserStores", { enumerable: true, get: function () { return useGetUserStores_1.useGetUserStores; } });
var useGetAdminCompanyStores_1 = require("./api/useGetAdminCompanyStores");
Object.defineProperty(exports, "useGetAdminCompanyStores", { enumerable: true, get: function () { return useGetAdminCompanyStores_1.useGetAdminCompanyStores; } });
var useGetAdminCompanyStore_1 = require("./api/useGetAdminCompanyStore");
Object.defineProperty(exports, "useGetAdminCompanyStore", { enumerable: true, get: function () { return useGetAdminCompanyStore_1.useGetAdminCompanyStore; } });
var useAuditQuestionsMutations_1 = require("./api/useAuditQuestionsMutations");
Object.defineProperty(exports, "useAuditQuestionsMutations", { enumerable: true, get: function () { return useAuditQuestionsMutations_1.useAuditQuestionsMutations; } });
var useGetAdminAudits_1 = require("./api/useGetAdminAudits");
Object.defineProperty(exports, "useGetAdminAudits", { enumerable: true, get: function () { return useGetAdminAudits_1.useGetAdminAudits; } });
var useWindowDimensions_1 = require("./useWindowDimensions");
Object.defineProperty(exports, "useWindowDimensions", { enumerable: true, get: function () { return useWindowDimensions_1.useWindowDimensions; } });
var useGetGroups_1 = require("./api/useGetGroups");
Object.defineProperty(exports, "useGetGroups", { enumerable: true, get: function () { return useGetGroups_1.useGetGroups; } });
var usePauseOptimizations_1 = require("./api/usePauseOptimizations");
Object.defineProperty(exports, "usePauseOptimizations", { enumerable: true, get: function () { return usePauseOptimizations_1.usePauseOptimizations; } });
var useStopOptimizations_1 = require("./api/useStopOptimizations");
Object.defineProperty(exports, "useStopOptimizations", { enumerable: true, get: function () { return useStopOptimizations_1.useStopOptimizations; } });
var useResumeOptimizations_1 = require("./api/useResumeOptimizations");
Object.defineProperty(exports, "useResumeOptimizations", { enumerable: true, get: function () { return useResumeOptimizations_1.useResumeOptimizations; } });
var useGetStoreHistory_1 = require("./api/useGetStoreHistory");
Object.defineProperty(exports, "useGetStoreHistory", { enumerable: true, get: function () { return useGetStoreHistory_1.useGetStoreHistory; } });
var useUpdateOptimizationSchedule_1 = require("./api/useUpdateOptimizationSchedule");
Object.defineProperty(exports, "useUpdateOptimizationSchedule", { enumerable: true, get: function () { return useUpdateOptimizationSchedule_1.useUpdateOptimizationSchedule; } });
var use_url_search_params_1 = require("./use-url-search-params");
Object.defineProperty(exports, "useUrlSearchParams", { enumerable: true, get: function () { return use_url_search_params_1.useUrlSearchParams; } });
__exportStar(require("./api/useGetAdminCompanyRegions"), exports);
__exportStar(require("./api/useGetCompanyRegions"), exports);
__exportStar(require("./api/useGetCompanyRegion"), exports);
__exportStar(require("./api/useCreateRegion"), exports);
__exportStar(require("./api/useDeleteRegion"), exports);
__exportStar(require("./api/useGetCompanyStore"), exports);
__exportStar(require("./api/useCompanyFormulaGroups"), exports);
__exportStar(require("./api/useUpdateCompanyStore"), exports);
