"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUDIT_SCORE_FRAGMENT = exports.CATEGORY_SCORE_FRAGMENT = exports.SCORE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.SCORE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Score on Score {\n    numerator\n    denominator\n  }\n"], ["\n  fragment Score on Score {\n    numerator\n    denominator\n  }\n"])));
exports.CATEGORY_SCORE_FRAGMENT = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment CategoryScore on CategoryScore {\n    customerCasesCount\n    competitorCasesCount\n    category\n  }\n"], ["\n  fragment CategoryScore on CategoryScore {\n    customerCasesCount\n    competitorCasesCount\n    category\n  }\n"])));
exports.AUDIT_SCORE_FRAGMENT = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment AuditScore on AuditScore {\n    created\n    modified\n    id\n    questions {\n      ...Score\n    }\n    soviScores {\n      ...CategoryScore\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment AuditScore on AuditScore {\n    created\n    modified\n    id\n    questions {\n      ...Score\n    }\n    soviScores {\n      ...CategoryScore\n    }\n  }\n  ", "\n  ", "\n"])), exports.CATEGORY_SCORE_FRAGMENT, exports.SCORE_FRAGMENT);
var templateObject_1, templateObject_2, templateObject_3;
