"use strict";
/**
 * Copyright © 2021 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowDimensions = void 0;
var react_1 = require("react");
function getWindowDimensions() {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
}
var useWindowDimensions = function () {
    var _a = (0, react_1.useState)(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
    (0, react_1.useEffect)(function () {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowDimensions;
};
exports.useWindowDimensions = useWindowDimensions;
