"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_UNVERIFIED_MULTI_PRODUCT_IMAGES = void 0;
var client_1 = require("@apollo/client");
var MultiProductImageFragment_1 = require("../fragments/MultiProductImageFragment");
exports.GET_UNVERIFIED_MULTI_PRODUCT_IMAGES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetUnverifiedMultiProductImages {\n    unverifiedMultiProductImages {\n      results {\n        ...MultiProductImage\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"], ["\n  query GetUnverifiedMultiProductImages {\n    unverifiedMultiProductImages {\n      results {\n        ...MultiProductImage\n      }\n      hasMore\n      count\n    }\n  }\n  ", "\n"])), MultiProductImageFragment_1.MULTI_PRODUCT_IMAGE_FRAGMENT);
var templateObject_1;
