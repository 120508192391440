"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LOGGED_IN_USER_ULTRA = void 0;
var client_1 = require("@apollo/client");
exports.GET_LOGGED_IN_USER_ULTRA = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetLoggedInUser {\n    loggedInUser {\n      uuid\n      id\n      email\n      firstName\n      lastName\n      isStaff\n      isSuperuser\n      roles\n    }\n  }\n"], ["\n  query GetLoggedInUser {\n    loggedInUser {\n      uuid\n      id\n      email\n      firstName\n      lastName\n      isStaff\n      isSuperuser\n      roles\n    }\n  }\n"])));
var templateObject_1;
