/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import {
  BrandExplorerQueryQuery,
  BrandExplorerQueryQueryVariables,
  BRAND_EXPLORER,
} from '@dai/graphql'
import {
  Brand,
  MuiTheme,
  ReportLayout,
  BubbleChart,
  BubbleDatum,
} from '@dai/web-components'
import React, { useEffect, useState } from 'react'
import { BrandDisruptorQueryProps } from 'brands/brand-disruptor/brand-disruptor.types'
import { BrandSelector } from './BrandSelector'

export const BrandExplorer: React.FC<BrandDisruptorQueryProps> = props => {
  const {
    startDate,
    endDate,
    dateRangeType,
    selectedBrands,
    setSelectedBrands,
    moreInfo,
  } = props

  const { data: brandExplorer, error, loading } = useQuery<
    BrandExplorerQueryQuery,
    BrandExplorerQueryQueryVariables
  >(BRAND_EXPLORER, {
    variables: {
      input: {
        startDate,
        endDate,
        type: dateRangeType,
      },
    },
    fetchPolicy: 'cache-first',
  })

  const [brandData, setBrandData] = useState<BubbleDatum[]>([])

  useEffect(() => {
    if (brandExplorer) {
      const dataList: BubbleDatum[] = brandExplorer?.brandExplorer.brandInfo.map(
        info => {
          const selected = selectedBrands
            .map(brand => brand.name)
            .includes(info.brand.name)
          return {
            title: info.brand.name,
            id: info.brand.id,
            color: selected ? MuiTheme.palette.success.main : undefined,
            x: info.soviPercentChange,
            y: info.numStores,
            value: info.sovi,
          }
        },
      )
      setBrandData(dataList)
    }
  }, [brandExplorer, selectedBrands, error])

  const handleBubbleClicked = (data: BubbleDatum) => {
    const selectedIds = selectedBrands.map(b => b.id)
    if (selectedIds.includes(data.id)) {
      setSelectedBrands(selectedBrands.filter(b => b.id != data.id))
    } else {
      setSelectedBrands([...selectedBrands, { id: data.id, name: data.title }])
    }
  }

  return (
    <ReportLayout
      title={'Brand Explorer'}
      subtitle={'Non-Core Brands'}
      moreInfo={moreInfo}
      Body={
        <BubbleChart
          isLoading={loading}
          isEmpty={!brandExplorer?.brandExplorer.brandInfo.length}
          chartId="bubble"
          data={brandData}
          xAxisLabel="Change in SOVI"
          yAxisLabel="Number of Stores"
          smallNumberPrefixes={[
            { number: 1.0 / 100, suffix: 'bps' },
            { number: 1.0 / 10000, suffix: 'cbps' },
            { number: 1.0 / 100000, suffix: 'mbps' },
            { number: 1.0 / 1000000, suffix: 'nbps' },
          ]}
          tooltip={[
            {
              text: '{title}',
              bold: true,
              newline: true,
            },
            {
              text: "Number of Stores: {valueY.formatNumber('#')}",
              newline: true,
            },
            {
              text: 'Change in SOVI: {valueX.formatNumber()}',
              newline: true,
            },
            {
              text: 'Current SOVI (14d): {value.formatNumber()}',
            },
          ]}
          onClick={handleBubbleClicked}
        />
      }
    />
  )
}
