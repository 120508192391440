"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FULL_STORE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var DisplayFragment_1 = require("./DisplayFragment");
var StoreAddressFragment_1 = require("./StoreAddressFragment");
exports.FULL_STORE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment FullStore on CompanyStore {\n    id\n    name\n    isActive\n    distance\n    daysSinceAudit\n    category\n    physicalStoreId\n    internalStoreId\n    hasAllFormulas\n    image {\n      url\n      id\n    }\n    address {\n      ...StoreAddress\n    }\n    point {\n      latitude\n      longitude\n    }\n    storeMap {\n      id\n      geoJson\n    }\n    storeChain\n    displays {\n      ...Display\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment FullStore on CompanyStore {\n    id\n    name\n    isActive\n    distance\n    daysSinceAudit\n    category\n    physicalStoreId\n    internalStoreId\n    hasAllFormulas\n    image {\n      url\n      id\n    }\n    address {\n      ...StoreAddress\n    }\n    point {\n      latitude\n      longitude\n    }\n    storeMap {\n      id\n      geoJson\n    }\n    storeChain\n    displays {\n      ...Display\n    }\n  }\n  ", "\n  ", "\n"])), DisplayFragment_1.DISPLAY_FRAGMENT, StoreAddressFragment_1.STORE_ADDRESS_FRAGMENT);
var templateObject_1;
