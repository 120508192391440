/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import {
  MuiFormModal,
  MuiFormModalProps,
  MuiTheme,
  StringHelpers,
} from '@dai/web-components'
import React, { useState } from 'react'
import { Close, Pause } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { DatePicker } from '@mui/x-date-pickers'
import { StoreRow } from 'stores/stores.types'
import { Box, Typography, useTheme, TextField } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { DateTimeConstants, usePauseOptimizations } from '@dai/common'
import { AdminCompanyStoresInput } from '@dai/graphql'
import { StoreRowHelpers } from 'stores/helpers/store-row.helpers'
import { CommaSeparatedStoresText } from '../CommaSeparatedStoresText'

interface PauseScansModalProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  stores: StoreRow[]
  companyStoresInput: AdminCompanyStoresInput
}

const PauseScansText: React.FC<{
  stores: StoreRow[]
  resumeDate: Dayjs | null
}> = props => {
  const { stores, resumeDate } = props

  const numStopped = StoreRowHelpers.numStopped(stores)
  const numPaused = StoreRowHelpers.numPaused(stores)
  const numScanned = StoreRowHelpers.numScanned(stores)

  if (numScanned > 0) {
    return (
      <Typography>
        {
          'This will pause all optimization and compliance scans for all coolers until '
        }
        <strong>
          {resumeDate?.format(DateTimeConstants.MMMM_D_YYYY) || '...'}
        </strong>{' '}
        in <strong>{numScanned} scanned </strong>
        {StringHelpers.plurality('store', numScanned)}
        {numPaused > 0 || numStopped > 0 ? (
          <>
            {', and update the resume date on '}
            {<CommaSeparatedStoresText stores={stores} scanned={false} />}
          </>
        ) : null}
        .
      </Typography>
    )
  }

  return (
    <Typography>
      {'This will update the resume date on '}
      {<CommaSeparatedStoresText stores={stores} scanned={false} />}
      {' to be '}
      <strong>
        {resumeDate?.format(DateTimeConstants.MMMM_D_YYYY) || '...'}
      </strong>
      .
    </Typography>
  )
}

export const PauseScansModal: React.FC<PauseScansModalProps> = props => {
  const theme = useTheme()
  const { isOpen, handleClose, stores, companyStoresInput } = props
  const { pauseOptimizations } = usePauseOptimizations(companyStoresInput)
  const [resumeDate, setResumeDate] = useState<Dayjs | null>(
    dayjs(new Date()).add(3, 'days'),
  )

  return (
    <MuiFormModal
      title={`Pause Scans Until ${
        resumeDate?.format(DateTimeConstants.MM_DD_YYYY) || '...'
      }${stores.length > 1 ? '' : ` (${stores.length} Stores)`}`}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          color: 'secondary',
          startIcon: <Close color="secondary" />,
        },
        {
          onClick: () => {
            pauseOptimizations({
              input: { storeIds: stores.map(store => store.id), resumeDate },
            })
            handleClose()
          },
          children: 'Pause Scans',
          startIcon: <Pause color="secondary" />,
        },
      ]}
    >
      <Box display="flex" flexDirection={'column'} gap={theme.spacing(2)}>
        <PauseScansText stores={stores} resumeDate={resumeDate} />
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <DatePicker
            label={'Resume Date'}
            value={resumeDate}
            onChange={setResumeDate}
            minDate={dayjs(new Date()).add(1, 'day')}
            maxDate={dayjs(new Date()).add(4, 'weeks')}
            renderInput={params => <TextField {...params} />}
            PopperProps={{
              sx: {
                '& .MuiPickersDay-dayWithMargin': {
                  color: MuiTheme.palette.primary.dark,
                  backgroundColor: grey[300],
                },
                '& .Mui-disabled.MuiPickersDay-dayWithMargin': {
                  color: MuiTheme.palette.primary.dark,
                  backgroundColor: MuiTheme.palette.primary.light,
                },
              },
            }}
          />
        </Box>
      </Box>
    </MuiFormModal>
  )
}
