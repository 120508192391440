/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import { Delete, ExpandMore } from '@mui/icons-material'
import { FlexBox, StringHelpers } from '@dai/web-components'
import { DateTimeHelpers } from '@dai/common'
import { CachedPicosPriority } from 'picos/helpers/picos.helpers'
import { Edit } from 'react-feather'

type PicOSDraftsAccordionProps = {
  draftsPicos: CachedPicosPriority[]
  onDeleteClick: (picosId: string) => void
  onSelectToEditClick: (picosId: string) => void
} & Omit<AccordionProps, 'children'>

const PicOSDraftsAccordion: React.FC<PicOSDraftsAccordionProps> = (
  props: PicOSDraftsAccordionProps,
) => {
  const { draftsPicos, onDeleteClick, onSelectToEditClick, ...rest } = props
  return (
    <Accordion {...rest}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`${
          draftsPicos.length
        } incomplete ${StringHelpers.plurality(
          'draft',
          draftsPicos.length,
        )}`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {draftsPicos.map(item => {
            return (
              <ListItem
                key={item.id}
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderColor: theme => theme.palette.grey[500],
                }}
              >
                <Stack direction="row" spacing={3}>
                  <Typography variant="body1">
                    {item.picosPriority.name}
                  </Typography>
                  <FlexBox.RowCenter>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'end' }}
                    >
                      <Typography
                        variant="body2"
                        color={theme => theme.palette.grey[500]}
                      >
                        Last Edited
                      </Typography>
                      <Typography>
                        {DateTimeHelpers.getFormattedDateStringDot(
                          new Date(item.lastModified),
                        )}
                      </Typography>
                    </Stack>
                  </FlexBox.RowCenter>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Button
                    onClick={() => onSelectToEditClick(item.id)}
                    startIcon={<Edit />}
                    color={'secondary'}
                    variant={'outlined'}
                  >
                    Continue Editing
                  </Button>
                  <IconButton onClick={() => onDeleteClick(item.id)}>
                    <Delete color={'error'} />
                  </IconButton>
                </Stack>
              </ListItem>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default PicOSDraftsAccordion
