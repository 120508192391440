"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOUNDING_CUBE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var PointFragment_1 = require("./PointFragment");
exports.BOUNDING_CUBE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment BoundingCube on BoundingCube {\n    x1 {\n      ...Point\n    }\n    x2 {\n      ...Point\n    }\n    y1 {\n      ...Point\n    }\n    z1 {\n      ...Point\n    }\n    width\n    height\n    depth\n  }\n  ", "\n"], ["\n  fragment BoundingCube on BoundingCube {\n    x1 {\n      ...Point\n    }\n    x2 {\n      ...Point\n    }\n    y1 {\n      ...Point\n    }\n    z1 {\n      ...Point\n    }\n    width\n    height\n    depth\n  }\n  ", "\n"])), PointFragment_1.POINT_FRAGMENT);
var templateObject_1;
