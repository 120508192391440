"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SINGLE_PRODUCT_POINT_CLOUDS = void 0;
var client_1 = require("@apollo/client");
var SingleProductPointCloud_1 = require("../fragments/SingleProductPointCloud");
exports.GET_SINGLE_PRODUCT_POINT_CLOUDS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetSingleProductPointClouds($input: SingleProductPointCloudsInput!) {\n    singleProductPointClouds(input: $input) {\n      count\n      hasMore\n      results {\n        ...SingleProductPointCloud\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetSingleProductPointClouds($input: SingleProductPointCloudsInput!) {\n    singleProductPointClouds(input: $input) {\n      count\n      hasMore\n      results {\n        ...SingleProductPointCloud\n      }\n    }\n  }\n  ", "\n"])), SingleProductPointCloud_1.SINGLE_PRODUCT_POINT_CLOUD_FRAGMENT);
var templateObject_1;
