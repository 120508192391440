"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateRegion = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var utils_1 = require("../../utils");
var updateHandler = function (input) {
    var companyId = input.companyId;
    return function (cache, _a) {
        var _b;
        var data = _a.data;
        if (!((_b = data === null || data === void 0 ? void 0 : data.createRegion) === null || _b === void 0 ? void 0 : _b.region)) {
            return;
        }
        var region = data === null || data === void 0 ? void 0 : data.createRegion.region;
        try {
            var cachedData = cache.readQuery({ query: graphql_1.GET_COMPANY_REGIONS, variables: { companyId: companyId } });
            var allRegions = (cachedData === null || cachedData === void 0 ? void 0 : cachedData.companyRegions) || [];
            cache.writeQuery({
                query: graphql_1.GET_COMPANY_REGIONS,
                variables: { companyId: companyId },
                data: { companyRegions: __spreadArray(__spreadArray([], allRegions, true), [region], false) },
            });
        }
        catch (error) {
            console.error(error);
        }
    };
};
var useCreateRegion = function () {
    var _a = (0, client_1.useMutation)(graphql_1.CREATE_REGION), _createRegion = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var createRegion = function (input) {
        var id = String(input.regionId || (0, utils_1.uniqueId)());
        _createRegion({
            variables: {
                input: input,
            },
            optimisticResponse: {
                createRegion: {
                    __typename: 'CreateRegion',
                    region: __assign(__assign({}, input), { __typename: 'Region', id: id, parentId: input.parentId ? String(input.parentId) : null, users: [], stores: [] }),
                },
            },
            update: updateHandler(__assign({ regionId: id }, input)),
        });
    };
    return {
        createRegion: createRegion,
        loading: loading,
        error: error,
    };
};
exports.useCreateRegion = useCreateRegion;
