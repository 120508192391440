"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MLModelType = exports.LocalVersionColumns = exports.LocalShippingColumns = exports.LocalProductFlavorRelationColumns = exports.LocalProductColumns = exports.LocalPackagingColumns = exports.LocalFlavorColumns = exports.LocalCompanyCategoryColumns = exports.IssueType = exports.InventoryTransactionMemo = exports.InventoryProductInventoryTransactionMemoChoices = exports.ImageType = exports.GroupTypes = exports.ForStoreSize = exports.FlaggedType = exports.FlaggedLevel = exports.FlagModelType = exports.FlagAppType = exports.ErrorLevel = exports.DoorHandlePlacement = exports.DisplaysDisplayAreaOldChoices = exports.DisplaysAssociatedStoreTypesCategoryChoices = exports.DisplayTypeAppRule = exports.DisplayTaskType = exports.DisplaySalesVolume = exports.DisplayElements = exports.DisplayActionType = exports.DeviceType = exports.DateRangeType = exports.DAIGroupEnum = exports.CompliancePlanogramTypeChoices = exports.CompliancePlanogramProductOrientationChoices = exports.ComplianceIssue = exports.CompanyUsersSortBy = exports.CompanyStoreSortBy = exports.CompanyStoreFilterOptions = exports.CommonStatusStatusTypeChoices = exports.CommonStatusStatusChoices = exports.CommonFlaggedReasonTypeChoices = exports.CommonFlaggedReasonOptionTypeChoices = exports.BadgeType = exports.BadgeGraphicType = exports.AuditsOrderBy = exports.AuditsAuditQuestionCategoryChoices = exports.AssignOrCreatedUserTask = exports.AppMLModelType = exports.AiMLModelModelTypeChoices = exports.AggregationMethod = exports.AggregateBy = exports.AdminPortalAuditsOrderBy = void 0;
exports.ZoneTier = exports.VerifiedLocation = exports.VerificationDecision = exports.UsersFCMTokenDeviceTypeChoices = exports.UserProductivityModelOptions = exports.UploadStatus = exports.StoresStoreTimezoneChoices = exports.StoresStoreCategoryChoices = exports.StoreTaskSortBy = exports.StoreTaskFilterOptions = exports.StoreOrderBy = exports.StoreFilterOut = exports.StoreFilter = exports.StoreDisplaysSortBy = exports.StoreDisplaysFilterOptions = exports.StoreCategory = exports.StoreActionType = exports.SortIssueDirection = exports.SortIssueBy = exports.SortByDirection = exports.SortBy = exports.SimpleUserProductivityModelOptions = exports.SceneTypeEnum = exports.ScanType = exports.ScanPurpose = exports.RestockAdjustmentType = exports.ProductsProductSourceChoices = exports.ProductsPackagingUnitChoices = exports.ProductsPackagingContainerChoices = exports.ProductTypeEnum = exports.ProductSortBy = exports.PlanogramStatus = exports.PlanogramProductOrientation = exports.Period = exports.PerformanceReportFilter = exports.PackagingUnits = exports.PackagingType = exports.OrderByDirection = exports.MerchandisingState = exports.MerchandisingAction = exports.MerchandiserScanType = exports.MapsStoreAreaDefaultForStoreSizeChoices = exports.MandateTypes = exports.MLPurpose = void 0;
var AdminPortalAuditsOrderBy;
(function (AdminPortalAuditsOrderBy) {
    AdminPortalAuditsOrderBy["DATE"] = "DATE";
    AdminPortalAuditsOrderBy["EMPLOYEE"] = "EMPLOYEE";
    AdminPortalAuditsOrderBy["SCORE"] = "SCORE";
    AdminPortalAuditsOrderBy["STORE"] = "STORE";
})(AdminPortalAuditsOrderBy = exports.AdminPortalAuditsOrderBy || (exports.AdminPortalAuditsOrderBy = {}));
/** An enumeration. */
var AggregateBy;
(function (AggregateBy) {
    AggregateBy["ALL_TIME"] = "ALL_TIME";
    AggregateBy["DAY"] = "DAY";
    AggregateBy["MONTH"] = "MONTH";
    AggregateBy["QUARTER"] = "QUARTER";
    AggregateBy["WEEK"] = "WEEK";
    AggregateBy["YEAR"] = "YEAR";
})(AggregateBy = exports.AggregateBy || (exports.AggregateBy = {}));
/** An enumeration. */
var AggregationMethod;
(function (AggregationMethod) {
    AggregationMethod["AVG"] = "AVG";
    AggregationMethod["COUNT"] = "COUNT";
    AggregationMethod["MEDIAN"] = "MEDIAN";
    AggregationMethod["SUM"] = "SUM";
})(AggregationMethod = exports.AggregationMethod || (exports.AggregationMethod = {}));
/** An enumeration. */
var AiMLModelModelTypeChoices;
(function (AiMLModelModelTypeChoices) {
    /** Bev */
    AiMLModelModelTypeChoices["BEV"] = "BEV";
    /** Binary */
    AiMLModelModelTypeChoices["BINARY"] = "BINARY";
    /** DaiZero */
    AiMLModelModelTypeChoices["DAI_ZERO"] = "DAI_ZERO";
    /** DensityNeonet */
    AiMLModelModelTypeChoices["DENSITY_NEONET"] = "DENSITY_NEONET";
    /** Meta */
    AiMLModelModelTypeChoices["META"] = "META";
    /** Neonet */
    AiMLModelModelTypeChoices["NEONET"] = "NEONET";
    /** PointBeamScorer */
    AiMLModelModelTypeChoices["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(AiMLModelModelTypeChoices = exports.AiMLModelModelTypeChoices || (exports.AiMLModelModelTypeChoices = {}));
/**
 * To simplify the frontend, we'll just expose the main model types.
 * The backend will serve up the correct type (Audit/Merch) based on user group
 */
var AppMLModelType;
(function (AppMLModelType) {
    AppMLModelType["BEV"] = "BEV";
    AppMLModelType["BINARY"] = "BINARY";
    AppMLModelType["DAI_ZERO"] = "DAI_ZERO";
    AppMLModelType["DENSITY_NEONET"] = "DENSITY_NEONET";
    AppMLModelType["META"] = "META";
    AppMLModelType["NEONET"] = "NEONET";
    AppMLModelType["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(AppMLModelType = exports.AppMLModelType || (exports.AppMLModelType = {}));
/** An enumeration. */
var AssignOrCreatedUserTask;
(function (AssignOrCreatedUserTask) {
    AssignOrCreatedUserTask["ASSIGNED"] = "ASSIGNED";
    AssignOrCreatedUserTask["CREATED"] = "CREATED";
})(AssignOrCreatedUserTask = exports.AssignOrCreatedUserTask || (exports.AssignOrCreatedUserTask = {}));
/** An enumeration. */
var AuditsAuditQuestionCategoryChoices;
(function (AuditsAuditQuestionCategoryChoices) {
    /** all */
    AuditsAuditQuestionCategoryChoices["AL"] = "AL";
    /** small */
    AuditsAuditQuestionCategoryChoices["CR"] = "CR";
    /** drug */
    AuditsAuditQuestionCategoryChoices["DR"] = "DR";
    /** home improvement */
    AuditsAuditQuestionCategoryChoices["HM"] = "HM";
    /** large */
    AuditsAuditQuestionCategoryChoices["LG"] = "LG";
    /** value */
    AuditsAuditQuestionCategoryChoices["VL"] = "VL";
})(AuditsAuditQuestionCategoryChoices = exports.AuditsAuditQuestionCategoryChoices || (exports.AuditsAuditQuestionCategoryChoices = {}));
var AuditsOrderBy;
(function (AuditsOrderBy) {
    AuditsOrderBy["COMPLETED"] = "COMPLETED";
    AuditsOrderBy["DATE"] = "DATE";
    AuditsOrderBy["STORE"] = "STORE";
})(AuditsOrderBy = exports.AuditsOrderBy || (exports.AuditsOrderBy = {}));
/** An enumeration. */
var BadgeGraphicType;
(function (BadgeGraphicType) {
    BadgeGraphicType["LG"] = "LG";
    BadgeGraphicType["LK"] = "LK";
    BadgeGraphicType["SM"] = "SM";
})(BadgeGraphicType = exports.BadgeGraphicType || (exports.BadgeGraphicType = {}));
/** An enumeration. */
var BadgeType;
(function (BadgeType) {
    BadgeType["COMPLIANCE"] = "COMPLIANCE";
    BadgeType["COVERAGE"] = "COVERAGE";
    BadgeType["CROWN"] = "CROWN";
    BadgeType["MILESTONE"] = "MILESTONE";
    BadgeType["TRAINING"] = "TRAINING";
})(BadgeType = exports.BadgeType || (exports.BadgeType = {}));
/** An enumeration. */
var CommonFlaggedReasonOptionTypeChoices;
(function (CommonFlaggedReasonOptionTypeChoices) {
    /** Common */
    CommonFlaggedReasonOptionTypeChoices["COMMON"] = "COMMON";
    /** Image */
    CommonFlaggedReasonOptionTypeChoices["IMAGE"] = "IMAGE";
    /** Planogram Segment */
    CommonFlaggedReasonOptionTypeChoices["PLAN_SEG"] = "PLAN_SEG";
    /** Product */
    CommonFlaggedReasonOptionTypeChoices["PRODUCT"] = "PRODUCT";
    /** Store Map */
    CommonFlaggedReasonOptionTypeChoices["STORE_MAP"] = "STORE_MAP";
})(CommonFlaggedReasonOptionTypeChoices = exports.CommonFlaggedReasonOptionTypeChoices || (exports.CommonFlaggedReasonOptionTypeChoices = {}));
/** An enumeration. */
var CommonFlaggedReasonTypeChoices;
(function (CommonFlaggedReasonTypeChoices) {
    /** Common */
    CommonFlaggedReasonTypeChoices["COMMON"] = "COMMON";
    /** Image */
    CommonFlaggedReasonTypeChoices["IMAGE"] = "IMAGE";
    /** Planogram Segment */
    CommonFlaggedReasonTypeChoices["PLAN_SEG"] = "PLAN_SEG";
    /** Product */
    CommonFlaggedReasonTypeChoices["PRODUCT"] = "PRODUCT";
    /** Store Map */
    CommonFlaggedReasonTypeChoices["STORE_MAP"] = "STORE_MAP";
})(CommonFlaggedReasonTypeChoices = exports.CommonFlaggedReasonTypeChoices || (exports.CommonFlaggedReasonTypeChoices = {}));
/** An enumeration. */
var CommonStatusStatusChoices;
(function (CommonStatusStatusChoices) {
    /** Complete */
    CommonStatusStatusChoices["COMPLETE"] = "COMPLETE";
    /** Failed */
    CommonStatusStatusChoices["FAILED"] = "FAILED";
    /** Pending */
    CommonStatusStatusChoices["PENDING"] = "PENDING";
})(CommonStatusStatusChoices = exports.CommonStatusStatusChoices || (exports.CommonStatusStatusChoices = {}));
/** An enumeration. */
var CommonStatusStatusTypeChoices;
(function (CommonStatusStatusTypeChoices) {
    /** Product Health Score Set */
    CommonStatusStatusTypeChoices["PRODUCT_HEALTH_SCORE_SET"] = "PRODUCT_HEALTH_SCORE_SET";
    /** Upload Stores */
    CommonStatusStatusTypeChoices["UPLOAD_STORES"] = "UPLOAD_STORES";
})(CommonStatusStatusTypeChoices = exports.CommonStatusStatusTypeChoices || (exports.CommonStatusStatusTypeChoices = {}));
var CompanyStoreFilterOptions;
(function (CompanyStoreFilterOptions) {
    CompanyStoreFilterOptions["CONVENIENCE_RETAIL"] = "CONVENIENCE_RETAIL";
    CompanyStoreFilterOptions["DRUG"] = "DRUG";
    CompanyStoreFilterOptions["HOME_IMPROVEMENT"] = "HOME_IMPROVEMENT";
    CompanyStoreFilterOptions["LARGE_STORE"] = "LARGE_STORE";
    CompanyStoreFilterOptions["MY_STORES"] = "MY_STORES";
    CompanyStoreFilterOptions["NEEDS_AUDIT"] = "NEEDS_AUDIT";
    CompanyStoreFilterOptions["NEVER_AUDITED"] = "NEVER_AUDITED";
    CompanyStoreFilterOptions["VALUE"] = "VALUE";
})(CompanyStoreFilterOptions = exports.CompanyStoreFilterOptions || (exports.CompanyStoreFilterOptions = {}));
var CompanyStoreSortBy;
(function (CompanyStoreSortBy) {
    CompanyStoreSortBy["ALPHABETICAL_REVERSE"] = "ALPHABETICAL_REVERSE";
    CompanyStoreSortBy["ALPHABETICAL_STANDARD"] = "ALPHABETICAL_STANDARD";
    CompanyStoreSortBy["AUDITED_NEW_TO_OLD"] = "AUDITED_NEW_TO_OLD";
    CompanyStoreSortBy["AUDITED_OLD_TO_NEW"] = "AUDITED_OLD_TO_NEW";
    CompanyStoreSortBy["LOCATION_CLOSE_TO_FAR"] = "LOCATION_CLOSE_TO_FAR";
    CompanyStoreSortBy["LOCATION_FAR_TO_CLOSE"] = "LOCATION_FAR_TO_CLOSE";
})(CompanyStoreSortBy = exports.CompanyStoreSortBy || (exports.CompanyStoreSortBy = {}));
var CompanyUsersSortBy;
(function (CompanyUsersSortBy) {
    CompanyUsersSortBy["ALPHABETICAL_REVERSE"] = "ALPHABETICAL_REVERSE";
    CompanyUsersSortBy["ALPHABETICAL_STANDARD"] = "ALPHABETICAL_STANDARD";
})(CompanyUsersSortBy = exports.CompanyUsersSortBy || (exports.CompanyUsersSortBy = {}));
/** An enumeration. */
var ComplianceIssue;
(function (ComplianceIssue) {
    ComplianceIssue["EMPTY_SLOTS"] = "EMPTY_SLOTS";
    ComplianceIssue["ENCROACHED"] = "ENCROACHED";
    ComplianceIssue["EXTRA_FACINGS"] = "EXTRA_FACINGS";
    ComplianceIssue["INCORRECT_LOCATION"] = "INCORRECT_LOCATION";
    ComplianceIssue["INCORRECT_ORIENTATION"] = "INCORRECT_ORIENTATION";
    ComplianceIssue["INCORRECT_PRODUCT"] = "INCORRECT_PRODUCT";
    ComplianceIssue["MIXED_FACING"] = "MIXED_FACING";
    ComplianceIssue["OUT_OF_STOCK"] = "OUT_OF_STOCK";
})(ComplianceIssue = exports.ComplianceIssue || (exports.ComplianceIssue = {}));
/** An enumeration. */
var CompliancePlanogramProductOrientationChoices;
(function (CompliancePlanogramProductOrientationChoices) {
    /** Front */
    CompliancePlanogramProductOrientationChoices["FRONT"] = "FRONT";
    /** Side */
    CompliancePlanogramProductOrientationChoices["SIDE"] = "SIDE";
    /** Top */
    CompliancePlanogramProductOrientationChoices["TOP"] = "TOP";
})(CompliancePlanogramProductOrientationChoices = exports.CompliancePlanogramProductOrientationChoices || (exports.CompliancePlanogramProductOrientationChoices = {}));
/** An enumeration. */
var CompliancePlanogramTypeChoices;
(function (CompliancePlanogramTypeChoices) {
    /** Ideal Inventory State */
    CompliancePlanogramTypeChoices["IDEAL_INVENTORY"] = "IDEAL_INVENTORY";
    /** Modular Compliance */
    CompliancePlanogramTypeChoices["MODCOM"] = "MODCOM";
})(CompliancePlanogramTypeChoices = exports.CompliancePlanogramTypeChoices || (exports.CompliancePlanogramTypeChoices = {}));
/** An enumeration. */
var DAIGroupEnum;
(function (DAIGroupEnum) {
    DAIGroupEnum["AUDITORS"] = "AUDITORS";
    DAIGroupEnum["BRAND_AGENTS"] = "BRAND_AGENTS";
    DAIGroupEnum["COMPANY_ADMINS"] = "COMPANY_ADMINS";
    DAIGroupEnum["IMAGE_LABELERS"] = "IMAGE_LABELERS";
    DAIGroupEnum["IMAGE_REVIEWERS"] = "IMAGE_REVIEWERS";
    DAIGroupEnum["LABELERS"] = "LABELERS";
    DAIGroupEnum["MAPPERS"] = "MAPPERS";
    DAIGroupEnum["MAPPER_ADMINS"] = "MAPPER_ADMINS";
    DAIGroupEnum["MERCHANDISERS"] = "MERCHANDISERS";
    DAIGroupEnum["MERCH_MANAGERS"] = "MERCH_MANAGERS";
    DAIGroupEnum["PICOS"] = "PICOS";
    DAIGroupEnum["PLAN_COM"] = "PLAN_COM";
    DAIGroupEnum["PLAN_COM_AUDITOR"] = "PLAN_COM_AUDITOR";
    DAIGroupEnum["POINT_CLOUD_VERIFIERS"] = "POINT_CLOUD_VERIFIERS";
    DAIGroupEnum["QA"] = "QA";
    DAIGroupEnum["RED_ADMINS"] = "RED_ADMINS";
    DAIGroupEnum["RED_MANAGERS"] = "RED_MANAGERS";
    DAIGroupEnum["REPORT_VIEWERS"] = "REPORT_VIEWERS";
    DAIGroupEnum["SALES"] = "SALES";
    DAIGroupEnum["SALES_ADMINS"] = "SALES_ADMINS";
    DAIGroupEnum["SALES_MANAGERS"] = "SALES_MANAGERS";
    DAIGroupEnum["STORE_MAP_SCANNERS"] = "STORE_MAP_SCANNERS";
})(DAIGroupEnum = exports.DAIGroupEnum || (exports.DAIGroupEnum = {}));
var DateRangeType;
(function (DateRangeType) {
    DateRangeType["NOW_VS_PAST"] = "NOW_VS_PAST";
    DateRangeType["YoY"] = "YoY";
})(DateRangeType = exports.DateRangeType || (exports.DateRangeType = {}));
/** An enumeration. */
var DeviceType;
(function (DeviceType) {
    DeviceType["MOBILE"] = "MOBILE";
    DeviceType["TABLET"] = "TABLET";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
/** An enumeration. */
var DisplayActionType;
(function (DisplayActionType) {
    DisplayActionType["CREATED"] = "CREATED";
    DisplayActionType["EDITED"] = "EDITED";
    DisplayActionType["MOVED"] = "MOVED";
    DisplayActionType["RESTOCK"] = "RESTOCK";
    DisplayActionType["RETIRED"] = "RETIRED";
    DisplayActionType["SET_PLAN"] = "SET_PLAN";
    DisplayActionType["THREE_D"] = "THREE_D";
    DisplayActionType["TWO_D"] = "TWO_D";
})(DisplayActionType = exports.DisplayActionType || (exports.DisplayActionType = {}));
/** An enumeration. */
var DisplayElements;
(function (DisplayElements) {
    DisplayElements["AD"] = "AD";
    DisplayElements["SUSTAIN"] = "SUSTAIN";
})(DisplayElements = exports.DisplayElements || (exports.DisplayElements = {}));
/** An enumeration. */
var DisplaySalesVolume;
(function (DisplaySalesVolume) {
    DisplaySalesVolume["HIGH"] = "HIGH";
    DisplaySalesVolume["LOW"] = "LOW";
    DisplaySalesVolume["MEDIUM"] = "MEDIUM";
})(DisplaySalesVolume = exports.DisplaySalesVolume || (exports.DisplaySalesVolume = {}));
/** An enumeration. */
var DisplayTaskType;
(function (DisplayTaskType) {
    DisplayTaskType["ADD_DISPLAY"] = "ADD_DISPLAY";
    DisplayTaskType["MOVE_DISPLAY"] = "MOVE_DISPLAY";
    DisplayTaskType["RESTOCK_DISPLAY"] = "RESTOCK_DISPLAY";
    DisplayTaskType["RETIRE_DISPLAY"] = "RETIRE_DISPLAY";
})(DisplayTaskType = exports.DisplayTaskType || (exports.DisplayTaskType = {}));
/** An enumeration. */
var DisplayTypeAppRule;
(function (DisplayTypeAppRule) {
    DisplayTypeAppRule["CAN_SET_DEPTH"] = "CAN_SET_DEPTH";
    DisplayTypeAppRule["COOLER"] = "COOLER";
    DisplayTypeAppRule["EXCLUDE_PRESET_CUBE"] = "EXCLUDE_PRESET_CUBE";
    DisplayTypeAppRule["HAS_COOLER_DOORS"] = "HAS_COOLER_DOORS";
    DisplayTypeAppRule["HAS_DOOR_HANDLE"] = "HAS_DOOR_HANDLE";
    DisplayTypeAppRule["SLOTTED"] = "SLOTTED";
})(DisplayTypeAppRule = exports.DisplayTypeAppRule || (exports.DisplayTypeAppRule = {}));
/** An enumeration. */
var DisplaysAssociatedStoreTypesCategoryChoices;
(function (DisplaysAssociatedStoreTypesCategoryChoices) {
    /** Convenience Retail */
    DisplaysAssociatedStoreTypesCategoryChoices["CR"] = "CR";
    /** Drug */
    DisplaysAssociatedStoreTypesCategoryChoices["DR"] = "DR";
    /** Home Improvement */
    DisplaysAssociatedStoreTypesCategoryChoices["HM"] = "HM";
    /** Large */
    DisplaysAssociatedStoreTypesCategoryChoices["LG"] = "LG";
    /** Other */
    DisplaysAssociatedStoreTypesCategoryChoices["OTHER"] = "OTHER";
    /** Value */
    DisplaysAssociatedStoreTypesCategoryChoices["VL"] = "VL";
})(DisplaysAssociatedStoreTypesCategoryChoices = exports.DisplaysAssociatedStoreTypesCategoryChoices || (exports.DisplaysAssociatedStoreTypesCategoryChoices = {}));
/** An enumeration. */
var DisplaysDisplayAreaOldChoices;
(function (DisplaysDisplayAreaOldChoices) {
    /** Back Alley */
    DisplaysDisplayAreaOldChoices["BA"] = "BA";
    /** Bakery */
    DisplaysDisplayAreaOldChoices["BK"] = "BK";
    /** Deli */
    DisplaysDisplayAreaOldChoices["DL"] = "DL";
    /** Entrance */
    DisplaysDisplayAreaOldChoices["EN"] = "EN";
    /** Lobby */
    DisplaysDisplayAreaOldChoices["LO"] = "LO";
    /** Produce */
    DisplaysDisplayAreaOldChoices["PR"] = "PR";
    /** Secondary Floor */
    DisplaysDisplayAreaOldChoices["SF"] = "SF";
    /** Unknown */
    DisplaysDisplayAreaOldChoices["UN"] = "UN";
})(DisplaysDisplayAreaOldChoices = exports.DisplaysDisplayAreaOldChoices || (exports.DisplaysDisplayAreaOldChoices = {}));
/** An enumeration. */
var DoorHandlePlacement;
(function (DoorHandlePlacement) {
    DoorHandlePlacement["LEFT"] = "LEFT";
    DoorHandlePlacement["MIDDLE"] = "MIDDLE";
    DoorHandlePlacement["RIGHT"] = "RIGHT";
    DoorHandlePlacement["SLIDING"] = "SLIDING";
})(DoorHandlePlacement = exports.DoorHandlePlacement || (exports.DoorHandlePlacement = {}));
/** An enumeration. */
var ErrorLevel;
(function (ErrorLevel) {
    ErrorLevel["ERROR"] = "ERROR";
    ErrorLevel["WARNING"] = "WARNING";
})(ErrorLevel = exports.ErrorLevel || (exports.ErrorLevel = {}));
var FlagAppType;
(function (FlagAppType) {
    FlagAppType["DISPLAYS"] = "DISPLAYS";
    FlagAppType["IMAGES"] = "IMAGES";
    FlagAppType["PRODUCTS"] = "PRODUCTS";
})(FlagAppType = exports.FlagAppType || (exports.FlagAppType = {}));
var FlagModelType;
(function (FlagModelType) {
    FlagModelType["DISPLAY_AUDIT_IMAGE"] = "DISPLAY_AUDIT_IMAGE";
    FlagModelType["DISPLAY_SEGMENT"] = "DISPLAY_SEGMENT";
    FlagModelType["PRODUCT"] = "PRODUCT";
})(FlagModelType = exports.FlagModelType || (exports.FlagModelType = {}));
/** An enumeration. */
var FlaggedLevel;
(function (FlaggedLevel) {
    FlaggedLevel["DELETE"] = "DELETE";
    FlaggedLevel["N_REVIEW"] = "N_REVIEW";
    FlaggedLevel["RED"] = "RED";
    FlaggedLevel["UNFLAG"] = "UNFLAG";
    FlaggedLevel["YELLOW"] = "YELLOW";
})(FlaggedLevel = exports.FlaggedLevel || (exports.FlaggedLevel = {}));
/** An enumeration. */
var FlaggedType;
(function (FlaggedType) {
    FlaggedType["COMMON"] = "COMMON";
    FlaggedType["IMAGE"] = "IMAGE";
    FlaggedType["PLANOGRAM_SEGMENT"] = "PLANOGRAM_SEGMENT";
    FlaggedType["PRODUCT"] = "PRODUCT";
    FlaggedType["STORE_MAP"] = "STORE_MAP";
})(FlaggedType = exports.FlaggedType || (exports.FlaggedType = {}));
/** An enumeration. */
var ForStoreSize;
(function (ForStoreSize) {
    ForStoreSize["ALL"] = "ALL";
    ForStoreSize["DRUG"] = "DRUG";
    ForStoreSize["HOME_IMPROVEMENT"] = "HOME_IMPROVEMENT";
    ForStoreSize["LARGE"] = "LARGE";
    ForStoreSize["SMALL"] = "SMALL";
    ForStoreSize["VALUE"] = "VALUE";
})(ForStoreSize = exports.ForStoreSize || (exports.ForStoreSize = {}));
/** An enumeration. */
var GroupTypes;
(function (GroupTypes) {
    GroupTypes["MERCH"] = "MERCH";
    GroupTypes["PICOS"] = "PICOS";
    GroupTypes["REDAUDIT"] = "REDAUDIT";
})(GroupTypes = exports.GroupTypes || (exports.GroupTypes = {}));
var ImageType;
(function (ImageType) {
    ImageType["AUDIT_ANSWER"] = "AUDIT_ANSWER";
    ImageType["DISPLAY"] = "DISPLAY";
    ImageType["DISPLAY_AUDIT"] = "DISPLAY_AUDIT";
    ImageType["DISPLAY_RESTOCK"] = "DISPLAY_RESTOCK";
    ImageType["EL_DORADO"] = "EL_DORADO";
    ImageType["IMAGE"] = "IMAGE";
    ImageType["MARKETING"] = "MARKETING";
    ImageType["MODCOM_COMPLIANCE"] = "MODCOM_COMPLIANCE";
    ImageType["MULTI_PRODUCT"] = "MULTI_PRODUCT";
    ImageType["PLANOGRAM"] = "PLANOGRAM";
    ImageType["PRODUCT"] = "PRODUCT";
    ImageType["SCAN"] = "SCAN";
    ImageType["STORE"] = "STORE";
    ImageType["TRAINING"] = "TRAINING";
    ImageType["USER"] = "USER";
})(ImageType = exports.ImageType || (exports.ImageType = {}));
/** An enumeration. */
var InventoryProductInventoryTransactionMemoChoices;
(function (InventoryProductInventoryTransactionMemoChoices) {
    /** Adjustment from manual count */
    InventoryProductInventoryTransactionMemoChoices["AD"] = "AD";
    /** Reset Inventory with new counts from 3D Scan */
    InventoryProductInventoryTransactionMemoChoices["PS"] = "PS";
    /** Return of damaged or expired product */
    InventoryProductInventoryTransactionMemoChoices["RE"] = "RE";
    /** Restock of product */
    InventoryProductInventoryTransactionMemoChoices["RS"] = "RS";
    /** Sale */
    InventoryProductInventoryTransactionMemoChoices["SA"] = "SA";
    /** Received shipment from main warehouse */
    InventoryProductInventoryTransactionMemoChoices["SH"] = "SH";
    /** Transfer of product */
    InventoryProductInventoryTransactionMemoChoices["TR"] = "TR";
})(InventoryProductInventoryTransactionMemoChoices = exports.InventoryProductInventoryTransactionMemoChoices || (exports.InventoryProductInventoryTransactionMemoChoices = {}));
/** An enumeration. */
var InventoryTransactionMemo;
(function (InventoryTransactionMemo) {
    InventoryTransactionMemo["ADJUSTMENT"] = "ADJUSTMENT";
    InventoryTransactionMemo["POST_SCAN"] = "POST_SCAN";
    InventoryTransactionMemo["RESTOCK"] = "RESTOCK";
    InventoryTransactionMemo["RETURN"] = "RETURN";
    InventoryTransactionMemo["SALE"] = "SALE";
    InventoryTransactionMemo["SHIPMENT"] = "SHIPMENT";
    InventoryTransactionMemo["TRANSFER"] = "TRANSFER";
})(InventoryTransactionMemo = exports.InventoryTransactionMemo || (exports.InventoryTransactionMemo = {}));
/** An enumeration. */
var IssueType;
(function (IssueType) {
    IssueType["DISPLAY_AUDIT"] = "DISPLAY_AUDIT";
})(IssueType = exports.IssueType || (exports.IssueType = {}));
/** An enumeration. */
var LocalCompanyCategoryColumns;
(function (LocalCompanyCategoryColumns) {
    LocalCompanyCategoryColumns["category"] = "category";
    LocalCompanyCategoryColumns["company_category_id"] = "company_category_id";
    LocalCompanyCategoryColumns["parent_category"] = "parent_category";
})(LocalCompanyCategoryColumns = exports.LocalCompanyCategoryColumns || (exports.LocalCompanyCategoryColumns = {}));
/** An enumeration. */
var LocalFlavorColumns;
(function (LocalFlavorColumns) {
    LocalFlavorColumns["display_flavor"] = "display_flavor";
    LocalFlavorColumns["flavor"] = "flavor";
})(LocalFlavorColumns = exports.LocalFlavorColumns || (exports.LocalFlavorColumns = {}));
/** An enumeration. */
var LocalPackagingColumns;
(function (LocalPackagingColumns) {
    LocalPackagingColumns["container"] = "container";
    LocalPackagingColumns["packaging_id"] = "packaging_id";
    LocalPackagingColumns["quantity"] = "quantity";
    LocalPackagingColumns["size"] = "size";
    LocalPackagingColumns["unit"] = "unit";
})(LocalPackagingColumns = exports.LocalPackagingColumns || (exports.LocalPackagingColumns = {}));
/** An enumeration. */
var LocalProductColumns;
(function (LocalProductColumns) {
    LocalProductColumns["appearances_in_audit"] = "appearances_in_audit";
    LocalProductColumns["brand"] = "brand";
    LocalProductColumns["brand_order"] = "brand_order";
    LocalProductColumns["category_order"] = "category_order";
    LocalProductColumns["common_name"] = "common_name";
    LocalProductColumns["company_category_id"] = "company_category_id";
    LocalProductColumns["geometry_id"] = "geometry_id";
    LocalProductColumns["is_active"] = "is_active";
    LocalProductColumns["manufacturer"] = "manufacturer";
    LocalProductColumns["marketing_image_url"] = "marketing_image_url";
    LocalProductColumns["name"] = "name";
    LocalProductColumns["packaging_id"] = "packaging_id";
    LocalProductColumns["planogram_thumbnail_image_url"] = "planogram_thumbnail_image_url";
    LocalProductColumns["product_id"] = "product_id";
    LocalProductColumns["product_order"] = "product_order";
    LocalProductColumns["shipping_id"] = "shipping_id";
    LocalProductColumns["upc_a"] = "upc_a";
    LocalProductColumns["upc_e"] = "upc_e";
})(LocalProductColumns = exports.LocalProductColumns || (exports.LocalProductColumns = {}));
/** An enumeration. */
var LocalProductFlavorRelationColumns;
(function (LocalProductFlavorRelationColumns) {
    LocalProductFlavorRelationColumns["flavor"] = "flavor";
    LocalProductFlavorRelationColumns["product_id"] = "product_id";
})(LocalProductFlavorRelationColumns = exports.LocalProductFlavorRelationColumns || (exports.LocalProductFlavorRelationColumns = {}));
/** An enumeration. */
var LocalShippingColumns;
(function (LocalShippingColumns) {
    LocalShippingColumns["consumables_per_case"] = "consumables_per_case";
    LocalShippingColumns["products_per_case"] = "products_per_case";
    LocalShippingColumns["shipping_id"] = "shipping_id";
})(LocalShippingColumns = exports.LocalShippingColumns || (exports.LocalShippingColumns = {}));
/** An enumeration. */
var LocalVersionColumns;
(function (LocalVersionColumns) {
    LocalVersionColumns["last_updated"] = "last_updated";
})(LocalVersionColumns = exports.LocalVersionColumns || (exports.LocalVersionColumns = {}));
/** An enumeration. */
var MLModelType;
(function (MLModelType) {
    MLModelType["BEV"] = "BEV";
    MLModelType["BINARY"] = "BINARY";
    MLModelType["DAI_ZERO"] = "DAI_ZERO";
    MLModelType["DENSITY_NEONET"] = "DENSITY_NEONET";
    MLModelType["META"] = "META";
    MLModelType["NEONET"] = "NEONET";
    MLModelType["POINT_BEAM_SCORER"] = "POINT_BEAM_SCORER";
})(MLModelType = exports.MLModelType || (exports.MLModelType = {}));
/** An enumeration. */
var MLPurpose;
(function (MLPurpose) {
    MLPurpose["TRAINING"] = "TRAINING";
    MLPurpose["VALIDATION"] = "VALIDATION";
})(MLPurpose = exports.MLPurpose || (exports.MLPurpose = {}));
/** An enumeration. */
var MandateTypes;
(function (MandateTypes) {
    MandateTypes["HQ"] = "HQ";
    MandateTypes["LOCAL"] = "LOCAL";
})(MandateTypes = exports.MandateTypes || (exports.MandateTypes = {}));
/** An enumeration. */
var MapsStoreAreaDefaultForStoreSizeChoices;
(function (MapsStoreAreaDefaultForStoreSizeChoices) {
    /** all */
    MapsStoreAreaDefaultForStoreSizeChoices["AL"] = "AL";
    /** small */
    MapsStoreAreaDefaultForStoreSizeChoices["CR"] = "CR";
    /** drug */
    MapsStoreAreaDefaultForStoreSizeChoices["DR"] = "DR";
    /** home improvement */
    MapsStoreAreaDefaultForStoreSizeChoices["HM"] = "HM";
    /** large */
    MapsStoreAreaDefaultForStoreSizeChoices["LG"] = "LG";
    /** value */
    MapsStoreAreaDefaultForStoreSizeChoices["VL"] = "VL";
})(MapsStoreAreaDefaultForStoreSizeChoices = exports.MapsStoreAreaDefaultForStoreSizeChoices || (exports.MapsStoreAreaDefaultForStoreSizeChoices = {}));
/** An enumeration. */
var MerchandiserScanType;
(function (MerchandiserScanType) {
    MerchandiserScanType["ADJUSTMENT"] = "ADJUSTMENT";
    MerchandiserScanType["PRESCAN"] = "PRESCAN";
    MerchandiserScanType["RESTOCK"] = "RESTOCK";
})(MerchandiserScanType = exports.MerchandiserScanType || (exports.MerchandiserScanType = {}));
/** An enumeration. */
var MerchandisingAction;
(function (MerchandisingAction) {
    MerchandisingAction["NONE"] = "NONE";
    MerchandisingAction["THREE_D"] = "THREE_D";
    MerchandisingAction["TWO_D"] = "TWO_D";
})(MerchandisingAction = exports.MerchandisingAction || (exports.MerchandisingAction = {}));
/** An enumeration. */
var MerchandisingState;
(function (MerchandisingState) {
    MerchandisingState["EXCLUDED"] = "EXCLUDED";
    MerchandisingState["OPTIMIZED"] = "OPTIMIZED";
    MerchandisingState["OPTIMIZING"] = "OPTIMIZING";
    MerchandisingState["REMOVED"] = "REMOVED";
    MerchandisingState["UNOPTIMIZED"] = "UNOPTIMIZED";
})(MerchandisingState = exports.MerchandisingState || (exports.MerchandisingState = {}));
var OrderByDirection;
(function (OrderByDirection) {
    OrderByDirection["ASCENDING"] = "ASCENDING";
    OrderByDirection["DESCENDING"] = "DESCENDING";
})(OrderByDirection = exports.OrderByDirection || (exports.OrderByDirection = {}));
/** An enumeration. */
var PackagingType;
(function (PackagingType) {
    PackagingType["ALUMINUM_BOTTLE"] = "ALUMINUM_BOTTLE";
    PackagingType["BAG"] = "BAG";
    PackagingType["BOTTLE"] = "BOTTLE";
    PackagingType["BOX"] = "BOX";
    PackagingType["CAN"] = "CAN";
    PackagingType["CANISTER"] = "CANISTER";
    PackagingType["CARTON"] = "CARTON";
    PackagingType["GLASS_BOTTLE"] = "GLASS_BOTTLE";
    PackagingType["JAR"] = "JAR";
    PackagingType["PACKET"] = "PACKET";
})(PackagingType = exports.PackagingType || (exports.PackagingType = {}));
/** An enumeration. */
var PackagingUnits;
(function (PackagingUnits) {
    PackagingUnits["GALLONS"] = "GALLONS";
    PackagingUnits["LITERS"] = "LITERS";
    PackagingUnits["MILLILITERS"] = "MILLILITERS";
    PackagingUnits["OUNCES"] = "OUNCES";
})(PackagingUnits = exports.PackagingUnits || (exports.PackagingUnits = {}));
var PerformanceReportFilter;
(function (PerformanceReportFilter) {
    PerformanceReportFilter["DISPLAYS"] = "DISPLAYS";
    PerformanceReportFilter["ROUTES"] = "ROUTES";
    PerformanceReportFilter["STORES"] = "STORES";
    PerformanceReportFilter["TERRITORIES"] = "TERRITORIES";
})(PerformanceReportFilter = exports.PerformanceReportFilter || (exports.PerformanceReportFilter = {}));
/** An enumeration. */
var Period;
(function (Period) {
    Period["ALL_TIME"] = "ALL_TIME";
    Period["DAY"] = "DAY";
    Period["MONTH"] = "MONTH";
    Period["QUARTER"] = "QUARTER";
    Period["WEEK"] = "WEEK";
    Period["YEAR"] = "YEAR";
})(Period = exports.Period || (exports.Period = {}));
/** An enumeration. */
var PlanogramProductOrientation;
(function (PlanogramProductOrientation) {
    PlanogramProductOrientation["FRONT"] = "FRONT";
    PlanogramProductOrientation["SIDE"] = "SIDE";
    PlanogramProductOrientation["TOP"] = "TOP";
})(PlanogramProductOrientation = exports.PlanogramProductOrientation || (exports.PlanogramProductOrientation = {}));
/** An enumeration. */
var PlanogramStatus;
(function (PlanogramStatus) {
    PlanogramStatus["APPROVED"] = "APPROVED";
    PlanogramStatus["DENIED"] = "DENIED";
    PlanogramStatus["DRAFT"] = "DRAFT";
    PlanogramStatus["EFFECTIVE"] = "EFFECTIVE";
    PlanogramStatus["EXPIRED"] = "EXPIRED";
    PlanogramStatus["ORPHANED"] = "ORPHANED";
})(PlanogramStatus = exports.PlanogramStatus || (exports.PlanogramStatus = {}));
var ProductSortBy;
(function (ProductSortBy) {
    ProductSortBy["AUDIT_APP_HIGH_TO_LOW"] = "AUDIT_APP_HIGH_TO_LOW";
    ProductSortBy["AUDIT_APP_LOW_TO_HIGH"] = "AUDIT_APP_LOW_TO_HIGH";
    ProductSortBy["RECENTLY_ADDED"] = "RECENTLY_ADDED";
    ProductSortBy["RECENTLY_MODIFIED"] = "RECENTLY_MODIFIED";
})(ProductSortBy = exports.ProductSortBy || (exports.ProductSortBy = {}));
/** An enumeration. */
var ProductTypeEnum;
(function (ProductTypeEnum) {
    ProductTypeEnum["BOTTLE_20OZ"] = "BOTTLE_20OZ";
    ProductTypeEnum["CANBOX_12OZ_8"] = "CANBOX_12OZ_8";
    ProductTypeEnum["CANBOX_12OZ_24"] = "CANBOX_12OZ_24";
    ProductTypeEnum["CANBOX_16OZ_4"] = "CANBOX_16OZ_4";
    ProductTypeEnum["CAN_7_5OZ_6"] = "CAN_7_5OZ_6";
    ProductTypeEnum["CAN_16OZ"] = "CAN_16OZ";
    ProductTypeEnum["CEREALBOX"] = "CEREALBOX";
    ProductTypeEnum["DOYPACK"] = "DOYPACK";
    ProductTypeEnum["FLATSIDEBOTTLE_17OZ"] = "FLATSIDEBOTTLE_17OZ";
    ProductTypeEnum["GATORADEBOTTLE_12OZ_12"] = "GATORADEBOTTLE_12OZ_12";
    ProductTypeEnum["GATORADEBOTTLE_20OZ"] = "GATORADEBOTTLE_20OZ";
    ProductTypeEnum["GATORADEBOTTLE_20OZ_8"] = "GATORADEBOTTLE_20OZ_8";
    ProductTypeEnum["GATORADEBOTTLE_32OZ"] = "GATORADEBOTTLE_32OZ";
    ProductTypeEnum["ISOTONICBOTTLE_16OZ"] = "ISOTONICBOTTLE_16OZ";
    ProductTypeEnum["LAYS"] = "LAYS";
    ProductTypeEnum["ONELITER"] = "ONELITER";
    ProductTypeEnum["SIXPACK"] = "SIXPACK";
    ProductTypeEnum["SLIMCAN_12OZ"] = "SLIMCAN_12OZ";
    ProductTypeEnum["TWELVEPACK"] = "TWELVEPACK";
    ProductTypeEnum["TWOLITER"] = "TWOLITER";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_6"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_6";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_12"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_12";
    ProductTypeEnum["WRAPPEDFLATSIDEBOTTLE_17OZ_24"] = "WRAPPEDFLATSIDEBOTTLE_17OZ_24";
})(ProductTypeEnum = exports.ProductTypeEnum || (exports.ProductTypeEnum = {}));
var ProductsPackagingContainerChoices;
(function (ProductsPackagingContainerChoices) {
    ProductsPackagingContainerChoices["ALUMINUM_BOTTLE"] = "ALUMINUM_BOTTLE";
    ProductsPackagingContainerChoices["BAG"] = "BAG";
    ProductsPackagingContainerChoices["BOTTLE"] = "BOTTLE";
    ProductsPackagingContainerChoices["BOX"] = "BOX";
    ProductsPackagingContainerChoices["CAN"] = "CAN";
    ProductsPackagingContainerChoices["CANISTER"] = "CANISTER";
    ProductsPackagingContainerChoices["CARTON"] = "CARTON";
    ProductsPackagingContainerChoices["GLASS_BOTTLE"] = "GLASS_BOTTLE";
    ProductsPackagingContainerChoices["JAR"] = "JAR";
    ProductsPackagingContainerChoices["PACKET"] = "PACKET";
})(ProductsPackagingContainerChoices = exports.ProductsPackagingContainerChoices || (exports.ProductsPackagingContainerChoices = {}));
var ProductsPackagingUnitChoices;
(function (ProductsPackagingUnitChoices) {
    ProductsPackagingUnitChoices["G"] = "G";
    ProductsPackagingUnitChoices["L"] = "L";
    ProductsPackagingUnitChoices["ML"] = "ML";
    ProductsPackagingUnitChoices["OZ"] = "OZ";
})(ProductsPackagingUnitChoices = exports.ProductsPackagingUnitChoices || (exports.ProductsPackagingUnitChoices = {}));
var ProductsProductSourceChoices;
(function (ProductsProductSourceChoices) {
    ProductsProductSourceChoices["COKE_PLANOGRAM"] = "COKE_PLANOGRAM";
    ProductsProductSourceChoices["KROGER"] = "KROGER";
    ProductsProductSourceChoices["OTHER"] = "OTHER";
    ProductsProductSourceChoices["SALSIFY"] = "SALSIFY";
    ProductsProductSourceChoices["SWIRE"] = "SWIRE";
    ProductsProductSourceChoices["TARGET"] = "TARGET";
    ProductsProductSourceChoices["WALMART"] = "WALMART";
})(ProductsProductSourceChoices = exports.ProductsProductSourceChoices || (exports.ProductsProductSourceChoices = {}));
/**
 * Determines if a display restock is resetting the inventory (SET_INVENTORY)
 * or adding product back onto the display (RESTOCK)
 */
var RestockAdjustmentType;
(function (RestockAdjustmentType) {
    RestockAdjustmentType["RESTOCK"] = "RESTOCK";
    RestockAdjustmentType["SET_INVENTORY"] = "SET_INVENTORY";
})(RestockAdjustmentType = exports.RestockAdjustmentType || (exports.RestockAdjustmentType = {}));
/** An enumeration. */
var ScanPurpose;
(function (ScanPurpose) {
    ScanPurpose["AUDIT"] = "AUDIT";
    ScanPurpose["MBF_ACTIVITY"] = "MBF_ACTIVITY";
    ScanPurpose["POST_RESTOCK"] = "POST_RESTOCK";
    ScanPurpose["PRE_RESTOCK"] = "PRE_RESTOCK";
    ScanPurpose["TRAINING"] = "TRAINING";
})(ScanPurpose = exports.ScanPurpose || (exports.ScanPurpose = {}));
/** An enumeration. */
var ScanType;
(function (ScanType) {
    ScanType["THREE_D"] = "THREE_D";
    ScanType["TWO_D"] = "TWO_D";
})(ScanType = exports.ScanType || (exports.ScanType = {}));
/** An enumeration. */
var SceneTypeEnum;
(function (SceneTypeEnum) {
    SceneTypeEnum["AISLE"] = "AISLE";
    SceneTypeEnum["CASE_STACK"] = "CASE_STACK";
    SceneTypeEnum["COLD_VAULT"] = "COLD_VAULT";
    SceneTypeEnum["FRIDGE"] = "FRIDGE";
    SceneTypeEnum["FRIDGE_EC"] = "FRIDGE_EC";
    SceneTypeEnum["MIXED_SNACKS"] = "MIXED_SNACKS";
    SceneTypeEnum["ONE_SIDED_ENDCAP"] = "ONE_SIDED_ENDCAP";
    SceneTypeEnum["PALLET_DROP"] = "PALLET_DROP";
    SceneTypeEnum["SHELF"] = "SHELF";
    SceneTypeEnum["SNACKS"] = "SNACKS";
    SceneTypeEnum["STANDALONE_RACK"] = "STANDALONE_RACK";
    SceneTypeEnum["THREE_SIDED_ENDCAP"] = "THREE_SIDED_ENDCAP";
    SceneTypeEnum["TWO_SIDED_ENDCAP"] = "TWO_SIDED_ENDCAP";
})(SceneTypeEnum = exports.SceneTypeEnum || (exports.SceneTypeEnum = {}));
/** An enumeration. */
var SimpleUserProductivityModelOptions;
(function (SimpleUserProductivityModelOptions) {
    SimpleUserProductivityModelOptions["TEAM"] = "TEAM";
    SimpleUserProductivityModelOptions["USER"] = "USER";
})(SimpleUserProductivityModelOptions = exports.SimpleUserProductivityModelOptions || (exports.SimpleUserProductivityModelOptions = {}));
var SortBy;
(function (SortBy) {
    SortBy["ADDRESS"] = "ADDRESS";
    SortBy["CATEGORY"] = "CATEGORY";
    SortBy["CHAIN"] = "CHAIN";
    SortBy["CUSTOMER_ID"] = "CUSTOMER_ID";
    SortBy["FORMULA_GROUP"] = "FORMULA_GROUP";
    SortBy["IS_ACTIVE"] = "IS_ACTIVE";
    SortBy["REGION"] = "REGION";
    SortBy["STORE_NAME"] = "STORE_NAME";
})(SortBy = exports.SortBy || (exports.SortBy = {}));
var SortByDirection;
(function (SortByDirection) {
    SortByDirection["ASCENDING"] = "ASCENDING";
    SortByDirection["DESCENDING"] = "DESCENDING";
})(SortByDirection = exports.SortByDirection || (exports.SortByDirection = {}));
var SortIssueBy;
(function (SortIssueBy) {
    SortIssueBy["CREATED"] = "CREATED";
    SortIssueBy["DISPLAY"] = "DISPLAY";
    SortIssueBy["ISSUE_TYPES"] = "ISSUE_TYPES";
    SortIssueBy["IS_RESOLVED"] = "IS_RESOLVED";
    SortIssueBy["REASON"] = "REASON";
    SortIssueBy["STORE_NAME"] = "STORE_NAME";
})(SortIssueBy = exports.SortIssueBy || (exports.SortIssueBy = {}));
var SortIssueDirection;
(function (SortIssueDirection) {
    SortIssueDirection["ASCENDING"] = "ASCENDING";
    SortIssueDirection["DESCENDING"] = "DESCENDING";
})(SortIssueDirection = exports.SortIssueDirection || (exports.SortIssueDirection = {}));
/** An enumeration. */
var StoreActionType;
(function (StoreActionType) {
    StoreActionType["EDITED"] = "EDITED";
    StoreActionType["PAUSED"] = "PAUSED";
    StoreActionType["RESUMED"] = "RESUMED";
    StoreActionType["STOPPED"] = "STOPPED";
})(StoreActionType = exports.StoreActionType || (exports.StoreActionType = {}));
/** An enumeration. */
var StoreCategory;
(function (StoreCategory) {
    StoreCategory["CR"] = "CR";
    StoreCategory["DR"] = "DR";
    StoreCategory["HM"] = "HM";
    StoreCategory["LG"] = "LG";
    StoreCategory["OTHER"] = "OTHER";
    StoreCategory["VL"] = "VL";
})(StoreCategory = exports.StoreCategory || (exports.StoreCategory = {}));
var StoreDisplaysFilterOptions;
(function (StoreDisplaysFilterOptions) {
    StoreDisplaysFilterOptions["CASE_STACKS"] = "CASE_STACKS";
    StoreDisplaysFilterOptions["COLD_VAULT"] = "COLD_VAULT";
    StoreDisplaysFilterOptions["COOLER"] = "COOLER";
    StoreDisplaysFilterOptions["END_CAP"] = "END_CAP";
    StoreDisplaysFilterOptions["OTHER"] = "OTHER";
    StoreDisplaysFilterOptions["PALLET_DROP"] = "PALLET_DROP";
    StoreDisplaysFilterOptions["STANDALONE_RACK"] = "STANDALONE_RACK";
})(StoreDisplaysFilterOptions = exports.StoreDisplaysFilterOptions || (exports.StoreDisplaysFilterOptions = {}));
var StoreDisplaysSortBy;
(function (StoreDisplaysSortBy) {
    StoreDisplaysSortBy["CREATED"] = "CREATED";
    StoreDisplaysSortBy["CREATED_REVERSE"] = "CREATED_REVERSE";
    StoreDisplaysSortBy["STORE_AREA_ALPHABETICAL_REVERSE"] = "STORE_AREA_ALPHABETICAL_REVERSE";
    StoreDisplaysSortBy["STORE_AREA_ALPHABETICAL_STANDARD"] = "STORE_AREA_ALPHABETICAL_STANDARD";
})(StoreDisplaysSortBy = exports.StoreDisplaysSortBy || (exports.StoreDisplaysSortBy = {}));
var StoreFilter;
(function (StoreFilter) {
    StoreFilter["NEEDS_TO_BE_AUDITED"] = "NEEDS_TO_BE_AUDITED";
})(StoreFilter = exports.StoreFilter || (exports.StoreFilter = {}));
var StoreFilterOut;
(function (StoreFilterOut) {
    StoreFilterOut["NO_NEED_FOR_AUDIT"] = "NO_NEED_FOR_AUDIT";
})(StoreFilterOut = exports.StoreFilterOut || (exports.StoreFilterOut = {}));
var StoreOrderBy;
(function (StoreOrderBy) {
    StoreOrderBy["CITY"] = "CITY";
    StoreOrderBy["DISTANCE"] = "DISTANCE";
    StoreOrderBy["NOTIFICATIONS"] = "NOTIFICATIONS";
    StoreOrderBy["RECENT_AUDIT_FIRST"] = "RECENT_AUDIT_FIRST";
    StoreOrderBy["RECENT_AUDIT_LAST"] = "RECENT_AUDIT_LAST";
    StoreOrderBy["STATE"] = "STATE";
    StoreOrderBy["STORE_NAME"] = "STORE_NAME";
})(StoreOrderBy = exports.StoreOrderBy || (exports.StoreOrderBy = {}));
var StoreTaskFilterOptions;
(function (StoreTaskFilterOptions) {
    StoreTaskFilterOptions["COMPLETE"] = "COMPLETE";
    StoreTaskFilterOptions["NOT_COMPLETE"] = "NOT_COMPLETE";
})(StoreTaskFilterOptions = exports.StoreTaskFilterOptions || (exports.StoreTaskFilterOptions = {}));
var StoreTaskSortBy;
(function (StoreTaskSortBy) {
    StoreTaskSortBy["COMPLETE"] = "COMPLETE";
    StoreTaskSortBy["CREATED"] = "CREATED";
    StoreTaskSortBy["CREATED_REVERSE"] = "CREATED_REVERSE";
    StoreTaskSortBy["NOT_COMPLETE"] = "NOT_COMPLETE";
})(StoreTaskSortBy = exports.StoreTaskSortBy || (exports.StoreTaskSortBy = {}));
/** An enumeration. */
var StoresStoreCategoryChoices;
(function (StoresStoreCategoryChoices) {
    /** Convenience Retail */
    StoresStoreCategoryChoices["CR"] = "CR";
    /** Drug */
    StoresStoreCategoryChoices["DR"] = "DR";
    /** Home Improvement */
    StoresStoreCategoryChoices["HM"] = "HM";
    /** Large */
    StoresStoreCategoryChoices["LG"] = "LG";
    /** Other */
    StoresStoreCategoryChoices["OTHER"] = "OTHER";
    /** Value */
    StoresStoreCategoryChoices["VL"] = "VL";
})(StoresStoreCategoryChoices = exports.StoresStoreCategoryChoices || (exports.StoresStoreCategoryChoices = {}));
/** An enumeration. */
var StoresStoreTimezoneChoices;
(function (StoresStoreTimezoneChoices) {
    /** Africa/Abidjan */
    StoresStoreTimezoneChoices["AFRICA_ABIDJAN"] = "AFRICA_ABIDJAN";
    /** Africa/Accra */
    StoresStoreTimezoneChoices["AFRICA_ACCRA"] = "AFRICA_ACCRA";
    /** Africa/Addis_Ababa */
    StoresStoreTimezoneChoices["AFRICA_ADDIS_ABABA"] = "AFRICA_ADDIS_ABABA";
    /** Africa/Algiers */
    StoresStoreTimezoneChoices["AFRICA_ALGIERS"] = "AFRICA_ALGIERS";
    /** Africa/Asmara */
    StoresStoreTimezoneChoices["AFRICA_ASMARA"] = "AFRICA_ASMARA";
    /** Africa/Asmera */
    StoresStoreTimezoneChoices["AFRICA_ASMERA"] = "AFRICA_ASMERA";
    /** Africa/Bamako */
    StoresStoreTimezoneChoices["AFRICA_BAMAKO"] = "AFRICA_BAMAKO";
    /** Africa/Bangui */
    StoresStoreTimezoneChoices["AFRICA_BANGUI"] = "AFRICA_BANGUI";
    /** Africa/Banjul */
    StoresStoreTimezoneChoices["AFRICA_BANJUL"] = "AFRICA_BANJUL";
    /** Africa/Bissau */
    StoresStoreTimezoneChoices["AFRICA_BISSAU"] = "AFRICA_BISSAU";
    /** Africa/Blantyre */
    StoresStoreTimezoneChoices["AFRICA_BLANTYRE"] = "AFRICA_BLANTYRE";
    /** Africa/Brazzaville */
    StoresStoreTimezoneChoices["AFRICA_BRAZZAVILLE"] = "AFRICA_BRAZZAVILLE";
    /** Africa/Bujumbura */
    StoresStoreTimezoneChoices["AFRICA_BUJUMBURA"] = "AFRICA_BUJUMBURA";
    /** Africa/Cairo */
    StoresStoreTimezoneChoices["AFRICA_CAIRO"] = "AFRICA_CAIRO";
    /** Africa/Casablanca */
    StoresStoreTimezoneChoices["AFRICA_CASABLANCA"] = "AFRICA_CASABLANCA";
    /** Africa/Ceuta */
    StoresStoreTimezoneChoices["AFRICA_CEUTA"] = "AFRICA_CEUTA";
    /** Africa/Conakry */
    StoresStoreTimezoneChoices["AFRICA_CONAKRY"] = "AFRICA_CONAKRY";
    /** Africa/Dakar */
    StoresStoreTimezoneChoices["AFRICA_DAKAR"] = "AFRICA_DAKAR";
    /** Africa/Dar_es_Salaam */
    StoresStoreTimezoneChoices["AFRICA_DAR_ES_SALAAM"] = "AFRICA_DAR_ES_SALAAM";
    /** Africa/Djibouti */
    StoresStoreTimezoneChoices["AFRICA_DJIBOUTI"] = "AFRICA_DJIBOUTI";
    /** Africa/Douala */
    StoresStoreTimezoneChoices["AFRICA_DOUALA"] = "AFRICA_DOUALA";
    /** Africa/El_Aaiun */
    StoresStoreTimezoneChoices["AFRICA_EL_AAIUN"] = "AFRICA_EL_AAIUN";
    /** Africa/Freetown */
    StoresStoreTimezoneChoices["AFRICA_FREETOWN"] = "AFRICA_FREETOWN";
    /** Africa/Gaborone */
    StoresStoreTimezoneChoices["AFRICA_GABORONE"] = "AFRICA_GABORONE";
    /** Africa/Harare */
    StoresStoreTimezoneChoices["AFRICA_HARARE"] = "AFRICA_HARARE";
    /** Africa/Johannesburg */
    StoresStoreTimezoneChoices["AFRICA_JOHANNESBURG"] = "AFRICA_JOHANNESBURG";
    /** Africa/Juba */
    StoresStoreTimezoneChoices["AFRICA_JUBA"] = "AFRICA_JUBA";
    /** Africa/Kampala */
    StoresStoreTimezoneChoices["AFRICA_KAMPALA"] = "AFRICA_KAMPALA";
    /** Africa/Khartoum */
    StoresStoreTimezoneChoices["AFRICA_KHARTOUM"] = "AFRICA_KHARTOUM";
    /** Africa/Kigali */
    StoresStoreTimezoneChoices["AFRICA_KIGALI"] = "AFRICA_KIGALI";
    /** Africa/Kinshasa */
    StoresStoreTimezoneChoices["AFRICA_KINSHASA"] = "AFRICA_KINSHASA";
    /** Africa/Lagos */
    StoresStoreTimezoneChoices["AFRICA_LAGOS"] = "AFRICA_LAGOS";
    /** Africa/Libreville */
    StoresStoreTimezoneChoices["AFRICA_LIBREVILLE"] = "AFRICA_LIBREVILLE";
    /** Africa/Lome */
    StoresStoreTimezoneChoices["AFRICA_LOME"] = "AFRICA_LOME";
    /** Africa/Luanda */
    StoresStoreTimezoneChoices["AFRICA_LUANDA"] = "AFRICA_LUANDA";
    /** Africa/Lubumbashi */
    StoresStoreTimezoneChoices["AFRICA_LUBUMBASHI"] = "AFRICA_LUBUMBASHI";
    /** Africa/Lusaka */
    StoresStoreTimezoneChoices["AFRICA_LUSAKA"] = "AFRICA_LUSAKA";
    /** Africa/Malabo */
    StoresStoreTimezoneChoices["AFRICA_MALABO"] = "AFRICA_MALABO";
    /** Africa/Maputo */
    StoresStoreTimezoneChoices["AFRICA_MAPUTO"] = "AFRICA_MAPUTO";
    /** Africa/Maseru */
    StoresStoreTimezoneChoices["AFRICA_MASERU"] = "AFRICA_MASERU";
    /** Africa/Mbabane */
    StoresStoreTimezoneChoices["AFRICA_MBABANE"] = "AFRICA_MBABANE";
    /** Africa/Mogadishu */
    StoresStoreTimezoneChoices["AFRICA_MOGADISHU"] = "AFRICA_MOGADISHU";
    /** Africa/Monrovia */
    StoresStoreTimezoneChoices["AFRICA_MONROVIA"] = "AFRICA_MONROVIA";
    /** Africa/Nairobi */
    StoresStoreTimezoneChoices["AFRICA_NAIROBI"] = "AFRICA_NAIROBI";
    /** Africa/Ndjamena */
    StoresStoreTimezoneChoices["AFRICA_NDJAMENA"] = "AFRICA_NDJAMENA";
    /** Africa/Niamey */
    StoresStoreTimezoneChoices["AFRICA_NIAMEY"] = "AFRICA_NIAMEY";
    /** Africa/Nouakchott */
    StoresStoreTimezoneChoices["AFRICA_NOUAKCHOTT"] = "AFRICA_NOUAKCHOTT";
    /** Africa/Ouagadougou */
    StoresStoreTimezoneChoices["AFRICA_OUAGADOUGOU"] = "AFRICA_OUAGADOUGOU";
    /** Africa/Porto-Novo */
    StoresStoreTimezoneChoices["AFRICA_PORTO_NOVO"] = "AFRICA_PORTO_NOVO";
    /** Africa/Sao_Tome */
    StoresStoreTimezoneChoices["AFRICA_SAO_TOME"] = "AFRICA_SAO_TOME";
    /** Africa/Timbuktu */
    StoresStoreTimezoneChoices["AFRICA_TIMBUKTU"] = "AFRICA_TIMBUKTU";
    /** Africa/Tripoli */
    StoresStoreTimezoneChoices["AFRICA_TRIPOLI"] = "AFRICA_TRIPOLI";
    /** Africa/Tunis */
    StoresStoreTimezoneChoices["AFRICA_TUNIS"] = "AFRICA_TUNIS";
    /** Africa/Windhoek */
    StoresStoreTimezoneChoices["AFRICA_WINDHOEK"] = "AFRICA_WINDHOEK";
    /** America/Adak */
    StoresStoreTimezoneChoices["AMERICA_ADAK"] = "AMERICA_ADAK";
    /** America/Anchorage */
    StoresStoreTimezoneChoices["AMERICA_ANCHORAGE"] = "AMERICA_ANCHORAGE";
    /** America/Anguilla */
    StoresStoreTimezoneChoices["AMERICA_ANGUILLA"] = "AMERICA_ANGUILLA";
    /** America/Antigua */
    StoresStoreTimezoneChoices["AMERICA_ANTIGUA"] = "AMERICA_ANTIGUA";
    /** America/Araguaina */
    StoresStoreTimezoneChoices["AMERICA_ARAGUAINA"] = "AMERICA_ARAGUAINA";
    /** America/Argentina/Buenos_Aires */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_BUENOS_AIRES"] = "AMERICA_ARGENTINA_BUENOS_AIRES";
    /** America/Argentina/Catamarca */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_CATAMARCA"] = "AMERICA_ARGENTINA_CATAMARCA";
    /** America/Argentina/ComodRivadavia */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_COMODRIVADAVIA"] = "AMERICA_ARGENTINA_COMODRIVADAVIA";
    /** America/Argentina/Cordoba */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_CORDOBA"] = "AMERICA_ARGENTINA_CORDOBA";
    /** America/Argentina/Jujuy */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_JUJUY"] = "AMERICA_ARGENTINA_JUJUY";
    /** America/Argentina/La_Rioja */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_LA_RIOJA"] = "AMERICA_ARGENTINA_LA_RIOJA";
    /** America/Argentina/Mendoza */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_MENDOZA"] = "AMERICA_ARGENTINA_MENDOZA";
    /** America/Argentina/Rio_Gallegos */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_RIO_GALLEGOS"] = "AMERICA_ARGENTINA_RIO_GALLEGOS";
    /** America/Argentina/Salta */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_SALTA"] = "AMERICA_ARGENTINA_SALTA";
    /** America/Argentina/San_Juan */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_SAN_JUAN"] = "AMERICA_ARGENTINA_SAN_JUAN";
    /** America/Argentina/San_Luis */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_SAN_LUIS"] = "AMERICA_ARGENTINA_SAN_LUIS";
    /** America/Argentina/Tucuman */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_TUCUMAN"] = "AMERICA_ARGENTINA_TUCUMAN";
    /** America/Argentina/Ushuaia */
    StoresStoreTimezoneChoices["AMERICA_ARGENTINA_USHUAIA"] = "AMERICA_ARGENTINA_USHUAIA";
    /** America/Aruba */
    StoresStoreTimezoneChoices["AMERICA_ARUBA"] = "AMERICA_ARUBA";
    /** America/Asuncion */
    StoresStoreTimezoneChoices["AMERICA_ASUNCION"] = "AMERICA_ASUNCION";
    /** America/Atikokan */
    StoresStoreTimezoneChoices["AMERICA_ATIKOKAN"] = "AMERICA_ATIKOKAN";
    /** America/Atka */
    StoresStoreTimezoneChoices["AMERICA_ATKA"] = "AMERICA_ATKA";
    /** America/Bahia */
    StoresStoreTimezoneChoices["AMERICA_BAHIA"] = "AMERICA_BAHIA";
    /** America/Bahia_Banderas */
    StoresStoreTimezoneChoices["AMERICA_BAHIA_BANDERAS"] = "AMERICA_BAHIA_BANDERAS";
    /** America/Barbados */
    StoresStoreTimezoneChoices["AMERICA_BARBADOS"] = "AMERICA_BARBADOS";
    /** America/Belem */
    StoresStoreTimezoneChoices["AMERICA_BELEM"] = "AMERICA_BELEM";
    /** America/Belize */
    StoresStoreTimezoneChoices["AMERICA_BELIZE"] = "AMERICA_BELIZE";
    /** America/Blanc-Sablon */
    StoresStoreTimezoneChoices["AMERICA_BLANC_SABLON"] = "AMERICA_BLANC_SABLON";
    /** America/Boa_Vista */
    StoresStoreTimezoneChoices["AMERICA_BOA_VISTA"] = "AMERICA_BOA_VISTA";
    /** America/Bogota */
    StoresStoreTimezoneChoices["AMERICA_BOGOTA"] = "AMERICA_BOGOTA";
    /** America/Boise */
    StoresStoreTimezoneChoices["AMERICA_BOISE"] = "AMERICA_BOISE";
    /** America/Buenos_Aires */
    StoresStoreTimezoneChoices["AMERICA_BUENOS_AIRES"] = "AMERICA_BUENOS_AIRES";
    /** America/Cambridge_Bay */
    StoresStoreTimezoneChoices["AMERICA_CAMBRIDGE_BAY"] = "AMERICA_CAMBRIDGE_BAY";
    /** America/Campo_Grande */
    StoresStoreTimezoneChoices["AMERICA_CAMPO_GRANDE"] = "AMERICA_CAMPO_GRANDE";
    /** America/Cancun */
    StoresStoreTimezoneChoices["AMERICA_CANCUN"] = "AMERICA_CANCUN";
    /** America/Caracas */
    StoresStoreTimezoneChoices["AMERICA_CARACAS"] = "AMERICA_CARACAS";
    /** America/Catamarca */
    StoresStoreTimezoneChoices["AMERICA_CATAMARCA"] = "AMERICA_CATAMARCA";
    /** America/Cayenne */
    StoresStoreTimezoneChoices["AMERICA_CAYENNE"] = "AMERICA_CAYENNE";
    /** America/Cayman */
    StoresStoreTimezoneChoices["AMERICA_CAYMAN"] = "AMERICA_CAYMAN";
    /** America/Chicago */
    StoresStoreTimezoneChoices["AMERICA_CHICAGO"] = "AMERICA_CHICAGO";
    /** America/Chihuahua */
    StoresStoreTimezoneChoices["AMERICA_CHIHUAHUA"] = "AMERICA_CHIHUAHUA";
    /** America/Coral_Harbour */
    StoresStoreTimezoneChoices["AMERICA_CORAL_HARBOUR"] = "AMERICA_CORAL_HARBOUR";
    /** America/Cordoba */
    StoresStoreTimezoneChoices["AMERICA_CORDOBA"] = "AMERICA_CORDOBA";
    /** America/Costa_Rica */
    StoresStoreTimezoneChoices["AMERICA_COSTA_RICA"] = "AMERICA_COSTA_RICA";
    /** America/Creston */
    StoresStoreTimezoneChoices["AMERICA_CRESTON"] = "AMERICA_CRESTON";
    /** America/Cuiaba */
    StoresStoreTimezoneChoices["AMERICA_CUIABA"] = "AMERICA_CUIABA";
    /** America/Curacao */
    StoresStoreTimezoneChoices["AMERICA_CURACAO"] = "AMERICA_CURACAO";
    /** America/Danmarkshavn */
    StoresStoreTimezoneChoices["AMERICA_DANMARKSHAVN"] = "AMERICA_DANMARKSHAVN";
    /** America/Dawson */
    StoresStoreTimezoneChoices["AMERICA_DAWSON"] = "AMERICA_DAWSON";
    /** America/Dawson_Creek */
    StoresStoreTimezoneChoices["AMERICA_DAWSON_CREEK"] = "AMERICA_DAWSON_CREEK";
    /** America/Denver */
    StoresStoreTimezoneChoices["AMERICA_DENVER"] = "AMERICA_DENVER";
    /** America/Detroit */
    StoresStoreTimezoneChoices["AMERICA_DETROIT"] = "AMERICA_DETROIT";
    /** America/Dominica */
    StoresStoreTimezoneChoices["AMERICA_DOMINICA"] = "AMERICA_DOMINICA";
    /** America/Edmonton */
    StoresStoreTimezoneChoices["AMERICA_EDMONTON"] = "AMERICA_EDMONTON";
    /** America/Eirunepe */
    StoresStoreTimezoneChoices["AMERICA_EIRUNEPE"] = "AMERICA_EIRUNEPE";
    /** America/El_Salvador */
    StoresStoreTimezoneChoices["AMERICA_EL_SALVADOR"] = "AMERICA_EL_SALVADOR";
    /** America/Ensenada */
    StoresStoreTimezoneChoices["AMERICA_ENSENADA"] = "AMERICA_ENSENADA";
    /** America/Fortaleza */
    StoresStoreTimezoneChoices["AMERICA_FORTALEZA"] = "AMERICA_FORTALEZA";
    /** America/Fort_Nelson */
    StoresStoreTimezoneChoices["AMERICA_FORT_NELSON"] = "AMERICA_FORT_NELSON";
    /** America/Fort_Wayne */
    StoresStoreTimezoneChoices["AMERICA_FORT_WAYNE"] = "AMERICA_FORT_WAYNE";
    /** America/Glace_Bay */
    StoresStoreTimezoneChoices["AMERICA_GLACE_BAY"] = "AMERICA_GLACE_BAY";
    /** America/Godthab */
    StoresStoreTimezoneChoices["AMERICA_GODTHAB"] = "AMERICA_GODTHAB";
    /** America/Goose_Bay */
    StoresStoreTimezoneChoices["AMERICA_GOOSE_BAY"] = "AMERICA_GOOSE_BAY";
    /** America/Grand_Turk */
    StoresStoreTimezoneChoices["AMERICA_GRAND_TURK"] = "AMERICA_GRAND_TURK";
    /** America/Grenada */
    StoresStoreTimezoneChoices["AMERICA_GRENADA"] = "AMERICA_GRENADA";
    /** America/Guadeloupe */
    StoresStoreTimezoneChoices["AMERICA_GUADELOUPE"] = "AMERICA_GUADELOUPE";
    /** America/Guatemala */
    StoresStoreTimezoneChoices["AMERICA_GUATEMALA"] = "AMERICA_GUATEMALA";
    /** America/Guayaquil */
    StoresStoreTimezoneChoices["AMERICA_GUAYAQUIL"] = "AMERICA_GUAYAQUIL";
    /** America/Guyana */
    StoresStoreTimezoneChoices["AMERICA_GUYANA"] = "AMERICA_GUYANA";
    /** America/Halifax */
    StoresStoreTimezoneChoices["AMERICA_HALIFAX"] = "AMERICA_HALIFAX";
    /** America/Havana */
    StoresStoreTimezoneChoices["AMERICA_HAVANA"] = "AMERICA_HAVANA";
    /** America/Hermosillo */
    StoresStoreTimezoneChoices["AMERICA_HERMOSILLO"] = "AMERICA_HERMOSILLO";
    /** America/Indianapolis */
    StoresStoreTimezoneChoices["AMERICA_INDIANAPOLIS"] = "AMERICA_INDIANAPOLIS";
    /** America/Indiana/Indianapolis */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_INDIANAPOLIS"] = "AMERICA_INDIANA_INDIANAPOLIS";
    /** America/Indiana/Knox */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_KNOX"] = "AMERICA_INDIANA_KNOX";
    /** America/Indiana/Marengo */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_MARENGO"] = "AMERICA_INDIANA_MARENGO";
    /** America/Indiana/Petersburg */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_PETERSBURG"] = "AMERICA_INDIANA_PETERSBURG";
    /** America/Indiana/Tell_City */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_TELL_CITY"] = "AMERICA_INDIANA_TELL_CITY";
    /** America/Indiana/Vevay */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_VEVAY"] = "AMERICA_INDIANA_VEVAY";
    /** America/Indiana/Vincennes */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_VINCENNES"] = "AMERICA_INDIANA_VINCENNES";
    /** America/Indiana/Winamac */
    StoresStoreTimezoneChoices["AMERICA_INDIANA_WINAMAC"] = "AMERICA_INDIANA_WINAMAC";
    /** America/Inuvik */
    StoresStoreTimezoneChoices["AMERICA_INUVIK"] = "AMERICA_INUVIK";
    /** America/Iqaluit */
    StoresStoreTimezoneChoices["AMERICA_IQALUIT"] = "AMERICA_IQALUIT";
    /** America/Jamaica */
    StoresStoreTimezoneChoices["AMERICA_JAMAICA"] = "AMERICA_JAMAICA";
    /** America/Jujuy */
    StoresStoreTimezoneChoices["AMERICA_JUJUY"] = "AMERICA_JUJUY";
    /** America/Juneau */
    StoresStoreTimezoneChoices["AMERICA_JUNEAU"] = "AMERICA_JUNEAU";
    /** America/Kentucky/Louisville */
    StoresStoreTimezoneChoices["AMERICA_KENTUCKY_LOUISVILLE"] = "AMERICA_KENTUCKY_LOUISVILLE";
    /** America/Kentucky/Monticello */
    StoresStoreTimezoneChoices["AMERICA_KENTUCKY_MONTICELLO"] = "AMERICA_KENTUCKY_MONTICELLO";
    /** America/Knox_IN */
    StoresStoreTimezoneChoices["AMERICA_KNOX_IN"] = "AMERICA_KNOX_IN";
    /** America/Kralendijk */
    StoresStoreTimezoneChoices["AMERICA_KRALENDIJK"] = "AMERICA_KRALENDIJK";
    /** America/La_Paz */
    StoresStoreTimezoneChoices["AMERICA_LA_PAZ"] = "AMERICA_LA_PAZ";
    /** America/Lima */
    StoresStoreTimezoneChoices["AMERICA_LIMA"] = "AMERICA_LIMA";
    /** America/Los_Angeles */
    StoresStoreTimezoneChoices["AMERICA_LOS_ANGELES"] = "AMERICA_LOS_ANGELES";
    /** America/Louisville */
    StoresStoreTimezoneChoices["AMERICA_LOUISVILLE"] = "AMERICA_LOUISVILLE";
    /** America/Lower_Princes */
    StoresStoreTimezoneChoices["AMERICA_LOWER_PRINCES"] = "AMERICA_LOWER_PRINCES";
    /** America/Maceio */
    StoresStoreTimezoneChoices["AMERICA_MACEIO"] = "AMERICA_MACEIO";
    /** America/Managua */
    StoresStoreTimezoneChoices["AMERICA_MANAGUA"] = "AMERICA_MANAGUA";
    /** America/Manaus */
    StoresStoreTimezoneChoices["AMERICA_MANAUS"] = "AMERICA_MANAUS";
    /** America/Marigot */
    StoresStoreTimezoneChoices["AMERICA_MARIGOT"] = "AMERICA_MARIGOT";
    /** America/Martinique */
    StoresStoreTimezoneChoices["AMERICA_MARTINIQUE"] = "AMERICA_MARTINIQUE";
    /** America/Matamoros */
    StoresStoreTimezoneChoices["AMERICA_MATAMOROS"] = "AMERICA_MATAMOROS";
    /** America/Mazatlan */
    StoresStoreTimezoneChoices["AMERICA_MAZATLAN"] = "AMERICA_MAZATLAN";
    /** America/Mendoza */
    StoresStoreTimezoneChoices["AMERICA_MENDOZA"] = "AMERICA_MENDOZA";
    /** America/Menominee */
    StoresStoreTimezoneChoices["AMERICA_MENOMINEE"] = "AMERICA_MENOMINEE";
    /** America/Merida */
    StoresStoreTimezoneChoices["AMERICA_MERIDA"] = "AMERICA_MERIDA";
    /** America/Metlakatla */
    StoresStoreTimezoneChoices["AMERICA_METLAKATLA"] = "AMERICA_METLAKATLA";
    /** America/Mexico_City */
    StoresStoreTimezoneChoices["AMERICA_MEXICO_CITY"] = "AMERICA_MEXICO_CITY";
    /** America/Miquelon */
    StoresStoreTimezoneChoices["AMERICA_MIQUELON"] = "AMERICA_MIQUELON";
    /** America/Moncton */
    StoresStoreTimezoneChoices["AMERICA_MONCTON"] = "AMERICA_MONCTON";
    /** America/Monterrey */
    StoresStoreTimezoneChoices["AMERICA_MONTERREY"] = "AMERICA_MONTERREY";
    /** America/Montevideo */
    StoresStoreTimezoneChoices["AMERICA_MONTEVIDEO"] = "AMERICA_MONTEVIDEO";
    /** America/Montreal */
    StoresStoreTimezoneChoices["AMERICA_MONTREAL"] = "AMERICA_MONTREAL";
    /** America/Montserrat */
    StoresStoreTimezoneChoices["AMERICA_MONTSERRAT"] = "AMERICA_MONTSERRAT";
    /** America/Nassau */
    StoresStoreTimezoneChoices["AMERICA_NASSAU"] = "AMERICA_NASSAU";
    /** America/New_York */
    StoresStoreTimezoneChoices["AMERICA_NEW_YORK"] = "AMERICA_NEW_YORK";
    /** America/Nipigon */
    StoresStoreTimezoneChoices["AMERICA_NIPIGON"] = "AMERICA_NIPIGON";
    /** America/Nome */
    StoresStoreTimezoneChoices["AMERICA_NOME"] = "AMERICA_NOME";
    /** America/Noronha */
    StoresStoreTimezoneChoices["AMERICA_NORONHA"] = "AMERICA_NORONHA";
    /** America/North_Dakota/Beulah */
    StoresStoreTimezoneChoices["AMERICA_NORTH_DAKOTA_BEULAH"] = "AMERICA_NORTH_DAKOTA_BEULAH";
    /** America/North_Dakota/Center */
    StoresStoreTimezoneChoices["AMERICA_NORTH_DAKOTA_CENTER"] = "AMERICA_NORTH_DAKOTA_CENTER";
    /** America/North_Dakota/New_Salem */
    StoresStoreTimezoneChoices["AMERICA_NORTH_DAKOTA_NEW_SALEM"] = "AMERICA_NORTH_DAKOTA_NEW_SALEM";
    /** America/Nuuk */
    StoresStoreTimezoneChoices["AMERICA_NUUK"] = "AMERICA_NUUK";
    /** America/Ojinaga */
    StoresStoreTimezoneChoices["AMERICA_OJINAGA"] = "AMERICA_OJINAGA";
    /** America/Panama */
    StoresStoreTimezoneChoices["AMERICA_PANAMA"] = "AMERICA_PANAMA";
    /** America/Pangnirtung */
    StoresStoreTimezoneChoices["AMERICA_PANGNIRTUNG"] = "AMERICA_PANGNIRTUNG";
    /** America/Paramaribo */
    StoresStoreTimezoneChoices["AMERICA_PARAMARIBO"] = "AMERICA_PARAMARIBO";
    /** America/Phoenix */
    StoresStoreTimezoneChoices["AMERICA_PHOENIX"] = "AMERICA_PHOENIX";
    /** America/Porto_Acre */
    StoresStoreTimezoneChoices["AMERICA_PORTO_ACRE"] = "AMERICA_PORTO_ACRE";
    /** America/Porto_Velho */
    StoresStoreTimezoneChoices["AMERICA_PORTO_VELHO"] = "AMERICA_PORTO_VELHO";
    /** America/Port-au-Prince */
    StoresStoreTimezoneChoices["AMERICA_PORT_AU_PRINCE"] = "AMERICA_PORT_AU_PRINCE";
    /** America/Port_of_Spain */
    StoresStoreTimezoneChoices["AMERICA_PORT_OF_SPAIN"] = "AMERICA_PORT_OF_SPAIN";
    /** America/Puerto_Rico */
    StoresStoreTimezoneChoices["AMERICA_PUERTO_RICO"] = "AMERICA_PUERTO_RICO";
    /** America/Punta_Arenas */
    StoresStoreTimezoneChoices["AMERICA_PUNTA_ARENAS"] = "AMERICA_PUNTA_ARENAS";
    /** America/Rainy_River */
    StoresStoreTimezoneChoices["AMERICA_RAINY_RIVER"] = "AMERICA_RAINY_RIVER";
    /** America/Rankin_Inlet */
    StoresStoreTimezoneChoices["AMERICA_RANKIN_INLET"] = "AMERICA_RANKIN_INLET";
    /** America/Recife */
    StoresStoreTimezoneChoices["AMERICA_RECIFE"] = "AMERICA_RECIFE";
    /** America/Regina */
    StoresStoreTimezoneChoices["AMERICA_REGINA"] = "AMERICA_REGINA";
    /** America/Resolute */
    StoresStoreTimezoneChoices["AMERICA_RESOLUTE"] = "AMERICA_RESOLUTE";
    /** America/Rio_Branco */
    StoresStoreTimezoneChoices["AMERICA_RIO_BRANCO"] = "AMERICA_RIO_BRANCO";
    /** America/Rosario */
    StoresStoreTimezoneChoices["AMERICA_ROSARIO"] = "AMERICA_ROSARIO";
    /** America/Santarem */
    StoresStoreTimezoneChoices["AMERICA_SANTAREM"] = "AMERICA_SANTAREM";
    /** America/Santa_Isabel */
    StoresStoreTimezoneChoices["AMERICA_SANTA_ISABEL"] = "AMERICA_SANTA_ISABEL";
    /** America/Santiago */
    StoresStoreTimezoneChoices["AMERICA_SANTIAGO"] = "AMERICA_SANTIAGO";
    /** America/Santo_Domingo */
    StoresStoreTimezoneChoices["AMERICA_SANTO_DOMINGO"] = "AMERICA_SANTO_DOMINGO";
    /** America/Sao_Paulo */
    StoresStoreTimezoneChoices["AMERICA_SAO_PAULO"] = "AMERICA_SAO_PAULO";
    /** America/Scoresbysund */
    StoresStoreTimezoneChoices["AMERICA_SCORESBYSUND"] = "AMERICA_SCORESBYSUND";
    /** America/Shiprock */
    StoresStoreTimezoneChoices["AMERICA_SHIPROCK"] = "AMERICA_SHIPROCK";
    /** America/Sitka */
    StoresStoreTimezoneChoices["AMERICA_SITKA"] = "AMERICA_SITKA";
    /** America/St_Barthelemy */
    StoresStoreTimezoneChoices["AMERICA_ST_BARTHELEMY"] = "AMERICA_ST_BARTHELEMY";
    /** America/St_Johns */
    StoresStoreTimezoneChoices["AMERICA_ST_JOHNS"] = "AMERICA_ST_JOHNS";
    /** America/St_Kitts */
    StoresStoreTimezoneChoices["AMERICA_ST_KITTS"] = "AMERICA_ST_KITTS";
    /** America/St_Lucia */
    StoresStoreTimezoneChoices["AMERICA_ST_LUCIA"] = "AMERICA_ST_LUCIA";
    /** America/St_Thomas */
    StoresStoreTimezoneChoices["AMERICA_ST_THOMAS"] = "AMERICA_ST_THOMAS";
    /** America/St_Vincent */
    StoresStoreTimezoneChoices["AMERICA_ST_VINCENT"] = "AMERICA_ST_VINCENT";
    /** America/Swift_Current */
    StoresStoreTimezoneChoices["AMERICA_SWIFT_CURRENT"] = "AMERICA_SWIFT_CURRENT";
    /** America/Tegucigalpa */
    StoresStoreTimezoneChoices["AMERICA_TEGUCIGALPA"] = "AMERICA_TEGUCIGALPA";
    /** America/Thule */
    StoresStoreTimezoneChoices["AMERICA_THULE"] = "AMERICA_THULE";
    /** America/Thunder_Bay */
    StoresStoreTimezoneChoices["AMERICA_THUNDER_BAY"] = "AMERICA_THUNDER_BAY";
    /** America/Tijuana */
    StoresStoreTimezoneChoices["AMERICA_TIJUANA"] = "AMERICA_TIJUANA";
    /** America/Toronto */
    StoresStoreTimezoneChoices["AMERICA_TORONTO"] = "AMERICA_TORONTO";
    /** America/Tortola */
    StoresStoreTimezoneChoices["AMERICA_TORTOLA"] = "AMERICA_TORTOLA";
    /** America/Vancouver */
    StoresStoreTimezoneChoices["AMERICA_VANCOUVER"] = "AMERICA_VANCOUVER";
    /** America/Virgin */
    StoresStoreTimezoneChoices["AMERICA_VIRGIN"] = "AMERICA_VIRGIN";
    /** America/Whitehorse */
    StoresStoreTimezoneChoices["AMERICA_WHITEHORSE"] = "AMERICA_WHITEHORSE";
    /** America/Winnipeg */
    StoresStoreTimezoneChoices["AMERICA_WINNIPEG"] = "AMERICA_WINNIPEG";
    /** America/Yakutat */
    StoresStoreTimezoneChoices["AMERICA_YAKUTAT"] = "AMERICA_YAKUTAT";
    /** America/Yellowknife */
    StoresStoreTimezoneChoices["AMERICA_YELLOWKNIFE"] = "AMERICA_YELLOWKNIFE";
    /** Antarctica/Casey */
    StoresStoreTimezoneChoices["ANTARCTICA_CASEY"] = "ANTARCTICA_CASEY";
    /** Antarctica/Davis */
    StoresStoreTimezoneChoices["ANTARCTICA_DAVIS"] = "ANTARCTICA_DAVIS";
    /** Antarctica/DumontDUrville */
    StoresStoreTimezoneChoices["ANTARCTICA_DUMONTDURVILLE"] = "ANTARCTICA_DUMONTDURVILLE";
    /** Antarctica/Macquarie */
    StoresStoreTimezoneChoices["ANTARCTICA_MACQUARIE"] = "ANTARCTICA_MACQUARIE";
    /** Antarctica/Mawson */
    StoresStoreTimezoneChoices["ANTARCTICA_MAWSON"] = "ANTARCTICA_MAWSON";
    /** Antarctica/McMurdo */
    StoresStoreTimezoneChoices["ANTARCTICA_MCMURDO"] = "ANTARCTICA_MCMURDO";
    /** Antarctica/Palmer */
    StoresStoreTimezoneChoices["ANTARCTICA_PALMER"] = "ANTARCTICA_PALMER";
    /** Antarctica/Rothera */
    StoresStoreTimezoneChoices["ANTARCTICA_ROTHERA"] = "ANTARCTICA_ROTHERA";
    /** Antarctica/South_Pole */
    StoresStoreTimezoneChoices["ANTARCTICA_SOUTH_POLE"] = "ANTARCTICA_SOUTH_POLE";
    /** Antarctica/Syowa */
    StoresStoreTimezoneChoices["ANTARCTICA_SYOWA"] = "ANTARCTICA_SYOWA";
    /** Antarctica/Troll */
    StoresStoreTimezoneChoices["ANTARCTICA_TROLL"] = "ANTARCTICA_TROLL";
    /** Antarctica/Vostok */
    StoresStoreTimezoneChoices["ANTARCTICA_VOSTOK"] = "ANTARCTICA_VOSTOK";
    /** Arctic/Longyearbyen */
    StoresStoreTimezoneChoices["ARCTIC_LONGYEARBYEN"] = "ARCTIC_LONGYEARBYEN";
    /** Asia/Aden */
    StoresStoreTimezoneChoices["ASIA_ADEN"] = "ASIA_ADEN";
    /** Asia/Almaty */
    StoresStoreTimezoneChoices["ASIA_ALMATY"] = "ASIA_ALMATY";
    /** Asia/Amman */
    StoresStoreTimezoneChoices["ASIA_AMMAN"] = "ASIA_AMMAN";
    /** Asia/Anadyr */
    StoresStoreTimezoneChoices["ASIA_ANADYR"] = "ASIA_ANADYR";
    /** Asia/Aqtau */
    StoresStoreTimezoneChoices["ASIA_AQTAU"] = "ASIA_AQTAU";
    /** Asia/Aqtobe */
    StoresStoreTimezoneChoices["ASIA_AQTOBE"] = "ASIA_AQTOBE";
    /** Asia/Ashgabat */
    StoresStoreTimezoneChoices["ASIA_ASHGABAT"] = "ASIA_ASHGABAT";
    /** Asia/Ashkhabad */
    StoresStoreTimezoneChoices["ASIA_ASHKHABAD"] = "ASIA_ASHKHABAD";
    /** Asia/Atyrau */
    StoresStoreTimezoneChoices["ASIA_ATYRAU"] = "ASIA_ATYRAU";
    /** Asia/Baghdad */
    StoresStoreTimezoneChoices["ASIA_BAGHDAD"] = "ASIA_BAGHDAD";
    /** Asia/Bahrain */
    StoresStoreTimezoneChoices["ASIA_BAHRAIN"] = "ASIA_BAHRAIN";
    /** Asia/Baku */
    StoresStoreTimezoneChoices["ASIA_BAKU"] = "ASIA_BAKU";
    /** Asia/Bangkok */
    StoresStoreTimezoneChoices["ASIA_BANGKOK"] = "ASIA_BANGKOK";
    /** Asia/Barnaul */
    StoresStoreTimezoneChoices["ASIA_BARNAUL"] = "ASIA_BARNAUL";
    /** Asia/Beirut */
    StoresStoreTimezoneChoices["ASIA_BEIRUT"] = "ASIA_BEIRUT";
    /** Asia/Bishkek */
    StoresStoreTimezoneChoices["ASIA_BISHKEK"] = "ASIA_BISHKEK";
    /** Asia/Brunei */
    StoresStoreTimezoneChoices["ASIA_BRUNEI"] = "ASIA_BRUNEI";
    /** Asia/Calcutta */
    StoresStoreTimezoneChoices["ASIA_CALCUTTA"] = "ASIA_CALCUTTA";
    /** Asia/Chita */
    StoresStoreTimezoneChoices["ASIA_CHITA"] = "ASIA_CHITA";
    /** Asia/Choibalsan */
    StoresStoreTimezoneChoices["ASIA_CHOIBALSAN"] = "ASIA_CHOIBALSAN";
    /** Asia/Chongqing */
    StoresStoreTimezoneChoices["ASIA_CHONGQING"] = "ASIA_CHONGQING";
    /** Asia/Chungking */
    StoresStoreTimezoneChoices["ASIA_CHUNGKING"] = "ASIA_CHUNGKING";
    /** Asia/Colombo */
    StoresStoreTimezoneChoices["ASIA_COLOMBO"] = "ASIA_COLOMBO";
    /** Asia/Dacca */
    StoresStoreTimezoneChoices["ASIA_DACCA"] = "ASIA_DACCA";
    /** Asia/Damascus */
    StoresStoreTimezoneChoices["ASIA_DAMASCUS"] = "ASIA_DAMASCUS";
    /** Asia/Dhaka */
    StoresStoreTimezoneChoices["ASIA_DHAKA"] = "ASIA_DHAKA";
    /** Asia/Dili */
    StoresStoreTimezoneChoices["ASIA_DILI"] = "ASIA_DILI";
    /** Asia/Dubai */
    StoresStoreTimezoneChoices["ASIA_DUBAI"] = "ASIA_DUBAI";
    /** Asia/Dushanbe */
    StoresStoreTimezoneChoices["ASIA_DUSHANBE"] = "ASIA_DUSHANBE";
    /** Asia/Famagusta */
    StoresStoreTimezoneChoices["ASIA_FAMAGUSTA"] = "ASIA_FAMAGUSTA";
    /** Asia/Gaza */
    StoresStoreTimezoneChoices["ASIA_GAZA"] = "ASIA_GAZA";
    /** Asia/Harbin */
    StoresStoreTimezoneChoices["ASIA_HARBIN"] = "ASIA_HARBIN";
    /** Asia/Hebron */
    StoresStoreTimezoneChoices["ASIA_HEBRON"] = "ASIA_HEBRON";
    /** Asia/Hong_Kong */
    StoresStoreTimezoneChoices["ASIA_HONG_KONG"] = "ASIA_HONG_KONG";
    /** Asia/Hovd */
    StoresStoreTimezoneChoices["ASIA_HOVD"] = "ASIA_HOVD";
    /** Asia/Ho_Chi_Minh */
    StoresStoreTimezoneChoices["ASIA_HO_CHI_MINH"] = "ASIA_HO_CHI_MINH";
    /** Asia/Irkutsk */
    StoresStoreTimezoneChoices["ASIA_IRKUTSK"] = "ASIA_IRKUTSK";
    /** Asia/Istanbul */
    StoresStoreTimezoneChoices["ASIA_ISTANBUL"] = "ASIA_ISTANBUL";
    /** Asia/Jakarta */
    StoresStoreTimezoneChoices["ASIA_JAKARTA"] = "ASIA_JAKARTA";
    /** Asia/Jayapura */
    StoresStoreTimezoneChoices["ASIA_JAYAPURA"] = "ASIA_JAYAPURA";
    /** Asia/Jerusalem */
    StoresStoreTimezoneChoices["ASIA_JERUSALEM"] = "ASIA_JERUSALEM";
    /** Asia/Kabul */
    StoresStoreTimezoneChoices["ASIA_KABUL"] = "ASIA_KABUL";
    /** Asia/Kamchatka */
    StoresStoreTimezoneChoices["ASIA_KAMCHATKA"] = "ASIA_KAMCHATKA";
    /** Asia/Karachi */
    StoresStoreTimezoneChoices["ASIA_KARACHI"] = "ASIA_KARACHI";
    /** Asia/Kashgar */
    StoresStoreTimezoneChoices["ASIA_KASHGAR"] = "ASIA_KASHGAR";
    /** Asia/Kathmandu */
    StoresStoreTimezoneChoices["ASIA_KATHMANDU"] = "ASIA_KATHMANDU";
    /** Asia/Katmandu */
    StoresStoreTimezoneChoices["ASIA_KATMANDU"] = "ASIA_KATMANDU";
    /** Asia/Khandyga */
    StoresStoreTimezoneChoices["ASIA_KHANDYGA"] = "ASIA_KHANDYGA";
    /** Asia/Kolkata */
    StoresStoreTimezoneChoices["ASIA_KOLKATA"] = "ASIA_KOLKATA";
    /** Asia/Krasnoyarsk */
    StoresStoreTimezoneChoices["ASIA_KRASNOYARSK"] = "ASIA_KRASNOYARSK";
    /** Asia/Kuala_Lumpur */
    StoresStoreTimezoneChoices["ASIA_KUALA_LUMPUR"] = "ASIA_KUALA_LUMPUR";
    /** Asia/Kuching */
    StoresStoreTimezoneChoices["ASIA_KUCHING"] = "ASIA_KUCHING";
    /** Asia/Kuwait */
    StoresStoreTimezoneChoices["ASIA_KUWAIT"] = "ASIA_KUWAIT";
    /** Asia/Macao */
    StoresStoreTimezoneChoices["ASIA_MACAO"] = "ASIA_MACAO";
    /** Asia/Macau */
    StoresStoreTimezoneChoices["ASIA_MACAU"] = "ASIA_MACAU";
    /** Asia/Magadan */
    StoresStoreTimezoneChoices["ASIA_MAGADAN"] = "ASIA_MAGADAN";
    /** Asia/Makassar */
    StoresStoreTimezoneChoices["ASIA_MAKASSAR"] = "ASIA_MAKASSAR";
    /** Asia/Manila */
    StoresStoreTimezoneChoices["ASIA_MANILA"] = "ASIA_MANILA";
    /** Asia/Muscat */
    StoresStoreTimezoneChoices["ASIA_MUSCAT"] = "ASIA_MUSCAT";
    /** Asia/Nicosia */
    StoresStoreTimezoneChoices["ASIA_NICOSIA"] = "ASIA_NICOSIA";
    /** Asia/Novokuznetsk */
    StoresStoreTimezoneChoices["ASIA_NOVOKUZNETSK"] = "ASIA_NOVOKUZNETSK";
    /** Asia/Novosibirsk */
    StoresStoreTimezoneChoices["ASIA_NOVOSIBIRSK"] = "ASIA_NOVOSIBIRSK";
    /** Asia/Omsk */
    StoresStoreTimezoneChoices["ASIA_OMSK"] = "ASIA_OMSK";
    /** Asia/Oral */
    StoresStoreTimezoneChoices["ASIA_ORAL"] = "ASIA_ORAL";
    /** Asia/Phnom_Penh */
    StoresStoreTimezoneChoices["ASIA_PHNOM_PENH"] = "ASIA_PHNOM_PENH";
    /** Asia/Pontianak */
    StoresStoreTimezoneChoices["ASIA_PONTIANAK"] = "ASIA_PONTIANAK";
    /** Asia/Pyongyang */
    StoresStoreTimezoneChoices["ASIA_PYONGYANG"] = "ASIA_PYONGYANG";
    /** Asia/Qatar */
    StoresStoreTimezoneChoices["ASIA_QATAR"] = "ASIA_QATAR";
    /** Asia/Qostanay */
    StoresStoreTimezoneChoices["ASIA_QOSTANAY"] = "ASIA_QOSTANAY";
    /** Asia/Qyzylorda */
    StoresStoreTimezoneChoices["ASIA_QYZYLORDA"] = "ASIA_QYZYLORDA";
    /** Asia/Rangoon */
    StoresStoreTimezoneChoices["ASIA_RANGOON"] = "ASIA_RANGOON";
    /** Asia/Riyadh */
    StoresStoreTimezoneChoices["ASIA_RIYADH"] = "ASIA_RIYADH";
    /** Asia/Saigon */
    StoresStoreTimezoneChoices["ASIA_SAIGON"] = "ASIA_SAIGON";
    /** Asia/Sakhalin */
    StoresStoreTimezoneChoices["ASIA_SAKHALIN"] = "ASIA_SAKHALIN";
    /** Asia/Samarkand */
    StoresStoreTimezoneChoices["ASIA_SAMARKAND"] = "ASIA_SAMARKAND";
    /** Asia/Seoul */
    StoresStoreTimezoneChoices["ASIA_SEOUL"] = "ASIA_SEOUL";
    /** Asia/Shanghai */
    StoresStoreTimezoneChoices["ASIA_SHANGHAI"] = "ASIA_SHANGHAI";
    /** Asia/Singapore */
    StoresStoreTimezoneChoices["ASIA_SINGAPORE"] = "ASIA_SINGAPORE";
    /** Asia/Srednekolymsk */
    StoresStoreTimezoneChoices["ASIA_SREDNEKOLYMSK"] = "ASIA_SREDNEKOLYMSK";
    /** Asia/Taipei */
    StoresStoreTimezoneChoices["ASIA_TAIPEI"] = "ASIA_TAIPEI";
    /** Asia/Tashkent */
    StoresStoreTimezoneChoices["ASIA_TASHKENT"] = "ASIA_TASHKENT";
    /** Asia/Tbilisi */
    StoresStoreTimezoneChoices["ASIA_TBILISI"] = "ASIA_TBILISI";
    /** Asia/Tehran */
    StoresStoreTimezoneChoices["ASIA_TEHRAN"] = "ASIA_TEHRAN";
    /** Asia/Tel_Aviv */
    StoresStoreTimezoneChoices["ASIA_TEL_AVIV"] = "ASIA_TEL_AVIV";
    /** Asia/Thimbu */
    StoresStoreTimezoneChoices["ASIA_THIMBU"] = "ASIA_THIMBU";
    /** Asia/Thimphu */
    StoresStoreTimezoneChoices["ASIA_THIMPHU"] = "ASIA_THIMPHU";
    /** Asia/Tokyo */
    StoresStoreTimezoneChoices["ASIA_TOKYO"] = "ASIA_TOKYO";
    /** Asia/Tomsk */
    StoresStoreTimezoneChoices["ASIA_TOMSK"] = "ASIA_TOMSK";
    /** Asia/Ujung_Pandang */
    StoresStoreTimezoneChoices["ASIA_UJUNG_PANDANG"] = "ASIA_UJUNG_PANDANG";
    /** Asia/Ulaanbaatar */
    StoresStoreTimezoneChoices["ASIA_ULAANBAATAR"] = "ASIA_ULAANBAATAR";
    /** Asia/Ulan_Bator */
    StoresStoreTimezoneChoices["ASIA_ULAN_BATOR"] = "ASIA_ULAN_BATOR";
    /** Asia/Urumqi */
    StoresStoreTimezoneChoices["ASIA_URUMQI"] = "ASIA_URUMQI";
    /** Asia/Ust-Nera */
    StoresStoreTimezoneChoices["ASIA_UST_NERA"] = "ASIA_UST_NERA";
    /** Asia/Vientiane */
    StoresStoreTimezoneChoices["ASIA_VIENTIANE"] = "ASIA_VIENTIANE";
    /** Asia/Vladivostok */
    StoresStoreTimezoneChoices["ASIA_VLADIVOSTOK"] = "ASIA_VLADIVOSTOK";
    /** Asia/Yakutsk */
    StoresStoreTimezoneChoices["ASIA_YAKUTSK"] = "ASIA_YAKUTSK";
    /** Asia/Yangon */
    StoresStoreTimezoneChoices["ASIA_YANGON"] = "ASIA_YANGON";
    /** Asia/Yekaterinburg */
    StoresStoreTimezoneChoices["ASIA_YEKATERINBURG"] = "ASIA_YEKATERINBURG";
    /** Asia/Yerevan */
    StoresStoreTimezoneChoices["ASIA_YEREVAN"] = "ASIA_YEREVAN";
    /** Atlantic/Azores */
    StoresStoreTimezoneChoices["ATLANTIC_AZORES"] = "ATLANTIC_AZORES";
    /** Atlantic/Bermuda */
    StoresStoreTimezoneChoices["ATLANTIC_BERMUDA"] = "ATLANTIC_BERMUDA";
    /** Atlantic/Canary */
    StoresStoreTimezoneChoices["ATLANTIC_CANARY"] = "ATLANTIC_CANARY";
    /** Atlantic/Cape_Verde */
    StoresStoreTimezoneChoices["ATLANTIC_CAPE_VERDE"] = "ATLANTIC_CAPE_VERDE";
    /** Atlantic/Faeroe */
    StoresStoreTimezoneChoices["ATLANTIC_FAEROE"] = "ATLANTIC_FAEROE";
    /** Atlantic/Faroe */
    StoresStoreTimezoneChoices["ATLANTIC_FAROE"] = "ATLANTIC_FAROE";
    /** Atlantic/Jan_Mayen */
    StoresStoreTimezoneChoices["ATLANTIC_JAN_MAYEN"] = "ATLANTIC_JAN_MAYEN";
    /** Atlantic/Madeira */
    StoresStoreTimezoneChoices["ATLANTIC_MADEIRA"] = "ATLANTIC_MADEIRA";
    /** Atlantic/Reykjavik */
    StoresStoreTimezoneChoices["ATLANTIC_REYKJAVIK"] = "ATLANTIC_REYKJAVIK";
    /** Atlantic/South_Georgia */
    StoresStoreTimezoneChoices["ATLANTIC_SOUTH_GEORGIA"] = "ATLANTIC_SOUTH_GEORGIA";
    /** Atlantic/Stanley */
    StoresStoreTimezoneChoices["ATLANTIC_STANLEY"] = "ATLANTIC_STANLEY";
    /** Atlantic/St_Helena */
    StoresStoreTimezoneChoices["ATLANTIC_ST_HELENA"] = "ATLANTIC_ST_HELENA";
    /** Australia/ACT */
    StoresStoreTimezoneChoices["AUSTRALIA_ACT"] = "AUSTRALIA_ACT";
    /** Australia/Adelaide */
    StoresStoreTimezoneChoices["AUSTRALIA_ADELAIDE"] = "AUSTRALIA_ADELAIDE";
    /** Australia/Brisbane */
    StoresStoreTimezoneChoices["AUSTRALIA_BRISBANE"] = "AUSTRALIA_BRISBANE";
    /** Australia/Broken_Hill */
    StoresStoreTimezoneChoices["AUSTRALIA_BROKEN_HILL"] = "AUSTRALIA_BROKEN_HILL";
    /** Australia/Canberra */
    StoresStoreTimezoneChoices["AUSTRALIA_CANBERRA"] = "AUSTRALIA_CANBERRA";
    /** Australia/Currie */
    StoresStoreTimezoneChoices["AUSTRALIA_CURRIE"] = "AUSTRALIA_CURRIE";
    /** Australia/Darwin */
    StoresStoreTimezoneChoices["AUSTRALIA_DARWIN"] = "AUSTRALIA_DARWIN";
    /** Australia/Eucla */
    StoresStoreTimezoneChoices["AUSTRALIA_EUCLA"] = "AUSTRALIA_EUCLA";
    /** Australia/Hobart */
    StoresStoreTimezoneChoices["AUSTRALIA_HOBART"] = "AUSTRALIA_HOBART";
    /** Australia/LHI */
    StoresStoreTimezoneChoices["AUSTRALIA_LHI"] = "AUSTRALIA_LHI";
    /** Australia/Lindeman */
    StoresStoreTimezoneChoices["AUSTRALIA_LINDEMAN"] = "AUSTRALIA_LINDEMAN";
    /** Australia/Lord_Howe */
    StoresStoreTimezoneChoices["AUSTRALIA_LORD_HOWE"] = "AUSTRALIA_LORD_HOWE";
    /** Australia/Melbourne */
    StoresStoreTimezoneChoices["AUSTRALIA_MELBOURNE"] = "AUSTRALIA_MELBOURNE";
    /** Australia/North */
    StoresStoreTimezoneChoices["AUSTRALIA_NORTH"] = "AUSTRALIA_NORTH";
    /** Australia/NSW */
    StoresStoreTimezoneChoices["AUSTRALIA_NSW"] = "AUSTRALIA_NSW";
    /** Australia/Perth */
    StoresStoreTimezoneChoices["AUSTRALIA_PERTH"] = "AUSTRALIA_PERTH";
    /** Australia/Queensland */
    StoresStoreTimezoneChoices["AUSTRALIA_QUEENSLAND"] = "AUSTRALIA_QUEENSLAND";
    /** Australia/South */
    StoresStoreTimezoneChoices["AUSTRALIA_SOUTH"] = "AUSTRALIA_SOUTH";
    /** Australia/Sydney */
    StoresStoreTimezoneChoices["AUSTRALIA_SYDNEY"] = "AUSTRALIA_SYDNEY";
    /** Australia/Tasmania */
    StoresStoreTimezoneChoices["AUSTRALIA_TASMANIA"] = "AUSTRALIA_TASMANIA";
    /** Australia/Victoria */
    StoresStoreTimezoneChoices["AUSTRALIA_VICTORIA"] = "AUSTRALIA_VICTORIA";
    /** Australia/West */
    StoresStoreTimezoneChoices["AUSTRALIA_WEST"] = "AUSTRALIA_WEST";
    /** Australia/Yancowinna */
    StoresStoreTimezoneChoices["AUSTRALIA_YANCOWINNA"] = "AUSTRALIA_YANCOWINNA";
    /** Brazil/Acre */
    StoresStoreTimezoneChoices["BRAZIL_ACRE"] = "BRAZIL_ACRE";
    /** Brazil/DeNoronha */
    StoresStoreTimezoneChoices["BRAZIL_DENORONHA"] = "BRAZIL_DENORONHA";
    /** Brazil/East */
    StoresStoreTimezoneChoices["BRAZIL_EAST"] = "BRAZIL_EAST";
    /** Brazil/West */
    StoresStoreTimezoneChoices["BRAZIL_WEST"] = "BRAZIL_WEST";
    /** Canada/Atlantic */
    StoresStoreTimezoneChoices["CANADA_ATLANTIC"] = "CANADA_ATLANTIC";
    /** Canada/Central */
    StoresStoreTimezoneChoices["CANADA_CENTRAL"] = "CANADA_CENTRAL";
    /** Canada/Eastern */
    StoresStoreTimezoneChoices["CANADA_EASTERN"] = "CANADA_EASTERN";
    /** Canada/Mountain */
    StoresStoreTimezoneChoices["CANADA_MOUNTAIN"] = "CANADA_MOUNTAIN";
    /** Canada/Newfoundland */
    StoresStoreTimezoneChoices["CANADA_NEWFOUNDLAND"] = "CANADA_NEWFOUNDLAND";
    /** Canada/Pacific */
    StoresStoreTimezoneChoices["CANADA_PACIFIC"] = "CANADA_PACIFIC";
    /** Canada/Saskatchewan */
    StoresStoreTimezoneChoices["CANADA_SASKATCHEWAN"] = "CANADA_SASKATCHEWAN";
    /** Canada/Yukon */
    StoresStoreTimezoneChoices["CANADA_YUKON"] = "CANADA_YUKON";
    /** CET */
    StoresStoreTimezoneChoices["CET"] = "CET";
    /** Chile/Continental */
    StoresStoreTimezoneChoices["CHILE_CONTINENTAL"] = "CHILE_CONTINENTAL";
    /** Chile/EasterIsland */
    StoresStoreTimezoneChoices["CHILE_EASTERISLAND"] = "CHILE_EASTERISLAND";
    /** CST6CDT */
    StoresStoreTimezoneChoices["CST6CDT"] = "CST6CDT";
    /** Cuba */
    StoresStoreTimezoneChoices["CUBA"] = "CUBA";
    /** EET */
    StoresStoreTimezoneChoices["EET"] = "EET";
    /** Egypt */
    StoresStoreTimezoneChoices["EGYPT"] = "EGYPT";
    /** Eire */
    StoresStoreTimezoneChoices["EIRE"] = "EIRE";
    /** EST */
    StoresStoreTimezoneChoices["EST"] = "EST";
    /** EST5EDT */
    StoresStoreTimezoneChoices["EST5EDT"] = "EST5EDT";
    /** Etc/GMT */
    StoresStoreTimezoneChoices["ETC_GMT"] = "ETC_GMT";
    /** Etc/GMT0 */
    StoresStoreTimezoneChoices["ETC_GMT0"] = "ETC_GMT0";
    /** Etc/GMT+0 */
    StoresStoreTimezoneChoices["ETC_GMT_0"] = "ETC_GMT_0";
    /** Etc/GMT-0 */
    StoresStoreTimezoneChoices["ETC_GMT_0_404"] = "ETC_GMT_0_404";
    /** Etc/GMT+1 */
    StoresStoreTimezoneChoices["ETC_GMT_1"] = "ETC_GMT_1";
    /** Etc/GMT-1 */
    StoresStoreTimezoneChoices["ETC_GMT_1_405"] = "ETC_GMT_1_405";
    /** Etc/GMT+2 */
    StoresStoreTimezoneChoices["ETC_GMT_2"] = "ETC_GMT_2";
    /** Etc/GMT-2 */
    StoresStoreTimezoneChoices["ETC_GMT_2_411"] = "ETC_GMT_2_411";
    /** Etc/GMT+3 */
    StoresStoreTimezoneChoices["ETC_GMT_3"] = "ETC_GMT_3";
    /** Etc/GMT-3 */
    StoresStoreTimezoneChoices["ETC_GMT_3_412"] = "ETC_GMT_3_412";
    /** Etc/GMT+4 */
    StoresStoreTimezoneChoices["ETC_GMT_4"] = "ETC_GMT_4";
    /** Etc/GMT-4 */
    StoresStoreTimezoneChoices["ETC_GMT_4_413"] = "ETC_GMT_4_413";
    /** Etc/GMT+5 */
    StoresStoreTimezoneChoices["ETC_GMT_5"] = "ETC_GMT_5";
    /** Etc/GMT-5 */
    StoresStoreTimezoneChoices["ETC_GMT_5_414"] = "ETC_GMT_5_414";
    /** Etc/GMT+6 */
    StoresStoreTimezoneChoices["ETC_GMT_6"] = "ETC_GMT_6";
    /** Etc/GMT-6 */
    StoresStoreTimezoneChoices["ETC_GMT_6_415"] = "ETC_GMT_6_415";
    /** Etc/GMT+7 */
    StoresStoreTimezoneChoices["ETC_GMT_7"] = "ETC_GMT_7";
    /** Etc/GMT-7 */
    StoresStoreTimezoneChoices["ETC_GMT_7_416"] = "ETC_GMT_7_416";
    /** Etc/GMT+8 */
    StoresStoreTimezoneChoices["ETC_GMT_8"] = "ETC_GMT_8";
    /** Etc/GMT-8 */
    StoresStoreTimezoneChoices["ETC_GMT_8_417"] = "ETC_GMT_8_417";
    /** Etc/GMT+9 */
    StoresStoreTimezoneChoices["ETC_GMT_9"] = "ETC_GMT_9";
    /** Etc/GMT-9 */
    StoresStoreTimezoneChoices["ETC_GMT_9_418"] = "ETC_GMT_9_418";
    /** Etc/GMT+10 */
    StoresStoreTimezoneChoices["ETC_GMT_10"] = "ETC_GMT_10";
    /** Etc/GMT-10 */
    StoresStoreTimezoneChoices["ETC_GMT_10_406"] = "ETC_GMT_10_406";
    /** Etc/GMT+11 */
    StoresStoreTimezoneChoices["ETC_GMT_11"] = "ETC_GMT_11";
    /** Etc/GMT-11 */
    StoresStoreTimezoneChoices["ETC_GMT_11_407"] = "ETC_GMT_11_407";
    /** Etc/GMT+12 */
    StoresStoreTimezoneChoices["ETC_GMT_12"] = "ETC_GMT_12";
    /** Etc/GMT-12 */
    StoresStoreTimezoneChoices["ETC_GMT_12_408"] = "ETC_GMT_12_408";
    /** Etc/GMT-13 */
    StoresStoreTimezoneChoices["ETC_GMT_13"] = "ETC_GMT_13";
    /** Etc/GMT-14 */
    StoresStoreTimezoneChoices["ETC_GMT_14"] = "ETC_GMT_14";
    /** Etc/Greenwich */
    StoresStoreTimezoneChoices["ETC_GREENWICH"] = "ETC_GREENWICH";
    /** Etc/UCT */
    StoresStoreTimezoneChoices["ETC_UCT"] = "ETC_UCT";
    /** Etc/Universal */
    StoresStoreTimezoneChoices["ETC_UNIVERSAL"] = "ETC_UNIVERSAL";
    /** Etc/UTC */
    StoresStoreTimezoneChoices["ETC_UTC"] = "ETC_UTC";
    /** Etc/Zulu */
    StoresStoreTimezoneChoices["ETC_ZULU"] = "ETC_ZULU";
    /** Europe/Amsterdam */
    StoresStoreTimezoneChoices["EUROPE_AMSTERDAM"] = "EUROPE_AMSTERDAM";
    /** Europe/Andorra */
    StoresStoreTimezoneChoices["EUROPE_ANDORRA"] = "EUROPE_ANDORRA";
    /** Europe/Astrakhan */
    StoresStoreTimezoneChoices["EUROPE_ASTRAKHAN"] = "EUROPE_ASTRAKHAN";
    /** Europe/Athens */
    StoresStoreTimezoneChoices["EUROPE_ATHENS"] = "EUROPE_ATHENS";
    /** Europe/Belfast */
    StoresStoreTimezoneChoices["EUROPE_BELFAST"] = "EUROPE_BELFAST";
    /** Europe/Belgrade */
    StoresStoreTimezoneChoices["EUROPE_BELGRADE"] = "EUROPE_BELGRADE";
    /** Europe/Berlin */
    StoresStoreTimezoneChoices["EUROPE_BERLIN"] = "EUROPE_BERLIN";
    /** Europe/Bratislava */
    StoresStoreTimezoneChoices["EUROPE_BRATISLAVA"] = "EUROPE_BRATISLAVA";
    /** Europe/Brussels */
    StoresStoreTimezoneChoices["EUROPE_BRUSSELS"] = "EUROPE_BRUSSELS";
    /** Europe/Bucharest */
    StoresStoreTimezoneChoices["EUROPE_BUCHAREST"] = "EUROPE_BUCHAREST";
    /** Europe/Budapest */
    StoresStoreTimezoneChoices["EUROPE_BUDAPEST"] = "EUROPE_BUDAPEST";
    /** Europe/Busingen */
    StoresStoreTimezoneChoices["EUROPE_BUSINGEN"] = "EUROPE_BUSINGEN";
    /** Europe/Chisinau */
    StoresStoreTimezoneChoices["EUROPE_CHISINAU"] = "EUROPE_CHISINAU";
    /** Europe/Copenhagen */
    StoresStoreTimezoneChoices["EUROPE_COPENHAGEN"] = "EUROPE_COPENHAGEN";
    /** Europe/Dublin */
    StoresStoreTimezoneChoices["EUROPE_DUBLIN"] = "EUROPE_DUBLIN";
    /** Europe/Gibraltar */
    StoresStoreTimezoneChoices["EUROPE_GIBRALTAR"] = "EUROPE_GIBRALTAR";
    /** Europe/Guernsey */
    StoresStoreTimezoneChoices["EUROPE_GUERNSEY"] = "EUROPE_GUERNSEY";
    /** Europe/Helsinki */
    StoresStoreTimezoneChoices["EUROPE_HELSINKI"] = "EUROPE_HELSINKI";
    /** Europe/Isle_of_Man */
    StoresStoreTimezoneChoices["EUROPE_ISLE_OF_MAN"] = "EUROPE_ISLE_OF_MAN";
    /** Europe/Istanbul */
    StoresStoreTimezoneChoices["EUROPE_ISTANBUL"] = "EUROPE_ISTANBUL";
    /** Europe/Jersey */
    StoresStoreTimezoneChoices["EUROPE_JERSEY"] = "EUROPE_JERSEY";
    /** Europe/Kaliningrad */
    StoresStoreTimezoneChoices["EUROPE_KALININGRAD"] = "EUROPE_KALININGRAD";
    /** Europe/Kiev */
    StoresStoreTimezoneChoices["EUROPE_KIEV"] = "EUROPE_KIEV";
    /** Europe/Kirov */
    StoresStoreTimezoneChoices["EUROPE_KIROV"] = "EUROPE_KIROV";
    /** Europe/Lisbon */
    StoresStoreTimezoneChoices["EUROPE_LISBON"] = "EUROPE_LISBON";
    /** Europe/Ljubljana */
    StoresStoreTimezoneChoices["EUROPE_LJUBLJANA"] = "EUROPE_LJUBLJANA";
    /** Europe/London */
    StoresStoreTimezoneChoices["EUROPE_LONDON"] = "EUROPE_LONDON";
    /** Europe/Luxembourg */
    StoresStoreTimezoneChoices["EUROPE_LUXEMBOURG"] = "EUROPE_LUXEMBOURG";
    /** Europe/Madrid */
    StoresStoreTimezoneChoices["EUROPE_MADRID"] = "EUROPE_MADRID";
    /** Europe/Malta */
    StoresStoreTimezoneChoices["EUROPE_MALTA"] = "EUROPE_MALTA";
    /** Europe/Mariehamn */
    StoresStoreTimezoneChoices["EUROPE_MARIEHAMN"] = "EUROPE_MARIEHAMN";
    /** Europe/Minsk */
    StoresStoreTimezoneChoices["EUROPE_MINSK"] = "EUROPE_MINSK";
    /** Europe/Monaco */
    StoresStoreTimezoneChoices["EUROPE_MONACO"] = "EUROPE_MONACO";
    /** Europe/Moscow */
    StoresStoreTimezoneChoices["EUROPE_MOSCOW"] = "EUROPE_MOSCOW";
    /** Europe/Nicosia */
    StoresStoreTimezoneChoices["EUROPE_NICOSIA"] = "EUROPE_NICOSIA";
    /** Europe/Oslo */
    StoresStoreTimezoneChoices["EUROPE_OSLO"] = "EUROPE_OSLO";
    /** Europe/Paris */
    StoresStoreTimezoneChoices["EUROPE_PARIS"] = "EUROPE_PARIS";
    /** Europe/Podgorica */
    StoresStoreTimezoneChoices["EUROPE_PODGORICA"] = "EUROPE_PODGORICA";
    /** Europe/Prague */
    StoresStoreTimezoneChoices["EUROPE_PRAGUE"] = "EUROPE_PRAGUE";
    /** Europe/Riga */
    StoresStoreTimezoneChoices["EUROPE_RIGA"] = "EUROPE_RIGA";
    /** Europe/Rome */
    StoresStoreTimezoneChoices["EUROPE_ROME"] = "EUROPE_ROME";
    /** Europe/Samara */
    StoresStoreTimezoneChoices["EUROPE_SAMARA"] = "EUROPE_SAMARA";
    /** Europe/San_Marino */
    StoresStoreTimezoneChoices["EUROPE_SAN_MARINO"] = "EUROPE_SAN_MARINO";
    /** Europe/Sarajevo */
    StoresStoreTimezoneChoices["EUROPE_SARAJEVO"] = "EUROPE_SARAJEVO";
    /** Europe/Saratov */
    StoresStoreTimezoneChoices["EUROPE_SARATOV"] = "EUROPE_SARATOV";
    /** Europe/Simferopol */
    StoresStoreTimezoneChoices["EUROPE_SIMFEROPOL"] = "EUROPE_SIMFEROPOL";
    /** Europe/Skopje */
    StoresStoreTimezoneChoices["EUROPE_SKOPJE"] = "EUROPE_SKOPJE";
    /** Europe/Sofia */
    StoresStoreTimezoneChoices["EUROPE_SOFIA"] = "EUROPE_SOFIA";
    /** Europe/Stockholm */
    StoresStoreTimezoneChoices["EUROPE_STOCKHOLM"] = "EUROPE_STOCKHOLM";
    /** Europe/Tallinn */
    StoresStoreTimezoneChoices["EUROPE_TALLINN"] = "EUROPE_TALLINN";
    /** Europe/Tirane */
    StoresStoreTimezoneChoices["EUROPE_TIRANE"] = "EUROPE_TIRANE";
    /** Europe/Tiraspol */
    StoresStoreTimezoneChoices["EUROPE_TIRASPOL"] = "EUROPE_TIRASPOL";
    /** Europe/Ulyanovsk */
    StoresStoreTimezoneChoices["EUROPE_ULYANOVSK"] = "EUROPE_ULYANOVSK";
    /** Europe/Uzhgorod */
    StoresStoreTimezoneChoices["EUROPE_UZHGOROD"] = "EUROPE_UZHGOROD";
    /** Europe/Vaduz */
    StoresStoreTimezoneChoices["EUROPE_VADUZ"] = "EUROPE_VADUZ";
    /** Europe/Vatican */
    StoresStoreTimezoneChoices["EUROPE_VATICAN"] = "EUROPE_VATICAN";
    /** Europe/Vienna */
    StoresStoreTimezoneChoices["EUROPE_VIENNA"] = "EUROPE_VIENNA";
    /** Europe/Vilnius */
    StoresStoreTimezoneChoices["EUROPE_VILNIUS"] = "EUROPE_VILNIUS";
    /** Europe/Volgograd */
    StoresStoreTimezoneChoices["EUROPE_VOLGOGRAD"] = "EUROPE_VOLGOGRAD";
    /** Europe/Warsaw */
    StoresStoreTimezoneChoices["EUROPE_WARSAW"] = "EUROPE_WARSAW";
    /** Europe/Zagreb */
    StoresStoreTimezoneChoices["EUROPE_ZAGREB"] = "EUROPE_ZAGREB";
    /** Europe/Zaporozhye */
    StoresStoreTimezoneChoices["EUROPE_ZAPOROZHYE"] = "EUROPE_ZAPOROZHYE";
    /** Europe/Zurich */
    StoresStoreTimezoneChoices["EUROPE_ZURICH"] = "EUROPE_ZURICH";
    /** GB */
    StoresStoreTimezoneChoices["GB"] = "GB";
    /** GB-Eire */
    StoresStoreTimezoneChoices["GB_EIRE"] = "GB_EIRE";
    /** GMT */
    StoresStoreTimezoneChoices["GMT"] = "GMT";
    /** GMT0 */
    StoresStoreTimezoneChoices["GMT0"] = "GMT0";
    /** GMT+0 */
    StoresStoreTimezoneChoices["GMT_0"] = "GMT_0";
    /** GMT-0 */
    StoresStoreTimezoneChoices["GMT_0_492"] = "GMT_0_492";
    /** Greenwich */
    StoresStoreTimezoneChoices["GREENWICH"] = "GREENWICH";
    /** Hongkong */
    StoresStoreTimezoneChoices["HONGKONG"] = "HONGKONG";
    /** HST */
    StoresStoreTimezoneChoices["HST"] = "HST";
    /** Iceland */
    StoresStoreTimezoneChoices["ICELAND"] = "ICELAND";
    /** Indian/Antananarivo */
    StoresStoreTimezoneChoices["INDIAN_ANTANANARIVO"] = "INDIAN_ANTANANARIVO";
    /** Indian/Chagos */
    StoresStoreTimezoneChoices["INDIAN_CHAGOS"] = "INDIAN_CHAGOS";
    /** Indian/Christmas */
    StoresStoreTimezoneChoices["INDIAN_CHRISTMAS"] = "INDIAN_CHRISTMAS";
    /** Indian/Cocos */
    StoresStoreTimezoneChoices["INDIAN_COCOS"] = "INDIAN_COCOS";
    /** Indian/Comoro */
    StoresStoreTimezoneChoices["INDIAN_COMORO"] = "INDIAN_COMORO";
    /** Indian/Kerguelen */
    StoresStoreTimezoneChoices["INDIAN_KERGUELEN"] = "INDIAN_KERGUELEN";
    /** Indian/Mahe */
    StoresStoreTimezoneChoices["INDIAN_MAHE"] = "INDIAN_MAHE";
    /** Indian/Maldives */
    StoresStoreTimezoneChoices["INDIAN_MALDIVES"] = "INDIAN_MALDIVES";
    /** Indian/Mauritius */
    StoresStoreTimezoneChoices["INDIAN_MAURITIUS"] = "INDIAN_MAURITIUS";
    /** Indian/Mayotte */
    StoresStoreTimezoneChoices["INDIAN_MAYOTTE"] = "INDIAN_MAYOTTE";
    /** Indian/Reunion */
    StoresStoreTimezoneChoices["INDIAN_REUNION"] = "INDIAN_REUNION";
    /** Iran */
    StoresStoreTimezoneChoices["IRAN"] = "IRAN";
    /** Israel */
    StoresStoreTimezoneChoices["ISRAEL"] = "ISRAEL";
    /** Jamaica */
    StoresStoreTimezoneChoices["JAMAICA"] = "JAMAICA";
    /** Japan */
    StoresStoreTimezoneChoices["JAPAN"] = "JAPAN";
    /** Kwajalein */
    StoresStoreTimezoneChoices["KWAJALEIN"] = "KWAJALEIN";
    /** Libya */
    StoresStoreTimezoneChoices["LIBYA"] = "LIBYA";
    /** MET */
    StoresStoreTimezoneChoices["MET"] = "MET";
    /** Mexico/BajaNorte */
    StoresStoreTimezoneChoices["MEXICO_BAJANORTE"] = "MEXICO_BAJANORTE";
    /** Mexico/BajaSur */
    StoresStoreTimezoneChoices["MEXICO_BAJASUR"] = "MEXICO_BAJASUR";
    /** Mexico/General */
    StoresStoreTimezoneChoices["MEXICO_GENERAL"] = "MEXICO_GENERAL";
    /** MST */
    StoresStoreTimezoneChoices["MST"] = "MST";
    /** MST7MDT */
    StoresStoreTimezoneChoices["MST7MDT"] = "MST7MDT";
    /** Navajo */
    StoresStoreTimezoneChoices["NAVAJO"] = "NAVAJO";
    /** NZ */
    StoresStoreTimezoneChoices["NZ"] = "NZ";
    /** NZ-CHAT */
    StoresStoreTimezoneChoices["NZ_CHAT"] = "NZ_CHAT";
    /** Pacific/Apia */
    StoresStoreTimezoneChoices["PACIFIC_APIA"] = "PACIFIC_APIA";
    /** Pacific/Auckland */
    StoresStoreTimezoneChoices["PACIFIC_AUCKLAND"] = "PACIFIC_AUCKLAND";
    /** Pacific/Bougainville */
    StoresStoreTimezoneChoices["PACIFIC_BOUGAINVILLE"] = "PACIFIC_BOUGAINVILLE";
    /** Pacific/Chatham */
    StoresStoreTimezoneChoices["PACIFIC_CHATHAM"] = "PACIFIC_CHATHAM";
    /** Pacific/Chuuk */
    StoresStoreTimezoneChoices["PACIFIC_CHUUK"] = "PACIFIC_CHUUK";
    /** Pacific/Easter */
    StoresStoreTimezoneChoices["PACIFIC_EASTER"] = "PACIFIC_EASTER";
    /** Pacific/Efate */
    StoresStoreTimezoneChoices["PACIFIC_EFATE"] = "PACIFIC_EFATE";
    /** Pacific/Enderbury */
    StoresStoreTimezoneChoices["PACIFIC_ENDERBURY"] = "PACIFIC_ENDERBURY";
    /** Pacific/Fakaofo */
    StoresStoreTimezoneChoices["PACIFIC_FAKAOFO"] = "PACIFIC_FAKAOFO";
    /** Pacific/Fiji */
    StoresStoreTimezoneChoices["PACIFIC_FIJI"] = "PACIFIC_FIJI";
    /** Pacific/Funafuti */
    StoresStoreTimezoneChoices["PACIFIC_FUNAFUTI"] = "PACIFIC_FUNAFUTI";
    /** Pacific/Galapagos */
    StoresStoreTimezoneChoices["PACIFIC_GALAPAGOS"] = "PACIFIC_GALAPAGOS";
    /** Pacific/Gambier */
    StoresStoreTimezoneChoices["PACIFIC_GAMBIER"] = "PACIFIC_GAMBIER";
    /** Pacific/Guadalcanal */
    StoresStoreTimezoneChoices["PACIFIC_GUADALCANAL"] = "PACIFIC_GUADALCANAL";
    /** Pacific/Guam */
    StoresStoreTimezoneChoices["PACIFIC_GUAM"] = "PACIFIC_GUAM";
    /** Pacific/Honolulu */
    StoresStoreTimezoneChoices["PACIFIC_HONOLULU"] = "PACIFIC_HONOLULU";
    /** Pacific/Johnston */
    StoresStoreTimezoneChoices["PACIFIC_JOHNSTON"] = "PACIFIC_JOHNSTON";
    /** Pacific/Kanton */
    StoresStoreTimezoneChoices["PACIFIC_KANTON"] = "PACIFIC_KANTON";
    /** Pacific/Kiritimati */
    StoresStoreTimezoneChoices["PACIFIC_KIRITIMATI"] = "PACIFIC_KIRITIMATI";
    /** Pacific/Kosrae */
    StoresStoreTimezoneChoices["PACIFIC_KOSRAE"] = "PACIFIC_KOSRAE";
    /** Pacific/Kwajalein */
    StoresStoreTimezoneChoices["PACIFIC_KWAJALEIN"] = "PACIFIC_KWAJALEIN";
    /** Pacific/Majuro */
    StoresStoreTimezoneChoices["PACIFIC_MAJURO"] = "PACIFIC_MAJURO";
    /** Pacific/Marquesas */
    StoresStoreTimezoneChoices["PACIFIC_MARQUESAS"] = "PACIFIC_MARQUESAS";
    /** Pacific/Midway */
    StoresStoreTimezoneChoices["PACIFIC_MIDWAY"] = "PACIFIC_MIDWAY";
    /** Pacific/Nauru */
    StoresStoreTimezoneChoices["PACIFIC_NAURU"] = "PACIFIC_NAURU";
    /** Pacific/Niue */
    StoresStoreTimezoneChoices["PACIFIC_NIUE"] = "PACIFIC_NIUE";
    /** Pacific/Norfolk */
    StoresStoreTimezoneChoices["PACIFIC_NORFOLK"] = "PACIFIC_NORFOLK";
    /** Pacific/Noumea */
    StoresStoreTimezoneChoices["PACIFIC_NOUMEA"] = "PACIFIC_NOUMEA";
    /** Pacific/Pago_Pago */
    StoresStoreTimezoneChoices["PACIFIC_PAGO_PAGO"] = "PACIFIC_PAGO_PAGO";
    /** Pacific/Palau */
    StoresStoreTimezoneChoices["PACIFIC_PALAU"] = "PACIFIC_PALAU";
    /** Pacific/Pitcairn */
    StoresStoreTimezoneChoices["PACIFIC_PITCAIRN"] = "PACIFIC_PITCAIRN";
    /** Pacific/Pohnpei */
    StoresStoreTimezoneChoices["PACIFIC_POHNPEI"] = "PACIFIC_POHNPEI";
    /** Pacific/Ponape */
    StoresStoreTimezoneChoices["PACIFIC_PONAPE"] = "PACIFIC_PONAPE";
    /** Pacific/Port_Moresby */
    StoresStoreTimezoneChoices["PACIFIC_PORT_MORESBY"] = "PACIFIC_PORT_MORESBY";
    /** Pacific/Rarotonga */
    StoresStoreTimezoneChoices["PACIFIC_RAROTONGA"] = "PACIFIC_RAROTONGA";
    /** Pacific/Saipan */
    StoresStoreTimezoneChoices["PACIFIC_SAIPAN"] = "PACIFIC_SAIPAN";
    /** Pacific/Samoa */
    StoresStoreTimezoneChoices["PACIFIC_SAMOA"] = "PACIFIC_SAMOA";
    /** Pacific/Tahiti */
    StoresStoreTimezoneChoices["PACIFIC_TAHITI"] = "PACIFIC_TAHITI";
    /** Pacific/Tarawa */
    StoresStoreTimezoneChoices["PACIFIC_TARAWA"] = "PACIFIC_TARAWA";
    /** Pacific/Tongatapu */
    StoresStoreTimezoneChoices["PACIFIC_TONGATAPU"] = "PACIFIC_TONGATAPU";
    /** Pacific/Truk */
    StoresStoreTimezoneChoices["PACIFIC_TRUK"] = "PACIFIC_TRUK";
    /** Pacific/Wake */
    StoresStoreTimezoneChoices["PACIFIC_WAKE"] = "PACIFIC_WAKE";
    /** Pacific/Wallis */
    StoresStoreTimezoneChoices["PACIFIC_WALLIS"] = "PACIFIC_WALLIS";
    /** Pacific/Yap */
    StoresStoreTimezoneChoices["PACIFIC_YAP"] = "PACIFIC_YAP";
    /** Poland */
    StoresStoreTimezoneChoices["POLAND"] = "POLAND";
    /** Portugal */
    StoresStoreTimezoneChoices["PORTUGAL"] = "PORTUGAL";
    /** PRC */
    StoresStoreTimezoneChoices["PRC"] = "PRC";
    /** PST8PDT */
    StoresStoreTimezoneChoices["PST8PDT"] = "PST8PDT";
    /** ROC */
    StoresStoreTimezoneChoices["ROC"] = "ROC";
    /** ROK */
    StoresStoreTimezoneChoices["ROK"] = "ROK";
    /** Singapore */
    StoresStoreTimezoneChoices["SINGAPORE"] = "SINGAPORE";
    /** Turkey */
    StoresStoreTimezoneChoices["TURKEY"] = "TURKEY";
    /** UCT */
    StoresStoreTimezoneChoices["UCT"] = "UCT";
    /** Universal */
    StoresStoreTimezoneChoices["UNIVERSAL"] = "UNIVERSAL";
    /** US/Alaska */
    StoresStoreTimezoneChoices["US_ALASKA"] = "US_ALASKA";
    /** US/Aleutian */
    StoresStoreTimezoneChoices["US_ALEUTIAN"] = "US_ALEUTIAN";
    /** US/Arizona */
    StoresStoreTimezoneChoices["US_ARIZONA"] = "US_ARIZONA";
    /** US/Central */
    StoresStoreTimezoneChoices["US_CENTRAL"] = "US_CENTRAL";
    /** US/Eastern */
    StoresStoreTimezoneChoices["US_EASTERN"] = "US_EASTERN";
    /** US/East-Indiana */
    StoresStoreTimezoneChoices["US_EAST_INDIANA"] = "US_EAST_INDIANA";
    /** US/Hawaii */
    StoresStoreTimezoneChoices["US_HAWAII"] = "US_HAWAII";
    /** US/Indiana-Starke */
    StoresStoreTimezoneChoices["US_INDIANA_STARKE"] = "US_INDIANA_STARKE";
    /** US/Michigan */
    StoresStoreTimezoneChoices["US_MICHIGAN"] = "US_MICHIGAN";
    /** US/Mountain */
    StoresStoreTimezoneChoices["US_MOUNTAIN"] = "US_MOUNTAIN";
    /** US/Pacific */
    StoresStoreTimezoneChoices["US_PACIFIC"] = "US_PACIFIC";
    /** US/Samoa */
    StoresStoreTimezoneChoices["US_SAMOA"] = "US_SAMOA";
    /** UTC */
    StoresStoreTimezoneChoices["UTC"] = "UTC";
    /** WET */
    StoresStoreTimezoneChoices["WET"] = "WET";
    /** W-SU */
    StoresStoreTimezoneChoices["W_SU"] = "W_SU";
    /** Zulu */
    StoresStoreTimezoneChoices["ZULU"] = "ZULU";
})(StoresStoreTimezoneChoices = exports.StoresStoreTimezoneChoices || (exports.StoresStoreTimezoneChoices = {}));
/** An enumeration. */
var UploadStatus;
(function (UploadStatus) {
    UploadStatus["COMPLETE"] = "COMPLETE";
    UploadStatus["FAILED"] = "FAILED";
    UploadStatus["PENDING"] = "PENDING";
})(UploadStatus = exports.UploadStatus || (exports.UploadStatus = {}));
/** An enumeration. */
var UserProductivityModelOptions;
(function (UserProductivityModelOptions) {
    UserProductivityModelOptions["DISPLAY"] = "DISPLAY";
    UserProductivityModelOptions["STORE"] = "STORE";
    UserProductivityModelOptions["TEAM"] = "TEAM";
    UserProductivityModelOptions["USER"] = "USER";
})(UserProductivityModelOptions = exports.UserProductivityModelOptions || (exports.UserProductivityModelOptions = {}));
/** An enumeration. */
var UsersFCMTokenDeviceTypeChoices;
(function (UsersFCMTokenDeviceTypeChoices) {
    /** Mobile */
    UsersFCMTokenDeviceTypeChoices["MOBILE"] = "MOBILE";
    /** Tablet */
    UsersFCMTokenDeviceTypeChoices["TABLET"] = "TABLET";
})(UsersFCMTokenDeviceTypeChoices = exports.UsersFCMTokenDeviceTypeChoices || (exports.UsersFCMTokenDeviceTypeChoices = {}));
/** An enumeration. */
var VerificationDecision;
(function (VerificationDecision) {
    VerificationDecision["FAIL"] = "FAIL";
    VerificationDecision["PASS"] = "PASS";
})(VerificationDecision = exports.VerificationDecision || (exports.VerificationDecision = {}));
/** An enumeration. */
var VerifiedLocation;
(function (VerifiedLocation) {
    VerifiedLocation["FIELD"] = "FIELD";
    VerifiedLocation["FIELD_AND_WHTE_RBT"] = "FIELD_AND_WHTE_RBT";
    VerifiedLocation["WHTE_RBT"] = "WHTE_RBT";
})(VerifiedLocation = exports.VerifiedLocation || (exports.VerifiedLocation = {}));
/**
 * We should eventually remove this, since the
 * zone hierarchy will be customer dependent
 */
var ZoneTier;
(function (ZoneTier) {
    ZoneTier["DISTRICT"] = "DISTRICT";
    ZoneTier["DIVISION"] = "DIVISION";
    ZoneTier["ROUTE"] = "ROUTE";
    ZoneTier["SALES_CENTER"] = "SALES_CENTER";
    ZoneTier["TERRITORY"] = "TERRITORY";
})(ZoneTier = exports.ZoneTier || (exports.ZoneTier = {}));
