/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportHook,
  ChartType,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { gridAllScreens, MuiTheme } from '@dai/web-components'
import {
  AggregateBy,
  AggregationMethod,
  GET_POINT_CLOUD_SCAN_SCORES,
  GET_RECOMMENDATION_COMPLIANCE_SCORES,
  GetPointCloudScoresQuery,
  GetPointCloudScores_pointCloudScanScores_results,
  GetPointCloudScoresQueryVariables,
  GetRecommendationComplianceScoresQuery,
  GetRecommendationComplianceScoresQueryVariables,
} from '@dai/graphql'
import { useQuery } from '@apollo/client'
import { useLocalStorage } from 'hooks/use-local-storage'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'
import { useEffect, useState } from 'react'
import { UserProductivityReportingConstants } from 'users/constants/reporting/user-productivity-reporting.constants'
import { noop } from 'lodash'
import { UserProductivityReportHelpers } from 'users/helpers/reporting/user-productivity-report.helpers'

export const useQualityLeaderboardReports: GenericReportHook<UserProductivityReportName> = () => {
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')
  const [complianceTitle, setComplianceTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const [pointCloudTitle, setPointCloudTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const [facingScoreTitle, setFacingScoreTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const [idTitle, setIdTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const titles = {
    complianceTitle,
    pointCloudTitle,
    facingScoreTitle,
    idTitle,
  }
  const getSetTitle = (
    key: keyof GetPointCloudScores_pointCloudScanScores_results,
  ) => {
    switch (key) {
      case 'pointCloudScore':
        return setPointCloudTitle
      case 'facingScore':
        return setFacingScoreTitle
      case 'identificationAccuracy':
        return setIdTitle
      default:
        return noop
    }
  }

  const tooltipBase = [
    {
      text: '{category}',
      color: MuiTheme.palette.secondary.light,
      newline: true,
    },
    {
      text: '{valueX}',
      fontSize: 28,
      color: MuiTheme.palette.secondary.main,
      bold: true,
    },
  ]
  const chartProps = {
    gridProps: { ...gridAllScreens(6), md: 12, sm: 12, xs: 12 },
    horizontal: true,
    type: 'bar-chart' as ChartType,
  }
  const bottomNChartProps = (title: keyof typeof titles) => ({
    ...chartProps,
    subtitle: `Bottom ${titles[title].range} Performing ${titles[title].type}`,
    color: MuiTheme.palette.primary.main,
  })
  const topNChartProps = (title: keyof typeof titles) => ({
    ...chartProps,
    subtitle: `Top ${titles[title].range} Performing ${titles[title].type}`,
  })
  const useGetTopNBottomNRecommendationComplianceScores = (
    reportParams: UserProductivityReportInput,
    orderBy: string[],
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const { data: scores, loading } = useQuery<
      GetRecommendationComplianceScoresQuery,
      GetRecommendationComplianceScoresQueryVariables
    >(GET_RECOMMENDATION_COMPLIANCE_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(
          AggregationMethod.AVG,
          AggregateBy.ALL_TIME,
          orderBy,
        ),
      },
    })

    const result = scores?.recommendationScores.results || []

    useEffect(() => {
      UserProductivityReportHelpers.setLeaderboardTitle(
        setComplianceTitle,
        result.length,
        reportParams.allTeams,
      )
    }, [result.length, reportParams.reportLevel])

    return {
      data: result
        .map(res => ({
          category: companyUsers?.[res.refId].name || res.refId,
          value: res.score * 100,
        }))
        .sort((a, b) => {
          if (orderBy[0][0] === '-') {
            return a.value > b.value ? 1 : -1
          } else {
            return a.value < b.value ? 1 : -1
          }
        }),
      isEmpty: !result.length,
      isLoading: loading || Object.values(titles).some(title => !title.range),
    }
  }
  const useGetTopNBottomNPointCloudScores = (
    reportParams: UserProductivityReportInput,
    orderBy: string[],
    dataKey: keyof GetPointCloudScores_pointCloudScanScores_results,
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const { data: scores, loading } = useQuery<
      GetPointCloudScoresQuery,
      GetPointCloudScoresQueryVariables
    >(GET_POINT_CLOUD_SCAN_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(
          AggregationMethod.AVG,
          AggregateBy.ALL_TIME,
          orderBy,
        ),
      },
    })
    const result = scores?.pointCloudScanScores?.results || []

    useEffect(() => {
      UserProductivityReportHelpers.setLeaderboardTitle(
        getSetTitle(dataKey),
        result.length,
        reportParams.allTeams,
      )
    }, [result.length, reportParams.reportLevel])

    const data = result
      .map(res => ({
        category: companyUsers?.[res.refId].name || res.refId,
        value: res[dataKey] ? res[dataKey] * 100 : 0,
      }))
      .filter(val => val.value)
      .sort((a, b) => {
        if (orderBy[0][0] === '-') {
          return a.value > b.value ? 1 : -1
        } else {
          return a.value < b.value ? 1 : -1
        }
      })
    return {
      data,
      isEmpty: !data.length,
      isLoading: loading || Object.values(titles).some(title => !title.range),
    }
  }

  return [
    {
      reportName: 'recommendation-compliance-score',
      useGetData: reportParams =>
        useGetTopNBottomNRecommendationComplianceScores(reportParams, [
          '-score',
        ]),
      ...topNChartProps('complianceTitle'),
      title: 'Avg Recommendation Compliance',
      chartId: 'top-10-rec-comp-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Compliance Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'recommendation-compliance-score',
      useGetData: reportParams =>
        useGetTopNBottomNRecommendationComplianceScores(reportParams, [
          'score',
        ]),
      ...bottomNChartProps('complianceTitle'),
      title: 'Avg Recommendation Compliance',
      chartId: 'bottom-10-rec-comp-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Compliance Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'point-cloud-score',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['-point_cloud_score'],
          'pointCloudScore',
        ),
      ...topNChartProps('pointCloudTitle'),
      title: 'Avg Point Cloud Score',
      chartId: 'top-10-point-cloud-score-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Point Cloud Scan Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'point-cloud-score',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['point_cloud_score'],
          'pointCloudScore',
        ),
      ...bottomNChartProps('pointCloudTitle'),
      title: 'Avg Point Cloud Score',
      chartId: 'bottom-10-point-cloud-score-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Point Cloud Scan Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'scan-facing-score',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['-facing_score'],
          'facingScore',
        ),
      ...topNChartProps('facingScoreTitle'),
      title: 'Avg Facing Scan Score Score',
      chartId: 'top-10-facing-score-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Facing Scan Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'scan-facing-score',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['facing_score'],
          'facingScore',
        ),
      ...bottomNChartProps('facingScoreTitle'),
      title: 'Avg Facing Scan Score Score',
      chartId: 'bottom-10-facing-score-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Facing Scan Score',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'identification-accuracy',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['-identification_accuracy'],
          'identificationAccuracy',
        ),
      ...topNChartProps('idTitle'),
      title: 'Avg Identification Accuracy',
      chartId: 'top-10-prod-id-accuracy-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Identification Accuracy',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'identification-accuracy',
      useGetData: reportParams =>
        useGetTopNBottomNPointCloudScores(
          reportParams,
          ['identification_accuracy'],
          'identificationAccuracy',
        ),
      ...bottomNChartProps('idTitle'),
      title: 'Avg Identification Accuracy',
      chartId: 'bottom-10-prod-id-accuracy-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Identification Accuracy',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
  ]
}
