/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  PicOSPriorityComplianceQuery,
  PicOSPriorityComplianceQueryVariables,
  PicOSPriorityStatsQuery,
  PicOSPriorityStatsQueryVariables,
  PICOS_PRIORITY_COMPLIANCE,
  PICOS_PRIORITY_STATS,
} from '@dai/graphql'
import {
  useDebouncedItemQuery,
  usePagination,
  PicOSReportContext,
} from '@dai/web-components'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'
import { useContext, useEffect } from 'react'

interface PriorityComplianceReportProps {
  priorityId: string
}

const usePriorityComplianceReport = (props: PriorityComplianceReportProps) => {
  const { priorityId } = props
  const { selectedDateRange, selectedPriority } = useContext(PicOSReportContext)
  const {
    offset,
    limit,
    handleOffset: { goToPage },
  } = usePagination({ pageSize: 20 })

  const OverviewQuery = useDebouncedItemQuery<
    PicOSPriorityComplianceQuery,
    PicOSPriorityComplianceQueryVariables
  >({
    queryStr: PICOS_PRIORITY_COMPLIANCE,
    options: {
      variables: {
        input: {
          priorityId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    PicOSPriorityStatsQuery,
    PicOSPriorityStatsQueryVariables
  >({
    queryStr: PICOS_PRIORITY_STATS,
    options: {
      variables: {
        input: {
          priorityId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          priorityId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          storeName: OverviewQuery.debouncedQuery || undefined,
          offset,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const overviewQuery = OverviewQuery.lazyQuery.meta
  const statsQuery = StatsQuery.lazyQuery.meta

  const statItems = PicOSReportHelpers.getStatItems(
    ['compliance', 'cost'],
    statsQuery.data?.picosPriorityStats,
  )

  return {
    data: {
      title: 'Priority',
      priority: selectedPriority,
      complianceResults:
        overviewQuery.data?.picosPriorityCompliance.results || [],
      statItems,
    },
    state: {
      limit,
      loadingStats: !!statsQuery.loading,
      loadingCompliance: !!overviewQuery.loading,
    },
    handlers: {
      handleSearch,
      goToPage,
    },
  }
}

export default usePriorityComplianceReport
