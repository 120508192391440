/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ProductColumns from './ProductRows'

type CentroidsListProps = {
  centroids: { upc: string; quantity: number }[]
}

const CentroidsTable: React.FC<CentroidsListProps> = (
  props: CentroidsListProps,
) => {
  const { centroids } = props
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {centroids.map(c => (
            <ProductColumns
              upc={c.upc}
              columnsFromProduct={['marketingImageUrl', 'name']}
              additionalColumnValues={[c.quantity]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CentroidsTable
