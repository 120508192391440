/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import React, { useContext, useEffect } from 'react'
import {
  useDebouncedItemQuery,
  usePagination,
  BasicPicOSCustomerInfo,
  PicOSReportContext,
} from '@dai/web-components'
import {
  GetPicOSDivisionComplianceQuery,
  GetPicOSZoneComplianceStatsQuery,
  GetPicOSZoneComplianceStatsQueryVariables,
  GetPicOSDivisionComplianceQueryVariables,
  GET_PICOS_DIVISION_COMPLIANCE,
  GET_PICOS_ZONE_COMPLIANCE_STATS,
} from '@dai/graphql'
import { useHistory } from 'react-router-dom'
import { PicOSReportHelpers } from 'picos/helpers/picos-reports.helpers'

interface DivisionComplianceReportProps {
  customerId: string
  divisionId: string
}

const pageSize = 20

const useDivisionComplianceReport = (props: DivisionComplianceReportProps) => {
  const { customerId, divisionId } = props
  const history = useHistory()
  const {
    selectedDateRange,
    selectedDivision,
    setSelectedSalesCenter,
  } = useContext(PicOSReportContext)
  const {
    offset,
    limit,
    currentPage,
    handleOffset: { goToPage },
  } = usePagination({ pageSize })

  const OverviewQuery = useDebouncedItemQuery<
    GetPicOSDivisionComplianceQuery,
    GetPicOSDivisionComplianceQueryVariables
  >({
    queryStr: GET_PICOS_DIVISION_COMPLIANCE,
    options: {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          offset,
          limit,
        },
      },
    },
    queryOnMount: true,
  })

  const StatsQuery = useDebouncedItemQuery<
    GetPicOSZoneComplianceStatsQuery,
    GetPicOSZoneComplianceStatsQueryVariables
  >({
    queryStr: GET_PICOS_ZONE_COMPLIANCE_STATS,
    options: {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
        },
      },
    },
    queryOnMount: true,
  })

  const handleSelect = (info: BasicPicOSCustomerInfo) => {
    setSelectedSalesCenter(info)
    history.push(`${divisionId}/${info.id}`)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    OverviewQuery.setItemQuery(searchTerm)
    StatsQuery.setItemQuery(searchTerm)
  }

  useEffect(() => {
    const variables = {
      variables: {
        input: {
          customerId,
          parentZoneId: divisionId,
          ...PicOSReportHelpers.dateRangeToDateInput(selectedDateRange),
          divisionName: OverviewQuery.debouncedQuery || undefined,
          offset,
        },
      },
    }
    OverviewQuery.lazyQuery.query(variables)
    StatsQuery.lazyQuery.query(variables)
  }, [OverviewQuery.debouncedQuery, selectedDateRange, offset])

  const overviewQuery = OverviewQuery.lazyQuery.meta
  const statsQuery = StatsQuery.lazyQuery.meta

  const statItems = PicOSReportHelpers.getStatItems(
    ['compliance', 'customers', 'stores', 'priorities', 'cost'],
    statsQuery.data?.picosZoneStats,
  )

  return {
    data: {
      title: selectedDivision?.name || 'Division',
      complianceResults:
        overviewQuery.data?.picosDivisionCompliance.results || [],
      statItems,
    },
    state: {
      limit,
      currentPage,
      loadingStats: !!statsQuery.loading,
      loadingCompliance: !!overviewQuery.loading,
    },
    handlers: {
      handleSearch,
      handleSelect,
      goToPage,
    },
  }
}

export default useDivisionComplianceReport
