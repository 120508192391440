/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useAudit } from '@dai/common'
import { useHistory } from 'react-router-dom'
import StoreInspectionHelpers from 'store-inspection/helpers/store-inspection.helpers'
import React from 'react'
import { QueryStats } from '@mui/icons-material'
import { Grid } from '@mui/material'
import {
  AvatarWithText,
  FillContainerLoading,
  FILL_GRID_ALL_SCREENS,
  gridAllScreens,
  MuiFormModalProps,
  GridSections,
  MuiFormModal,
} from '@dai/web-components'

interface AuditInfoModalProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  auditId: string
}

export const AuditInfoModal: React.FC<AuditInfoModalProps> = props => {
  const { auditId, handleClose, isOpen } = props
  const { audit, loading } = useAudit({ auditId })
  const history = useHistory()

  if (!audit) {
    return null
  }

  return (
    <MuiFormModal
      title={audit.store.name}
      subtitle="Audit Summary"
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          children: 'View Full Statistics',
          startIcon: <QueryStats color="secondary" />,
          onClick: () => history.push(`/audits/summary/${audit.id}`),
        },
      ]}
    >
      {loading ? (
        <FillContainerLoading />
      ) : (
        <Grid container>
          <GridSections
            items={{
              Auditor: {
                gridProps: FILL_GRID_ALL_SCREENS,
                body: (
                  <AvatarWithText
                    primaryText={`${audit.employee?.firstName} ${audit.employee?.lastName}`}
                    secondaryText={`Date Completed: ${StoreInspectionHelpers.formatDate(
                      new Date(audit.dateCompleted),
                    )}`}
                  >
                    {audit.employee?.firstName[0]}
                    {audit.employee?.lastName[0]}
                  </AvatarWithText>
                ),
              },
              'Audit Metadata': {
                gridProps: FILL_GRID_ALL_SCREENS,
                body: (
                  <>
                    <Grid item {...gridAllScreens(6)}>
                      <AvatarWithText
                        avatarVariant="rounded"
                        primaryText="Displays"
                        secondaryText="Audited"
                        boldSecondary
                      >
                        {audit.displayAudits.length}
                      </AvatarWithText>
                    </Grid>
                    <Grid item {...gridAllScreens(6)}>
                      <AvatarWithText
                        avatarVariant="rounded"
                        primaryText="Questions"
                        secondaryText="Answered"
                        boldSecondary
                      >
                        {audit.auditAnswers.length}
                      </AvatarWithText>
                    </Grid>
                  </>
                ),
              },
            }}
          />
        </Grid>
      )}
    </MuiFormModal>
  )
}
