"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMIN_AUDIT_INVENTORY_ITEM_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.ADMIN_AUDIT_INVENTORY_ITEM_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment AdminAuditInventoryItem on AuditInventoryItem {\n    __typename\n    id\n    upc\n    quantity\n    name\n    product {\n    \tshipping {\n      \tproductsPerCase\n    \t}\n    }\n  }\n"], ["\n  fragment AdminAuditInventoryItem on AuditInventoryItem {\n    __typename\n    id\n    upc\n    quantity\n    name\n    product {\n    \tshipping {\n      \tproductsPerCase\n    \t}\n    }\n  }\n"])));
var templateObject_1;
