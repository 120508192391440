/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Michael Selander <michael.selander@deliciousai.com>
 */

import { useMutation } from '@apollo/client'
import { useGetAdminCompanyStores } from '@dai/common'
import {
  UserFragment,
  UpdateUserMutation,
  UpdateUserInput,
  UpdateUserMutationVariables,
  UPDATE_USER,
} from '@dai/graphql'
import {
  DAITable,
  DAITableColumns,
  Filter,
  MuiFormModal,
  MuiFormModalProps,
  MuiTheme,
  SearchBar,
  TableContainerClass,
} from '@dai/web-components'
import { AssignmentTurnedIn, Close, Download, Save } from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { useStoresLogic } from 'stores/containers/Stores.logic'
import { SimpleStoreRow } from 'stores/stores.types'

interface UserStoresFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  userStores: string[]
  user: UserFragment
  onSubmit: () => void
  onReassignStores: () => void
}

const columns: DAITableColumns<SimpleStoreRow>[] = [
  {
    field: 'store',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: MuiTheme.sizes.table.column.width.lg,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: MuiTheme.sizes.table.column.width.xs,
  },
  {
    field: 'customerId',
    headerName: 'Customer Id',
    width: MuiTheme.sizes.table.column.width.sm,
  },
]

export const UserStoresModal: React.FunctionComponent<UserStoresFormProps> = props => {
  const {
    isOpen,
    user,
    userStores,
    onSubmit,
    handleClose,
    onReassignStores,
  } = props
  const {
    state: {
      setStoreSearch,
      selectedIds,
      setSelectedIds,
      FilterProps,
      setHideInactive,
      hideInactive,
    },
    handle: { handlePageChange },
    ref: { tableRef },
    data: { isRedAdmin, storeRows, loading, count, PAGE_SIZE },
  } = useStoresLogic<SimpleStoreRow>({ user, userStores })

  const selectedStores = useGetAdminCompanyStores({
    limit: selectedIds.length,
    prioritizeUserId: user?.uuid,
  })?.stores

  const [updateUser] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER)

  const saveUserStores = async () => {
    const input: UpdateUserInput = {
      userId: user!.id,
      stores: selectedIds,
    }
    await updateUser({ variables: { input } })
  }

  const download = () => {
    const storeInfo = selectedStores
      ?.map(store => ({
        store: `${store.name}`,
        address: store.address?.address1 || '',
        city: store.address?.city || '',
        state: store.address?.state || '',
        postalCode: store.address?.postalCode || '',
        category: `${store.category}`,
        id: store.id,
        customerId: store.internalStoreId || '',
      }))
      .map(
        store =>
          `${store.store},${store.address},${store.city},${store.state},${store.postalCode},${store.category},${store.customerId}`,
      )
    if (storeInfo) {
      const file = new File(
        [
          `Name,Address,City,State,Postal Code,Category,Customer ID\n${storeInfo?.join(
            '\n',
          )}`,
        ],
        `stores_assigned_to_${user?.firstName.replace(/ /gi, '_') || ''}_${
          user?.lastName.replace(/ /gi, '_') || ''
        }.csv`,
        {
          type: 'text/csv',
        },
      )
      const link = document.createElement('a')
      const url = URL.createObjectURL(file)

      link.href = url
      link.download = file.name
      link.click()

      window.URL.revokeObjectURL(url)
    }
  }

  return (
    <MuiFormModal
      sx={{ padding: 0 }}
      title="Assigned Stores"
      subtitle={
        user
          ? `${selectedIds.length} stores assigned to ${user.firstName} ${user.lastName}`
          : undefined
      }
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          startIcon: <Close color="secondary" />,
          disabled: false,
          color: 'secondary',
        },
        {
          onClick: () => {
            saveUserStores()
            onSubmit()
          },
          children: 'Save',
          startIcon: <Save color="secondary" />,
          disabled: !selectedIds.length,
        },
      ]}
    >
      <DAITable
        className={TableContainerClass.MODAL}
        tableToolBarProps={{
          tableTitle: 'Stores',
          right: {
            others: [
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setHideInactive(!hideInactive)}
                    checked={hideInactive}
                  />
                }
                label="Hide Inactive"
              />,
              <Tooltip title="Download Data">
                <IconButton onClick={download}>
                  <Download />
                </IconButton>
              </Tooltip>,
              <Tooltip title="Reassign Stores">
                <IconButton
                  onClick={async () => {
                    await saveUserStores()
                    onReassignStores()
                  }}
                >
                  <AssignmentTurnedIn />
                </IconButton>
              </Tooltip>,
              <SearchBar
                onChange={value => setStoreSearch(value)}
                useResponsive
              />,
              <Filter filterTitle="Filter Stores" {...FilterProps} />,
            ],
          },
        }}
        ref={tableRef}
        rows={storeRows}
        columns={columns}
        pageSize={PAGE_SIZE}
        rowCount={count}
        onPageChange={handlePageChange}
        loading={loading}
        disableBulkActions
        disableSelectAll
        showCheckbox={isRedAdmin}
        onSelectionChange={setSelectedIds}
        initialSelected={props.userStores || []}
      />
    </MuiFormModal>
  )
}
