"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCAL_DATABASE_TYPES = void 0;
var client_1 = require("@apollo/client");
exports.LOCAL_DATABASE_TYPES = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query LocalProductDatabaseSchema {\n        localProductColumns\n        localProductForeignKeys {\n          companyCategoryId {\n            name\n            endTable\n          }\n          packagingId {\n            name\n            endTable\n          }\n          shippingId {\n            name\n            endTable\n          }\n          flavor {\n            name\n            endTable\n          }\n        }\n        localPackagingColumns\n        localCompanyCategoryColumns\n        localShippingColumns\n        localFlavorColumns\n        localVersionColumns\n        localProductFlavorRelationColumns\n        localProductFlavorRelationForeignKeys {\n          flavor {\n            name\n            endTable\n          }\n          productId {\n            name\n            endTable\n          }\n        }\n    }\n"], ["\n    query LocalProductDatabaseSchema {\n        localProductColumns\n        localProductForeignKeys {\n          companyCategoryId {\n            name\n            endTable\n          }\n          packagingId {\n            name\n            endTable\n          }\n          shippingId {\n            name\n            endTable\n          }\n          flavor {\n            name\n            endTable\n          }\n        }\n        localPackagingColumns\n        localCompanyCategoryColumns\n        localShippingColumns\n        localFlavorColumns\n        localVersionColumns\n        localProductFlavorRelationColumns\n        localProductFlavorRelationForeignKeys {\n          flavor {\n            name\n            endTable\n          }\n          productId {\n            name\n            endTable\n          }\n        }\n    }\n"])));
var templateObject_1;
