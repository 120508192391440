"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLANOGRAM_IMAGES_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ImageFragment_1 = require("./ImageFragment");
exports.PLANOGRAM_IMAGES_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment PlanogramImages on PlanogramProductImages {\n    normal {\n      ...Image\n    }\n    thumbnail {\n      ...Image\n    }\n  }\n  ", "\n"], ["\n  fragment PlanogramImages on PlanogramProductImages {\n    normal {\n      ...Image\n    }\n    thumbnail {\n      ...Image\n    }\n  }\n  ", "\n"])), ImageFragment_1.IMAGE_FRAGMENT);
var templateObject_1;
