"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZONE_HIERARCHY_GLOBAL_SEARCH = void 0;
var client_1 = require("@apollo/client");
var ZoneHierarchyGlobalSearchResultFragment_1 = require("../fragments/ZoneHierarchyGlobalSearchResultFragment");
exports.ZONE_HIERARCHY_GLOBAL_SEARCH = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query ZoneHierarchyGlobalSearch($searchTerm: String!) {\n    zoneHierarchyGlobalSearch(searchTerm: $searchTerm) {\n      areas {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n      people {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n      stores {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n    }\n  }\n  ", "\n"], ["\n  query ZoneHierarchyGlobalSearch($searchTerm: String!) {\n    zoneHierarchyGlobalSearch(searchTerm: $searchTerm) {\n      areas {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n      people {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n      stores {\n        ...ZoneHierarchyGlobalSearchResult\n      }\n    }\n  }\n  ", "\n"])), ZoneHierarchyGlobalSearchResultFragment_1.ZONE_HIERARCHY_GLOBAL_SEARCH_RESULT_FRAGMENT);
var templateObject_1;
