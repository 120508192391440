/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import { StringHelpers } from '@dai/web-components'
import { StoreRow } from 'stores/stores.types'

const numStopped = (stores: StoreRow[]): number =>
  stores.filter(store => !store.isScanned && store.resumeDate === 'n/a')
    .length || 0

const numPaused = (stores: StoreRow[]): number =>
  stores.filter(
    store => !store.isScanned && store.resumeDate && store.resumeDate !== 'n/a',
  ).length || 0

const numScanned = (stores: StoreRow[]): number =>
  stores.filter(store => store.isScanned).length

export const StoreRowHelpers = {
  numStopped,
  numPaused,
  numScanned,
}
