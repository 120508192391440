"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SabreProductImageType = exports.SabreProductImageOrientation = exports.SabrePackagingUnits = exports.SabrePackagingType = void 0;
/** An enumeration. */
var SabrePackagingType;
(function (SabrePackagingType) {
    SabrePackagingType["ALUMINUM_BOTTLE"] = "ALUMINUM_BOTTLE";
    SabrePackagingType["BAG"] = "BAG";
    SabrePackagingType["BOTTLE"] = "BOTTLE";
    SabrePackagingType["BOX"] = "BOX";
    SabrePackagingType["CAN"] = "CAN";
    SabrePackagingType["CANISTER"] = "CANISTER";
    SabrePackagingType["CARTON"] = "CARTON";
    SabrePackagingType["GLASS_BOTTLE"] = "GLASS_BOTTLE";
    SabrePackagingType["JAR"] = "JAR";
    SabrePackagingType["PACKET"] = "PACKET";
})(SabrePackagingType = exports.SabrePackagingType || (exports.SabrePackagingType = {}));
/** An enumeration. */
var SabrePackagingUnits;
(function (SabrePackagingUnits) {
    SabrePackagingUnits["G"] = "G";
    SabrePackagingUnits["L"] = "L";
    SabrePackagingUnits["ML"] = "ML";
    SabrePackagingUnits["OZ"] = "OZ";
})(SabrePackagingUnits = exports.SabrePackagingUnits || (exports.SabrePackagingUnits = {}));
/** An enumeration. */
var SabreProductImageOrientation;
(function (SabreProductImageOrientation) {
    SabreProductImageOrientation["FRONT"] = "FRONT";
    SabreProductImageOrientation["SIDE"] = "SIDE";
    SabreProductImageOrientation["TOP"] = "TOP";
})(SabreProductImageOrientation = exports.SabreProductImageOrientation || (exports.SabreProductImageOrientation = {}));
/** An enumeration. */
var SabreProductImageType;
(function (SabreProductImageType) {
    SabreProductImageType["FIELD_GENERATED"] = "FIELD_GENERATED";
    SabreProductImageType["MARKETING"] = "MARKETING";
    SabreProductImageType["PLANOGRAM"] = "PLANOGRAM";
})(SabreProductImageType = exports.SabreProductImageType || (exports.SabreProductImageType = {}));
