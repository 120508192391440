/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  FillContainerLoading,
  SearchBar,
  FlexBox,
  Sidebar,
  TreeList,
  TreeHelpers,
  AutoSelectNodeContext,
  NotificationAlert,
  StoresHelpers,
  EmptyList,
} from '@dai/web-components'
import {
  Box,
  IconButton,
  alpha,
  Chip,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  Paper,
  List,
  Card,
  Button,
  SvgIconTypeMap,
  Breadcrumbs,
} from '@mui/material'
import React from 'react'
import RetiredUserWarning from 'users/components/RetiredUserWarning'
import { Add, AddCircle, LocationOn } from '@mui/icons-material'

import { Delete, Groups, Place, Storefront } from '@mui/icons-material'
import { StringHelpers } from '@dai/web-components'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { ZoneTier } from '@dai/graphql'
import ZoneHierarchyGlobalSearch from './ZoneSearchResults'
import ZoneMutateModal from './ZoneMutateModal'
import { ZoneHierarchyConstants } from './constants'
import {
  SubZone,
  Zone,
  ZoneAssignedUser,
  ZoneLevel,
  ZoneRow,
  ZoneStore,
  ZoneStoreRow,
  ZoneUserRow,
} from './types'
import { useZoneHierarchyLogic } from './ZoneHierarchy.logic'
import ZoneErrorState from './ZoneErrorState'
import { ZoneFormHeader } from './ZoneFormHeader'

type RenderParam<T> = {
  items: T[]
  renderItem: (item: T) => React.ReactNode
  handleAdd: () => void
  EmptyIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

const ZoneHierarchyContainer: React.FC = () => {
  const {
    state: { zoneId, zoneTab, searchString, setSearchString },
    data: {
      zonesWithRetiredUsers,
      searchResults,
      zone,
      zoneError,
      zoneLoading,
      zoneHierarchy,
      zoneHierarchyLoading,
      filteredUsers,
      filteredStores,
      filteredSubzones,
    },
    handle: { handleUpdateSearchParams, handleRemoveStoreFromZone },
    ZoneSearch,
    zoneUsersLogic,
    zoneStoresLogic,
    zoneAreasLogic,
  } = useZoneHierarchyLogic()

  const handleIsOpen = (_zone: Zone, givenNode: ZoneLevel) => {
    // TODO: maybe improve this by returning the zoneIdPath on _zone and comparing to givenNode.id
    if (!zone || !zone.zonePath?.length) return false
    return zone.zonePath!.includes(givenNode.name) || givenNode.id === zoneId
  }

  const tabOptions = {
    areas: {
      label: 'Areas',
      Icon: Place,
      hidden: zone?.tier === ZoneTier.ROUTE,
    },
    stores: {
      label: 'Stores',
      Icon: Storefront,
      hidden: zone?.tier !== ZoneTier.ROUTE,
    },
    people: {
      label: 'People',
      Icon: Groups,
      hidden: false,
    },
  }

  function ListBody(
    tab: string,
  ): (callback: <T>(args: RenderParam<T>) => void) => void {
    const renderAreas = (
      render: (o: RenderParam<Pick<SubZone, 'name' | 'manager'>>) => void,
    ) => {
      return render({
        items: filteredSubzones,
        renderItem: item => (
          <Card sx={{ flex: 1, p: 2 }} variant="outlined">
            <ListItemText
              primary={item.name}
              secondary={item.manager?.fullName || 'Manager Needed'}
              disableTypography={false}
              secondaryTypographyProps={{
                sx: theme => ({ color: theme.palette.grey['100'] }),
              }}
            />
          </Card>
        ),
        handleAdd: () => zoneAreasLogic.state.setIsOpen(true),
        EmptyIcon: LocationOn,
      })
    }

    const renderStores = (render: (o: RenderParam<ZoneStore>) => void) => {
      return render({
        items: filteredStores,
        renderItem: item => (
          <FlexBox.RowBetween flex={1}>
            <FlexBox.Col>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                {item.name.toUpperCase()}
              </Typography>
              {item.address && (
                <Typography variant="subtitle1">
                  {StoresHelpers.formatAddress(item.address)}
                </Typography>
              )}
              <Typography variant="subtitle2">
                Internal Store Id: {item.internalStoreId || 'N/A'}
              </Typography>
            </FlexBox.Col>
            <IconButton
              onClick={() => {
                if (zone?.id) {
                  handleRemoveStoreFromZone(zone.id, item.id)
                  handleUpdateSearchParams({
                    zoneId,
                    zoneTab: tab,
                  })
                }
              }}
            >
              <Delete color={'error'} />
            </IconButton>
          </FlexBox.RowBetween>
        ),
        handleAdd: () => zoneStoresLogic.state.setIsOpen(true),
        EmptyIcon: Storefront,
      })
    }

    const renderPeople = (
      render: (o: RenderParam<ZoneAssignedUser>) => void,
    ) => {
      return render({
        items: filteredUsers,
        renderItem: item => (
          <>
            <ListItemText
              primary={item.name}
              secondary={
                item.mainRole
                  ? StringHelpers.titleCase(
                      StringHelpers.stringValue(item.mainRole),
                    )
                  : 'No Assigned Role'
              }
            />
            {item.dateRemoved && <Chip label="Retired" color="error" />}
          </>
        ),
        handleAdd: () => zoneUsersLogic.state.setIsOpen(true),
        EmptyIcon: Groups,
      })
    }

    if (tab === 'areas') {
      return renderAreas
    } else if (tab === 'stores') {
      return renderStores
    } else if (tab === 'people') {
      return renderPeople
    }
    throw new Error('Invalid Tab')
  }

  return (
    <>
      <NotificationAlert />
      <AutoSelectNodeContext.Provider value={{ state: zone, handleIsOpen }}>
        <RetiredUserWarning
          zones={zonesWithRetiredUsers}
          handleViewZone={({ zoneId }) => {
            handleUpdateSearchParams({
              zoneId,
              zoneTab: 'people',
            })
          }}
        />
        <FlexBox.Row flex={1}>
          <Sidebar side={'left'}>
            <Box px={2}>
              <SearchBar
                value={ZoneSearch.itemQuery}
                onChange={ZoneSearch.setItemQuery}
              />
            </Box>
            {ZoneSearch.itemQuery.length ? (
              <Box sx={{ p: 2 }} flex={1}>
                <ZoneHierarchyGlobalSearch
                  {...searchResults}
                  handleSelectResult={zoneId => {
                    handleUpdateSearchParams({ zoneId, query: undefined })
                    ZoneSearch.setItemQuery('')
                  }}
                  loading={ZoneSearch.lazyQuery.meta.loading}
                />
              </Box>
            ) : (
              <TreeList<ZoneLevel>
                loading={zoneHierarchyLoading}
                handleSelect={zone =>
                  handleUpdateSearchParams({
                    zoneId: String(zone.id),
                    zoneTab: undefined,
                  })
                }
                nodes={TreeHelpers.buildTreeAndGetRoots(zoneHierarchy)}
                renderBody={({ node: zone }) => (
                  <FlexBox.Col>
                    <Typography variant="subtitle1">{zone.name}</Typography>
                    <Breadcrumbs
                      separator="◦"
                      sx={theme => ({ color: theme.palette.grey[400] })}
                    >
                      <Typography
                        variant="body2"
                        sx={theme => ({ color: theme.palette.grey[400] })}
                      >
                        {StringHelpers.stringValue(zone.tier)}
                      </Typography>
                      {zone.manager && (
                        <Typography
                          variant="body2"
                          sx={theme => ({ color: theme.palette.grey[400] })}
                        >
                          {zone.manager?.fullName}
                        </Typography>
                      )}
                    </Breadcrumbs>
                  </FlexBox.Col>
                )}
                selected={zone => zone.id === zoneId}
              >
                <ListItem
                  sx={theme => ({
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.grey['200'], 0.5),
                    },
                  })}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <IconButton
                      onClick={() => {
                        handleUpdateSearchParams({ zoneId: undefined })
                        zoneAreasLogic.state.setIsOpen(true)
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                    <Typography>Add Area</Typography>
                  </Stack>
                </ListItem>
              </TreeList>
            )}
          </Sidebar>
          <Paper sx={{ m: 2, flex: 1, p: 2 }} elevation={0}>
            {zoneLoading ? (
              <FillContainerLoading />
            ) : zoneError ? (
              <FlexBox.ColCenter sx={{ height: '100%' }}>
                <ZoneErrorState error={zoneError} />
              </FlexBox.ColCenter>
            ) : zone ? (
              <>
                <ZoneFormHeader
                  zone={zone}
                  onPressEdit={() => {
                    if (zoneTab === 'areas') {
                      zoneAreasLogic.state.setIsOpen(true)
                    }
                    if (zoneTab === 'people') {
                      zoneUsersLogic.state.setIsOpen(true)
                    } else {
                      zoneStoresLogic.state.setIsOpen(true)
                    }
                  }}
                  Tabs={
                    <Tabs
                      value={
                        zoneTab
                          ? Object.keys(tabOptions).indexOf(zoneTab)
                          : undefined
                      }
                    >
                      {Object.values(tabOptions).map(t => (
                        <Tab
                          label={t.label}
                          icon={<t.Icon color="inherit" sx={{ height: 20 }} />}
                          iconPosition="start"
                          sx={theme => ({
                            color: theme.palette.grey['400'],
                            display: t.hidden ? 'none' : undefined,
                          })}
                          onClick={() =>
                            handleUpdateSearchParams({
                              zoneId,
                              zoneTab: t.label.toLowerCase(),
                            })
                          }
                        />
                      ))}
                    </Tabs>
                  }
                />
                <Box sx={{ mx: 2 }}>
                  <SearchBar value={searchString} onChange={setSearchString} />
                </Box>
                {zoneTab &&
                  ListBody(zoneTab)(
                    ({ items, renderItem, handleAdd, EmptyIcon }) => (
                      <>
                        {items.length ? (
                          <List>
                            {items.map(item => (
                              <ListItem>{renderItem(item)}</ListItem>
                            ))}
                          </List>
                        ) : (
                          <EmptyList
                            sx={{
                              px: 2,
                              py: 10,
                            }}
                            caption={
                              !StringHelpers.isNullOrEmpty(searchString)
                                ? 'Nothing matched that search.'
                                : 'Nothing here yet. Press the button below to get started.'
                            }
                            Icon={
                              <EmptyIcon
                                sx={theme => theme.sizes.icon.lg}
                                color="disabled"
                              />
                            }
                            Button={
                              <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<Add />}
                                onClick={handleAdd}
                              >
                                Add
                              </Button>
                            }
                          />
                        )}
                      </>
                    ),
                  )}
              </>
            ) : null}
          </Paper>
        </FlexBox.Row>
        <ZoneMutateModal<ZoneUserRow>
          title={zone?.name || ''}
          subtitle="Edit Roster"
          label="user"
          logic={zoneUsersLogic}
          columns={ZoneHierarchyConstants.columns.user}
        />
        <ZoneMutateModal<ZoneStoreRow>
          title={zone?.name || ''}
          subtitle="Edit Stores"
          label="store"
          logic={zoneStoresLogic}
          columns={ZoneHierarchyConstants.columns.store}
        />
        <ZoneMutateModal<ZoneRow>
          title={zone?.name || ''}
          subtitle="Edit Areas"
          label="area"
          logic={zoneAreasLogic}
          columns={ZoneHierarchyConstants.columns.area}
        />
      </AutoSelectNodeContext.Provider>
    </>
  )
}

export default ZoneHierarchyContainer
