"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStoreAreas = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useStoreAreas = function () {
    var _a = (0, client_1.useQuery)(graphql_1.GET_STORE_AREAS), data = _a.data, loading = _a.loading, error = _a.error;
    var areas = (data === null || data === void 0 ? void 0 : data.storeAreas) || [];
    var areaNames = (data === null || data === void 0 ? void 0 : data.storeAreas.map(function (a) { return a.name; })) || [];
    return { areas: areas, areaNames: areaNames, loading: loading, error: error };
};
exports.useStoreAreas = useStoreAreas;
