"use strict";
/**
 * Copyright © 2024 Delicious AI, LLC
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUrlSearchParams = void 0;
var react_router_dom_1 = require("react-router-dom");
var lodash_1 = require("lodash");
var useUrlSearchParams = function () {
    var location = (0, react_router_dom_1.useLocation)();
    var history = (0, react_router_dom_1.useHistory)();
    var parseUrlSearchParams = function (searchString) {
        var search = new URLSearchParams(searchString);
        var params = Object.fromEntries(search.entries());
        return params;
    };
    var isValidValue = function (value) { return (0, lodash_1.isString)(value) || (0, lodash_1.isNumber)(value); };
    var handleSetSearchParams = function (params) {
        var entries = Object.entries((0, lodash_1.pickBy)(params, isValidValue));
        var path = "".concat(location.pathname, "?").concat(entries
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return "".concat(key, "=").concat(value);
        })
            .join('&'));
        history.push(path);
    };
    var handleUpdateSearchParams = function (params) {
        if (params === void 0) { params = {}; }
        var currentState = parseUrlSearchParams(location.search);
        var entries = Object.entries((0, lodash_1.pickBy)(__assign(__assign({}, currentState), params), isValidValue));
        if (!entries.length) {
            history.push(location.pathname);
        }
        else {
            var path = "".concat(location.pathname, "?").concat(entries
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return "".concat(key, "=").concat(value);
            })
                .join('&'));
            history.replace(path);
        }
    };
    var handleNavTo = function (path, options) {
        var _path = path.startsWith('/') ? path : "/".concat(path);
        var currentState = (0, lodash_1.omit)((options === null || options === void 0 ? void 0 : options.resetParams) ? {} : parseUrlSearchParams(location.search), (options === null || options === void 0 ? void 0 : options.removeParams) || []);
        var entries = Object.entries((0, lodash_1.pickBy)(__assign(__assign({}, currentState), ((options === null || options === void 0 ? void 0 : options.replaceParams) || {})), isValidValue));
        if (!entries.length) {
            history.push(_path);
        }
        else {
            var newPath = "".concat(_path, "?").concat(entries
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return "".concat(key, "=").concat(value);
            })
                .join('&'));
            history.push(newPath);
        }
    };
    return {
        state: parseUrlSearchParams(location.search),
        handle: {
            handleSetSearchParams: handleSetSearchParams,
            handleUpdateSearchParams: handleUpdateSearchParams,
            handleNavTo: handleNavTo,
        },
    };
};
exports.useUrlSearchParams = useUrlSearchParams;
