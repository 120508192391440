"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ALL_COMPANY_USER_OPTIONS = void 0;
var client_1 = require("@apollo/client");
exports.GET_ALL_COMPANY_USER_OPTIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery GetAllCompanyUserOptions {\n  adminCompanyAllUsers {\n      id\n      lastName\n      firstName\n  }\n}\n"], ["\nquery GetAllCompanyUserOptions {\n  adminCompanyAllUsers {\n      id\n      lastName\n      firstName\n  }\n}\n"])));
var templateObject_1;
