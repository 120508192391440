/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useState } from 'react'
import {
  gridAllScreens,
  MuiFormTypes,
  NotificationContext,
  SelectableOption,
  useDebouncedItemQuery,
  useMuiForm,
} from '@dai/web-components'
import {
  CREATE_STORE_GROUP,
  GET_PHYSICAL_STORES,
  GET_STORE_GROUPS,
  GetPhysicalStoresQuery,
  GetPhysicalStoresQueryVariables,
  GroupTypes,
  StoreGroupNameMutation,
  StoreGroupNameMutationVariables,
  StoreGroupsQuery,
  StoreGroupsQueryVariables,
} from '@dai/graphql'
import PicOSContext from 'context/PicOSContext'
import { useMutation } from '@apollo/client'
import { PicosConstants } from 'picos/picos.constants'
import { unionBy } from 'lodash'

export type PicosGroupForm = {
  searchSelect: {
    GROUPS: MuiFormTypes['searchSelect']
  }
  text: {
    NAME: MuiFormTypes['text']
  }
}

export const usePicosStoreGroupForm = () => {
  const { selectedBanner, priorityToEdit, setPriorityToEdit } = useContext(
    PicOSContext,
  )
  const { setSuccess, setError } = useContext(NotificationContext)
  const [storeGroupName, setStoreGroupName] = useState('')
  const [choiceStores, setChoiceStores] = useState<readonly SelectableOption[]>(
    [],
  )
  const [chosenStores, setChosenStores] = useState<readonly SelectableOption[]>(
    [],
  )
  const [showTransferList, setShowTransferList] = useState<boolean>(false)
  const [internalIDs, setInternalIds] = useState<string[]>([])

  const [
    showCreateStoreGroupSuccess,
    setShowCreateStoreGroupSuccess,
  ] = useState<boolean>(false)

  const PhysicalStoresLazyQuery = useDebouncedItemQuery<
    GetPhysicalStoresQuery,
    GetPhysicalStoresQueryVariables
  >({
    queryStr: GET_PHYSICAL_STORES,
    options: { fetchPolicy: 'cache-first' },
  })

  const StoreGroupsLazyQuery = useDebouncedItemQuery<
    StoreGroupsQuery,
    StoreGroupsQueryVariables
  >({
    queryStr: GET_STORE_GROUPS,
    options: { fetchPolicy: 'cache-and-network' },
  })

  const [
    createStoreGroupMutation,
    { loading: createStoreGroupLoading, data: createStoreGroupRes },
  ] = useMutation<StoreGroupNameMutation, StoreGroupNameMutationVariables>(
    CREATE_STORE_GROUP,
    {
      onCompleted: data => handleCreateStoreGroupSuccess(data),
    },
  )

  const handleCreateStoreGroupSuccess = (data: StoreGroupNameMutation) => {
    if (data && data.createStoreGroup) {
      setShowCreateStoreGroupSuccess(true)
      setPriorityToEdit({
        ...priorityToEdit,
        storeGroups: [
          {
            label: data.createStoreGroup.storeGroup.name,
            value: data.createStoreGroup.storeGroup.id,
          },
        ],
      })
      setChosenStores(
        PhysicalStoresLazyQuery.lazyQuery.meta.data?.stores.results.map(
          store => ({
            label: store.name,
            value: store.id,
          }),
        ) || [],
      )
      handleFetchStoreGroups()
    }
  }

  const handleCreateStoreGroup = () => {
    if (selectedBanner?.id) {
      createStoreGroupMutation({
        variables: {
          input: {
            storeGroupName,
            groupType: GroupTypes.PICOS,
            idsOfStoresToAdd: internalIDs,
            storeChainId: selectedBanner.id,
          },
        },
      })
        .then(() => {
          setSuccess('Successfully created new Store Group')
          setShowTransferList(false)
        })
        .catch(error => setError(error))
    }
  }

  const handleFetchStoreGroups = () => {
    if (selectedBanner) {
      StoreGroupsLazyQuery.lazyQuery.query({
        variables: { input: { storeChainId: selectedBanner.id } },
      })
    }
  }

  const handleResetSelectedStoreGroup = () => {
    setPriorityToEdit({ ...priorityToEdit, storeGroups: [] })
  }

  const storeGroupsFromQuery =
    StoreGroupsLazyQuery.lazyQuery.meta.data?.storeGroups.results.map(sg => ({
      label: sg.name,
      value: sg.id,
    })) || []

  const storeGroupForm: PicosGroupForm = {
    searchSelect: {
      GROUPS: {
        label: 'Store Groups',
        gridProps: { ...gridAllScreens(6), xs: 12 },
        variant: 'filled',
        value: priorityToEdit.storeGroups?.map(gr => gr.value) || [],
        multiple: true,
        options: unionBy(
          [...storeGroupsFromQuery, ...priorityToEdit.storeGroups],
          'value',
        ),
        setSearchText: _ => null,
        loading: false,
        required: true,
        onChange: storeGroups =>
          setPriorityToEdit({ ...priorityToEdit, storeGroups }),
      },
    },
    text: {
      NAME: {
        input: {
          required: false,
          type: 'text',
          variant: 'standard',
          label: 'Name',
          placeholder: 'New Store Group Name',
        },
        gridProps: gridAllScreens(12),
        value: storeGroupName,
        onChange: setStoreGroupName,
      },
    },
  }
  const { data } = useMuiForm({
    formState: storeGroupForm,
  })

  useEffect(() => {
    handleFetchStoreGroups()
  }, [])

  useEffect(() => {
    if (selectedBanner) {
      PhysicalStoresLazyQuery.lazyQuery.query({
        variables: {
          input: {
            storeChainId: selectedBanner.id,
          },
        },
      })
    }
  }, [selectedBanner])

  useEffect(() => {
    if (PhysicalStoresLazyQuery.lazyQuery.meta.data) {
      setChoiceStores(
        PhysicalStoresLazyQuery.lazyQuery.meta.data.stores.results.map(
          store => ({
            label: store.name,
            value: store.id,
          }),
        ),
      )
    }
  }, [PhysicalStoresLazyQuery.lazyQuery.meta.data?.stores.results.length])

  return {
    state: {
      formState: storeGroupForm,
      choiceStores,
      setChoiceStores,
      chosenStores,
      setChosenStores,
      internalIDs,
      setInternalIds,
      showTransferList,
      setShowTransferList,
      showCreateStoreGroupSuccess,
      setShowCreateStoreGroupSuccess,
    },
    handle: {
      handleCreateStoreGroup,
      handleResetSelectedStoreGroup,
    },
    data: {
      ...data,
      createStoreGroupLoading,
      createStoreGroupRes,
    },
  }
}
