"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULAR_COMPLIANCE_AUDIT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var SegmentComplianceGroupFragment_1 = require("./SegmentComplianceGroupFragment");
var UserFragment_1 = require("./UserFragment");
exports.MODULAR_COMPLIANCE_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment ComplianceAudit on ComplianceAudit {\n    id\n    displayId\n    dateCompleted\n    saved\n    strictScore\n    sequenceScore\n    employee {\n      ...User\n    }\n    segmentCompliance {\n      ...SegmentComplianceGroup\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment ComplianceAudit on ComplianceAudit {\n    id\n    displayId\n    dateCompleted\n    saved\n    strictScore\n    sequenceScore\n    employee {\n      ...User\n    }\n    segmentCompliance {\n      ...SegmentComplianceGroup\n    }\n  }\n  ", "\n  ", "\n"])), SegmentComplianceGroupFragment_1.SEGMENT_COMPLIANCE_GROUP_FRAGMENT, UserFragment_1.USER_FRAGMENT);
var templateObject_1;
