"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SABRE_PRODUCT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var PackagingFragment_1 = require("./PackagingFragment");
var ShippingFragment_1 = require("./ShippingFragment");
var CategoryFragment_1 = require("./CategoryFragment");
var ImageFragment_1 = require("./ImageFragment");
var PlanogramImageFragment_1 = require("./PlanogramImageFragment");
var FlavorFragment_1 = require("./FlavorFragment");
var ManufacturerFragment_1 = require("./ManufacturerFragment");
exports.SABRE_PRODUCT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Product on Product {\n    id\n    created\n    modified\n    upcA\n    upcE\n    name\n    source\n    fullName\n    brand {\n      id\n      name\n    }\n    manufacturer {\n      ...Manufacturer\n    }\n    drafts {\n      id\n      notes\n      createdBy\n      dateResolved\n    }\n    flavors {\n      ...Flavor\n    }\n    marketingImageUrl\n    planogramImages {\n      ...PlanogramImages\n    }\n    images {\n      ...Image\n    }\n    category {\n      ...Category\n    }\n    packaging {\n      ...Packaging\n    }\n    shipping {\n      ...Shipping\n    }\n    verifiedBy\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment Product on Product {\n    id\n    created\n    modified\n    upcA\n    upcE\n    name\n    source\n    fullName\n    brand {\n      id\n      name\n    }\n    manufacturer {\n      ...Manufacturer\n    }\n    drafts {\n      id\n      notes\n      createdBy\n      dateResolved\n    }\n    flavors {\n      ...Flavor\n    }\n    marketingImageUrl\n    planogramImages {\n      ...PlanogramImages\n    }\n    images {\n      ...Image\n    }\n    category {\n      ...Category\n    }\n    packaging {\n      ...Packaging\n    }\n    shipping {\n      ...Shipping\n    }\n    verifiedBy\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), PlanogramImageFragment_1.PLANOGRAM_IMAGES_FRAGMENT, FlavorFragment_1.SABRE_FLAVOR_FRAGMENT, ImageFragment_1.IMAGE_FRAGMENT, CategoryFragment_1.CATEGORY_FRAGMENT, PackagingFragment_1.SABRE_PACKAGING_FRAGMENT, ShippingFragment_1.SABRE_SHIPPING_FRAGMENT, ManufacturerFragment_1.SABRE_MANUFACTURER_FRAGMENT);
var templateObject_1;
