"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_COMPANY_STORE_OPTIONS = void 0;
var client_1 = require("@apollo/client");
exports.GET_COMPANY_STORE_OPTIONS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetCompanyStoreOptions($searchTerm: String!) {\n    companyStoreOptions(searchTerm: $searchTerm) {\n      id\n      store {\n        name\n      }\n    }\n  }\n"], ["\n  query GetCompanyStoreOptions($searchTerm: String!) {\n    companyStoreOptions(searchTerm: $searchTerm) {\n      id\n      store {\n        name\n      }\n    }\n  }\n"])));
var templateObject_1;
