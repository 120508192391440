"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORES_STATS = void 0;
var client_1 = require("@apollo/client");
exports.GET_STORES_STATS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetStoresStats($input: StoresStatsInput) {\n    storesStats(input: $input) {\n      store {\n        id\n        store {\n          name\n          address {\n            address1\n            address2\n            city\n            state\n            postalCode\n          }\n          image {\n            id\n            url\n          }\n        }\n      }\n      displaysObservedToday\n      displaysObservedTodayIds\n      displayScansToday\n      displaysScannedTodayIds\n      avgScoreToday\n      date\n      observedDisplays\n    }\n  }\n"], ["\n  query GetStoresStats($input: StoresStatsInput) {\n    storesStats(input: $input) {\n      store {\n        id\n        store {\n          name\n          address {\n            address1\n            address2\n            city\n            state\n            postalCode\n          }\n          image {\n            id\n            url\n          }\n        }\n      }\n      displaysObservedToday\n      displaysObservedTodayIds\n      displayScansToday\n      displaysScannedTodayIds\n      avgScoreToday\n      date\n      observedDisplays\n    }\n  }\n"])));
var templateObject_1;
