/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material'
import {
  Check,
  Close,
  CloudUpload,
  InfoRounded,
  Movie,
  Save,
  VideoCameraBack,
} from '@mui/icons-material'
import { FlexBox, FileUploader, MuiFormModal } from '@dai/web-components'
import { DZFile } from '@dai/common'

type VideoUploadCardProps = {
  title: string
  subheader: string
  objectType: string
  videoUrl: string | undefined | null
  videoFile: DZFile | undefined
  onVideoFileChange: (file: DZFile | undefined) => void
}

const VideoUploadCard: React.FC<VideoUploadCardProps> = props => {
  const {
    title,
    subheader,
    objectType,
    videoUrl,
    onVideoFileChange,
    videoFile,
  } = props
  const [showVideoUpload, setShowVideoUpload] = React.useState(false)
  return (
    <>
      <Card sx={{ height: '100%', flex: 1 }}>
        <CardHeader
          action={
            <Avatar
              sx={theme => ({
                bgcolor: theme.palette.secondary.main,
              })}
            >
              <VideoCameraBack color="secondary" />
            </Avatar>
          }
          title={title}
          subheader={subheader}
        />
        <FlexBox.ColLeft sx={{ p: 2, flex: 1 }}>
          {videoUrl ? (
            <Stack spacing={2} direction={'row'}>
              <Check color="success" />
              <Stack spacing={2} alignItems={'flex-start'}>
                <Typography>This {objectType} already has a video</Typography>
                {videoFile && (
                  <Stack direction={'row'} spacing={2}>
                    <Movie color={'success'} />
                    <Typography
                      sx={theme => ({ color: theme.palette.success.main })}
                    >
                      {videoFile.path}
                    </Typography>
                  </Stack>
                )}
                <Button
                  startIcon={<CloudUpload color={'secondary'} />}
                  sx={{ ml: 2 }}
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => setShowVideoUpload(true)}
                >
                  Replace Video
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing={2} direction={'row'}>
              <InfoRounded />
              <Stack spacing={2} alignItems={'flex-start'}>
                <Typography>
                  This {objectType} doesn't have a video yet
                </Typography>
                <Button
                  startIcon={<CloudUpload color={'secondary'} />}
                  sx={{ ml: 2 }}
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => setShowVideoUpload(true)}
                >
                  Upload Video
                </Button>
              </Stack>
            </Stack>
          )}
        </FlexBox.ColLeft>
      </Card>
      <MuiFormModal
        title={`Upload new ${objectType} video`}
        isOpen={showVideoUpload}
        handleClose={() => setShowVideoUpload(false)}
        actionButtonsProps={[
          {
            children: 'Cancel',
            color: 'secondary',
            onClick: () => {
              setShowVideoUpload(false)
              onVideoFileChange(undefined)
            },
            startIcon: <Close color="secondary" />,
          },
          {
            onClick: () => setShowVideoUpload(false),
            children: 'Save',
            startIcon: <Save color="secondary" />,
            disabled: !videoFile,
          },
        ]}
      >
        <FileUploader
          onFilesSelected={files => onVideoFileChange(files[0])}
          files={videoFile ? [videoFile] : undefined}
          onClearFiles={() => onVideoFileChange(undefined)}
          allowedFileTypes={['.mp4']}
        />
      </MuiFormModal>
    </>
  )
}

export default VideoUploadCard
