"use strict";
/**
 * Copyright © 2020 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.uniqueId = void 0;
var uuid_1 = require("uuid");
var uniqueId = function () { return (0, uuid_1.v4)(); };
exports.uniqueId = uniqueId;
