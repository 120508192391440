"use strict";
/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompanyFormulaGroups = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useCompanyFormulaGroups = function () {
    var coldData = (0, client_1.useQuery)(graphql_1.COMPANY_COLD_AVAILABILITY_FORMULA_GROUPS).data;
    var categoryData = (0, client_1.useQuery)(graphql_1.COMPANY_CATEGORY_SOVI_FORMULA_GROUPS).data;
    var coldAvailabilityFormulaGroups = coldData === null || coldData === void 0 ? void 0 : coldData.companyColdAvailabilityFormulaGroups;
    var categorySoviFormulaGroups = categoryData === null || categoryData === void 0 ? void 0 : categoryData.companyCategorySoviFormulaGroups;
    return { coldAvailabilityFormulaGroups: coldAvailabilityFormulaGroups, categorySoviFormulaGroups: categorySoviFormulaGroups };
};
exports.useCompanyFormulaGroups = useCompanyFormulaGroups;
