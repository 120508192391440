"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SIMPLE_STORE_AUDITS = void 0;
var client_1 = require("@apollo/client");
var SimpleStoreAuditFragment_1 = require("../fragments/SimpleStoreAuditFragment");
exports.GET_SIMPLE_STORE_AUDITS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetSimpleStoreAudits($input: AuditsInput!) {\n    audits(input: $input) {\n      hasMore\n      count\n      results {\n        ...SimpleAudit\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetSimpleStoreAudits($input: AuditsInput!) {\n    audits(input: $input) {\n      hasMore\n      count\n      results {\n        ...SimpleAudit\n      }\n    }\n  }\n  ", "\n"])), SimpleStoreAuditFragment_1.SIMPLE_AUDIT_FRAGMENT);
var templateObject_1;
