/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { PropsWithChildren, useContext } from 'react'
import { Box, CircularProgress, Grid, Stack } from '@mui/material'
import {
  CondensedInputWithDropdown,
  DAIFormState,
  MuiForm,
  useMuiForm,
  ReportContainerLayout,
  ReportLayout,
  Filter,
  gridAllScreens,
  ChangeOverTimeChip,
  useCommonFilter,
  StatCard,
  FlexScroll,
  CollapsibleContent,
  FilterLabel,
  FormInputType,
  FilterHelpers,
} from '@dai/web-components'
import {
  GenericReportProps,
  GenericReportSummaryProps,
  ReportLayoutProps,
} from 'reporting/admin-reporting.types'
import { ReportSelector } from 'reporting/selectors/Report.selector'
import ReportContext from 'context/ReportContext'
import { useReportDisplay } from 'users/hooks/reporting/use-report-display'

type ReportBuilderProps<ReportName extends string> = {
  inputForm: {
    formState: DAIFormState<FormInputType, any>
    setFormState: (form: DAIFormState<FormInputType, any>) => void
    orderOfForms?: FormInputType[]
  }
  summaries?: Record<string, GenericReportSummaryProps<ReportName>[]>
  summaryMoreInfo?: React.ReactNode
  reports: Record<string, GenericReportProps<ReportName>[]>
  FilterProps?: ReturnType<typeof useCommonFilter>
} & Omit<ReportLayoutProps, 'Body'>

export const ReportBuilder = <ReportName extends string>(
  props: PropsWithChildren<ReportBuilderProps<ReportName>>,
) => {
  const {
    inputForm, // DAIFormState
    summaries,
    reports,
    FilterProps,
    summaryMoreInfo,
    ...rest
  } = props
  const {
    handle: { handleOnChange },
  } = useMuiForm(inputForm)
  const {
    selectedReportGroup,
    selectedSummaryGroup,
    reportParams,
  } = useContext(ReportContext)
  const ReportDisplay = useReportDisplay<
    ReportName,
    GenericReportProps<ReportName>
  >()
  if (!selectedReportGroup) return null
  const SelectedReports = ReportDisplay.filter(
    reportParams.userType,
    reports[selectedReportGroup],
  ).map(ReportProps => ({
    Report: ReportSelector.select(ReportProps.type),
    Props: ReportProps,
  }))
  const SelectedSummaries =
    summaries && selectedSummaryGroup
      ? summaries[selectedSummaryGroup]
      : undefined

  const gridProps: any = {
    container: true,
    spacing: 2,
    direction: 'row',
  }

  return (
    <ReportContainerLayout
      Header={
        <>
          <Grid {...gridProps} display={{ xs: 'none', sm: 'flex' }}>
            <MuiForm
              {...inputForm}
              handleOnChange={handleOnChange}
              disableGrid
            />
            {FilterProps && (
              <Grid item {...gridAllScreens(5)}>
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent={'flex-end'}
                  py={1}
                >
                  <Filter filterTitle={'Filter Time Frame'} {...FilterProps} />
                  {FilterHelpers.getDateFilterLabels(FilterProps).map(label => (
                    <FilterLabel {...label} />
                  ))}
                </Stack>
              </Grid>
            )}
          </Grid>
          <Grid {...gridProps} display={{ xs: 'flex', sm: 'none' }}>
            <Grid item xs={10}>
              <CondensedInputWithDropdown
                label={'Select Report'}
                {...inputForm}
              />
            </Grid>
            {FilterProps && (
              <>
                <Grid item xs={2} justifyContent={'flex-end'}>
                  <Box py={1}>
                    <Filter
                      filterTitle={'Filter Time Frame'}
                      {...FilterProps}
                    />
                  </Box>
                </Grid>
                {FilterProps.filterHasDateRangeSet() && (
                  <Grid item xs={12} p={1}>
                    {FilterHelpers.getDateFilterLabels({
                      ...FilterProps,
                      join: true,
                    }).map(label => (
                      <FilterLabel fillWidth {...label} />
                    ))}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </>
      }
      Summary={
        SelectedSummaries && !!SelectedSummaries.length ? (
          <CollapsibleContent LeftComponent={summaryMoreInfo}>
            <FlexScroll.Horizontal
              items={SelectedSummaries}
              renderItem={summaryHook => (
                <ReportStatCard<ReportName> {...summaryHook} />
              )}
            />
          </CollapsibleContent>
        ) : undefined
      }
      Body={
        <ReportLayout
          container
          {...rest}
          Body={
            <Grid container>
              {SelectedReports.map(Selected => (
                <Grid item {...Selected.Props.gridProps}>
                  <Selected.Report {...Selected.Props} />
                </Grid>
              ))}
            </Grid>
          }
        />
      }
    />
  )
}

/**
 * This has to be in its own component since we are rendering a hook "useGetData"
 * Otherwise, React will freak out whenever we change the number of stat cards
 * that are rendered
 */
const ReportStatCard = <ReportName extends string>(
  props: PropsWithChildren<GenericReportSummaryProps<ReportName>>,
) => {
  const { reportParams } = useContext(ReportContext)
  const ReportDisplay = useReportDisplay<
    ReportName,
    GenericReportSummaryProps<ReportName>
  >()
  const { progress, change, ...cardProps } = props.useGetData(reportParams)
  if (ReportDisplay.include(reportParams.userType, props)) {
    return (
      <StatCard
        {...cardProps}
        extraComponent={
          progress ? (
            <CircularProgress size={28} variant="determinate" {...progress} />
          ) : undefined
        }
        extraComponentPlacement={progress?.placement}
        footer={
          change ? (
            <ChangeOverTimeChip change={change.value} {...change} />
          ) : undefined
        }
      />
    )
  }
  return null
}
