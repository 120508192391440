"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PICOS_ALL_PRIORITIES_COMPLIANCE = void 0;
var client_1 = require("@apollo/client");
var PicOSPriorityOverviewFragment_1 = require("../fragments/PicOSPriorityOverviewFragment");
exports.PICOS_ALL_PRIORITIES_COMPLIANCE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PicOSAllPrioritiesCompliance(\n    $input: PicOSAllPrioritiesComplianceInput!\n  ) {\n    picosAllPrioritiesCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        id\n        priority {\n          ...PicOSPriorityOverview\n        }\n        regionCompliance {\n          name\n          compliancePercentage\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  query PicOSAllPrioritiesCompliance(\n    $input: PicOSAllPrioritiesComplianceInput!\n  ) {\n    picosAllPrioritiesCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        id\n        priority {\n          ...PicOSPriorityOverview\n        }\n        regionCompliance {\n          name\n          compliancePercentage\n        }\n      }\n    }\n  }\n  ", "\n"])), PicOSPriorityOverviewFragment_1.PICOS_PRIORITY_OVERVIEW_FRAGMENT);
var templateObject_1;
