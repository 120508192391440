/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import dayjs from 'dayjs'
import { FC, useContext, useEffect, useState } from 'react'
import {
  PicOSReportContext,
  BasicPicOSCustomerInfo,
  DateRange,
  PicOSReportType,
  PicOSPriorityInfo,
} from '@dai/web-components'
import { Switch, useLocation } from 'react-router-dom'
import ProtectedRoute from 'routes/ProtectedRoute'
import { Box } from '@mui/material'
import PicOSReportBreadcrumbs from 'picos/components/PicOSReportBreadcrumbs'
import PicOSContext from 'context/PicOSContext'
import PicOSAllCustomers from './PicOSAllCustomers.container'
import PicOSCustomerReport from './PicOSCustomerReport.container'
import PicOSDivisionReport from './PicOSDivisionReport.container'
import PicOSSalesCenterReport from './PicOSSalesCenterReport.container'
import PicOSStoreReport from './PicOSStoreReport.container'
import PicOSAllPrioritiesReport from './PicOSAllPrioritiesReport.container'
import PicOSPriorityComplianceReport from './PicOSPriorityComplianceReport.container'

const PicOSReportsContainer: FC = () => {
  const { handleNavTo } = useContext(PicOSContext)
  const location = useLocation()

  const [reportType, _setReportType] = useState<PicOSReportType>(
    location.pathname.includes('reports/priorities')
      ? 'All Priorities'
      : 'Divisions',
  )

  const [
    selectedCustomer,
    setSelectedCustomer,
  ] = useState<BasicPicOSCustomerInfo | null>(null)
  const [
    selectedDivision,
    setSelectedDivision,
  ] = useState<BasicPicOSCustomerInfo | null>(null)
  const [
    selectedSalesCenter,
    setSelectedSalesCenter,
  ] = useState<BasicPicOSCustomerInfo | null>(null)
  const [
    selectedStore,
    setSelectedStore,
  ] = useState<BasicPicOSCustomerInfo | null>(null)
  const [
    selectedPriority,
    setSelectedPriority,
  ] = useState<PicOSPriorityInfo | null>(null)
  const [selectedDateRange, setDateRange] = useState<DateRange>({
    start: dayjs().subtract(4, 'week'),
    end: dayjs(),
  })

  const handleSetDate = (type: 'start' | 'end', date: string) => {
    const dateObject = dayjs(date)
    setDateRange({ ...selectedDateRange, [type]: dateObject })
  }

  const setReportType = (type: PicOSReportType) => {
    _setReportType(type)
    if (type === 'All Priorities') {
      handleNavTo('/picos/reports/priorities')
    } else {
      handleNavTo('/picos/reports/customers')
    }
  }

  useEffect(() => {
    if (
      location.pathname === '/picos/reports/' ||
      location.pathname === '/picos/reports'
    ) {
      setTimeout(() => {
        handleNavTo('/picos/reports/customers')
      }, 250)
    }
  }, [])

  return (
    <PicOSReportContext.Provider
      value={{
        reportType,
        selectedCustomer,
        selectedDivision,
        selectedSalesCenter,
        selectedStore,
        selectedPriority,
        selectedDateRange,
        setReportType,
        setSelectedCustomer,
        setSelectedDivision,
        setSelectedSalesCenter,
        setSelectedStore,
        setSelectedPriority,
        setDateRange,
        handleSetDate,
      }}
    >
      <Box sx={{ height: '100vh' }}>
        {reportType === 'Divisions' && <PicOSReportBreadcrumbs />}
        <Switch>
          <ProtectedRoute path="/picos/reports/customers" exact>
            <PicOSAllCustomers />
          </ProtectedRoute>
          <ProtectedRoute path="/picos/reports/customers/:customerId" exact>
            <PicOSCustomerReport />
          </ProtectedRoute>
          <ProtectedRoute
            path="/picos/reports/customers/:customerId/:divisionId"
            exact
          >
            <PicOSDivisionReport />
          </ProtectedRoute>
          <ProtectedRoute
            path="/picos/reports/customers/:customerId/:divisionId/:salesCenterId"
            exact
          >
            <PicOSSalesCenterReport />
          </ProtectedRoute>
          <ProtectedRoute
            path="/picos/reports/customers/:customerId/:divisionId/:salesCenterId/:storeId"
            exact
          >
            <PicOSStoreReport />
          </ProtectedRoute>
          <ProtectedRoute path="/picos/reports/priorities" exact>
            <PicOSAllPrioritiesReport />
          </ProtectedRoute>
          <ProtectedRoute path="/picos/reports/priorities/:priorityId" exact>
            <PicOSPriorityComplianceReport />
          </ProtectedRoute>
        </Switch>
      </Box>
    </PicOSReportContext.Provider>
  )
}

export default PicOSReportsContainer
