/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { PicOSGroupComplianceStatsFragment } from '@dai/graphql'
import {
  ChangeOverTimeChip,
  CircularProgressThresholds,
  DateRange,
} from '@dai/web-components'
import { Box } from '@mui/material'
import {
  isPicOSAllCustomersStats,
  isPicOSCustomerStats,
  isPicOSRegionalStats,
} from 'picos/picos.type-guards'

const TIME_PERIOD_LABEL = 'From Previous Period'

const percentChange = (current: number, previous: number): number => {
  if (current === 0 && previous === 0) {
    return 0
  }
  if (isFinite(previous) && previous !== 0) {
    return ((current - previous) / Math.abs(previous)) * 100
  }
  return 100
}

const periodChangeDetails = (
  current: number,
  previous: number,
): { change: string; isBadChange: boolean } => {
  const changePercentage = percentChange(current, previous)
  const isBadChange = changePercentage < 0

  const symbol = isBadChange ? '-' : '+'
  const change = `${symbol}${Math.trunc(changePercentage)}%`

  return {
    change,
    isBadChange,
  }
}

const dateRangeToDateInput = (range: DateRange) => {
  return {
    startDate: range.start.format('YYYY-MM-DD'),
    endDate: range.end.format('YYYY-MM-DD'),
  }
}

const averageCompliance = (stat: PicOSGroupComplianceStatsFragment): number => {
  if (stat.totalPriorities === 0) {
    return 0
  }
  return (stat.numCompliantPriorities / stat.totalPriorities) * 100
}

/**
 * Given a large number, returns an abbreviated string value
 * e.g. (205,000 -> '205K')
 * @param number
 * @returns string
 */
const formatLargeNumber = (value: number): string => {
  const formatted = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(value)
  return formatted
}

const compliantDisplayString = (args: {
  compliant: number
  totalPriorities: number
  totalCost: number | null
}) => {
  const { compliant, totalPriorities, totalCost = 0 } = args
  const compliantCost =
    totalCost && totalPriorities > 0
      ? (totalCost / totalPriorities) * compliant
      : 0
  return `$${formatLargeNumber(compliantCost)}/$${formatLargeNumber(
    totalCost || 0,
  )}`
}

/**
 * Calculate the cost of compliant displays
 * from the total display cost from stats object
 * @param stats
 * @returns
 */
// const compliantDisplayCost = (
//   stats: PicOSGroupComplianceStats,
// ): { compliant: number; total: number; display: string } => {
//   const { numCompliantPriorities, totalPriorities, totalDisplayCost } = stats
//   const totalCost = totalDisplayCost || 0
//   const compliant =
//     totalPriorities > 0
//       ? (totalCost / totalPriorities) * numCompliantPriorities
//       : 0
//   return {
//     compliant,
//     total: totalCost,
//     display: compliantDisplayString({
//       compliant: numCompliantPriorities,
//       totalPriorities,
//       totalCost,
//     }),
//   }
// }

const changeOverTime = (current: number, previous: number) => (
  <ChangeOverTimeChip
    {...periodChangeDetails(current, previous)}
    timeLabel={TIME_PERIOD_LABEL}
  />
)

type StatFields =
  | 'compliance'
  | 'customers'
  | 'stores'
  | 'priorities'
  | 'cost'
  | 'surveyed'

/**
 * Given a current and previous stats object
 * return items to fill StatCard row for given fields
 */
const getStatItems = (
  fields: StatFields[],
  stats?: {
    current: PicOSGroupComplianceStatsFragment
    previous: PicOSGroupComplianceStatsFragment
  },
) => {
  if (!stats) {
    return []
  }
  const { current, previous } = stats

  // Add this to get consistent height with
  // cards that have a footer
  const Spacer = <Box height={35} />

  const items = []
  if (fields.includes('compliance')) {
    items.push({
      title: 'Avg. Compliance Rating',
      stat: `${String(averageCompliance(current).toFixed(0))}%`,
      extraComponent: (
        <CircularProgressThresholds
          size={45}
          value={averageCompliance(current)}
          thresholds={{ warning: 50, success: 80 }}
        />
      ),
      footer: changeOverTime(
        averageCompliance(current),
        averageCompliance(previous),
      ),
    })
  }
  if (
    fields.includes('customers') &&
    isPicOSAllCustomersStats(current) &&
    isPicOSAllCustomersStats(previous)
  ) {
    items.push({
      title: 'Customers',
      stat: String(current.numCustomers),
      footer: Spacer,
    })
  }
  if (
    fields.includes('stores') &&
    ((isPicOSAllCustomersStats(current) &&
      isPicOSAllCustomersStats(previous)) ||
      (isPicOSCustomerStats(current) && isPicOSCustomerStats(previous)) ||
      (isPicOSRegionalStats(current) && isPicOSRegionalStats(previous)))
  ) {
    items.push({
      title: 'Total Stores Surveyed',
      stat: String(current.numStoresSurveyed),
      footer: Spacer,
    })
  }
  // if (fields.includes('priorities')) {
  //   items.push({
  //     title: 'Total Priorities',
  //     stat: String(current.numDistinctPriorities),
  //     footer: Spacer,
  //   })
  // }
  // if (fields.includes('cost')) {
  //   const { display } = compliantDisplayCost(current)
  //   items.push({
  //     title: 'Compliance/Display Cost',
  //     stat: display,
  //     footer: Spacer,
  //   })
  // }
  return items
}

export const PicOSReportHelpers = {
  percentChange,
  periodChangeDetails,
  changeOverTime,
  dateRangeToDateInput,
  getStatItems,
  compliantDisplayString,
}
