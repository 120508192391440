"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ProductImageFragment_1 = require("./ProductImageFragment");
var PackagingFragment_1 = require("./PackagingFragment");
var ShippingFragment_1 = require("./ShippingFragment");
exports.PRODUCT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Product on Product {\n    id\n    created\n    modified\n    upcA\n    upcE\n    name\n    isActive\n    brand {\n      id\n      name\n    }\n    manufacturer {\n      id\n      name\n    }\n    marketingImageUrl\n    images(verified: false, hasPrediction: true) {\n      ...ProductImage\n    }\n    packaging {\n      ...Packaging\n    }\n    shipping {\n      ...Shipping\n    }\n    verifiedBy\n  }\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment Product on Product {\n    id\n    created\n    modified\n    upcA\n    upcE\n    name\n    isActive\n    brand {\n      id\n      name\n    }\n    manufacturer {\n      id\n      name\n    }\n    marketingImageUrl\n    images(verified: false, hasPrediction: true) {\n      ...ProductImage\n    }\n    packaging {\n      ...Packaging\n    }\n    shipping {\n      ...Shipping\n    }\n    verifiedBy\n  }\n  ", "\n  ", "\n  ", "\n"])), ProductImageFragment_1.PRODUCT_IMAGE_FRAGMENT, PackagingFragment_1.PACKAGING_FRAGMENT, ShippingFragment_1.SHIPPING_FRAGMENT);
var templateObject_1;
