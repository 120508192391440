"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_DRAW_MAP_TASK = void 0;
var client_1 = require("@apollo/client");
var DrawMapTaskFragment_1 = require("../fragments/DrawMapTaskFragment");
exports.GET_DRAW_MAP_TASK = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetDrawMapTask($taskId: ID!) {\n    getDrawMapTask(taskId: $taskId) {\n      ...DrawMapTask\n    }\n  }\n  ", "\n"], ["\n  query GetDrawMapTask($taskId: ID!) {\n    getDrawMapTask(taskId: $taskId) {\n      ...DrawMapTask\n    }\n  }\n  ", "\n"])), DrawMapTaskFragment_1.DRAW_MAP_TASK_FRAGMENT);
var templateObject_1;
