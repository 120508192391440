/**
 * Copyright © 2024 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useState } from 'react'
import { DZFile } from '@dai/common'
import { useMutation } from '@apollo/client'
import {
  CREATE_PICOS_PRIORITY,
  CreatePicosPriorityMutation as CPP,
  CreatePicosPriorityMutationVariables,
  UPDATE_PICOS_PRIORITY,
  UpdatePicOSPriorityMutation,
  UpdatePicOSPriorityMutationVariables,
  AddImageMutationVariables,
  AddImageMutation,
  ADD_IMAGE,
  DeletePicOSPriorityMutation,
  DeletePicOSPriorityMutationVariables,
  DELETE_PICOS_PRIORITY,
} from '@dai/graphql'
import { NotificationContext, StringHelpers } from '@dai/web-components'
import { PicOSPriority } from 'picos/picos.types'
import { uniqueId } from '@dai/common'
import { useUploadToFirestorage } from 'hooks/use-upload-to-firestorage'
import PicOSContext from 'context/PicOSContext'

export const usePicosPriorityMutations = () => {
  const { setSuccess, setError } = useContext(NotificationContext)
  const [imageFile, setImageFile] = useState<DZFile>()
  const { handleNavTo, selectedBanner, priorityToEdit } = useContext(
    PicOSContext,
  )

  const [addImage, { loading: imageUploadLoading }] = useMutation<
    AddImageMutation,
    AddImageMutationVariables
  >(ADD_IMAGE)
  const { handleUploadFile } = useUploadToFirestorage()
  const [deletePicosMutation] = useMutation<
    DeletePicOSPriorityMutation,
    DeletePicOSPriorityMutationVariables
  >(DELETE_PICOS_PRIORITY)

  const [createPicosMutation, { loading: createPicOSLoading }] = useMutation<
    CPP,
    CreatePicosPriorityMutationVariables
  >(CREATE_PICOS_PRIORITY)

  const [updatePicosMutation, { loading: updatePicOSLoading }] = useMutation<
    UpdatePicOSPriorityMutation,
    UpdatePicOSPriorityMutationVariables
  >(UPDATE_PICOS_PRIORITY)

  const priorityToCreatePriorityInput = (
    priority: PicOSPriority,
    selectedBannerId: string,
  ) => {
    return {
      name: priority.name,
      priority: priority.priorityNum,
      startDate: priority.startDate,
      endDate: priority.endDate,
      mandateType: priority.mandateType,
      description: priority.description,
      cost: priority.cost,
      storeGroupIds: priority.isAllStores
        ? []
        : priority.storeGroups.map(s => s.value),
      displayElements: priority.displayElements,
      displayTypeIds: priority.displayTypes.map(o => o.value),
      displayLocationIds: priority.displayLocations.map(o => o.value),
      posEleReq: priority.posElementRequired,
      otherDisplayTypesAllowed: priority.otherDisplayTypesAllowed,
      otherDisplayLocationsAllowed: priority.otherDisplayLocationsAllowed,
      otherCategories: false, // TODO: remove this when gnarnia is updated
      otherBrands: priority.otherBrandsAllowed,
      otherPackaging: priority.otherPackagingAllowed,
      otherProducts: priority.otherProductsAllowed,
      brandIds: getProductAttr('brand'),
      packagingIds: getProductAttr('packaging'),
      upcs: getProductAttr('product'),
      pricingInfo: priority.pricing,
      storeChainId: selectedBannerId,
    }
  }

  const getProductAttr = (key: 'brand' | 'packaging' | 'product') =>
    Array.from(
      new Set(
        priorityToEdit.addedProducts
          ?.filter(p => !StringHelpers.isNullOrEmpty(p[key]?.value))
          .map<string>(p => p[key]!.value!),
      ),
    )

  const handleSaveImage = async (
    _picosId: string | undefined,
    onComplete: () => void,
  ) => {
    if (imageFile && _picosId) {
      const id = uniqueId()
      const { bucketPath, uploadTask } = await handleUploadFile({
        file: imageFile,
        id,
        cloudDir: 'picos',
        fileType: 'images',
      })
      const onError = (error: any) =>
        setError(`Image upload was unsuccessful.\n${error}`)
      uploadTask.on('state_changed', {
        error: onError,
        complete: () => {
          addImage({
            variables: {
              input: {
                picosId: _picosId,
                bucketFilePath: bucketPath,
              },
            },
          })
            .catch(onError)
            .finally(onComplete)
        },
      })

      return { bucketFile: bucketPath, imageUuid: id }
    } else {
      onComplete()
    }
  }

  const handleCreatePicOSFromInput = (
    priority: PicOSPriority,
    selectedBannerId: string,
  ) => {
    if (!selectedBanner) {
      return
    }

    return createPicosMutation({
      variables: {
        input: {
          ...priorityToCreatePriorityInput(priority, selectedBannerId),
        },
      },
    })
      .then(({ data }) => {
        const _picosId = data?.createPicosPriority?.picosPriority.id
        handleSaveImage(_picosId, () => {
          setSuccess('Successfully created new PicOS Priority.')
          handleNavTo('/picos/priorities', { removeParams: ['priorityId'] })
        })
      })
      .catch(error => setError(error))
  }

  const handleDeletePicosPriority = async (
    id: string,
    refreshPage: boolean = true,
  ) => {
    return deletePicosMutation({
      variables: {
        input: id,
      },
    })
      .then(({ data }) => {
        if (data?.deletePicosPriority?.deleted) {
          if (refreshPage) window.location.reload()
          setSuccess('Successfully deleted PicOS Priority.')
        }
      })
      .catch(error => setError(error))
  }

  const handleSubmitUpdatePicOS = () => {
    updatePicosMutation({
      variables: {
        input: {
          picosId: priorityToEdit.id,
          newPriorityName: priorityToEdit.name,
          newPriority: priorityToEdit.priorityNum,
          newStartDate: priorityToEdit.startDate,
          newEndDate: priorityToEdit.endDate,
          newMandateType: priorityToEdit.mandateType,
          newDescription: priorityToEdit.description,
          newCost: priorityToEdit.cost,
          newStoreGroupIds: priorityToEdit.isAllStores
            ? []
            : priorityToEdit.storeGroups.map(s => s.value),
          newDisplayElements: priorityToEdit.displayElements,
          newDisplayTypeIds: priorityToEdit.displayTypes.map(o => o.value),
          newDisplayLocationIds: priorityToEdit.displayLocations.map(
            o => o.value,
          ),
          newPosEleReq: priorityToEdit.posElementRequired,
          newOtherDisplayTypesAllowed: priorityToEdit.otherDisplayTypesAllowed,
          newOtherDisplayLocationsAllowed:
            priorityToEdit.otherDisplayLocationsAllowed,
          newOtherBrands: priorityToEdit.otherBrandsAllowed,
          newOtherPackaging: priorityToEdit.otherPackagingAllowed,
          newOtherProducts: priorityToEdit.otherProductsAllowed,
          newBrandIds: getProductAttr('brand'),
          newPackagingIds: getProductAttr('packaging'),
          newUpcs: getProductAttr('product'),
          newPricingInfo: priorityToEdit.pricing,
          isMarketStreetChallenge: priorityToEdit.isMarketStreetChallenge,
        },
      },
    })
      .then(({ data }) => {
        const _picosId = data?.updatePicosPriority?.picosPriority.id
        handleSaveImage(_picosId, () => {
          setSuccess('Successfully updated PicOS Priority.')
          handleNavTo('/picos/priorities', { removeParams: ['priorityId'] })
        })
      })
      .catch(error => setError(error))
  }

  const handleSubmitCreatePicOS = () => {
    if (!selectedBanner) {
      return
    }
    if (
      !priorityToEdit?.displayElements ||
      !priorityToEdit.mandateType ||
      !priorityToEdit.priorityNum
    ) {
      return
    }

    return createPicosMutation({
      variables: {
        input: {
          name: priorityToEdit.name,
          priority: priorityToEdit.priorityNum,
          startDate: priorityToEdit.startDate,
          endDate: priorityToEdit.endDate,
          mandateType: priorityToEdit.mandateType,
          description: priorityToEdit.description,
          cost: priorityToEdit.cost,
          storeGroupIds: priorityToEdit.isAllStores
            ? []
            : priorityToEdit.storeGroups.map(s => s.value),
          displayElements: priorityToEdit.displayElements,
          displayTypeIds: priorityToEdit.displayTypes.map(o => o.value),
          displayLocationIds: priorityToEdit.displayLocations.map(o => o.value),
          posEleReq: priorityToEdit.posElementRequired,
          otherDisplayTypesAllowed: priorityToEdit.otherDisplayTypesAllowed,
          otherDisplayLocationsAllowed:
            priorityToEdit.otherDisplayLocationsAllowed,
          otherCategories: false, // TODO: remove this when gnarnia is updated
          otherBrands: priorityToEdit.otherBrandsAllowed,
          otherPackaging: priorityToEdit.otherPackagingAllowed,
          otherProducts: priorityToEdit.otherProductsAllowed,
          brandIds: getProductAttr('brand'),
          packagingIds: getProductAttr('packaging'),
          upcs: getProductAttr('product'),
          pricingInfo: priorityToEdit.pricing,
          isMarketStreetChallenge: priorityToEdit.isMarketStreetChallenge,
          storeChainId: selectedBanner.id,
        },
      },
    })
      .then(({ data }) => {
        const _picosId = data?.createPicosPriority?.picosPriority.id

        handleSaveImage(_picosId, () => {
          setSuccess('Successfully created new PicOS Priority.')
          handleNavTo('/picos/priorities', { removeParams: ['priorityId'] })
        })
      })
      .catch(error => setError(error))
  }

  return {
    state: {
      imageFile,
      setImageFile,
      createPicOSLoading,
      updatePicOSLoading,
      imageUploadLoading,
    },
    handle: {
      handleCreatePicOSFromInput,
      handleDeletePicosPriority,
      handleSubmitCreatePicOS,
      handleSaveImage,
      handleSubmitUpdatePicOS,
    },
  }
}
