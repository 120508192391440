"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_REGION = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.CREATE_REGION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CreateRegion($input: CreateRegionInput!) {\n    createRegion(input: $input) {\n      region {\n        ...StoreRegion\n      }\n    }\n  }\n  ", "\n"], ["\n  mutation CreateRegion($input: CreateRegionInput!) {\n    createRegion(input: $input) {\n      region {\n        ...StoreRegion\n      }\n    }\n  }\n  ", "\n"])), fragments_1.STORE_REGION_FRAGMENT);
var templateObject_1;
