/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import {
  PicOSComplianceTableReport,
  SalesCenterReportRow,
  SALES_CENTER_REPORT_TABLE_COLUMNS,
  AddressBlock,
  RankingChangeOverTime,
} from '@dai/web-components'
import PicOSCellActionButton from 'picos/components/PicOSCellActionButton'
import PicOSComplianceLinearProgress from 'picos/components/PicOSComplianceLinearProgress'
import useSalesCenterComplianceReport from 'picos/hooks/use-sales-center-compliance-report'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const PicOSSalesCenterReport: React.FC = () => {
  const routeParams = useParams<{
    customerId: string
    divisionId: string
    salesCenterId: string
  }>()

  const {
    data: { title, complianceResults, statItems },
    state: { limit, loadingCompliance, loadingStats },
    handlers: { handleSearch, handleSelect, goToPage },
  } = useSalesCenterComplianceReport(routeParams)

  const rows = useMemo(
    () =>
      complianceResults.map(row => ({
        id: row.id,
        name: row.name,
        address: <AddressBlock address={row.address} />,
        compliance: (
          <PicOSComplianceLinearProgress
            numCompliant={row.numCompliant}
            totalPriorities={row.totalPriorities}
          />
        ),
        totalPriorities: row.totalPriorities,
        rank: (
          <RankingChangeOverTime
            rank={row.rank}
            previousRank={row.previousRank}
          />
        ),
        action: (
          <PicOSCellActionButton
            label="View Store"
            onClick={() => handleSelect(row)}
          />
        ),
      })),
    [complianceResults],
  )

  return (
    <PicOSComplianceTableReport<SalesCenterReportRow>
      title={title}
      columns={SALES_CENTER_REPORT_TABLE_COLUMNS}
      rows={rows}
      onSearch={handleSearch}
      onClickExportCsv={() => null}
      loadingComplianceItems={loadingCompliance}
      pageSize={limit}
      onPageChange={(page, _) => goToPage(page)}
      loadingStats={loadingStats}
      statItems={statItems}
    />
  )
}

export default PicOSSalesCenterReport
