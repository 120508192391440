/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  FullScreenLayout,
  NavigationTabs,
  PageTitle,
  NotificationAlert,
  MuiTheme,
  SearchableSelectAutocomplete,
} from '@dai/web-components'
import { Box, CircularProgress, Typography } from '@mui/material'
import { Route, Switch, useLocation } from 'react-router-dom'
import { usePicOSLogic } from 'picos/container/PicOS.logic'
import PicOSContext from 'context/PicOSContext'
import CreatePicOSPriorityContainer from 'picos/container/create/CreatePicOSPriority.container'
import EditPicOSPrioritiesContainer from 'picos/container/edit/EditPicOSPriorities.container'
import PicOSReportsContainer from 'picos/container/reports/PicOSReports.container'
import { useWindowDimensions } from '@dai/common'
import { PicosConstants } from 'picos/picos.constants'
import { Search } from '@mui/icons-material'
import { Banner } from 'picos/picos.types'

const PicOSContainer: React.FC = () => {
  const {
    state: {
      CustomerBannersLazyQuery,
      selectedBanner,
      priorityToEdit,
      setPriorityToEdit,
      storeTypesInCustomer,
      setStoreTypesInCustomer,
      setIsNewPriority,
      isNewPriority,
      urlSearchParams,
    },
    data: { banners },
    handle: { handleUpdateSearchParams, handleSelectBanner, handleNavTo },
  } = usePicOSLogic()

  const { width } = useWindowDimensions()
  const isDesktop = width >= MuiTheme.breakpoints.values.lg
  const inputWidth = MuiTheme.sizes.forms[isDesktop ? 'lg' : 'md']
  const location = useLocation()

  return (
    <>
      <NotificationAlert />
      <FullScreenLayout
        header={
          <PageTitle
            disableTypography
            hideBorder={location.pathname.includes('edit') && !!selectedBanner}
            title={
              location.pathname.includes('picos/reports') ? null : (
                <Box sx={{ maxWidth: inputWidth, flex: 1 }}>
                  <SearchableSelectAutocomplete<Banner>
                    options={banners}
                    handleOnChange={banner => {
                      setPriorityToEdit(PicosConstants.EMPTY_PICOS)
                      handleSelectBanner(banner)
                    }}
                    value={selectedBanner}
                    loading={CustomerBannersLazyQuery.lazyQuery.meta.loading}
                    TextFieldProps={{
                      size: 'small',
                      label: 'Customer Banner',
                      variant: 'filled',
                      onChange: e =>
                        CustomerBannersLazyQuery.setItemQuery(e.target.value),
                      InputProps: {
                        startAdornment: CustomerBannersLazyQuery.lazyQuery.meta
                          .loading ? (
                          <CircularProgress size={18} sx={{ mr: 1 }} />
                        ) : (
                          <Search sx={{ height: 18 }} />
                        ),
                      },
                    }}
                    renderOption={banner => (
                      <Typography>{banner.label}</Typography>
                    )}
                  />
                </Box>
              )
            }
            RightComponent={
              <NavigationTabs<'priorities' | 'reports'>
                routeBase="picos"
                tabs={[
                  {
                    label: 'Priorities',
                    ext: 'priorities',
                    aliases: ['create'],
                  },
                  {
                    label: 'Reports',
                    ext: 'reports',
                  },
                ]}
              />
            }
          />
        }
        body={
          <PicOSContext.Provider
            value={{
              selectedBanner,
              urlSearchParams,
              setSelectedBanner: handleSelectBanner,
              handleUpdateSearchParams,
              handleNavTo,
              isNewPriority,
              setIsNewPriority,
              priorityToEdit,
              setPriorityToEdit,
              storeTypesInCustomer: storeTypesInCustomer || [],
              setStoreTypesInCustomer,
            }}
          >
            <Switch>
              <Route path="/picos/priorities">
                <EditPicOSPrioritiesContainer />
              </Route>
              <Route
                path="/picos/create"
                component={CreatePicOSPriorityContainer}
              />
              <Route path="/picos/reports" component={PicOSReportsContainer} />
            </Switch>
          </PicOSContext.Provider>
        }
      />
    </>
  )
}

export default PicOSContainer
