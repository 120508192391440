/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  useAuditQuestionsMutations,
  useCompanyAuditQuestions,
} from '@dai/common'
import { useMemo, useRef, useState } from 'react'
import { DAITable, DAITableColumns, MuiTheme } from '@dai/web-components'
import { QuestionRow } from 'store-inspection/store-inspection.types'
import {
  CreateAuditQuestionMutationVariables,
  UpdateAuditQuestionsInput,
} from '@dai/graphql'

export const useSurveyQuestionsLogic = () => {
  const { questions, loading, refetch } = useCompanyAuditQuestions()
  const {
    deleteAuditQuestions,
    CreateAuditQuestionMutation,
    UpdateAuditQuestionsMutation,
  } = useAuditQuestionsMutations()
  const [
    showCreateEditQuestionModal,
    setShowCreateEditQuestionModal,
  ] = useState(false)
  const [showConfirmDeleteQuestionsModal, setShowConfirmDeleteModal] = useState(
    false,
  )
  const [submittingQuestion, setSubmittingQuestion] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const tableRef = useRef<DAITable<QuestionRow>>(null)

  const questionRows: QuestionRow[] = useMemo(
    () =>
      questions?.map(q => ({
        id: q.id,
        question: q.question,
        group: q.group,
        points: q.pointWorth,
        type: q.category,
        active: q.active,
      })) || [],
    [questions],
  )

  const handleCancelDelete = () => {
    tableRef.current?.setSelectedIds([])
    setShowConfirmDeleteModal(false)
  }
  const handleConfirmDelete = async () => {
    await deleteAuditQuestions(selectedIds)
    refetch()
    setShowConfirmDeleteModal(false)
  }

  const handleSubmitCreateOrEditQuestion = async (
    question:
      | CreateAuditQuestionMutationVariables
      | Omit<UpdateAuditQuestionsInput, 'ids'>,
    update: boolean,
  ) => {
    setSubmittingQuestion(true)
    if (update) {
      await UpdateAuditQuestionsMutation({
        input: {
          ...(question as Omit<UpdateAuditQuestionsInput, 'ids'>),
          ids: selectedIds,
        },
      })
    } else {
      await CreateAuditQuestionMutation(
        question as CreateAuditQuestionMutationVariables,
      )
    }
    refetch()
    setSubmittingQuestion(false)
    setShowCreateEditQuestionModal(false)
    tableRef.current?.setSelectedIds([])
  }

  const groups: Set<string> = useMemo(
    () =>
      questionRows.reduce(
        (groupAccum, q) => groupAccum.add(q.group),
        new Set<string>(),
      ),
    [questionRows],
  )
  const selectedQuestion =
    selectedIds.length === 1
      ? questions?.find(q => q.id === selectedIds[0])
      : null

  const columns: DAITableColumns<QuestionRow>[] = useMemo(
    () => [
      {
        field: 'question',
        headerName: 'Question',
        width: MuiTheme.sizes.table.column.width.xl * 2,
      },
      { field: 'group', headerName: 'Group' },
      { field: 'points', headerName: 'Points' },
      { field: 'type', headerName: 'Type' },
      { field: 'active', headerName: 'Active' },
    ],
    [MuiTheme],
  )

  return {
    state: {
      showCreateEditQuestionModal,
      setShowCreateEditQuestionModal,
      showConfirmDeleteQuestionsModal,
      setShowConfirmDeleteModal,
      submittingQuestion,
      setSubmittingQuestion,
      selectedIds,
      setSelectedIds,
    },
    ref: {
      tableRef,
    },
    handle: {
      handleSubmitCreateOrEditQuestion,
      handleCancelDelete,
      handleConfirmDelete,
    },
    data: {
      questionRows,
      columns,
      groups,
      selectedQuestion,
      loading,
    },
  }
}
