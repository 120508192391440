/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportProps,
  GenericReportSummaryProps,
} from 'reporting/admin-reporting.types'
import { useState } from 'react'

export const useReportBuilder = <ReportName extends string>({
  reportHooks,
  summaryHooks,
}: {
  reportHooks: Record<string, () => GenericReportProps<ReportName>[]>
  summaryHooks: Record<string, () => GenericReportSummaryProps<ReportName>[]>
}) => {
  const [selectedReportGroup, setSelectedReportGroup] = useState<string>()
  const reports = Object.entries(reportHooks).reduce<
    Record<string, GenericReportProps<ReportName>[]>
  >(
    (allReports, [groupName, reportHook]) => ({
      ...allReports,
      [groupName]: reportHook(),
    }),
    {},
  )
  const summaries = Object.entries(summaryHooks).reduce<
    Record<string, GenericReportSummaryProps<ReportName>[]>
  >(
    (allSummaries, [groupName, summaryHook]) => ({
      ...allSummaries,
      [groupName]: summaryHook(),
    }),
    {},
  )

  return {
    reports,
    summaries,
    selectedReportGroup,
    setSelectedReportGroup,
  }
}
