"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_PRICE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var BrandFragment_1 = require("./BrandFragment");
var ManufacturerFragment_1 = require("./ManufacturerFragment");
var PackagingFragment_1 = require("./PackagingFragment");
var ProductPriceObjectFragment_1 = require("./ProductPriceObjectFragment");
exports.PRODUCT_PRICE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment ProductPrice on ProductPrice {\n    id\n    price\n    notAvailable\n    priceVisible\n    productPriceObject {\n      ...ProductPriceObject\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment ProductPrice on ProductPrice {\n    id\n    price\n    notAvailable\n    priceVisible\n    productPriceObject {\n      ...ProductPriceObject\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n"])), ProductPriceObjectFragment_1.PRODUCT_PRICE_OBJECT_FRAGMENT, PackagingFragment_1.PACKAGING_FRAGMENT, BrandFragment_1.BRAND_FRAGMENT, ManufacturerFragment_1.MANUFACTURER_FRAGMENT);
var templateObject_1;
