"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMIN_WHOLE_AUDIT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ProductPriceFragment_1 = require("./ProductPriceFragment");
var ScoreFragments_1 = require("./ScoreFragments");
var AuditAnswerFragment_1 = require("./AuditAnswerFragment");
var CRCountItemFragment_1 = require("./CRCountItemFragment");
var AdminWholeDisplayAuditFragment_1 = require("./AdminWholeDisplayAuditFragment");
exports.ADMIN_WHOLE_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment AdminWholeAudit on Audit {\n    id\n    created\n    saved\n    dateCompleted\n    displaysCompletedAt\n    pricingCompletedAt\n    surveyCompletedAt\n    employee {\n      firstName\n      lastName\n      id\n      email\n    }\n    store {\n      id\n      storeChain\n      name\n      hasAllFormulas\n      address {\n        address1\n        city\n        state\n        postalCode\n      }\n    }\n    status\n    score {\n      ...AuditScore\n    }\n    notes\n    auditAnswers {\n      ...AuditAnswer\n    }\n    displayAudits {\n      ...AdminWholeDisplayAudit\n    }\n    crCountItems {\n      ...CRCountItem\n    }\n    productPrices {\n      ...ProductPrice\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  fragment AdminWholeAudit on Audit {\n    id\n    created\n    saved\n    dateCompleted\n    displaysCompletedAt\n    pricingCompletedAt\n    surveyCompletedAt\n    employee {\n      firstName\n      lastName\n      id\n      email\n    }\n    store {\n      id\n      storeChain\n      name\n      hasAllFormulas\n      address {\n        address1\n        city\n        state\n        postalCode\n      }\n    }\n    status\n    score {\n      ...AuditScore\n    }\n    notes\n    auditAnswers {\n      ...AuditAnswer\n    }\n    displayAudits {\n      ...AdminWholeDisplayAudit\n    }\n    crCountItems {\n      ...CRCountItem\n    }\n    productPrices {\n      ...ProductPrice\n    }\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), AuditAnswerFragment_1.AUDIT_ANSWER_FRAGMENT, ScoreFragments_1.AUDIT_SCORE_FRAGMENT, AdminWholeDisplayAuditFragment_1.ADMIN_WHOLE_DISPLAY_AUDIT_FRAGMENT, ProductPriceFragment_1.PRODUCT_PRICE_FRAGMENT, CRCountItemFragment_1.CR_COUNT_ITEM_FRAGMENT);
var templateObject_1;
