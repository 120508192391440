/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  GenericReportDataType,
  GenericReportHook,
  GenericReportProps,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { useEffect, useState } from 'react'
import { FILL_GRID_ALL_SCREENS } from '@dai/web-components'
import { useLocalStorage } from 'hooks/use-local-storage'
import { useQuery } from '@apollo/client'
import {
  AggregateBy,
  AggregationMethod,
  GET_POINT_CLOUD_SCAN_SCORES,
  GET_RECOMMENDATION_COMPLIANCE_SCORES,
  GetPointCloudScoresQuery,
  GetPointCloudScoresQueryVariables,
  GetRecommendationComplianceScoresQuery,
  GetRecommendationComplianceScoresQueryVariables,
} from '@dai/graphql'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'
import { UserProductivityReportHelpers } from 'users/helpers/reporting/user-productivity-report.helpers'
import { useReportDisplay } from 'users/hooks/reporting/use-report-display'

export const useQualityOverviewReports: GenericReportHook<UserProductivityReportName> = () => {
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')
  const numUsers = Object.keys(companyUsers || {}).length
  const useGetUserQualityTable = (
    reportParams: UserProductivityReportInput,
  ) => {
    const isSingleUser =
      !!reportParams.userUuids.length && !reportParams.managerUuid
    const ReportDisplay = useReportDisplay<
      UserProductivityReportName,
      GenericReportProps<UserProductivityReportName>
    >()
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const {
      getUserProductivityQueryInput,
      formatCompositeIdRow,
    } = useReportQuery()
    const [data, setData] = useState<GenericReportDataType[]>([])
    const { data: complianceScores, loading: complianceLoading } = useQuery<
      GetRecommendationComplianceScoresQuery,
      GetRecommendationComplianceScoresQueryVariables
    >(GET_RECOMMENDATION_COMPLIANCE_SCORES, {
      variables: {
        input: getUserProductivityQueryInput(
          AggregationMethod.AVG,
          AggregateBy.ALL_TIME,
        ),
      },
    })
    const {
      data: pointCloudScores,
      loading: pointCloudScoresLoading,
    } = useQuery<GetPointCloudScoresQuery, GetPointCloudScoresQueryVariables>(
      GET_POINT_CLOUD_SCAN_SCORES,
      {
        variables: {
          input: getUserProductivityQueryInput(
            AggregationMethod.AVG,
            AggregateBy.ALL_TIME,
          ),
        },
      },
    )

    useEffect(() => {
      if (
        complianceScores &&
        pointCloudScores &&
        reportParams.selectedReportTab === 'overview'
      ) {
        let data: GenericReportDataType[]
        if (isSingleUser) {
          const compliance = complianceScores.recommendationScores.results
            .map(res =>
              formatCompositeIdRow(res, res => ({
                group: res.compositeId!,
                value: UserProductivityReportHelpers.formatPct(res.score),
                category: 'recommendation-compliance-score',
              })),
            )
            .flat()
          const pointCloud = pointCloudScores.pointCloudScanScores.results
            .map(res =>
              formatCompositeIdRow(res, res => [
                {
                  group: res.compositeId!,
                  value: UserProductivityReportHelpers.formatPct(
                    res.pointCloudScore,
                  ),
                  category: 'point-cloud-score',
                },
                {
                  group: res.compositeId!,
                  value: UserProductivityReportHelpers.formatPct(
                    res.facingScore,
                  ),
                  category: 'scan-facing-score',
                },
                {
                  group: res.compositeId!,
                  value: UserProductivityReportHelpers.formatPct(
                    res.identificationAccuracy,
                  ),
                  category: 'identification-accuracy',
                },
              ]),
            )
            .flat()
          data = ReportDisplay.filterData(
            reportParams.userType,
            [...compliance, ...pointCloud],
            ['Store Name', 'Display Name'],
          )
        } else {
          const compliance = complianceScores.recommendationScores.results.map(
            res => ({
              group: res.refId,
              value: UserProductivityReportHelpers.formatPct(res.score),
              category: 'recommendation-compliance-score',
            }),
          )
          const pointCloud = pointCloudScores.pointCloudScanScores.results
            .map(res => [
              {
                group: res.refId,
                value: UserProductivityReportHelpers.formatPct(
                  res.pointCloudScore,
                ),
                category: 'point-cloud-score',
              },
              {
                group: res.refId,
                value: UserProductivityReportHelpers.formatPct(res.facingScore),
                category: 'scan-facing-score',
              },
              {
                group: res.refId,
                value: UserProductivityReportHelpers.formatPct(
                  res.identificationAccuracy,
                ),
                category: 'identification-accuracy',
              },
            ])
            .flat()
          const desiredData = ReportDisplay.filterData(reportParams.userType, [
            ...compliance,
            ...pointCloud,
          ])
          const users = Object.entries(companyUsers || {})
            .filter(([userId, _]) =>
              desiredData.find(datum => datum.group === userId),
            )
            .map(([userId, user]) => ({
              group: userId,
              value: user.name,
              category: 'User Name',
            }))
          data = [...users, ...desiredData]
        }
        setData(data)
      }
    }, [
      complianceScores?.recommendationScores.results.length,
      pointCloudScores?.pointCloudScanScores.results.length,
      reportParams.selectedReportTab,
      reportParams.userType,
      reportParams.reportType,
      numUsers,
    ])

    return {
      data,
      isEmpty: !data.length,
      isLoading: complianceLoading || pointCloudScoresLoading,
    }
  }
  return [
    {
      reportName: 'overview',
      useGetData: useGetUserQualityTable,
      type: 'table',
      gridProps: FILL_GRID_ALL_SCREENS,
      title: '',
      chartId: 'user-quality-table',
    },
  ]
}
