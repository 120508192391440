/**
 * Copyright © 2020-2022 Delicious AI, LLC
 *
 * @author Clark Sandholtz <clarksandholtz@gmail.com>
 */

import React, { useContext } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import UserContext from 'context/UserContext'
import { FillContainerLoading } from '@dai/web-components'
import { DAIGroupEnum } from '@dai/graphql'
import { hasPermission } from '@dai/common'

type ProtectedRouteProps = {
  authorized?: DAIGroupEnum[]
} & RouteProps

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = props => {
  const { children, authorized = [], ...rest } = props
  const { user, loading } = useContext(UserContext)

  const isNotAuthorized =
    !!authorized.length && !hasPermission(user, authorized)

  return !loading ? (
    <Route {...rest}>
      {!user?.isSuperuser && isNotAuthorized ? (
        <Redirect to="/login" />
      ) : user ? (
        children
      ) : !user ? (
        <Redirect to="/login" />
      ) : (
        <Redirect to="/users" />
      )}
    </Route>
  ) : (
    <FillContainerLoading />
  )
}

export default ProtectedRoute
