"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRAINING_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.TRAINING_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Training on Training {\n    title\n    description\n    id\n    timeRequired\n    groups {\n      id\n      name\n    }\n    keyTakeaways\n    deviceType\n    trainingImage {\n      id\n      url\n      bucketFileName\n    }\n    videoUrl\n  }\n"], ["\n  fragment Training on Training {\n    title\n    description\n    id\n    timeRequired\n    groups {\n      id\n      name\n    }\n    keyTakeaways\n    deviceType\n    trainingImage {\n      id\n      url\n      bucketFileName\n    }\n    videoUrl\n  }\n"])));
var templateObject_1;
