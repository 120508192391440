"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICOS_SALES_CENTER_COMPLIANCE = void 0;
var client_1 = require("@apollo/client");
var PicOSStoreComplianceFragment_1 = require("../fragments/PicOSStoreComplianceFragment");
exports.GET_PICOS_SALES_CENTER_COMPLIANCE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetPicOSSalesCenterCompliance(\n    $input: PicOSSalesCenterComplianceInput!\n  ) {\n    picosSalesCenterCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        ...PicOSStoreCompliance\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetPicOSSalesCenterCompliance(\n    $input: PicOSSalesCenterComplianceInput!\n  ) {\n    picosSalesCenterCompliance(input: $input) {\n      hasMore\n      count\n      results {\n        ...PicOSStoreCompliance\n      }\n    }\n  }\n  ", "\n"])), PicOSStoreComplianceFragment_1.PICOS_STORE_COMPLIANCE_FRAGMENT);
var templateObject_1;
