/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Braxton Geary <braxton.geary@deliciousai.com>
 */

import { CustomIcon, FlexBox, MuiTheme as theme } from '@dai/web-components'
import { Typography } from '@mui/material'
import React from 'react'

type ErrorIconAndDescriptionProps = {
  iconSrc?: string
  title?: string
  subtitle: string
}

export const ErrorIconAndDescription: React.FC<ErrorIconAndDescriptionProps> = props => {
  const {
    iconSrc = 'dai_folder.svg',
    title = 'No Results Found',
    subtitle,
  } = props
  return (
    <FlexBox.ColCenter
      height={`calc(100vh - ${theme.mixins.toolbar.minHeight}px - 250px)`}
      width="100%"
    >
      <CustomIcon
        style={{
          height: 250,
          width: 250,
          margin: theme.spacing(0, 0, 2, 0),
        }}
        src={iconSrc}
      />
      <Typography variant="h5">{title}</Typography>
      <Typography variant="h6" textAlign="center" maxWidth={400}>
        {subtitle}
      </Typography>
    </FlexBox.ColCenter>
  )
}
