/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useState } from 'react'
import { Brand, DAITableColumns } from '@dai/web-components'
import { useDisruptiveBrandPeriod } from 'brands/brand-disruptor/hooks/use-disruptive-brand-period'
import { useQuery } from '@apollo/client'
import {
  BRAND_DISRUPTOR_SUMMARY_STATS,
  SummaryStatsQueryQuery,
  SummaryStatsQueryQueryVariables,
} from '@dai/graphql'
import {
  NewBrandRow,
  TrendRow,
} from 'brands/brand-disruptor/brand-disruptor.types'
import { DateTimeHelpers } from '@dai/common'

const roundBps = (num: number) => {
  return Math.round(num * 1000) / 1000
}

export const useDisruptiveBrandsContainerLogic = () => {
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([])
  const {
    state: { formState, setFormState },
    data: { startDate, endDate, dateRangeType },
  } = useDisruptiveBrandPeriod()

  const { data: stats, loading, error } = useQuery<
    SummaryStatsQueryQuery,
    SummaryStatsQueryQueryVariables
  >(BRAND_DISRUPTOR_SUMMARY_STATS, {
    variables: {
      input: {
        startDate,
        endDate,
        type: dateRangeType,
      },
    },
    fetchPolicy: 'network-only',
  })

  const trendingUpRows: TrendRow[] = stats
    ? stats.summaryStats.trendingUp.map(stat => ({
        brand: stat.brand.name,
        id: stat.brand.id,
        changeInSovi: `${stat.delta > 0 ? '+' : ''}${roundBps(
          stat.delta,
        )} cbps`,
      }))
    : []

  const trendingColumns: DAITableColumns<TrendRow>[] = [
    { field: 'brand', headerName: 'Brand' },
    { field: 'changeInSovi', headerName: 'Change in SOVI' },
  ]

  const trendingDownRows = stats
    ? stats.summaryStats.trendingDown.map(stat => ({
        brand: stat.brand.name,
        id: stat.brand.id,
        changeInSovi: `${stat.delta > 0 ? '+' : ''}${roundBps(
          stat.delta,
        )} cbps`,
      }))
    : []

  const newBrandRows: NewBrandRow[] = stats
    ? stats.summaryStats.newBrands.map(dta => ({
        brand: dta.brand.name,
        id: dta.brand.id,
        firstObserved: dta.dateFirstObserved,
        soviQuantity: dta.numberOfFacings,
        numberOfStores: dta.numberOfStores,
      }))
    : []

  const newBrandsColumns: DAITableColumns<NewBrandRow>[] = [
    { field: 'brand', headerName: 'Brand' },
    { field: 'firstObserved', headerName: 'First Observed' },
    { field: 'soviQuantity', headerName: 'SOVI Quantity' },
    { field: 'numberOfStores', headerName: '# of Stores' },
  ]

  return {
    state: {
      selectedBrands,
      setSelectedBrands,
      formState,
      setFormState,
    },
    data: {
      trendingUpRows,
      trendingDownRows,
      trendingColumns,
      newBrandRows,
      newBrandsColumns,
      loading,
      startDate,
      endDate,
      dateRangeType,
    },
  }
}
