/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useParams } from 'react-router-dom'
import { useAdminAudit } from '@dai/common'
import { useMemo, useState } from 'react'
import {
  AnswerRow,
  AuditProduct,
  DisplayRow,
  PriceRow,
} from 'store-inspection/store-inspection.types'
import { DAITableColumns } from '@dai/web-components'

interface AuditSummaryParams {
  auditId: string
}

export const useAuditSummaryLogic = () => {
  const { auditId } = useParams<AuditSummaryParams>()

  const { audit, loading } = useAdminAudit({ auditId })

  const [showDisplayProducts, setShowDisplayProducts] = useState(false)
  const [displayAuditProducts, setDisplayAuditProducts] = useState<
    AuditProduct[]
  >([])
  const [displayAuditTitle, setDisplayAuditTitle] = useState('')

  const answerRows: AnswerRow[] =
    audit?.auditAnswers.map(answer => ({
      id: answer.id,
      question: answer.question,
      response: answer.answeredYes,
    })) || []

  const priceRows: PriceRow[] =
    audit?.productPrices.map(priceObject => {
      const brand_or_man =
        priceObject.productPriceObject.brand ||
        priceObject.productPriceObject.manufacturer
      const name =
        !brand_or_man || !brand_or_man.name
          ? priceObject.productPriceObject.category.category
          : brand_or_man.name
      const packaging = priceObject.productPriceObject.packaging

      return {
        id: priceObject.id,
        isVisible: priceObject.priceVisible,
        price: priceObject.price,
        productName: `${name}, ${
          packaging?.quantity
        } ${packaging?.container?.toLowerCase()} of ${
          packaging?.size
        }${packaging?.unit?.toLowerCase()}`,
      }
    }) || []

  const displayRows: DisplayRow[] =
    audit?.displayAudits.map(display => {
      const casesCount = display.products.reduce<number>((sum, product) => {
        const cases = product.product?.shipping.productsPerCase
          ? product.quantity / product.product.shipping.productsPerCase
          : 0
        return sum + cases
      }, 0)
      return {
        id: display.id,
        description: `${display.display.area} ${display.display.displayType}, ${display.display.shortDescription}`,
        numProducts: display.products.length,
        totalCases: +casesCount.toFixed(2),
      }
    }) || []

  const handleOpenDisplayProducts = (displayAuditId: string) => {
    const display = audit?.displayAudits.find(x => x.id === displayAuditId)

    if (!display) return

    const displayTitle = `${display.display.area} ${display.display.displayType}, ${display.display.shortDescription}`

    const auditProducts: AuditProduct[] = display.products.map(product => ({
      id: product.id,
      quantity: product.quantity,
      name: product.name,
      upc: product.upc,
      cases: product.product?.shipping.productsPerCase
        ? +(
            product.quantity / product.product.shipping.productsPerCase
          ).toFixed(2)
        : 0,
    }))

    setDisplayAuditTitle(displayTitle)
    setDisplayAuditProducts(auditProducts)
    setShowDisplayProducts(true)
  }

  const answerColumns: DAITableColumns<AnswerRow>[] = useMemo(
    () => [
      { field: 'question', headerName: 'Question', width: 250 },
      { field: 'response', headerName: 'Response', width: 10 },
    ],
    [],
  )

  const priceColumns: DAITableColumns<PriceRow>[] = useMemo(
    () => [
      { field: 'productName', headerName: 'Product', width: 250 },
      { field: 'isVisible', headerName: 'Visible', width: 10 },
      { field: 'price', headerName: 'Price', width: 10 },
    ],
    [],
  )

  const displayColumns: DAITableColumns<DisplayRow>[] = useMemo(
    () => [
      { field: 'description', headerName: 'Description', width: 250 },
      { field: 'numProducts', headerName: '# Products', width: 10 },
      { field: 'totalCases', headerName: 'Total Cases', width: 10 },
    ],
    [],
  )

  return {
    state: {
      showDisplayProducts,
      setShowDisplayProducts,
      displayAuditProducts,
      setDisplayAuditProducts,
      displayAuditTitle,
      setDisplayAuditTitle,
    },
    data: {
      priceColumns,
      answerColumns,
      displayColumns,
      displayRows,
      priceRows,
      answerRows,
      audit,
      loading,
    },
    handle: {
      handleOpenDisplayProducts,
    },
  }
}
