/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useLocalStorage } from 'hooks/use-local-storage'
import {
  GenericReportDataType,
  GenericReportProps,
  GenericReportSummaryProps,
  ReportDisplayRules,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { DAIGroupEnum } from '@dai/graphql'

/**
 * A hook that manages what reports/charts are displayed for a selected
 * user type
 */
export const useReportDisplay = <
  Name extends string,
  Report extends GenericReportProps<Name> | GenericReportSummaryProps<Name>
>() => {
  const { Storage } = useLocalStorage()

  const writeRules = (rules: ReportDisplayRules<Name>) =>
    Storage.setItem('reportRules', rules)

  const readRules = () => {
    const rules = Storage.getItem('reportRules')
    if (!rules) {
      throw new Error('No Report Rules have been defined!')
    }
    return rules
  }

  const filter = (
    selectedUserType: DAIGroupEnum,
    reports: Report[],
  ): Report[] => {
    const rules = readRules()
    return reports.filter(
      report => !!rules[selectedUserType]?.includes(report.reportName),
    )
  }

  const filterData = (
    selectedUserType: DAIGroupEnum,
    data: GenericReportDataType[],
    extras?: string[],
  ) => {
    const rules = readRules()
    return data.filter(
      datum =>
        !!rules[selectedUserType]?.includes(datum.category as Name) ||
        extras?.includes(datum.category as string),
    )
  }

  const include = (
    selectedUserType: DAIGroupEnum,
    report: Report,
  ): Report | undefined => {
    const rules = readRules()
    if (rules[selectedUserType]?.includes(report.reportName)) {
      return report
    }
  }

  return {
    writeRules,
    filter,
    filterData,
    include,
  }
}
