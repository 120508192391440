/**
 * Copyright © 2023 Delicious AI, LLC
 */

import React from 'react'
import { SabreCategoryFragment } from '@dai/graphql'
import { Save } from '@mui/icons-material'
import {
  FillContainerLoading,
  MuiForm,
  MuiFormModal,
  MuiFormModalProps,
  useMuiForm,
} from '@dai/web-components'
import { useCreateEditCategoriesForm } from 'categories/hooks/create-edit-categories.form'
import { SelectableOption } from '@dai/web-components'
import { ModalState } from 'categories/containers/Categories.logic'

interface EditCategoriesFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  loading?: boolean
  onSubmit: () => void
  category?: SabreCategoryFragment
  handleNameChange: (name: string) => void
  handleParentChange: (parentId: string) => void
  modalState: ModalState
  searchResults?: SelectableOption[]
  setSearchResults: React.Dispatch<React.SetStateAction<SelectableOption[]>>
}
export const CreateEditCategoryModal: React.FC<EditCategoriesFormProps> = props => {
  const {
    loading,
    isOpen,
    onSubmit,
    handleClose,
    category,
    handleNameChange,
    handleParentChange,
    modalState,
    searchResults,
    setSearchResults,
  } = props

  const { formState, setFormState } = useCreateEditCategoriesForm({
    category,
    handleNameChange,
    handleParentChange,
    searchResults,
    setSearchResults,
  })
  const {
    handle: { handleOnChange },
    data: { canSubmit },
  } = useMuiForm({
    formState,
    setFormState,
  })

  const getFormTitle = (modalState: ModalState) => {
    if (modalState === 'edit-category') {
      return 'Edit Category'
    }
    if (modalState === 'create-category') {
      return 'Create Category'
    }
  }

  const handleSubmitPress = () => {
    onSubmit()
  }

  return (
    <MuiFormModal
      title={getFormTitle(modalState) || ''}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleSubmitPress,
          children: 'Save & Close',
          startIcon: <Save color="secondary" />,
          disabled: !canSubmit,
        },
      ]}
    >
      {loading ? (
        <FillContainerLoading />
      ) : (
        <MuiForm
          formState={formState}
          handleOnChange={handleOnChange}
          loading={false}
        />
      )}
    </MuiFormModal>
  )
}
