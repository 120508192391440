"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PRODUCTS_MORE_INFO = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_PRODUCTS_MORE_INFO = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetProductsMoreInfo($input: ProductsInput) {\n    products(input: $input) {\n      results {\n        created\n        modified\n        brand {\n          id\n          name\n        }\n        packaging {\n          ...Packaging\n        }\n        shipping {\n          ...Shipping\n        }\n        name\n        upcA\n        upcE\n        id\n        description\n        photosTaken\n        lastUpdated\n        marketingImageUrl\n      }\n      hasMore\n    }\n  }\n  ", "\n  ", "\n"], ["\n  query GetProductsMoreInfo($input: ProductsInput) {\n    products(input: $input) {\n      results {\n        created\n        modified\n        brand {\n          id\n          name\n        }\n        packaging {\n          ...Packaging\n        }\n        shipping {\n          ...Shipping\n        }\n        name\n        upcA\n        upcE\n        id\n        description\n        photosTaken\n        lastUpdated\n        marketingImageUrl\n      }\n      hasMore\n    }\n  }\n  ", "\n  ", "\n"])), fragments_1.PACKAGING_FRAGMENT, fragments_1.SHIPPING_FRAGMENT);
var templateObject_1;
