/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import { StringHelpers } from '@dai/web-components'
import { StoreRowHelpers } from 'stores/helpers/store-row.helpers'
import { StoreRow } from 'stores/stores.types'

export const CommaSeparatedStoresText: React.FC<{
  stores: StoreRow[]
  scanned?: boolean
  paused?: boolean
  stopped?: boolean
}> = props => {
  const { stores, scanned = true, paused = true, stopped = true } = props
  const numStopped = StoreRowHelpers.numStopped(stores)
  const numPaused = StoreRowHelpers.numPaused(stores)
  const numScanned = StoreRowHelpers.numScanned(stores)

  const textArray = []
  if (numScanned > 0 && scanned) {
    textArray.push(<strong>{numScanned} scanned</strong>)
  }
  if (numPaused > 0 && paused) {
    textArray.push(<strong>{numPaused} paused</strong>)
  }
  if (numStopped > 0 && stopped) {
    textArray.push(<strong>{numStopped} stopped</strong>)
  }

  return (
    <>
      {StringHelpers.commaList(textArray, 'and')}{' '}
      {StringHelpers.plurality('store', numStopped || numPaused || numScanned)}
    </>
  )
}
