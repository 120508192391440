"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORE_CHAIN = void 0;
var client_1 = require("@apollo/client");
exports.GET_STORE_CHAIN = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetStoreChain($storeChainId: ID!) {\n    storeChain(storeChainId: $storeChainId) {\n      id\n      name\n    }\n  }\n"], ["\n  query GetStoreChain($storeChainId: ID!) {\n    storeChain(storeChainId: $storeChainId) {\n      id\n      name\n    }\n  }\n"])));
var templateObject_1;
