"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SCAN_QUALITY_SCORE = void 0;
var client_1 = require("@apollo/client");
exports.GET_SCAN_QUALITY_SCORE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   query GetScanQualityScores($input: UserProductivityInput!) {\n      scanQualityScores(input: $input) {\n        results {\n          scanSuccessRate\n          refId\n          compositeId\n          allRank\n          groupRank\n          allRankChange\n          groupRankChange\n          compositeId\n          compositeName\n          date\n        }\n        hasMore\n        count\n      }\n   }\n"], ["\n   query GetScanQualityScores($input: UserProductivityInput!) {\n      scanQualityScores(input: $input) {\n        results {\n          scanSuccessRate\n          refId\n          compositeId\n          allRank\n          groupRank\n          allRankChange\n          groupRankChange\n          compositeId\n          compositeName\n          date\n        }\n        hasMore\n        count\n      }\n   }\n"])));
var templateObject_1;
