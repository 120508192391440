/**
 * Copyright © 2020-2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  Product,
  FullScreenModal,
  Sidebar,
  ProductTile,
  FilterComponentProps,
  SearchableListWithSkeleton,
} from '@dai/web-components'
import { Box } from '@mui/material'
import { SabreProductFragment } from '@dai/graphql'

type ProductSearchProps = {
  products: SabreProductFragment[] | null
  onProductSelected: (upc: string) => void
  selectedProductUPC: string | null
  loading: boolean
  setSearchValue: (value: string) => void
  onClick?: () => void
  hasMore: boolean
  onLoadMore: () => void
  modalOpen: boolean
  setModalOpen: (isOpen: boolean) => void
} & Omit<FilterComponentProps, 'filterTitle'>

const ProductSearch: React.FunctionComponent<ProductSearchProps> = props => {
  const {
    products,
    onProductSelected,
    selectedProductUPC,
    loading = true,
    setSearchValue,
    onLoadMore,
    modalOpen,
    setModalOpen,
    hasMore,
    ...FilterProps
  } = props

  const productToProp = (prod: SabreProductFragment) => {
    return {
      id: prod?.id,
      upcA: prod?.upcA,
      productName: prod?.name,
      brandName: prod?.brand?.name,
      marketingImageUrl: prod?.marketingImageUrl || '',
    }
  }

  const ProductList = (
    <SearchableListWithSkeleton<SabreProductFragment>
      items={products || []}
      onSearchChange={setSearchValue}
      filterProps={{
        filterTitle: 'Filter Products',
        ...FilterProps,
      }}
      loading={loading}
      hasMore={hasMore}
      fetchMore={onLoadMore}
      renderItem={({ item, loading, index }) => (
        <Box mx={2} mb={1} flex={1} mt={index === 0 ? 1 : 0}>
          <ProductTile
            enableShowLargeImage
            product={productToProp(item)}
            loading={loading && !products?.length}
            selected={selectedProductUPC === item?.upcA}
            onSelect={upc => {
              onProductSelected(upc)
              setModalOpen(false)
            }}
          />
        </Box>
      )}
    />
  )
  return (
    <>
      <FullScreenModal
        title="Products"
        handleClose={() => setModalOpen(false)}
        mobileOnly
        open={modalOpen}
      >
        {ProductList}
      </FullScreenModal>
      <Sidebar side="left">{ProductList}</Sidebar>
    </>
  )
}

export default ProductSearch
