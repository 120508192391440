"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASIC_DISPLAY_AUDIT_IMAGE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var BasicBoundingBoxesFragment_1 = require("./BasicBoundingBoxesFragment");
var BasicProductFragment_1 = require("./BasicProductFragment");
exports.BASIC_DISPLAY_AUDIT_IMAGE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment BasicDisplayAuditImage on DisplayAuditImage {\n    id\n    url\n    created\n    boundingBoxSet {\n      ...BasicBoundingBoxes\n    }\n    products {\n      ...BasicProduct\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment BasicDisplayAuditImage on DisplayAuditImage {\n    id\n    url\n    created\n    boundingBoxSet {\n      ...BasicBoundingBoxes\n    }\n    products {\n      ...BasicProduct\n    }\n  }\n  ", "\n  ", "\n"])), BasicBoundingBoxesFragment_1.BASIC_BOUNDING_BOXES_FRAGMENT, BasicProductFragment_1.BASIC_PRODUCT_FRAGMENT);
var templateObject_1;
