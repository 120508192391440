/**
 * Copyright © 2024 Delicious AI, LLC
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { ZoneHierarchyGlobalSearchResultFragment } from '@dai/graphql'
import { FillContainerLoading, GroupedSearchResults } from '@dai/web-components'
import { FmdGood, Groups, Store } from '@mui/icons-material'
import { Breadcrumbs, Stack, Typography } from '@mui/material'
import React from 'react'

type ZoneHierarchyGlobalSearchProps = {
  areas: ZoneHierarchyGlobalSearchResultFragment[]
  stores: ZoneHierarchyGlobalSearchResultFragment[]
  people: ZoneHierarchyGlobalSearchResultFragment[]
  handleSelectResult: (zoneId: string) => void
  loading?: boolean
}

const ZoneHierarchyGlobalSearch: React.FC<ZoneHierarchyGlobalSearchProps> = props => {
  const { areas, stores, people, loading, handleSelectResult } = props

  const sections = [
    {
      header: { title: 'Stores', icon: <Store color="action" /> },
      items: stores,
    },
    {
      header: { title: 'Areas', icon: <FmdGood color="action" /> },
      items: areas,
    },
    {
      header: { title: 'People', icon: <Groups color="action" /> },
      items: people,
    },
  ]

  if (loading) return <FillContainerLoading />

  return (
    <GroupedSearchResults<ZoneHierarchyGlobalSearchResultFragment>
      sections={sections}
      renderItem={result => (
        <Stack
          direction={'column'}
          onClick={() => {
            if (result.zoneId) {
              handleSelectResult(result.zoneId)
            }
          }}
          flex={1}
        >
          <Typography
            style={{ wordBreak: 'break-word' }}
            fontWeight={'bold'}
            variant="subtitle2"
          >
            {result.name}
          </Typography>
          {result.zonePath && (
            <Breadcrumbs separator="›">
              {result.zonePath.map(p => (
                <Typography
                  variant="body1"
                  sx={theme => ({ color: theme.palette.grey[600] })}
                >
                  {p}
                </Typography>
              ))}
            </Breadcrumbs>
          )}
        </Stack>
      )}
    />
  )
}

export default ZoneHierarchyGlobalSearch
