/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../api/Firebase'

const getUserTenant = async (email: string): Promise<string | null> => {
  return getDocs(
    query(collection(firestore, 'users'), where('email', '==', email)),
  ).then(
    snapshot =>
      snapshot.docs.map(d => {
        const user = d.data()
        return {
          id: d.id,
          ...user,
          tenant: user.tenant.id,
        }
      })[0]?.tenant || null,
  )
}

export const FirebaseHelpers = {
  getUserTenant,
}
