"use strict";
/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetGroups = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var DAI_GROUPS = [
    graphql_1.DAIGroupEnum.IMAGE_LABELERS,
    graphql_1.DAIGroupEnum.POINT_CLOUD_VERIFIERS,
    graphql_1.DAIGroupEnum.MAPPERS,
    graphql_1.DAIGroupEnum.MAPPER_ADMINS,
    graphql_1.DAIGroupEnum.STORE_MAP_SCANNERS,
];
var useGetGroups = function () {
    var _a = (0, client_1.useQuery)(graphql_1.GET_GROUPS, {
        notifyOnNetworkStatusChange: true,
    }), data = _a.data, rest = __rest(_a, ["data"]);
    var allGroups = data === null || data === void 0 ? void 0 : data.groups;
    var groups = allGroups === null || allGroups === void 0 ? void 0 : allGroups.filter(function (group) {
        return !DAI_GROUPS.some(function (g) { return group.name.toLowerCase() === g.toString().toLowerCase(); });
    });
    var handleGetGroupByName = function (name) {
        return allGroups === null || allGroups === void 0 ? void 0 : allGroups.find(function (g) { return g.name === name; });
    };
    return __assign({ allGroups: allGroups, groups: groups, handleGetGroupByName: handleGetGroupByName }, rest);
};
exports.useGetGroups = useGetGroups;
