/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { uniqueId } from '@dai/common'
import PicOSContext from 'context/PicOSContext'
import { useSearchPriorities } from 'picos/hooks/use-search-priorities'
import { PicosConstants } from 'picos/picos.constants'
import { useContext } from 'react'

export const useEditPicOSPrioritiesLogic = () => {
  const {
    state: searchState,
    data: searchData,
    handle,
    PriorityLazyQuery,
  } = useSearchPriorities()
  const {
    setPriorityToEdit,
    setIsNewPriority,
    selectedBanner,
    handleNavTo,
  } = useContext(PicOSContext)

  const bannerSelected: boolean = !!selectedBanner?.id

  const handleSelectCreatePriority = () => {
    setPriorityToEdit(PicosConstants.EMPTY_PICOS)
    setIsNewPriority(true)
    handleNavTo('/picos/create', { replaceParams: { priorityId: uniqueId() } })
  }

  return {
    state: {
      ...searchState,
      bannerSelected,
    },
    handle: {
      ...handle,
      handleSelectCreatePriority,
    },
    data: {
      ...searchData,
    },
    PriorityLazyQuery,
  }
}
