"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATE_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
exports.GEOGRAPHIC_HIERARCHY_LEAD_CANDIDATE_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment GeographicHierarchyLead on GeographicHierarchyLeadCandidate {\n    uuid\n    id\n    email\n    firstName\n    lastName\n    username\n    customerUserId\n    numDraftsApproved\n    numDraftsTotal\n    mainRole\n    territoryId\n  }\n"], ["\n  fragment GeographicHierarchyLead on GeographicHierarchyLeadCandidate {\n    uuid\n    id\n    email\n    firstName\n    lastName\n    username\n    customerUserId\n    numDraftsApproved\n    numDraftsTotal\n    mainRole\n    territoryId\n  }\n"])));
var templateObject_1;
