"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ZONE = void 0;
var client_1 = require("@apollo/client");
exports.GET_ZONE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetZone($zoneId: ID) {\n    zone(zoneId: $zoneId) {\n      id\n      name\n      zonePath\n      tier\n      manager {\n        fullName\n        id\n      }\n      assignedUsers {\n        id\n        email\n        name\n        mainRole\n        dateRemoved\n      }\n      parentId\n      stores {\n        id\n        name\n        internalStoreId\n        image {\n          id\n          url\n        }\n        category\n        address {\n          address1\n          address2\n          city\n          state\n          postalCode\n        }\n      }\n      subZones {\n        id\n        name\n        numStores\n        manager {\n          fullName\n        }\n      }\n    }\n  }\n"], ["\n  query GetZone($zoneId: ID) {\n    zone(zoneId: $zoneId) {\n      id\n      name\n      zonePath\n      tier\n      manager {\n        fullName\n        id\n      }\n      assignedUsers {\n        id\n        email\n        name\n        mainRole\n        dateRemoved\n      }\n      parentId\n      stores {\n        id\n        name\n        internalStoreId\n        image {\n          id\n          url\n        }\n        category\n        address {\n          address1\n          address2\n          city\n          state\n          postalCode\n        }\n      }\n      subZones {\n        id\n        name\n        numStores\n        manager {\n          fullName\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
