"use strict";
/**
 * Copyright © 2020-2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.plurality = exports.toTitleCase = exports.dedupById = exports.notNullOrUndefined = void 0;
var notNullOrUndefined = function (value) {
    return value !== null && value !== undefined;
};
exports.notNullOrUndefined = notNullOrUndefined;
var dedupById = function (items) {
    return items.reduce(function (uniqueItems, item) {
        var match = uniqueItems.find(function (i) { return i.id === item.id; });
        if (match) {
            return uniqueItems;
        }
        else {
            return __spreadArray(__spreadArray([], uniqueItems, true), [item], false);
        }
    }, []);
};
exports.dedupById = dedupById;
function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
exports.toTitleCase = toTitleCase;
/**
 * Adds 's' to a word for counts greater than 1
 * @param word
 * @param count
 * @param ignore
 * @returns formatted string
 */
var plurality = function (word, count, ignore) {
    if (ignore && ignore.includes(word.toLowerCase()))
        return word;
    var plural = count === 1 || word.endsWith('s') ? '' : 's';
    return "".concat(word).concat(plural);
};
exports.plurality = plurality;
