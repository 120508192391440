/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import * as React from 'react'
import { useContext } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material'
import {
  ButtonWithProgress,
  FileUploader,
  FlexBox,
  FormInputType,
  gridAllScreens,
  ImageCarousel,
  MuiForm,
  MuiFormModal,
} from '@dai/web-components'
import StoreGroupForm from 'picos/components/StoreGroupForm'
import { useCreatePicOSPriorityLogic } from 'picos/container/create/CreatePicOSPriority.logic'
import PicOSLayout from 'picos/components/PicOSLayout'
import PicOSContext from 'context/PicOSContext'
import { ImageType } from '@dai/graphql'
import PicOSDraftsAccordion from 'picos/components/PicOSDraftsAccordion'
import { ArrowRightAlt, Clear, Delete, Done } from '@mui/icons-material'
import PicOSAddedProductsAccordion from 'picos/components/PicOSAddedProductsAccordion'
import { usePicosPriorityMutations } from 'picos/hooks/use-picos-priority-mutations'
import { isEqual } from 'lodash'
import { PicosConstants } from 'picos/picos.constants'

const CreatePicOSPriorityContainer: React.FC = () => {
  const {
    state: {
      activeStep,
      imageFile,
      setImageFile,
      draftPicos,
      incompleteOrLoading,
      showConfirmCancel,
      setShowConfirmCancel,
      showConfirmDelete,
      setShowConfirmDelete,
    },
    data: {
      forms,
      picosId,
      isImageStep,
      totalSteps,
      activeStepCompleted,
      orderOfForms,
      steps,
      createPicOSLoading,
      updatePicOSLoading,
      imageUploadLoading,
      allowedFileFormats,
    },
    forms: { SGF },
    handle: {
      handleStep,
      handleSubmit,
      handleClearForms,
      handleDeletePicosDraft,
      handleEditPicosDraft,
    },
  } = useCreatePicOSPriorityLogic()

  const {
    handle: { handleDeletePicosPriority },
  } = usePicosPriorityMutations()

  const {
    priorityToEdit,
    setPriorityToEdit,
    selectedBanner,
    handleNavTo,
    isNewPriority,
  } = useContext(PicOSContext)
  // @ts-ignore
  const activeForm = forms[activeStep]

  const draftIsSameAsSelected =
    draftPicos.length === 1 && draftPicos[0].id === picosId
  const showPicosDraftsAccordion =
    selectedBanner && !!draftPicos.length && !draftIsSameAsSelected

  const handleCancel = (remove: boolean) => {
    setImageFile(undefined)
    handleClearForms()
    if (remove) {
      if (selectedBanner) handleDeletePicosDraft(picosId, selectedBanner?.label)
    }
    handleNavTo('/picos/priorities', { removeParams: ['priorityId'] })
  }

  const handleDeletePriority = () => {
    setImageFile(undefined)
    handleClearForms()
    handleDeletePicosPriority(picosId, false).then(() => {
      handleNavTo('/picos/priorities', { removeParams: ['priorityId'] })
    })
  }

  return (
    <>
      <PicOSLayout>
        <Grid container direction="row">
          <Grid item {...gridAllScreens(2)} sm={0} xs={0} />
          <Grid item {...gridAllScreens(8)} sm={12} xs={12}>
            <Stack spacing={4}>
              <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {Object.values(steps).map((step, index) => (
                  // @ts-ignore
                  <Step key={step.name} completed={forms[index].completed}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {step.name}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              {showPicosDraftsAccordion && (
                <PicOSDraftsAccordion
                  onDeleteClick={id =>
                    handleDeletePicosDraft(id, selectedBanner.label)
                  }
                  onSelectToEditClick={id => handleEditPicosDraft(id)}
                  draftsPicos={draftPicos}
                />
              )}
              <Stack component={Paper} spacing={2} flex={1} p={2}>
                {!isImageStep ? (
                  <Box>
                    <MuiForm
                      orderOfForms={orderOfForms as FormInputType[]}
                      {...activeForm}
                    />
                    <StoreGroupForm
                      show={
                        forms[0].formState?.radio.STORES.value === 'false' &&
                        activeStep === 0
                      }
                      form={SGF}
                    />
                  </Box>
                ) : (
                  <>
                    {priorityToEdit?.imageUrl && !imageFile && (
                      <ImageCarousel
                        hidePaginator
                        title="Current PicOS Image"
                        images={[
                          {
                            uri: priorityToEdit.imageUrl,
                            id: priorityToEdit.id,
                            imageType: ImageType.IMAGE,
                          },
                        ]}
                        buttons={[]}
                      />
                    )}
                    <Box sx={{ alignSelf: 'center' }}>
                      <FileUploader
                        title={`${imageFile ? '' : 'Upload '}New PicOS Image`}
                        onFilesSelected={files => setImageFile(files[0])}
                        files={imageFile ? [imageFile] : undefined}
                        onClearFiles={() => setImageFile(undefined)}
                        allowedFileTypes={allowedFileFormats}
                      />
                    </Box>
                  </>
                )}
                <FlexBox.RowBetween>
                  <Stack direction="row" spacing={2}>
                    <Button
                      startIcon={<Clear color="secondary" />}
                      variant="contained"
                      onClick={() => {
                        if (
                          !isEqual(
                            priorityToEdit,
                            PicosConstants.EMPTY_PICOS,
                          ) &&
                          isNewPriority
                        ) {
                          setShowConfirmCancel(true)
                        } else {
                          handleCancel(true)
                        }
                      }}
                      color={isNewPriority ? 'primary' : 'secondary'}
                    >
                      Cancel
                    </Button>
                    {!isNewPriority && (
                      <Tooltip title="Permanantly delete this priority">
                        <Button
                          startIcon={<Delete color="secondary" />}
                          variant="contained"
                          onClick={() => setShowConfirmDelete(true)}
                          color="primary"
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    )}
                  </Stack>
                  <ButtonWithProgress
                    endIcon={
                      incompleteOrLoading ? (
                        <ArrowRightAlt
                          color={!activeStepCompleted ? 'disabled' : 'success'}
                        />
                      ) : (
                        <Done
                          color={incompleteOrLoading ? 'disabled' : 'secondary'}
                        />
                      )
                    }
                    variant={incompleteOrLoading ? 'outlined' : 'contained'}
                    disabled={!activeStepCompleted}
                    onClick={
                      activeStep !== steps.picosImage.indexNum
                        ? handleStep((activeStep + 1) % totalSteps)
                        : handleSubmit
                    }
                    loading={
                      createPicOSLoading ||
                      updatePicOSLoading ||
                      imageUploadLoading
                    }
                    color="success"
                  >
                    {!isImageStep ? 'Next' : 'Submit'}
                  </ButtonWithProgress>
                </FlexBox.RowBetween>
              </Stack>
              {activeStep === 2 && (
                <PicOSAddedProductsAccordion
                  items={priorityToEdit.addedProducts}
                  handleRemove={ids => {
                    setPriorityToEdit({
                      ...priorityToEdit,
                      addedProducts: [
                        ...priorityToEdit.addedProducts!.filter(
                          p => !ids.includes(p.id),
                        ),
                      ],
                    })
                  }}
                />
              )}
            </Stack>
          </Grid>
          <Grid item {...gridAllScreens(2)} sm={0} xs={0} />
        </Grid>
      </PicOSLayout>
      <MuiFormModal
        isOpen={showConfirmCancel}
        handleClose={() => setShowConfirmCancel(false)}
        title="Are you sure?"
        subtitle="Any changes you have made will not be saved."
        actionButtonsProps={[
          {
            children: 'No, leave without saving',
            onClick: () => handleCancel(true),
            variant: 'text',
          },
          {
            color: 'secondary',
            variant: 'text',
            children: 'Yes, save as a draft',
            onClick: () => handleCancel(false),
          },
        ]}
      />
      <MuiFormModal
        isOpen={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        title="Are you sure?"
        subtitle="This priority will be deleted. This action cannot be undone."
        actionButtonsProps={[
          {
            children: 'Cancel',
            onClick: () => setShowConfirmDelete(false),
            variant: 'text',
            color: 'secondary',
          },
          {
            variant: 'text',
            children: 'Confirm',
            onClick: handleDeletePriority,
          },
        ]}
      />
    </>
  )
}

export default CreatePicOSPriorityContainer
