"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuditQuestionsMutations = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useAuditQuestionsMutations = function () {
    var _deleteAuditQuestions = (0, client_1.useMutation)(graphql_1.BULK_DELETE_AUDIT_QUESTIONS)[0];
    var _CreateAuditQuestionMutation = (0, client_1.useMutation)(graphql_1.CREATE_AUDIT_QUESTION)[0];
    var _editAuditQuestions = (0, client_1.useMutation)(graphql_1.UPDATE_AUDIT_QUESTIONS)[0];
    var deleteAuditQuestions = function (ids) {
        return _deleteAuditQuestions({ variables: { input: { ids: ids } } });
    };
    var CreateAuditQuestionMutation = function (variables) {
        return _CreateAuditQuestionMutation({ variables: variables });
    };
    var UpdateAuditQuestionsMutation = function (variables) {
        _editAuditQuestions({ variables: variables });
    };
    return {
        deleteAuditQuestions: deleteAuditQuestions,
        CreateAuditQuestionMutation: CreateAuditQuestionMutation,
        UpdateAuditQuestionsMutation: UpdateAuditQuestionsMutation,
    };
};
exports.useAuditQuestionsMutations = useAuditQuestionsMutations;
