/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import { BarChartData, ReportLayout } from '@dai/web-components'
import { BarChart } from '@dai/web-components'
import {
  GenericReportDataType,
  GenericReportProps,
} from 'reporting/admin-reporting.types'
import ReportContext from 'context/ReportContext'

export const BarChartReport: React.FC<GenericReportProps<string>> = props => {
  const { useGetData, ...rest } = props
  const { reportParams } = useContext(ReportContext)
  const { data, isEmpty, isLoading } = useGetData(reportParams)
  const formatData = (data: GenericReportDataType[]) =>
    data.map(d => ({
      value: d.value as number,
      category: d.category as string,
    }))
  const formattedData: BarChartData[] = formatData(data)

  return (
    <ReportLayout
      {...props}
      Body={
        <BarChart
          {...rest}
          isEmpty={isEmpty}
          isLoading={isLoading}
          xAxisLabel={''}
          yAxisLabel={''}
          data={formattedData}
        />
      }
    />
  )
}
