/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import { Alert, CardContent } from '@mui/material'
import {
  AvatarWithText,
  gridAllScreens,
  GridSections,
} from '@dai/web-components'
import { BugReport } from '@mui/icons-material'

export type DraftProduct = {
  id: string
  notes: string | null
  createdBy: string | null
}

type DraftInfoProps = {
  errorMessage: string
  draft: DraftProduct
  severity?: 'warning' | 'error'
}

export const DraftInfo: React.FC<DraftInfoProps> = props => {
  const { errorMessage, draft, severity = 'error' } = props

  return (
    <>
      <Alert severity={severity}>{errorMessage}</Alert>
      <CardContent>
        <GridSections
          items={{
            [draft.notes || '']: {
              gridProps: gridAllScreens(12),
              body: (
                <AvatarWithText
                  avatarVariant="rounded"
                  avatarColor="primary"
                  primaryText={`${draft?.createdBy}`}
                  // secondaryText={DateTimeHelpers.getDateFromStringYearMonthDay(
                  //   flagged.date,
                  // )}
                  boldPrimary
                >
                  <BugReport color="secondary" />
                </AvatarWithText>
              ),
            },
          }}
        />
      </CardContent>
    </>
  )
}

export default DraftInfo
