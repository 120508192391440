"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIMPLE_AUDIT_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ScoreFragments_1 = require("./ScoreFragments");
exports.SIMPLE_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment SimpleAudit on Audit {\n    id\n    created\n    saved\n    dateCompleted\n    displaysCompletedAt\n    pricingCompletedAt\n    surveyCompletedAt\n    employee {\n      firstName\n      lastName\n      id\n      email\n    }\n    store {\n      id\n      storeChain\n      name\n      hasAllFormulas\n      address {\n        address1\n        city\n        state\n        postalCode\n      }\n    }\n    status\n    score {\n      created\n      modified\n      id\n      total {\n        ...Score\n      }\n    }\n    notes\n  }\n  ", "\n"], ["\n  fragment SimpleAudit on Audit {\n    id\n    created\n    saved\n    dateCompleted\n    displaysCompletedAt\n    pricingCompletedAt\n    surveyCompletedAt\n    employee {\n      firstName\n      lastName\n      id\n      email\n    }\n    store {\n      id\n      storeChain\n      name\n      hasAllFormulas\n      address {\n        address1\n        city\n        state\n        postalCode\n      }\n    }\n    status\n    score {\n      created\n      modified\n      id\n      total {\n        ...Score\n      }\n    }\n    notes\n  }\n  ", "\n"])), ScoreFragments_1.SCORE_FRAGMENT);
var templateObject_1;
