/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React, { useContext } from 'react'
import {
  PageTitle,
  FullScreenLayout,
  NavigationTabs,
} from '@dai/web-components'
import ZoneHierarchyContainer from 'stores/containers/hierarchy-management/ZoneHierarchy.container'
import StoreManagementContainer from 'stores/containers/store-management/StoreManagement.container'
import { Switch } from 'react-router-dom'
import ProtectedRoute from 'routes/ProtectedRoute'
import UserContext from 'context/UserContext'
import { DAIGroupEnum } from '@dai/graphql'
import { hasGroup } from '@dai/common'

const StoresContainer: React.FC = () => {
  const { user } = useContext(UserContext)
  const authorized = [
    DAIGroupEnum.SALES,
    DAIGroupEnum.SALES_ADMINS,
    DAIGroupEnum.SALES_MANAGERS,
    DAIGroupEnum.MERCHANDISERS,
    DAIGroupEnum.RED_ADMINS,
  ]

  return (
    <>
      <FullScreenLayout
        header={
          <PageTitle
            title="Stores"
            RightComponent={
              <NavigationTabs<'management' | 'hierarchy'>
                routeBase={'stores'}
                tabs={[
                  {
                    label: 'Management',
                    ext: 'management',
                  },
                  ...(authorized.some(group => hasGroup(user, group))
                    ? [
                        {
                          label: 'Hierarchy',
                          ext: 'hierarchy' as 'hierarchy',
                        },
                      ]
                    : []),
                ]}
              />
            }
          />
        }
        bodyStyle={{ p: 0 }}
        body={
          <Switch>
            <ProtectedRoute path={'/stores/management'}>
              <StoreManagementContainer />
            </ProtectedRoute>
            <ProtectedRoute path={'/stores/hierarchy'} authorized={authorized}>
              <ZoneHierarchyContainer />
            </ProtectedRoute>
          </Switch>
        }
      />
    </>
  )
}

export default StoresContainer
