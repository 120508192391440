/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  BrandGeographyQueryQuery,
  BrandGeographyQueryQueryVariables,
  BRAND_GEOGRAPHY,
} from '@dai/graphql'
import {
  Brand,
  ReportLayout,
  SwireUSAMapChart,
  MapBubbleDatum,
  MuiTheme,
  useDebouncedItemQuery,
} from '@dai/web-components'
import React, { useEffect, useState } from 'react'
import { BrandDisruptorQueryProps } from 'brands/brand-disruptor/brand-disruptor.types'
import { BrandSelector } from './BrandSelector'

export const BrandGeography: React.FC<BrandDisruptorQueryProps> = props => {
  const { startDate, endDate, dateRangeType, selectedBrands, moreInfo } = props

  const [geoData, setGeoData] = useState<MapBubbleDatum[]>([])

  const BrandGeo = useDebouncedItemQuery<
    BrandGeographyQueryQuery,
    BrandGeographyQueryQueryVariables
  >({ queryStr: BRAND_GEOGRAPHY, options: {} })
  const res = BrandGeo.lazyQuery.meta.data?.brandGeography.results || []

  useEffect(() => {
    if (selectedBrands.length > 0) {
      BrandGeo.lazyQuery.query({
        variables: {
          input: {
            startDate,
            endDate,
            type: dateRangeType,
            brandIds: selectedBrands.map(b => b.id),
          },
        },
      })
    }
  }, [selectedBrands.length])

  useEffect(() => {
    if (res.length) {
      const geoData: MapBubbleDatum[] = res.map(geo => ({
        latitude: geo.latitude,
        longitude: geo.longitude,
        size: geo.quantity,
        label: geo.storeName,
        colorIndex: selectedBrands.map(b => b.name).indexOf(geo.brand.name),
        brandLabel: geo.brand.name,
      }))
      setGeoData(geoData)
    }
  }, [res.length])

  return (
    <ReportLayout
      title={'Brand Geography'}
      moreInfo={moreInfo}
      Body={
        <SwireUSAMapChart
          tooltip={[
            {
              text: '{brandLabel}',
              newline: true,
              color: MuiTheme.palette.secondary.light,
            },
            {
              text: '{size}',
              fontSize: 28,
              color: MuiTheme.palette.secondary.main,
            },
            {
              text: 'Facings',
              color: MuiTheme.palette.secondary.light,
              newline: true,
            },
            {
              text: '{label}',
              color: MuiTheme.palette.secondary.light,
            },
          ]}
          chartId="map-chart"
          data={geoData}
          regions={[
            'US-UT',
            'US-ID',
            'US-NM',
            'US-AZ',
            'US-OR',
            'US-WA',
            'US-MT',
            'US-WY',
            'US-NV',
            'US-CO',
            'US-ND',
            'US-SD',
            'US-NE',
          ]}
          isEmpty={!selectedBrands.length || !res.length}
          isLoading={BrandGeo.lazyQuery.meta.loading}
        />
      }
    />
  )
}
