"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_STORE_DISPLAYS = void 0;
var client_1 = require("@apollo/client");
var DisplayFragment_1 = require("../fragments/DisplayFragment");
exports.GET_STORE_DISPLAYS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetStoreDisplays($input: StoreDisplaysInput!) {\n    storeDisplays(input: $input) {\n      ...Display\n    }\n  }\n  ", "\n"], ["\n  query GetStoreDisplays($input: StoreDisplaysInput!) {\n    storeDisplays(input: $input) {\n      ...Display\n    }\n  }\n  ", "\n"])), DisplayFragment_1.DISPLAY_FRAGMENT);
var templateObject_1;
