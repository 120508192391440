/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, EmailAuthProvider, OAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const {
  REACT_APP__FB_API_KEY,
  REACT_APP__FB_AUTH_DOMAIN,
  REACT_APP__FB_DATABASE_URL,
  REACT_APP__FB_PROJECT_ID,
  REACT_APP__FB_STORAGE_BUCKET,
  REACT_APP__FB_MAP_STORAGE_BUCKET,
  REACT_APP__MESSAGING_SENDER_ID,
  REACT_APP__APP_ID,
  REACT_APP_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP__FB_API_KEY,
  authDomain: REACT_APP__FB_AUTH_DOMAIN,
  databaseURL: REACT_APP__FB_DATABASE_URL,
  projectId: REACT_APP__FB_PROJECT_ID,
  storageBucket: REACT_APP__FB_STORAGE_BUCKET,
  messagingSenderId: REACT_APP__MESSAGING_SENDER_ID,
  appId: REACT_APP__APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)

const microsoftProvider = new OAuthProvider('microsoft.com')

export const uiConfig: firebaseui.auth.Config = {
  callbacks: { signInSuccessWithAuthResult: () => false },
  signInOptions: [microsoftProvider.providerId, EmailAuthProvider.PROVIDER_ID],
  credentialHelper: 'none',
}

// Firebase instances
export const auth = getAuth()
export const storage = getStorage()
export const firestore = getFirestore()
export const mapStorage = getStorage(
  app,
  `gs://${REACT_APP__FB_MAP_STORAGE_BUCKET}`,
)

export const imageStorage = getStorage(
  app,
  `gs://${REACT_APP__FB_PROJECT_ID}-images`,
)

export const videoStorage = getStorage(
  app,
  `gs://${REACT_APP__FB_PROJECT_ID}-videos`,
)
