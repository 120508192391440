"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_PRODUCTS_SABRE = void 0;
var client_1 = require("@apollo/client");
exports.SEARCH_PRODUCTS_SABRE = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query SearchProducts($productsInput: ProductsInput!) {\n    products(input: $productsInput) {\n      count\n      hasMore\n      results {\n        id\n        created\n        modified\n        upcA\n        name\n        brand {\n          id\n          name\n        }\n        flavors {\n          id\n          displayFlavor\n        }\n        marketingImageUrl\n        verifiedBy\n      }\n    }\n  }\n"], ["\n  query SearchProducts($productsInput: ProductsInput!) {\n    products(input: $productsInput) {\n      count\n      hasMore\n      results {\n        id\n        created\n        modified\n        upcA\n        name\n        brand {\n          id\n          name\n        }\n        flavors {\n          id\n          displayFlavor\n        }\n        marketingImageUrl\n        verifiedBy\n      }\n    }\n  }\n"])));
var templateObject_1;
