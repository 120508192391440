"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BRAND_GEOGRAPHY = void 0;
var client_1 = require("@apollo/client");
exports.BRAND_GEOGRAPHY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery BrandGeographyQuery($input: BrandGeographyInput!) {\n    brandGeography(input:$input) {\n    \tresults {\n        latitude\n        longitude\n        quantity\n        storeName\n        brand {\n          name\n        }\n      }\n    }\n}\n"], ["\nquery BrandGeographyQuery($input: BrandGeographyInput!) {\n    brandGeography(input:$input) {\n    \tresults {\n        latitude\n        longitude\n        quantity\n        storeName\n        brand {\n          name\n        }\n      }\n    }\n}\n"])));
var templateObject_1;
