/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  SelectableOption,
  AutocompleteSelectInput,
  useProductSearch,
  StringHelpers,
  Brand,
} from '@dai/web-components'

type BrandSelectorProps = {
  label?: string
  selectedBrands: Brand[]
  setSelectedBrands: React.Dispatch<React.SetStateAction<Brand[]>>
}

export const BrandSelector: React.FC<BrandSelectorProps> = (
  props: BrandSelectorProps,
) => {
  const { selectedBrands, setSelectedBrands, label = 'Selected Brands' } = props
  const { BrandLazyQuery: BLQ } = useProductSearch()
  const [options, setOptions] = useState<SelectableOption[]>([])

  useEffect(() => {
    if (!StringHelpers.isNullOrEmpty(BLQ.debouncedQuery)) {
      BLQ.lazyQuery.query({
        variables: { input: { brandName: BLQ.debouncedQuery } },
      })
    }
  }, [BLQ.debouncedQuery, BLQ.Pagination.offset, BLQ.lazyQuery.query])

  useEffect(() => {
    if (BLQ.lazyQuery.meta.data?.brands.count) {
      setOptions(
        BLQ.lazyQuery.meta.data.brands?.results.map(br => ({
          value: br.id,
          label: br.name,
        })) || [],
      )
    }
  }, [BLQ.lazyQuery.meta.data])

  return (
    <Stack direction="column" spacing={1} alignItems={'flex-end'}>
      <AutocompleteSelectInput
        label={label}
        options={options}
        multiple
        loading={BLQ.lazyQuery.meta.loading}
        searchText={BLQ.itemQuery}
        setSearchText={BLQ.setItemQuery}
        selectedOption={selectedBrands.map(b => ({
          value: b.id,
          label: b.name,
        }))}
        setSelectedOption={val =>
          val
            ? setSelectedBrands(
                (val as SelectableOption[]).map(brand => ({
                  id: brand.value,
                  name: brand.label,
                })),
              )
            : []
        }
      />
    </Stack>
  )
}
