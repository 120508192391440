"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MERCHANDISER_STATS = void 0;
var client_1 = require("@apollo/client");
exports.GET_MERCHANDISER_STATS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetMerchandiserStats($input: MerchReportsInput) {\n    merchandisersStats(input: $input) {\n      user {\n        email\n        firstName\n        lastName\n      }\n      restocksToday\n      restocksThisWeek\n      displaysToday\n      displaysThisWeek\n      storesRestockedToday\n      storesRestockedThisWeek\n      avgScoreToday\n      avgScoreThisWeek\n      date\n    }\n  }\n"], ["\n  query GetMerchandiserStats($input: MerchReportsInput) {\n    merchandisersStats(input: $input) {\n      user {\n        email\n        firstName\n        lastName\n      }\n      restocksToday\n      restocksThisWeek\n      displaysToday\n      displaysThisWeek\n      storesRestockedToday\n      storesRestockedThisWeek\n      avgScoreToday\n      avgScoreThisWeek\n      date\n    }\n  }\n"])));
var templateObject_1;
