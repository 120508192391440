"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.wilkey@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addOperationNameToUrl = exports.keyArgsIgnoreOffsetLimit = exports.mergePreferIncoming = exports.mergePaginatedResults = void 0;
var client_1 = require("@apollo/client");
var dedupById = function (results, readField) {
    return results.reduce(function (acc, item) {
        var match = acc.find(function (exItem) { return readField('id', exItem) === readField('id', item); });
        if (match) {
            return acc;
        }
        else {
            return __spreadArray(__spreadArray([], acc, true), [item], false);
        }
    }, []);
};
var mergePaginatedResults = function (existing, incoming, options) {
    var _a;
    var readField = options.readField;
    if (incoming.results.length) {
        var results = [];
        if ((_a = existing === null || existing === void 0 ? void 0 : existing.results) === null || _a === void 0 ? void 0 : _a.length) {
            var existingIds_1 = new Set(existing.results.map(function (item) { return readField('id', item); }));
            var uniqueExisting = dedupById(existing.results, readField);
            var uniqueIncoming = incoming.results.filter(function (item) { return !existingIds_1.has(readField('id', item)); });
            results = __spreadArray(__spreadArray([], uniqueExisting, true), uniqueIncoming, true);
        }
        else {
            results = __spreadArray([], incoming.results, true);
        }
        return __assign(__assign({}, incoming), { results: results });
    }
    return (existing === null || existing === void 0 ? void 0 : existing.results) ? dedupById(existing === null || existing === void 0 ? void 0 : existing.results, readField) : existing;
};
exports.mergePaginatedResults = mergePaginatedResults;
var mergePreferIncoming = function (existing, incoming) {
    if (incoming.length === 0) {
        return existing;
    }
    return incoming;
};
exports.mergePreferIncoming = mergePreferIncoming;
var keyArgsIgnoreOffsetLimit = function (args) {
    if (args === null || args === void 0 ? void 0 : args.input) {
        var _a = args.input, offset = _a.offset, limit = _a.limit, rest = __rest(_a, ["offset", "limit"]);
        return JSON.stringify(Object.values(rest));
    }
};
exports.keyArgsIgnoreOffsetLimit = keyArgsIgnoreOffsetLimit;
exports.addOperationNameToUrl = new client_1.ApolloLink(function (operation, forward) {
    if (process.env.NODE_ENV === 'development') {
        var context = operation.getContext();
        var contextURI = context.uri || '/graphql';
        operation.setContext({
            uri: "".concat(contextURI, "?").concat(operation.operationName),
        });
    }
    return forward(operation);
});
