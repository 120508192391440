/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import {
  hasPermission,
  useGetCompanyUsers,
  useGetUserStores,
} from '@dai/common'
import {
  ADD_USER,
  AddUserMutation,
  AddUserInput,
  AddUserMutationVariables,
  ASSIGN_USERS_ACTIVE,
  AssignUsersActiveMutation,
  AssignUsersActiveMutationVariables,
  DAIGroupEnum,
  UserFragment,
} from '@dai/graphql'
import { DAITable, DAITableColumns, MuiTheme } from '@dai/web-components'
import { useMutation } from '@apollo/client'
import { UserRow } from '../users.types'
import UserContext from '../../../context/UserContext'

const PAGE_SIZE = MuiTheme.sizes.table.page.md

export const useUserManagementLogic = () => {
  const { user } = useContext(UserContext)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [showEditStoresModal, setShowEditStoresModal] = useState(false)
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [showEditSupervisorModal, setShowEditSupervisorModal] = useState(false)
  const [showReassignStoresModal, setShowReassignStoresModal] = useState(false)
  const [hideInactive, setHideInactive] = useState(true)

  const [userSearch, setUserSearch] = useState('')
  const [debouncedUserSearchQuery] = useDebounce(userSearch, 500)

  const { users, count, loading, fetchNextPage, refetch } = useGetCompanyUsers({
    limit: PAGE_SIZE,
    searchTerm: debouncedUserSearchQuery,
  })

  useEffect(() => {
    tableRef.current?.returnToFirstPage()
  }, [debouncedUserSearchQuery])

  const isRedAdmin = hasPermission(user, [DAIGroupEnum.RED_ADMINS])
  const tableRef = useRef<DAITable<UserRow>>(null)

  const [assignUserActive] = useMutation<
    AssignUsersActiveMutation,
    AssignUsersActiveMutationVariables
  >(ASSIGN_USERS_ACTIVE)

  const [addUserMutation] = useMutation<
    AddUserMutation,
    AddUserMutationVariables
  >(ADD_USER)

  const selectedUser =
    selectedIds.length === 1 ? users?.find(q => q.id === selectedIds[0]) : null

  const selectedUsers = users.filter(u => selectedIds.includes(u.id))
  const { getUserStores, userStores, refetchUserStores } = useGetUserStores({
    userId: selectedUser?.uuid || '',
  })

  const handlePageChange = async (page: number, cursorPosition: number) => {
    if (cursorPosition >= users.length) {
      await fetchNextPage()
    }
  }

  const handleAddUser = async (addUserInput: AddUserInput) => {
    const variables = {
      input: {
        email: addUserInput.email,
        firstName: addUserInput.firstName,
        lastName: addUserInput.lastName,
        groups: addUserInput.groups,
      },
    }
    await addUserMutation({ variables })
    setShowCreateUser(false)
    refetch()
  }

  const getRole = (user: UserFragment): string => {
    return user.roles.join('/')
  }

  const handleEditStoresPressed = () => setShowEditStoresModal(true)

  const handleSupervisorPressed = () => setShowEditSupervisorModal(true)

  const handleMarkActive = async () => {
    const variables = {
      input: {
        users: selectedUser
          ? [selectedUser.uuid]
          : selectedUsers.map(user => user.uuid),
        isActive: true,
      },
    }
    await assignUserActive({ variables })
  }

  const handleMarkInactive = async () => {
    const variables = {
      input: {
        users: selectedUser
          ? [selectedUser.uuid]
          : selectedUsers.map(user => user.uuid),
        isActive: false,
      },
    }
    await assignUserActive({ variables })
    setSelectedIds([])
    tableRef.current?.setSelectedIds([])
  }

  const userRows = useMemo(
    () =>
      users
        .map(user => ({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: getRole(user),
          numStores: user.stores.length,
          manager: user.supervisor
            ? `${user.supervisor.firstName} ${user.supervisor.lastName}`
            : 'N/A',
          active: user.isActive,
        }))
        .filter(user => (hideInactive ? user.active : true)),
    [users, hideInactive],
  )

  const columns: DAITableColumns<UserRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: MuiTheme.sizes.table.column.width.xl,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: MuiTheme.sizes.table.column.width.md,
      },
      {
        field: 'numStores',
        headerName: '# Stores',
        width: MuiTheme.sizes.table.column.width.md,
      },
      { field: 'manager', headerName: 'Manager' },
      { field: 'active', headerName: 'Active' },
    ],
    [MuiTheme],
  )

  useEffect(() => {
    if (selectedUser) {
      getUserStores({
        variables: {
          input: {
            userId: selectedUser?.uuid,
          },
        },
      })
    }
  }, [selectedUser])

  return {
    state: {
      setUserSearch,
      selectedIds,
      setSelectedIds,
      showEditStoresModal,
      showCreateUser,
      showEditSupervisorModal,
      showReassignStoresModal,
      hideInactive,
      setHideInactive,
      setShowEditSupervisorModal,
      setShowReassignStoresModal,
      setShowCreateUser,
      setShowEditStoresModal,
    },
    data: {
      selectedUser,
      isRedAdmin,
      userRows,
      columns,
      activeSelectedUsers:
        selectedUser?.isActive || selectedUsers.some(user => user.isActive),
      inactiveSelectedUsers:
        (selectedUser && !selectedUser?.isActive) ||
        selectedUsers.every(user => !user.isActive),
      PAGE_SIZE,
      count,
      loading,
      selectedUsers,
      userStores,
    },
    ref: {
      tableRef,
    },
    handle: {
      handleAddUser,
      handleEditStoresPressed,
      handleMarkActive,
      handleMarkInactive,
      handlePageChange,
      handleSupervisorPressed,
      handleRefetchUserStores: refetchUserStores,
      handleRefetchUsers: refetch,
    },
  }
}
