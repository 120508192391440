"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_TRAINING = void 0;
var client_1 = require("@apollo/client");
exports.UPDATE_TRAINING = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdateTraining($input: UpdateTrainingInput!) {\n    updateTraining(input: $input) {\n      training {\n        title\n        description\n        id\n        timeRequired\n        groups {\n          id\n          name\n        }\n        deviceType\n        trainingImage {\n          id\n          url\n          bucketFileName\n        }\n      }\n    }\n  }\n"], ["\n  mutation UpdateTraining($input: UpdateTrainingInput!) {\n    updateTraining(input: $input) {\n      training {\n        title\n        description\n        id\n        timeRequired\n        groups {\n          id\n          name\n        }\n        deviceType\n        trainingImage {\n          id\n          url\n          bucketFileName\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
