/**
 * Copyright © 2023 Delicious AI, LLC
 *
 * @author Bryan Sheranian <bryan.sheranian@deliciousai.com>
 */

import { MuiFormModal, MuiFormModalProps } from '@dai/web-components'
import React from 'react'
import { Close, Stop } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { StoreRow } from 'stores/stores.types'
import { useStopOptimizations } from '@dai/common'
import { AdminCompanyStoresInput } from '@dai/graphql'
import { CommaSeparatedStoresText } from '../CommaSeparatedStoresText'

interface StopScansModalProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  stores: StoreRow[]
  companyStoresInput: AdminCompanyStoresInput
}

export const StopScansModal: React.FC<StopScansModalProps> = props => {
  const { isOpen, handleClose, stores, companyStoresInput } = props
  const { stopOptimizations } = useStopOptimizations(companyStoresInput)

  return (
    <MuiFormModal
      title={`Stop Scans${
        stores.length > 1 ? ` (${stores.length} Stores)` : ''
      }`}
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleClose,
          children: 'Cancel',
          color: 'secondary',
          startIcon: <Close color="secondary" />,
        },
        {
          onClick: () => {
            stopOptimizations({
              input: { storeIds: stores.map(store => store.id) },
            })
            handleClose()
          },
          children: 'Stop Scans',
          startIcon: <Stop color="secondary" />,
        },
      ]}
    >
      <Typography>
        This will indefinitely stop optimization and compliance scans for all
        coolers in {<CommaSeparatedStoresText stores={stores} />}.
      </Typography>
    </MuiFormModal>
  )
}
