"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SIMPLE_MODULAR_COMPLIANCE_AUDITS = exports.SIMPLE_MODULAR_COMPLIANCE_AUDIT_FRAGMENT = exports.GET_MODULAR_COMPLIANCE_AUDITS = void 0;
var client_1 = require("@apollo/client");
var fragments_1 = require("../fragments");
exports.GET_MODULAR_COMPLIANCE_AUDITS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query ModularComplianceAudits($input: ComplianceAuditsInput!) {\n    modularComplianceAudits(input: $input) {\n      results {\n        ...ComplianceAudit\n        before {\n          strictScore\n          sequenceScore\n          segmentCompliance {\n            scanComplete\n            shelfCompliance {\n              shelf {\n                depth\n                isUnderShelf\n              }\n              facingScores {\n                upc\n                score\n                issue\n                actuals {\n                  upc\n                  index\n                  vFacingUpcs\n                  distanceFromHome\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  ", "\n"], ["\n  query ModularComplianceAudits($input: ComplianceAuditsInput!) {\n    modularComplianceAudits(input: $input) {\n      results {\n        ...ComplianceAudit\n        before {\n          strictScore\n          sequenceScore\n          segmentCompliance {\n            scanComplete\n            shelfCompliance {\n              shelf {\n                depth\n                isUnderShelf\n              }\n              facingScores {\n                upc\n                score\n                issue\n                actuals {\n                  upc\n                  index\n                  vFacingUpcs\n                  distanceFromHome\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  ", "\n"])), fragments_1.MODULAR_COMPLIANCE_AUDIT_FRAGMENT);
exports.SIMPLE_MODULAR_COMPLIANCE_AUDIT_FRAGMENT = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment SimpleModularComplianceAudit on ComplianceAudit {\n    id\n    dateCompleted\n    strictScore\n    sequenceScore\n    saved\n    employee {\n      ...User\n    }\n  }\n  ", "\n"], ["\n  fragment SimpleModularComplianceAudit on ComplianceAudit {\n    id\n    dateCompleted\n    strictScore\n    sequenceScore\n    saved\n    employee {\n      ...User\n    }\n  }\n  ", "\n"])), fragments_1.USER_FRAGMENT);
exports.GET_SIMPLE_MODULAR_COMPLIANCE_AUDITS = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query SimpleModularComplianceAudits($input: ComplianceAuditsInput!) {\n    modularComplianceAudits(input: $input) {\n      results {\n        ...SimpleModularComplianceAudit\n      }\n    }\n  }\n  ", "\n"], ["\n  query SimpleModularComplianceAudits($input: ComplianceAuditsInput!) {\n    modularComplianceAudits(input: $input) {\n      results {\n        ...SimpleModularComplianceAudit\n      }\n    }\n  }\n  ", "\n"])), exports.SIMPLE_MODULAR_COMPLIANCE_AUDIT_FRAGMENT);
var templateObject_1, templateObject_2, templateObject_3;
