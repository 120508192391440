/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Wade Wilkey <wade.w.wilkey@gmail.com>
 */

import { Button } from '@mui/material'
import React from 'react'

interface PicOSCellActionButtonProps {
  label: string
  onClick: () => void
}

const PicOSCellActionButton: React.FC<PicOSCellActionButtonProps> = props => {
  const { label, onClick } = props
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      {label}
    </Button>
  )
}

export default PicOSCellActionButton
