/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import {
  AggregateBy,
  AggregationMethod,
  GET_DISPLAY_SCANS,
  GET_PRODUCT_COUNTED,
  GetDisplayScansQuery,
  GetDisplayScansQueryVariables,
  GetProductCountedQuery,
  GetProductCountedQueryVariables,
} from '@dai/graphql'
import {
  GenericReportDataType,
  GenericReportHook,
  ChartType,
  UserProductivityReportName,
} from 'reporting/admin-reporting.types'
import { gridAllScreens, MuiTheme } from '@dai/web-components'
import { useLocalStorage } from 'hooks/use-local-storage'
import { UserProductivityReportInput } from 'users/users.types'
import { useUserProductivityQuery } from 'users/hooks/reporting/use-user-productivity-query'
import { useEffect, useState } from 'react'
import { UserProductivityReportingConstants } from 'users/constants/reporting/user-productivity-reporting.constants'
import { UserProductivityReportHelpers } from 'users/helpers/reporting/user-productivity-report.helpers'

export const useActivityLeaderboardReports: GenericReportHook<UserProductivityReportName> = () => {
  const [restockTitle, setRestockTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const [productCountedTitle, setProductCountedTitle] = useState(
    UserProductivityReportingConstants.EMPTY_LEADERBOARD_TITLE,
  )
  const titles = {
    restockTitle,
    productCountedTitle,
  }
  const tooltipBase = [
    {
      text: '{category}',
      color: MuiTheme.palette.secondary.light,
      newline: true,
    },
    {
      text: '{valueX}',
      fontSize: 28,
      color: MuiTheme.palette.secondary.main,
      bold: true,
    },
  ]
  const chartProps = {
    gridProps: { ...gridAllScreens(6), md: 12, sm: 12, xs: 12 },
    horizontal: true,
    type: 'bar-chart' as ChartType,
  }
  const bottomNChartProps = (title: keyof typeof titles) => ({
    ...chartProps,
    subtitle: `Bottom ${titles[title].range} Performing ${titles[title].type}`,
    color: MuiTheme.palette.primary.main,
  })
  const topNChartProps = (title: keyof typeof titles) => ({
    ...chartProps,
    subtitle: `Top ${titles[title].range} Performing ${titles[title].type}`,
  })
  const { Storage } = useLocalStorage()
  const companyUsers = Storage.getItem('companyUsers')

  const useGetTopNBottomNProductCounted = (
    reportParams: UserProductivityReportInput,
    orderBy: string[],
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getProductProductivityQueryInput } = useReportQuery()
    const { data: usersAllTime, loading } = useQuery<
      GetProductCountedQuery,
      GetProductCountedQueryVariables
    >(GET_PRODUCT_COUNTED, {
      variables: {
        input: {
          ...getProductProductivityQueryInput(
            AggregationMethod.SUM,
            AggregateBy.ALL_TIME,
            orderBy,
          ),
          limit: UserProductivityReportingConstants.LEADERBOARD_DEFAULT_LIMIT,
        },
      },
    })

    const result = usersAllTime?.productCounted.results || []

    useEffect(() => {
      UserProductivityReportHelpers.setLeaderboardTitle(
        setProductCountedTitle,
        result.length,
        reportParams.allTeams,
      )
    }, [result.length, reportParams.reportLevel])

    return {
      data: result
        .map(res => ({
          category: companyUsers?.[res.refId]?.name || res.refId,
          value: res.count!,
        }))
        .sort((a, b) => {
          if (orderBy[0][0] === '-') {
            return a.value > b.value ? 1 : -1
          } else {
            return a.value < b.value ? 1 : -1
          }
        }),
      isEmpty: !result.length,
      isLoading: loading,
    }
  }

  const useGetTopNBottomNTotalRestocks = (
    reportParams: UserProductivityReportInput,
    orderBy: string[],
  ) => {
    const { useReportQuery } = useUserProductivityQuery(reportParams)
    const { getUserProductivityQueryInput } = useReportQuery()
    const { data: restocks, loading } = useQuery<
      GetDisplayScansQuery,
      GetDisplayScansQueryVariables
    >(GET_DISPLAY_SCANS, {
      variables: {
        // TODO: Have some way to easily get user info from id...
        //  AND be able to filter by user type...
        input: {
          ...getUserProductivityQueryInput(
            AggregationMethod.SUM,
            AggregateBy.ALL_TIME,
            orderBy,
          ),
          limit: UserProductivityReportingConstants.LEADERBOARD_DEFAULT_LIMIT,
        },
      },
    })

    const result = restocks?.displayScans.results || []

    useEffect(() => {
      UserProductivityReportHelpers.setLeaderboardTitle(
        setRestockTitle,
        result.length,
        reportParams.allTeams,
      )
    }, [result.length, reportParams.reportLevel])

    return {
      data: result
        .map(res => ({
          category: companyUsers?.[res.refId].name || res.refId,
          value: res.count!,
        }))
        .sort((a, b) => {
          if (orderBy[0][0] === '-') {
            return a.value > b.value ? 1 : -1
          } else {
            return a.value < b.value ? 1 : -1
          }
        }),
      isEmpty: !result.length,
      isLoading: loading,
    }
  }

  return [
    {
      reportName: 'restocks',
      useGetData: reportParams =>
        useGetTopNBottomNTotalRestocks(reportParams, ['-count']),
      ...topNChartProps('restockTitle'),
      title: 'Number of Restocks',
      chartId: 'top-10-total-restocks-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Total Restocks',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'restocks',
      useGetData: reportParams =>
        useGetTopNBottomNTotalRestocks(reportParams, ['count']),
      ...bottomNChartProps('restockTitle'),
      title: 'Number of Restocks',
      chartId: 'bottom-10-total-restocks-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Total Restocks',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'product-counted',
      useGetData: reportParams =>
        useGetTopNBottomNProductCounted(reportParams, ['-count']),
      ...topNChartProps('productCountedTitle'),
      title: 'Product Counted',
      chartId: 'top-10-product-counted-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Products Counted',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
    {
      reportName: 'product-counted',
      useGetData: reportParams =>
        useGetTopNBottomNProductCounted(reportParams, ['count']),
      ...bottomNChartProps('productCountedTitle'),
      title: 'Product Counted',
      chartId: 'bottom-10-product-counted-bar-chart',
      tooltip: [
        ...tooltipBase,
        {
          text: 'Products Counted',
          color: MuiTheme.palette.secondary.light,
        },
      ],
    },
  ]
}
