/**
 * Copyright © 2021-2022 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import { AddUserInput } from '@dai/graphql'
import { Save } from '@mui/icons-material'
import {
  MuiFormModal,
  MuiForm,
  MuiFormModalProps,
  useMuiForm,
} from '@dai/web-components'
import { useCreateUserForm } from 'users/hooks/use-create-user.form'

interface CreateUserFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  loading?: boolean
  onSubmit: (storeInput: AddUserInput) => void
}

export const CreateUserModal: React.FC<CreateUserFormProps> = props => {
  const { formState, setFormState } = useCreateUserForm()
  const {
    handle: { handleOnChange },
    data: { canSubmit },
  } = useMuiForm({
    formState,
    setFormState,
  })

  const { isOpen, handleClose, onSubmit } = props

  const handleSubmitPress = () => {
    const addUserInput: AddUserInput = {
      firstName: formState.text['FIRST NAME'].value as string,
      lastName: formState.text['LAST NAME'].value as string,
      email: formState.text.EMAIL.value as string,
      groups: formState.select.GROUPS.value as string[],
    }
    onSubmit(addUserInput)
  }

  return (
    <MuiFormModal
      title="Add User"
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[
        {
          onClick: handleSubmitPress,
          children: 'Save User',
          startIcon: <Save color="secondary" />,
          disabled: !canSubmit,
        },
      ]}
    >
      <MuiForm
        formState={formState}
        handleOnChange={handleOnChange}
        loading={false}
      />
    </MuiFormModal>
  )
}
