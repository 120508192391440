"use strict";
/**
 * Copyright © 2021-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetCompanyStore = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useGetCompanyStore = function (storeId) {
    var _a = (0, client_1.useQuery)(graphql_1.GET_STORE, { variables: { storeId: storeId } }), data = _a.data, loading = _a.loading, error = _a.error, rest = __rest(_a, ["data", "loading", "error"]);
    var store = data === null || data === void 0 ? void 0 : data.store;
    return __assign({ store: store, loading: loading, error: error }, rest);
};
exports.useGetCompanyStore = useGetCompanyStore;
