"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DRAW_MAP_TASK_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var UserFragment_1 = require("./UserFragment");
var PhysicalStoreFragment_1 = require("./PhysicalStoreFragment");
exports.DRAW_MAP_TASK_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment DrawMapTask on IndoorMapDrawTask {\n    id\n    needsScan\n    scannerPaidOn\n    scanVerifiedOn\n    store {\n      ...PhysicalStore\n    }\n    mapper {\n      ...User\n    }\n    scanner {\n      ...User\n    }\n    storeAreas {\n      id\n      name\n      defaultForStoreSize\n    }\n    daiEmployeeVerifier {\n      ...User\n    }\n    mapUploadedOn\n    scannerVerifiedOn\n    daiEmployeeVerifiedOn\n    scanBucketFilePath\n    faroBucketFilePath\n    pdfBucketFilePath\n    comments {\n      comment\n      id\n      user {\n        ...User\n      }\n    }\n  }\n  ", "\n  ", "\n"], ["\n  fragment DrawMapTask on IndoorMapDrawTask {\n    id\n    needsScan\n    scannerPaidOn\n    scanVerifiedOn\n    store {\n      ...PhysicalStore\n    }\n    mapper {\n      ...User\n    }\n    scanner {\n      ...User\n    }\n    storeAreas {\n      id\n      name\n      defaultForStoreSize\n    }\n    daiEmployeeVerifier {\n      ...User\n    }\n    mapUploadedOn\n    scannerVerifiedOn\n    daiEmployeeVerifiedOn\n    scanBucketFilePath\n    faroBucketFilePath\n    pdfBucketFilePath\n    comments {\n      comment\n      id\n      user {\n        ...User\n      }\n    }\n  }\n  ", "\n  ", "\n"])), UserFragment_1.USER_FRAGMENT, PhysicalStoreFragment_1.PHYSICAL_STORE_FRAGMENT);
var templateObject_1;
