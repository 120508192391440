"use strict";
/**
 * Copyright © 2020-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuditQuestions = void 0;
var client_1 = require("@apollo/client");
var graphql_1 = require("@dai/graphql");
var useAuditQuestions = function (props) {
    var storeId = props.storeId;
    var _a = (0, client_1.useQuery)(graphql_1.GET_STORE, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        variables: { storeId: storeId },
    }), storeData = _a.data, storeLoading = _a.loading;
    var _b = (0, client_1.useQuery)(graphql_1.GET_AUDIT_QUESTIONS, {
        fetchPolicy: 'cache-and-network',
    }), data = _b.data, loading = _b.loading;
    var questions = storeData && data
        ? data.auditQuestions.filter(function (question) {
            return question.category === graphql_1.AuditsAuditQuestionCategoryChoices.AL ||
                question.category === storeData.store.category;
        })
        : undefined;
    return { questions: questions, loading: loading || storeLoading };
};
exports.useAuditQuestions = useAuditQuestions;
