/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Mitch Wilson <mitchell.wilson@deliciousai.com>
 */

import { useQuery } from '@apollo/client'
import StoreInspectionHelpers from 'store-inspection/helpers/store-inspection.helpers'
import React from 'react'
import { BugReport, Storefront, CalendarMonth, Flag } from '@mui/icons-material'
import {
  AvatarWithText,
  FillContainerLoading,
  gridAllScreens,
  MuiTheme,
  UserHelpers,
  FILL_GRID_ALL_SCREENS,
  ScreenController,
  GridSections,
  MuiFormModal,
  MuiFormModalProps,
} from '@dai/web-components'
import {
  GetPlanogramFeedbackImageByIdQuery,
  GetPlanogramFeedbackImageByIdQueryVariables,
  GET_PLANOGRAM_FEEDBACK_IMAGE_BY_ID,
} from '@dai/graphql'
import { Grid } from '@mui/material'

interface ViewPlanogramFeedbackFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  planogramSectionId: string
}

export const ViewPlanogramFeedbackModal: React.FC<ViewPlanogramFeedbackFormProps> = props => {
  const { isOpen, handleClose, planogramSectionId } = props
  const { data, loading } = useQuery<
    GetPlanogramFeedbackImageByIdQuery,
    GetPlanogramFeedbackImageByIdQueryVariables
  >(GET_PLANOGRAM_FEEDBACK_IMAGE_BY_ID, {
    variables: {
      input: planogramSectionId,
    },
    fetchPolicy: 'network-only',
  })
  const planogramFeedback = data?.planogramFeedbackImageById
  const reportedBy = planogramFeedback?.flaggedReason?.reasonBy
  const displaySegment = planogramFeedback?.displaySegment

  return (
    <MuiFormModal
      title="Planogram Feedback Details"
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={[]}
    >
      {loading ? (
        <FillContainerLoading />
      ) : (
        <GridSections
          items={{
            'Reported By': {
              gridProps: FILL_GRID_ALL_SCREENS,
              body: (
                <AvatarWithText
                  avatarColor="secondary"
                  avatarVariant="circular"
                  primaryText={`${reportedBy?.firstName} ${reportedBy?.lastName}`}
                  secondaryText={
                    reportedBy
                      ? UserHelpers.formatGroups(reportedBy)
                      : undefined
                  }
                  boldPrimary
                >
                  {reportedBy?.firstName[0]}
                  {reportedBy?.lastName[0]}
                </AvatarWithText>
              ),
            },
            'Feedback Metadata': {
              gridProps: FILL_GRID_ALL_SCREENS,
              body: (
                <>
                  <Grid item {...gridAllScreens(12)}>
                    <AvatarWithText
                      avatarColor="primary"
                      avatarVariant="rounded"
                      primaryText="Reported Reason From User"
                      secondaryText={planogramFeedback?.flaggedReason.reason}
                      boldPrimary
                    >
                      <Flag color="secondary" />
                    </AvatarWithText>
                  </Grid>
                  <Grid item {...gridAllScreens(6)} xs={12}>
                    <AvatarWithText
                      avatarColor="primary"
                      avatarVariant="rounded"
                      primaryText="Date Reported"
                      secondaryText={StoreInspectionHelpers.formatDate(
                        new Date(planogramFeedback?.created),
                      )}
                      boldPrimary
                    >
                      <CalendarMonth color="secondary" />
                    </AvatarWithText>
                  </Grid>
                  <Grid item {...gridAllScreens(6)} xs={12}>
                    <AvatarWithText
                      avatarColor="primary"
                      avatarVariant="rounded"
                      primaryText="Store Location"
                      secondaryText={
                        displaySegment?.planogram?.display?.store?.store?.name
                      }
                      boldPrimary
                    >
                      <Storefront color="secondary" />
                    </AvatarWithText>
                  </Grid>
                  <Grid item {...gridAllScreens(6)} xs={12}>
                    <AvatarWithText
                      avatarColor="primary"
                      avatarVariant="rounded"
                      primaryText="Issue Type(s)"
                      secondaryText={
                        planogramFeedback?.flaggedReason?.issueTypes?.join(
                          ', ',
                        ) || ''
                      }
                      boldPrimary
                    >
                      <BugReport color="secondary" />
                    </AvatarWithText>
                  </Grid>
                </>
              ),
            },
            'Segment Image': {
              gridProps: FILL_GRID_ALL_SCREENS,
              body: (
                <>
                  <ScreenController.LargeScreen>
                    <img
                      style={{ paddingTop: MuiTheme.spacing(1) }}
                      src={planogramFeedback?.url}
                      alt="planogram-img"
                      height={MuiTheme.sizes.img.xl}
                      width={MuiTheme.sizes.img.xl}
                    />
                  </ScreenController.LargeScreen>
                  <ScreenController.Mobile>
                    <img
                      style={{ paddingTop: MuiTheme.spacing(1) }}
                      src={planogramFeedback?.url}
                      alt="planogram-img"
                      height={MuiTheme.sizes.img.lg}
                      width={MuiTheme.sizes.img.lg}
                    />
                  </ScreenController.Mobile>
                </>
              ),
            },
          }}
        />
      )}
    </MuiFormModal>
  )
}
