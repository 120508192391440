"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_FRAGMENT = void 0;
var client_1 = require("@apollo/client");
var ZoneHierarchyGlobalSearchResultFragment_1 = require("./ZoneHierarchyGlobalSearchResultFragment");
exports.USER_FRAGMENT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment User on User {\n    uuid\n    id\n    email\n    name\n    firstName\n    lastName\n    username\n    created\n    isStaff\n    isSuperuser\n    isActive\n    customerUserId\n    companyName\n    mainRole\n    roles\n    dateRemoved\n    supervisor {\n      firstName\n      lastName\n    }\n    stores {\n      id\n    }\n    zone {\n      ...ZoneHierarchyGlobalSearchResult\n    }\n  }\n  ", "\n"], ["\n  fragment User on User {\n    uuid\n    id\n    email\n    name\n    firstName\n    lastName\n    username\n    created\n    isStaff\n    isSuperuser\n    isActive\n    customerUserId\n    companyName\n    mainRole\n    roles\n    dateRemoved\n    supervisor {\n      firstName\n      lastName\n    }\n    stores {\n      id\n    }\n    zone {\n      ...ZoneHierarchyGlobalSearchResult\n    }\n  }\n  ", "\n"])), ZoneHierarchyGlobalSearchResultFragment_1.ZONE_HIERARCHY_GLOBAL_SEARCH_RESULT_FRAGMENT);
var templateObject_1;
