/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import { useMutation } from '@apollo/client'
import {
  DAIGroupEnum,
  UPDATE_INVENTORY_ITEM,
  UpdateInventoryItemMutation,
  UpdateInventoryItemMutationVariables,
} from '@dai/graphql'
import React, { useContext, useState } from 'react'
import UserContext from 'context/UserContext'
import { hasPermission } from '@dai/common'
import {
  DAITable,
  DAITableColumns,
  TableContainerClass,
  MuiFormModal,
  MuiFormModalProps,
  MuiTheme,
} from '@dai/web-components'
import { AuditProduct } from 'store-inspection/store-inspection.types'
import { Save } from '@mui/icons-material'
import { Box, FormControl, TextField } from '@mui/material'

interface DisplayAuditProductsFormProps
  extends Omit<MuiFormModalProps, 'actionButtonsProps' | 'title'> {
  displayTitle: string
  products: AuditProduct[]
}

const productColumns: DAITableColumns<AuditProduct>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: MuiTheme.sizes.table.column.width.md,
  },
  {
    field: 'upc',
    headerName: 'UPC',
    width: MuiTheme.sizes.table.column.width.md,
  },
  {
    field: 'cases',
    headerName: 'Cases',
    width: MuiTheme.sizes.table.column.width.md,
  },
]

export const DisplayAuditProductsModal = (
  props: DisplayAuditProductsFormProps,
) => {
  const { products, displayTitle, isOpen, handleClose } = props

  const [quantityForUpdate, setQuantityForUpdate] = useState(0)
  const [productForUpdate, setProductForUpdate] = useState<AuditProduct | null>(
    null,
  )

  const context = useContext(UserContext)
  const authUser = context.user
  const isRedAdmin = hasPermission(authUser, [DAIGroupEnum.RED_ADMINS])

  const [updateInventoryItem] = useMutation<
    UpdateInventoryItemMutation,
    UpdateInventoryItemMutationVariables
  >(UPDATE_INVENTORY_ITEM)

  const handleProductSelect = (productId: string) => {
    const product = products.find(x => x.id === productId)

    if (!product) {
      setProductForUpdate(null)
      return
    }

    setProductForUpdate(product)
    setQuantityForUpdate(product.quantity)
  }

  const handleProductUpdate = async () => {
    if (productForUpdate && quantityForUpdate !== productForUpdate.quantity) {
      const variables = {
        input: {
          auditInventoryItemId: productForUpdate.id,
          quantity: quantityForUpdate,
        },
      }
      await updateInventoryItem({ variables })
      products.find(
        x => x.id === productForUpdate.id,
      )!.quantity = quantityForUpdate
      setProductForUpdate(null)
    } else {
      setProductForUpdate(null)
    }
  }

  return (
    <MuiFormModal
      sx={{ padding: 0 }}
      title={displayTitle}
      subtitle="Product on display"
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtonsProps={
        productForUpdate && isRedAdmin
          ? [
              {
                children: 'Update',
                startIcon: <Save color="secondary" />,
                onClick: handleProductUpdate,
              },
            ]
          : []
      }
    >
      <Box>
        <DAITable
          rows={products}
          onRowSelect={handleProductSelect}
          columns={productColumns}
          rowCount={products?.length}
          disableSelectAll
          rowsPerPageOptions={[]}
          showCheckbox={false}
          singleSelect
          pageSize={10}
          className={TableContainerClass.MODAL}
        />
        {productForUpdate && isRedAdmin && (
          <FormControl fullWidth variant="filled" sx={{ p: 2 }}>
            <TextField
              label={productForUpdate.name}
              type="number"
              value={quantityForUpdate}
              onChange={event =>
                setQuantityForUpdate(parseInt(event.target.value, 10))
              }
              helperText="Quantity of product on display during audit"
            />
          </FormControl>
        )}
      </Box>
    </MuiFormModal>
  )
}
