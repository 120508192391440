/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Kyle Armstrong <kyle.armstrong@deliciousai.com>
 */

import React from 'react'
import { Stack } from '@mui/material'
import {
  GetDisplayWithStoreData_display,
  MerchandisingState,
} from '@dai/graphql'
import { AvatarWithText, MuiTheme } from '@dai/web-components'
import {
  CalendarMonth,
  DataObject,
  Delete,
  DoorSliding,
  Numbers,
  SensorDoor,
} from '@mui/icons-material'

type DisplayInfoProps = {
  display: GetDisplayWithStoreData_display
}

const DisplayInfo: React.FC<DisplayInfoProps> = (props: DisplayInfoProps) => {
  const { display } = props
  return (
    <Stack spacing={0.5}>
      <AvatarWithText
        primaryText={'Is this display being observed?'}
        secondaryText={
          display.merchandisingState === MerchandisingState.OPTIMIZING
            ? `Yes — ${String(
                display.idealInventory?.upcs.flatMap(segment =>
                  segment.flatMap(shelf => shelf),
                ).length || 0,
              )} max slots`
            : 'Not being observed'
        }
        avatarColor={
          display.merchandisingState === MerchandisingState.OPTIMIZING
            ? MuiTheme.palette.success.main
            : MuiTheme.palette.error.main
        }
      >
        <Numbers color="secondary" />
      </AvatarWithText>
      <AvatarWithText
        primaryText={'Date Created'}
        secondaryText={display.created}
        boldSecondary
      >
        <CalendarMonth color="secondary" />
      </AvatarWithText>
      {display.dateRemoved && (
        <AvatarWithText
          primaryText={'Date Removed'}
          secondaryText={display.dateRemoved}
          boldSecondary
        >
          <Delete color="secondary" />
        </AvatarWithText>
      )}
      <AvatarWithText
        primaryText={'Number of Doors'}
        secondaryText={String(display.numCoolerDoors)}
        boldSecondary
      >
        <DoorSliding color="secondary" />
      </AvatarWithText>
      <AvatarWithText
        primaryText={'Door Handle Placement'}
        secondaryText={display.doorHandlePlacement || 'Unknown'}
        boldSecondary
      >
        <SensorDoor color="secondary" />
      </AvatarWithText>
      <AvatarWithText
        primaryText={'Display ID'}
        secondaryText={display.id}
        boldSecondary
      >
        <DataObject color="secondary" />
      </AvatarWithText>
    </Stack>
  )
}

export default DisplayInfo
