"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SABRE_GET_FLAVORS = void 0;
var client_1 = require("@apollo/client");
var FlavorFragment_1 = require("../fragments/FlavorFragment");
exports.SABRE_GET_FLAVORS = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetFlavors($input: FlavorsInput) {\n    flavors(input: $input) {\n      hasMore\n      count\n      results {\n        ...Flavor\n      }\n    }\n  }\n  ", "\n"], ["\n  query GetFlavors($input: FlavorsInput) {\n    flavors(input: $input) {\n      hasMore\n      count\n      results {\n        ...Flavor\n      }\n    }\n  }\n  ", "\n"])), FlavorFragment_1.SABRE_FLAVOR_FRAGMENT);
var templateObject_1;
