"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GEOOGRAPHIC_HIERARCHY_DRAFT = void 0;
var client_1 = require("@apollo/client");
exports.GEOOGRAPHIC_HIERARCHY_DRAFT = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment GeographicHierarchyDraft on RouteDraft {\n    rar {\n      uuid\n      id\n      email\n      firstName\n      lastName\n      username\n      customerUserId\n      mainRole\n      roles\n    }\n    stores {\n      id\n      name\n      internalStoreId\n      category\n      image {\n        id\n        url\n      }\n      address {\n        ...StoreAddress\n      }\n    }\n    approvedBy {\n      firstName\n      lastName\n    }\n    routeId\n    routeName\n  \n  }\n"], ["\n  fragment GeographicHierarchyDraft on RouteDraft {\n    rar {\n      uuid\n      id\n      email\n      firstName\n      lastName\n      username\n      customerUserId\n      mainRole\n      roles\n    }\n    stores {\n      id\n      name\n      internalStoreId\n      category\n      image {\n        id\n        url\n      }\n      address {\n        ...StoreAddress\n      }\n    }\n    approvedBy {\n      firstName\n      lastName\n    }\n    routeId\n    routeName\n  \n  }\n"])));
var templateObject_1;
