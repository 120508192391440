/**
 * Copyright © 2022 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import {
  AggregateBy,
  AggregationMethod,
  ProductProductivityInput,
  SimpleUserProductivityModelOptions,
  UserProductivityInput,
  UserProductivityModelOptions,
} from '@dai/graphql'
import { UserProductivityReportInput } from 'users/users.types'
import { DateTimeConstants, DateTimeHelpers } from '@dai/common'
import { GenericReportDataType } from 'reporting/admin-reporting.types'
import { UserProductivityReportHelpers } from 'users/helpers/reporting/user-productivity-report.helpers'

export const useUserProductivityQuery = (
  reportParams: UserProductivityReportInput,
) => {
  const isQueryingManager = !!reportParams.managerUuid
  const uuidsToFilterBy = reportParams.userUuids
  const reportLevelUserUuid = reportParams.managerUuid
    ? reportParams.managerUuid
    : reportParams.userUuids[0]

  const getTimeGroupBy = () => {
    const diffDays = DateTimeHelpers.getDiffDays(
      reportParams.startDate,
      reportParams.endDate,
    )
    if (diffDays <= DateTimeConstants.TWO_WEEKS) {
      return AggregateBy.DAY
    } else if (diffDays < DateTimeConstants.THIRTY_DAYS) {
      return AggregateBy.WEEK
    } else return AggregateBy.MONTH
  }

  const getModelGroupBy = (isSummary: boolean) => {
    // Report Summary
    if (isSummary) {
      return uuidsToFilterBy.length && !isQueryingManager ? ['USER'] : ['TEAM']
    }
    // All-Users or All-Teams
    if (reportParams.allUsers || reportParams.allTeams) {
      return reportParams.allUsers ? ['USER'] : ['TEAM']
    }
    // Single User
    if (!isQueryingManager) {
      switch (reportParams.selectedReportTab) {
        case 'overview':
          return ['USER', 'STORE', 'DISPLAY']
        case 'leaderboard':
          throw new Error(
            'We are not showing the leaderboard for an individual user right now.',
          )
        case 'history':
          return ['USER']
      }
    } else {
      // Single Manager and his/her team
      // We group by user since we also will FILTER by user
      return ['USER']
    }
  }

  const mergeTeams = (isSummary: boolean) =>
    !isQueryingManager && (!reportParams.allTeams || isSummary)

  const dates = {
    startDate: reportParams.startDate
      ? DateTimeHelpers.getDateFromStringYearMonthDay(reportParams.startDate)
      : undefined,
    endDate: reportParams.endDate
      ? DateTimeHelpers.getDateFromStringYearMonthDay(reportParams.endDate)
      : undefined,
  }

  const useReportQuery = () => {
    const getProductProductivityQueryInput = (
      aggregationFn: AggregationMethod,
      timeGroupBy: AggregateBy,
      orderBy?: string[],
    ): ProductProductivityInput => {
      const modelGroup = getModelGroupBy(
        false,
      ) as SimpleUserProductivityModelOptions[]
      return {
        modelType: modelGroup[0],
        userTypes: [reportParams.userType],
        userUuids: uuidsToFilterBy,
        reportLevelUserUuid,
        aggregationInput: {
          timeGroupBy,
          aggregationFn,
          orderBy,
          mergeTeams: mergeTeams(false),
        },
        ...dates,
      }
    }

    const getUserProductivityQueryInput = (
      aggregationFn: AggregationMethod,
      timeGroupBy: AggregateBy,
      orderBy?: string[],
    ): UserProductivityInput => {
      const input = getProductProductivityQueryInput(
        aggregationFn,
        // @ts-ignore
        timeGroupBy,
        orderBy,
      )
      const modelGroup = getModelGroupBy(
        false,
      ) as UserProductivityModelOptions[]
      return {
        ...input,
        modelType: modelGroup[0],
        aggregationInput: {
          ...input.aggregationInput!,
          modelGroupBy: modelGroup,
        },
      }
    }

    const formatCompositeIdRow = <
      T extends { compositeId: string | null; compositeName: string | null }
    >(
      row: T,
      renderExtra: (row: T) => GenericReportDataType | GenericReportDataType[],
    ): GenericReportDataType[] => {
      const [_, storeName, displayName] = row.compositeName!.split('/')
      const res = [
        {
          group: row.compositeId!,
          value: storeName,
          category: 'Store Name',
        },
        {
          group: row.compositeId!,
          value: displayName,
          category: 'Display Name',
        },
      ]
      const extra = renderExtra(row)
      if (Array.isArray(extra)) {
        return [...res, ...extra]
      }
      return [...res, extra]
    }

    return {
      getUserProductivityQueryInput,
      getProductProductivityQueryInput,
      formatCompositeIdRow,
    }
  }

  const useSummaryQuery = () => {
    // TODO: revisit when we can group by 30-day periods
    const timeLabel = `From previous ${getTimeGroupBy().toLowerCase()}`

    const formatOverTimeChange = <T>(
      results: T[] | undefined,
      dataKey: keyof T,
    ) => {
      const _default = {
        isBadChange: true,
        value: ``,
        timeLabel: 'No Data',
      }
      if (results && results.length > 1) {
        const dataPointA = results[0][dataKey]
        const dataPointB = results[1][dataKey]
        if (typeof dataPointA !== 'number' || typeof dataPointB !== 'number') {
          return _default
        }
        const change = (dataPointA - dataPointB) / dataPointB
        const isBad = change < 0
        return {
          isBadChange: isBad,
          value: `${isBad ? '' : '+'}${UserProductivityReportHelpers.formatPct(
            change,
          )}`,
          timeLabel,
        }
      }
      return _default
    }
    const formatStatCardStatistic = (
      value: number | undefined,
      pct?: boolean,
    ) =>
      value
        ? pct
          ? UserProductivityReportHelpers.formatPct(value)
          : value.toLocaleString('en-us')
        : 'Data Unavailable'

    const getProductProductivityQueryInput = (
      aggregationFn: AggregationMethod,
      measuringChange: boolean,
    ): ProductProductivityInput => {
      // TODO: get modelType from reportParams
      const isAllTime =
        !measuringChange && reportParams.summaryTimeFrame === 'All-Time'
      const timeGroupBy = isAllTime ? AggregateBy.ALL_TIME : getTimeGroupBy()
      const orderBy = isAllTime
        ? undefined
        : [`-${getTimeGroupBy().toLowerCase()}`]
      const modelGroup = getModelGroupBy(
        true,
      ) as SimpleUserProductivityModelOptions[]
      return {
        modelType: modelGroup[0],
        userUuids: uuidsToFilterBy,
        userTypes: [reportParams.userType],
        reportLevelUserUuid,
        aggregationInput: {
          timeGroupBy,
          aggregationFn,
          orderBy,
          mergeTeams: mergeTeams(true),
        },
        ...dates,
      }
    }

    const getUserProductivityQueryInput = (
      aggregationFn: AggregationMethod,
      measuringChange: boolean,
    ): UserProductivityInput => {
      const input = getProductProductivityQueryInput(
        aggregationFn,
        measuringChange,
      )
      const modelGroup = getModelGroupBy(true) as UserProductivityModelOptions[]
      return {
        ...input,
        modelType: modelGroup[0],
        aggregationInput: {
          ...input.aggregationInput!,
          modelGroupBy: modelGroup,
        },
      }
    }

    return {
      getUserProductivityQueryInput,
      getProductProductivityQueryInput,
      formatStatCardStatistic,
      formatOverTimeChange,
    }
  }

  return { useSummaryQuery, useReportQuery }
}
