/**
 * Copyright © 2022-2023 Delicious AI, LLC
 *
 * @author Stockton Jenkins <stockton.jenkins@deliciousai.com>
 */

import React from 'react'
import {
  PageTitle,
  DAITable,
  FullScreenLayout,
  SearchBar,
  MuiFormModal,
  Filter,
} from '@dai/web-components'
import { AuditInfoModal } from 'store-inspection/components/modal/AuditInfo.modal'
import ProtectedRoute from 'routes/ProtectedRoute'
import { Switch, useRouteMatch } from 'react-router-dom'
import { Preview, Delete, Close } from '@mui/icons-material'
import { useAuditsLogic } from 'store-inspection/containers/Audits.logic'
import { Typography } from '@mui/material'
import AuditSummaryContainer from './AuditSummary.container'

const AuditsContainer: React.FC = () => {
  const match = useRouteMatch()

  const {
    state: {
      selectedIds,
      setSelectedIds,
      setAuditSearch,
      showConfirmDeleteAuditsModal,
      setShowConfirmDeleteAuditsModal,
      showAuditInfo,
      setShowAuditInfo,
      FilterProps,
    },
    handle: { handleDeletePressConfirm, handlePageChange },
    ref: { tableRef },
    data: { isRedAdmin, auditRows, columns, count, loading, PAGE_SIZE },
  } = useAuditsLogic()

  return (
    <Switch>
      <ProtectedRoute path={`${match.path}/summary/:auditId`}>
        <AuditSummaryContainer />
      </ProtectedRoute>
      <ProtectedRoute path="/">
        <FullScreenLayout
          header={<PageTitle title="Audits" />}
          bodyStyle={{ p: 0 }}
          body={
            <DAITable
              ref={tableRef}
              tableToolBarProps={{
                tableTitle: 'Submitted RED Surveys',
                right: {
                  others: [
                    <SearchBar
                      useResponsive
                      placeholder="Search Audits"
                      onChange={setAuditSearch}
                    />,
                    <Filter filterTitle="Filter Audits" {...FilterProps} />,
                  ],
                },
              }}
              rows={auditRows}
              columns={columns}
              pageSize={PAGE_SIZE}
              rowCount={count}
              onPageChange={handlePageChange}
              loading={loading}
              onSelectionChange={setSelectedIds}
              bulkActions={[
                {
                  condition: true,
                  onClick: () => setShowAuditInfo(true),
                  label: 'Info',
                  icon: <Preview color="secondary" />,
                },
                {
                  condition: isRedAdmin,
                  onClick: () => setShowConfirmDeleteAuditsModal(true),
                  label: 'Delete',
                  icon: <Delete color="secondary" />,
                },
              ]}
              singleSelect
            />
          }
        />
        <MuiFormModal
          title="Delete Audits"
          subtitle="Are you sure?"
          handleClose={() => setShowConfirmDeleteAuditsModal(false)}
          actionButtonsProps={[
            {
              children: 'Cancel',
              color: 'secondary',
              onClick: () => setShowConfirmDeleteAuditsModal(false),
              startIcon: <Close color="secondary" />,
            },
            {
              children: 'Delete',
              onClick: handleDeletePressConfirm,
              startIcon: <Delete color="secondary" />,
            },
          ]}
          isOpen={showConfirmDeleteAuditsModal}
        >
          <Typography variant="h6">
            {`Are you sure you want to delete ${selectedIds.length} audit(s)?`}
          </Typography>
        </MuiFormModal>
        {selectedIds.length && (
          <AuditInfoModal
            isOpen={showAuditInfo}
            auditId={selectedIds[0]}
            handleClose={() => setShowAuditInfo(false)}
          />
        )}
      </ProtectedRoute>
    </Switch>
  )
}

export default AuditsContainer
